

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import IncorrectOwnerContent from '../appeal-content/incorrect-owner-content.component';
import DecisionReview from '../decision-review/decision-review.component';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '20px',
    backgroundColor: "whitesmoke"
  },
}));

const IncorrectOwnerAppealReview = ({ title, appealData, setAppealData, ...decisionReviewProps }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} align="center" className={classes.title}>
        <Typography variant="h5" gutterBottom color='primary'>{title}</Typography>
      </Grid>
      <IncorrectOwnerContent appealData={appealData} setAppealData={setAppealData}/>
      <DecisionReview {...decisionReviewProps} />
    </Grid>
  )
}

export default IncorrectOwnerAppealReview;