import React, { useState, useEffect, Fragment } from 'react';

/* MUI */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, TextField, Button, DialogContentText, Typography } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createNewAltAddress } from '../../../services/APIClient';

const CreateAlternateAddressDialog = (props => {
  const [premiseID, setPremiseID] = useState('');
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [address4, setAddress4] = useState("");
  const [alternateAddress, setAlternateAddress] = useState("");
  const [assessorBlock, setAssessorBlock] = useState("");
  const [assessorLot, setAssessorLot] = useState("");
  const [mapBlockLot, setMapBlockLot] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [alert, setAlert] = useState('')
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [endDate, setEndDate] = useState(new Date(2021, 11, 30));

  useEffect(() => {
    setAddress(props.address);
  }, [props])

  const submit = () => {
    const data = {
      address: address,
      alternateAddress: alternateAddress,
      assessorBlock: assessorBlock,
      assessorLot: assessorLot,
      mapBlockLot: mapBlockLot,
      startDate: startDate,
      premiseID: premiseID,
      address2: address2,
      address3: address3,
      address4: address4,
    }
    setAlert('Updating the alternate address...');
    setAlertOpen(true);
    setLoading(true);
    createNewAltAddress(data).then((response) => {
      setLoading(false);
      setAlert('Alternate address has been updated. Please submit the search again to see the updated record.');
      handleCloseSubmit();
    })
      .catch((error) => {
        setLoading(false);
        const errorRes = error.response
        if (errorRes.status === 500) {
          setAlert("This address has multiple assessor blklot attached to it. Please contact SDMS to update.")
        } else if (errorRes.status === 400) {
          setAlert('Please Enter the Required Field')
        } else if (errorRes.status === 404) {
          setAlert("This address is not a part of alternate address. Please contact SDMS to add new alternate address.")
        } else {
          setAlert("An unknown issue has occurred.")
        }
      })
  }

  const handleCloseSubmit = () => {
    props.handleClose();
    setAddress2('');
    setAddress3('');
    setAddress4('');
    setPremiseID('');
    setAssessorBlock('');
    setAssessorLot('');
    setAlternateAddress('');
    setMapBlockLot('');
  }

  const handleErrorClose = () => {
    setAlertOpen(false);
  }

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update an Alternate Address</DialogTitle>
        <DialogContent>
          <DialogContentText>Please check whether all addresses field are entered correctly.</DialogContentText>
          <Grid container >
            <Grid item xs={12}>
              <TextField margin="dense" id="premiseID" label="Premise ID" value={premiseID} onChange={(e) => setPremiseID(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField margin="dense" autoFocus id="address" label="Address" required error={address === ''} value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField margin="dense" id="address2" label="Address2" value={address2} onChange={(e) => setAddress2(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField margin="dense" id="address3" label="Address3" value={address3} onChange={(e) => setAddress3(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField margin="dense" id="address4" label="Address4" value={address4} onChange={(e) => setAddress4(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField margin="dense" id="alternateAddress" label="Alternate Address" value={alternateAddress} onChange={(e) => setAlternateAddress(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextField margin="dense" id="assessorBlock" label="Assessor Block" value={assessorBlock} required error={assessorBlock === ''} onChange={(e) => setAssessorBlock(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextField margin="dense" id="assessorLot" label="Assessor Lot" value={assessorLot} required error={assessorLot === ''} onChange={(e) => setAssessorLot(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextField margin="dense" id="mapBlockLot" label="Map Block Lot" value={mapBlockLot} required error={mapBlockLot === ''} onChange={(e) => setMapBlockLot(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12} align="center">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker disableToolbar inputVariant="outlined" format="MM/dd/yyyy" margin="normal" id="date-picker-inline" label="Start Date" KeyboardButtonProps={{ 'aria-label': 'change date', }} required fullWidth
                  value={startDate} onChange={(date) => {
                    setStartDate(date) }} />
              </MuiPickersUtilsProvider>
            </Grid>
            {/* <Grid item xs={6} align="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar inputVariant="outlined" format="MM/dd/yyyy" margin="normal" id="date-picker-inline" label="End Date" KeyboardButtonProps={{ 'aria-label': 'change date', }} required fullWidth
                value={endDate} onChange={(date) => { setEndDate(date) }} />
            </MuiPickersUtilsProvider>
          </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSubmit} color="primary">
            Cancel
          </Button>
          <Button onClick={submit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={alertOpen}>
        <DialogContent>
          <Typography>{alert}</Typography>
        </DialogContent>
        <DialogActions>
          {!loading &&
            <Button onClick={handleErrorClose} color="primary">
              Ok
            </Button>}
        </DialogActions>
      </Dialog>
    </Fragment>
  )
})

export default CreateAlternateAddressDialog;