import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

// Services
import { useAppeals, assignReviewer } from "../../services/APIClient";

/* Langan Components */
import DashbaordNavigation from '../../components/dashboard/dashboard-navigation/dashboard-navigation.component';
import PendingAppeals from '../../components/dashboard/PendingAppeals';
import UnassignedAppeals from '../../components/dashboard/UnassignedAppeals';

import { makeStyles } from '@material-ui/core/styles';
import CreditApplications from '../../components/dashboard/CreditApplications';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 200px)",
    backgroundColor: theme.palette.background.paper,
    bottom: 0,
  },
}));

const DashboardRouter = (props) => {
  const classes = useStyles();
  const { isLoading, isError, data, error } = useAppeals();
  const [pendingAppealData, setPendingAppealData] = useState([]);
  const [unassignedAppealData, setUnassignedAppealData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const pendingFormattedAppeal = data
      .filter(appeal => appeal !== null)
      .map(appeal => {
          const row = {
            id: appeal.customer_appeal_id,
            masterId: appeal.masterId,
            customerId: appeal.customer_appeal_id,
            appealType: formatAppealType(appeal.appeal_type),
            appealTypeID: appeal.appeal_type_id,
            ccbAccount: appeal.ccb_account,
            zipcode: appeal.zipcode,
            appealDate: appeal.date_of_appeal,
            nextBillDate: appeal.nextBillDate,
            reviewerUsername: appeal.reviewer,
            review: getReviewURL(appeal),
          }
          return row
      })
      setPendingAppealData(pendingFormattedAppeal);

      const unassignedFormattedAppeal = [];
      data.forEach(appeal => {
        if (appeal !== null) {
          if (!appeal?.reviewer || !appeal?.reviewer.trim()) {
            const row = {
              id: appeal.customer_appeal_id,
              masterId: appeal.masterId,
              customerId: appeal.customer_appeal_id,
              appealType: formatAppealType(appeal.appeal_type),
              appealTypeID: appeal.appeal_type_id,
              ccbAccount: appeal.ccb_account,
              zipcode: appeal.zipcode,
              appealDate: appeal.date_of_appeal,
              nextBillDate: appeal.nextBillDate,
              applicant: appeal.applicant_name,
              reviewerUsername: "",
            }
            unassignedFormattedAppeal.push(row);
          }
        }
      })
      setUnassignedAppealData(unassignedFormattedAppeal);
    }
  }, [data])

  const formatAppealType = (type) => {
    switch (type) {
      case 'LegalOwner':
        return 'Legal Owner';
      case 'ImpermeableArea':
        return 'Impermeable Area';
      case 'ExcludedArea':
        return 'Excluded Area';
      case 'ChargeAllocations':
        return 'Charge Allocations';
      default:
        return '';
    }
  };

  const getReviewURL = (data) => {
    switch (data.appeal_type) {
      case 'LegalOwner':
        return `/appeals/review/incorrectowner/${data.customer_appeal_id}`;
      case 'ImpermeableArea':
        return `/appeals/review/impermeablearea/${data.customer_appeal_id}`;
      case 'ExcludedArea':
        return `/appeals/review/excludedarea/${data.customer_appeal_id}`;
      case 'ChargeAllocations':
        return `/appeals/review/serviceagreement/${data.customer_appeal_id}`;
      default:
        return null;
    }
  };

  const handleSubmitReviewer = (params) => {
    let updatedUnassignedAppealData = [];
    const data = {
      customer_appeal_id: params.row.customerId,
      masterId: params.row.masterId,
      //appeal_type: params.row.appealType,
      appeal_type: params.row.appealType.replaceAll(' ', ''),
      appeal_type_id: params.row.appealTypeID,
      ccb_account: params.row.ccbAccount,
      zipcode: params.row.zipcode,
      date_of_appeal: params.row.appealDate,
      nextBillDate: params.row.nextBillDate,
      applicant_name: params.row.applicant,
      reviewer: params.row.reviewerUsername.trim(),
    }

    // update unassigned appeal data
    updatedUnassignedAppealData = unassignedAppealData.filter(appeal => appeal.id !== data.customer_appeal_id);
    setUnassignedAppealData(updatedUnassignedAppealData);
    assignReviewer(params.row.customerId, data)
      .then(result => {
        setPendingAppealData(pendingAppealData.map(appeal => ((appeal.id === data.customer_appeal_id) ? { ...appeal, reviewerUsername: data.reviewer } : { ...appeal })));
      })
  };

  return (
    <main className={classes.root}>
      {props &&
        <Routes>
          <Route element={<DashbaordNavigation role={props.role} notif={unassignedAppealData.length === 0} />}>
            {(props.role.admin)
              ? <Route index element={<PendingAppeals data={pendingAppealData} />} />
              : (props.role.business)
                ? <Route index element={<PendingAppeals data={pendingAppealData} />} />
                : (props.role.credit) && <Route index element={<CreditApplications />} />}
            <Route path='pending' element={<PendingAppeals data={pendingAppealData} />} />
            <Route path='unassigned' element={<UnassignedAppeals data={unassignedAppealData} assignReview={handleSubmitReviewer} />} />
            <Route path='credits' element={<CreditApplications />} />
          </Route>
        </Routes>}
    </main>
  )
}

export default DashboardRouter;