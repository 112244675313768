const openParcelAction = {
    title: "View",
    id: "view-parcel",
}

const parcelPopupTemplate = {
    // autocasts as new PopupTemplate()
    title: "Parcel Block Lot: {mapblklot}",
    // outFields: ["*"],
    // content: generateContent,
    content: [{
        type: "fields",
        fieldInfos: [
            {
                fieldName: "mapblklot",
                label: "Map Block Lot"
            },
            {
                fieldName: "zoning_dis",
                label: "Zoning"
            },
            // {
            //     fieldName: "Permeable",
            //     label: "Permeable Area",
            //     format: {
            //         digitSeparator: true,
            //         places: 0
            //     }
            // },
        ]
    }],
    actions: [openParcelAction]
};

// function generateContent(feature) {
//     var div = document.createElement("div");
//     div.innerHTML =
//         "Address: " + feature.graphic.attributes.to_address + " " + feature.graphic.attributes.street_nam + " " + feature.graphic.attributes.street_typ;
//     return div;
// }

export default parcelPopupTemplate;
