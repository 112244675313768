import React from "react";
import { useParams } from "react-router-dom";

//MobX
import { observer } from "mobx-react";

import ReviewIncorrectOwnerAppeal from '../components/appeal/review-form/appeal-review/incorrect-owner-appeal-review/incorrect-owner-appeal-review.page';
import ReviewImpermeableAreaAppeal from '../components/appeal/review-form/appeal-review/impermeable-area-appeal-review/impermeable-area-appeal-review.page'
import ReviewExcludedAreaAppeal from '../components/appeal/review-form/appeal-review/excluded-area-appeal-review/excluded-area-appeal-review.page';
import ReviewSAChargeAppeal from '../components/appeal/review-form/appeal-review/charge-allocations-appeal-review/charge-allocations-appeal-review.page';

const AppealReviewPage = observer(props => {
  const { type, appealid } = useParams();

  return (
    <div>
      {type === "incorrectowner" &&
        <ReviewIncorrectOwnerAppeal appealid={appealid} role={props.store.role} user={props.store.user}/>
      }
      {type === "impermeablearea" &&
        <ReviewImpermeableAreaAppeal appealid={appealid} role={props.store.role} user={props.store.user}/>
      }
      {type === "excludedarea" &&
        <ReviewExcludedAreaAppeal appealid={appealid} role={props.store.role} user={props.store.user}/>
      }
      {type === "serviceagreement" &&
        <ReviewSAChargeAppeal appealid={appealid} role={props.store.role} user={props.store.user}/>
      }
    </div>
  )
})

export default AppealReviewPage;