import { Fragment } from "react";

/* MUI */
import { Grid, Typography, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';


const FormRadio = ({ value, label, ...otherProps }) => {

  return (
    <FormControlLabel
      value={value}
      label={label}
      control={
        <Radio
          color="primary"
          {...otherProps}
        />}
    />
  )
}

const FormInputRadio = ({ label, value, onChange, ariaLabel, options, labelSize = 4, RadioSize = 7, error='',...otherProps }) => {

  return (
    <Fragment>
      <Grid item xs={labelSize}>
        <Typography variant="h6">{label}</Typography>
      </Grid>

      <Grid item xs={RadioSize} align="left">
        <RadioGroup aria-label={ariaLabel} value={value} onChange={onChange} row>
          {options.length > 0 && options.map((option, index) => {
            return (
              <FormRadio {...option} {...otherProps} key={index} />
            )
          })}
        </RadioGroup>
        {error && <Typography variant="body1" color="error">{error}</Typography>}
      </Grid>
    </Fragment>
  )
}

export default FormInputRadio;