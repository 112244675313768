import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';

/* MUI */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Button, Typography } from '@material-ui/core';
import { useCredits } from '../../../stores/CreditStore/CreditModuleStore';

const CreditSignIn = observer(props => {
  let navigate = useNavigate();
  const credits = useCredits();
  const [message, setMessage] = useState("");

  const updateAccount = (event) => {
    props.store.setItem("ccbAccount", event.target.value)
    setMessage("");
  };

  const updateZipcode = (event) => {
    props.store.setItem("zipcode", event.target.value)
    setMessage("");
  };


  const validateAccount = () => {
    // //by pass api call
    // credits.resetCredits();
    // credits.getSubmittedForm({ CCB_Account: props.store.ccbAccount, Zipcode: props.store.zipcode }).then((response) => {
    //   credits.setItem('isSubmitted', true);
    //   navigate(`/credit/confirmation/${response.credit_Application_ID}`);
    // }).catch(() => {
    //   //console.log(getSnapshot(credits));
    //   navigate(`/credit/${props.store.ccbAccount}/${props.store.zipcode}`);
    // });

    // validate account with api
    credits.resetCredits();
    credits.getSubmittedForm({ CCB_Account: props.store.ccbAccount, Zipcode: props.store.zipcode }).then((response) => {
      credits.setItem('isSubmitted', true);
      navigate(`/credit/confirmation/${response.credit_Application_ID}`);
    }).catch(() => {
      //console.log(getSnapshot(credits));
      props.store.getAccountStore().then((response) => {
        if (response === 200) {
          if (props.store.accountStore.stormwaterChargeType !== 'EXEMPT') {
            navigate(`/credit/${props.store.ccbAccount}/${props.store.zipcode}`);
          } else {
            setMessage("Account is exempt from Stormwater Charge");
          }
        } else {
          console.log(response);
          setMessage("Account Number or Zip Code is invalid");
        }
      });
    });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Sign In</DialogTitle>
      <DialogContent>
        <DialogContentText style={{color: '#0A0A0A'}}>
          Enter Account Number and ZIP Code to file credit application.
        </DialogContentText>
        <TextField autoFocus margin="dense" id="acc_no" label="Account Number" type="search" value={props.store.ccbAccount} onChange={updateAccount} fullWidth />
        <TextField margin="dense" id="zip_code" label="Service Address ZIP code" type="search" value={props.store.zipcode} onChange={updateZipcode} fullWidth />

        <div>
          <Typography variant="caption" color='error'>{message}</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={validateAccount} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default CreditSignIn;