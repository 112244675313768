import { Fragment } from "react";

/* MUI */
import { Grid, TextField, Typography } from '@material-ui/core';
import { CustomToolTip } from "../data-display/data-display.component";

const FormInputTextField = ({ label, required=false, size=4, toolTip='', ...otherProps }) => {
  return (
    <Fragment>
      <Grid item xs={size}>
        <Typography variant="h6" display='inline'>{label}</Typography>
        {required && <Typography variant="h6" display='inline' color='error'>*</Typography>}
        {toolTip.length > 0 && <CustomToolTip title={toolTip} />}
      </Grid>

      <Grid item xs={12-size} align="center">
        <TextField
        required={required}
        fullWidth
        variant="outlined" {...otherProps} />
      </Grid>
    </Fragment>
  )
}

export default FormInputTextField;