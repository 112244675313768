import { flow, types } from "mobx-state-tree"

// Stores
import { UserStore } from "./UserStore"

// api
import { validateAccount } from "../services/APIClient"
import { AccountStore } from "./AccountStore"

const RoleStore = types.model("Role", {
  admin: false,
  business: false,
  viewer: false,
  user: false,
  credit: false,
  customer: true,  
})
.actions(self => ({
  setItem(item, value) {
    self[item] = value;
  }
}))
;

export const RootStore = types
  .model("Root", {
    name: "Langan",
    user: types.optional(UserStore, () => {
      UserStore.create({
        id: "",
        fullName: "",
        email: "",
        token: "",
        role: "",
        groups: [""]
      })
    }),
    isLoggedIn: false,
    role: types.optional(RoleStore, () => {
      RoleStore.create({
        admin: false,
        business: false,
        viewer: false,
        user: false,
        credit: false,
        customer: true,  
      })
    }),
    done: false,
    ccbAccount: "",
    zipcode: "",
    // accountStore: types.optional(AccountStore, () => AccountStore.create({
    //   mapBlkLot: "0194012",
    //   grossTotalArea: 5145,
    //   permeableArea: 0,
    //   billablePermeableArea: 45,
    //   impermeableArea: 5145,
    //   billableImpermeableArea: 5100,
    //   excludedArea: 0,
    //   isSimplifiedResidential: false,
    //   numberOfServiceAgreement: 0,
    //   stormwaterChargeType: 'SRT1',
    //   assessorBlkLotList: [
    //     {
    //       assessorBlkLot: "0194/012",
    //       legalOwnerName: "SOO YUEN BENEVOLENT ASSN",
    //       propertyAddress: "900 GRANT AVE - 916 GRANT AVE"
    //     }
    //   ],
    //   areaHistory: [
    //     {
    //       grossTotal: 5145.807507,
    //       permeableArea: 11.84581849,
    //       impermeableArea: 5133.177323,
    //       excludedArea: 0,
    //       startDate: "2021-12-28T00:00:00",
    //       endDate: "2021-12-31T00:00:00"
    //     }
    //   ],
    //   accounts: [
    //     {
    //       account: "0491911060"
    //     },
    //     {
    //       account: "0970707319"
    //     },
    //     {
    //       account: "3190800000"
    //     },
    //     {
    //       account: "3391115521"
    //     },
    //     {
    //       account: "6999006933"
    //     },
    //     {
    //       account: "7223910000"
    //     },
    //     {
    //       account: "9999696791"
    //     }
    //   ]
    // })),
    accountStore: types.maybeNull(AccountStore)
  })
  .actions((self) => ({
    afterCreate() {
      self.user.configurePortal();
      // self.user.auth();
      console.log("Create root store");
    },
    updateLogin() {
      self.isLoggedIn = true;
    },
    setItem(item, value) {
      self[item] = value;
    },
    getAccountStore: flow(function* getAccountStore() {
      let param = {
        "accountNumber": self.ccbAccount,
        "zipCode": self.zipcode
      };
      let error = '';
      const response = yield validateAccount(param).catch(e => error = e.message);
      
      if (response.status === 200) {
        self.setItem('accountStore', response.data);
        return 200;
      }
      return error;
    }),
  }
  ))

let _rootStore;
export const useRootStore = () => {
  if (!_rootStore) {
    _rootStore = RootStore.create({});
  }

  return _rootStore;
}