import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
import { observer } from 'mobx-react-lite';

import { getAppealById, getAppealDetail, reviewServiceAgreement, assignReviewer, getFile, postReviewFile } from '../../../../../services/APIClient';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, LinearProgress, Paper, Typography } from '@material-ui/core';

/* Langan Components */
import AppealInfo from '../../appeal-info/appeal-info.component';
import AppealValidation from '../../appeal-validation/appeal-validation.component';
import DocumentsReview from '../../documents-review/documents-review.component';
import ChargeAllocationsAppealReview from './charge-allocations-appeal-review.component';
import ReviewButton from '../../../../form-input/form-button-review.component';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  formRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: "20px",
    marginBottom: theme.spacing(5)
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
    
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

const defaultDocReview = {
  r_Documentary_Proof_Submitted: false,
  r_Additional_Documents: false
};

const defaultDecisionReview = {
  r_Reviewer_Comments: "",
  r_Decision: "inprogress",
  r_Effective_Billing_Date: new Date().toISOString(),
  r_Pending_Doc_Reason: "",
  r_Denied_Reason: "",
  r_Cancelled_Reason: ""
};

const ReviewSAChargeAppeal = observer(props => {
  const classes = useStyles();
  let navigate = useNavigate();

  const [masterData, setMasterData] = useState({});
  const [appealData, setAppealData] = useState({});
  const [initStatus, setInitStatus] = useState(false);
  const [docReview, setDocReview] = useState(defaultDocReview);
  const [mapBlock, setMapBlock] = useState("");
  const [file, setFile] = useState([]);
  const [decisionReview, setDecisionReview] = useState(defaultDecisionReview);
  const [reviewFileName, setReviewFileName] = useState("");
  const [reviewFile, setReviewFile] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [approvedCharge, setApprovedCharge] = useState([]);
  const [status, setStatus] = useState(true);
  const [warning, setWarning] = useState("");

  useEffect(() => {

    getAppealById(props.appealid)
      .then((response) => {
        setMasterData(response);

        //get file data
        getFile(response.masterId, response.appeal_type_id, response.appeal_type)
          .then(response => {
            if (response.status == 200) {
              setFile(response.data);
            }
          })

        getAppealDetail("AppealsSA", response.appeal_type_id)
          .then((response) => {
            setAppealData(response);
            setMapBlock(response.s_Map_Block_Lot_No ? response.s_Map_Block_Lot_No : "");
            if (response?.r_Decision === "rejected" || response?.r_Decision === "cancelled" || response?.r_Decision === "approved") setInitStatus(true);
            setDocReview({
              r_Documentary_Proof_Submitted: response.r_Documentary_Proof_Submitted === "true" ? true : false,
              r_Additional_Documents: response.r_Additional_Documents === "true" ? true : false
            })
            setDecisionReview({
              r_Reviewer_Comments: response?.r_Reviewer_Comments ? response.r_Reviewer_Comments : "",
              r_Decision: response?.r_Decision ? response.r_Decision : "inprogress",
              r_Effective_Billing_Date: response?.r_Effective_Billing_Date ? new Date(response.r_Effective_Billing_Date) : new Date(),
              r_Pending_Doc_Reason: response?.r_Pending_Doc_Reason ? response.r_Pending_Doc_Reason : "",
              r_Denied_Reason: response?.r_Denied_Reason ? response.r_Denied_Reason : "",
              r_Cancelled_Reason: response?.r_Cancelled_Reason ? response.r_Cancelled_Reason : ""
            })
            const account_arr = [];
            for (let step = 0; step < response.accounts.length; step++) {
              account_arr.push({
                f_Appeal_Account_Num: response.accounts[step].f_Appeal_Account_Num,
                r_Approved_Distb_Percent: response.accounts[step]?.r_Approved_Distb_Percent ? response.accounts[step].r_Approved_Distb_Percent : response.accounts[step].f_Appealed_Distb_Percent
              });
            }
            setApprovedCharge(account_arr);
          })
      })
      .catch(error => {
        //console.log(error);
      });
  }, [props.appealid])

  useEffect(() => {
    let sum = 0;
    approvedCharge.forEach((sa) => {
      if (sa.r_Approved_Distb_Percent && parseInt(sa.r_Approved_Distb_Percent, 10) > 0) {
        sum += parseInt(sa.r_Approved_Distb_Percent, 10);
      }
    })

    if (sum === 100) {
      setWarning("");
      setStatus(false);
    } else {
      setWarning("*The charge distribution should add to 100% for all accounts");
      setStatus(true);
    }
  }, [approvedCharge])

  const saHandleChange = (value, index) => {
    const temp = approvedCharge.slice();
    temp[index].r_Approved_Distb_Percent = value;
    setApprovedCharge(temp);
  };

  const docChange = (event) => {
    const { name, checked } = event.target
    setDocReview(prev => {
      return {
        ...prev,
        [name]: checked
      }
    })
  };

  const fileChange = (event) => {
    if (event) {
      setReviewFileName(event.target.files[0].name);
      setReviewFile(event.target.files[0]);
    } else {
      setReviewFileName("");
      setReviewFile("");
    }
  };
  const submitReview = async () => {
    let today = new Date();
    today = today.toISOString();
    setSubmitting(true);
    const review = {
      r_Documentary_Proof_Submitted: docReview.r_Documentary_Proof_Submitted ? "true" : "false",
      r_Additional_Documents: docReview.r_Additional_Documents ? "true" : "false",
      ...decisionReview,
      r_Effective_Billing_Date: decisionReview.r_Effective_Billing_Date.toISOString(),
      r_Status_Change_Date: today, // temp set to today
      r_Review_Submitted_By: masterData.reviewer ? masterData.reviewer : props.user.email,
      accountList: approvedCharge
    };
    if (decisionReview.r_Decision === "pending") {
      review.r_Pending_Doc_Date = today;
    } else if (decisionReview.r_Decision === "cancelled") {
      review.r_Cancelled_Date = today;
    } else if (decisionReview.r_Decision === "rejected") {
      review.r_Denied_Date = today;
    } else if (decisionReview.r_Decision === "approved") {
      review.r_Approved_Date = today;
    }

    const reviewResult = await reviewServiceAgreement(appealData.appeaL_TYPE_ID, review);

    // submit review file
    if (reviewFile !== "") {
      const postFile = new FormData();
      postFile.append("master_id", masterData.masterId);
      postFile.append("appeal_type_id", masterData.appeal_type_id);
      postFile.append("appeal_type", masterData.appeal_type);
      postFile.append("AttachmentFile", reviewFile);
      postFile.append("FileName", reviewFileName);
      const fileResult = await postReviewFile(postFile);
    }

    // assign user
    const user = {
      customer_appeal_id: masterData.customer_appeal_id,
      masterId: masterData.masterId,
      appeal_type: masterData.appeal_type,
      appeal_type_id: masterData.appeal_type_id,
      ccb_account: masterData.ccb_account,
      zipcode: masterData.zipcode,
      date_of_appeal: masterData.date_of_appeal,
      nextBillDate: masterData.nextBillDate,
      reviewer: masterData.reviewer ? masterData.reviewer : props.user.email,
      applicant_name: masterData.applicant_name
    }
    const assignRsult = await assignReviewer(masterData.customer_appeal_id, user)
    setSubmitting(false);
  };

  const handleClick = async (event) => {
    event.preventDefault();
    submitReview()
      .then(result => {
        navigate("/dashboard");
      }).catch((err) => {
        //console.log(err);
        setSubmitting(false);
      });
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent} style={{ backgroundColor: "whitesmoke" }}>
          <Container maxWidth="md">
            <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
              Service Agreement Charge Allocations Appeal Review
            </Typography>
          </Container>
        </div>
        <div >
          <Container maxWidth="md">
            <Paper className={classes.paper}>
              {/* <form autoComplete="off" noValidate className={`${classes.formRoot} ${classes.form}`} onSubmit={mapHandleSubmit}> */}

              <AppealInfo title="Service Agreement Charge Allocations Appeal" masterData={masterData} mapblock={mapBlock} type="2" />
              <AppealValidation masterData={masterData} file={file} />
              {/* <TechnicalReview /> */}
              <DocumentsReview docReview={docReview} docChange={docChange} type="2" role={props.role} initStatus={initStatus} />

              <ChargeAllocationsAppealReview
                title="Service Agreement Charge Allocations Appeal Technical Review" // title="Service Agreement Charge Allocations Appeal Review"
                appealData={appealData}
                approvedCharge={approvedCharge}
                saHandleChange={saHandleChange}
                warning={warning}
                decisionReview={decisionReview}
                decisionReviewOnChange={setDecisionReview}
                file={file}
                fileName={reviewFileName}
                fileChange={fileChange}
                role={props.role}
                initStatus={initStatus}
                masterData={masterData}
              />
              <ReviewButton
                criteria1={(props.role.admin || props.role.business) && !initStatus}
                criteria2={decisionReview.r_Decision === "inprogress"}
                handleClick={handleClick}
                status={submitting || status}
            />
            {submitting && <LinearProgress />}
            </Paper>
          </Container>
        </div>
      </main>
    </React.Fragment>
  );
});

export default ReviewSAChargeAppeal;