import React, { useEffect, useState } from 'react';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { OpenInNew } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: '300px',
  },
  cardContent: {
    flexGrow: 1,
  },
  cardIcon: {
    display: 'block',
    'margin-left': 'auto',
    'margin-right': 'auto',
    'margin-top': '20px',
    fontSize: '90px',
  },
  cardMediaArea: {
    height: '40%',
  },
  cardContentArea: {
    'padding-top': '20px',
  },
  link: theme.link,
  cardText: {
    fontSize: '20px',
  },
  cardTitle: {
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Red Hat Display, sans-serif',
  }
}));

const PortalCard = props => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.cardContentArea}>
          <Typography gutterBottom className={classes.cardTitle} component="h2">
            {props.title}
          </Typography>
          <Typography className={classes.cardText}>
            {props.content}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <CardActions>
          {props.title === 'Calculate Your Charge' ?
            <>
              <Button size="medium" onClick={() => window.open('https://sfpuc.org/accounts-services/about-your-bill/water-and-sewer-bill-calculator', '_blank')}>
                <Typography className={classes.link}>{props.buttonText}</Typography>
              </Button>
              <OpenInNew fontSize='small' />
            </>
            : <Button size="medium" className={classes.link} onClick={props.onClick}>
              <Typography className={classes.link}>{props.buttonText}</Typography>
            </Button>
          }
        </CardActions>
      </CardActions>
    </Card>
  )
};

export default PortalCard;