import { Routes, Route } from 'react-router-dom';
import ParcelPage from '../../pages/ParcelPage';

const ParcelRouter = () => {
  return (
    <Routes>
      <Route path=':id' element={<ParcelPage />} />
    </Routes>
  )
}

export default ParcelRouter;