import { Fragment } from "react";

/* MUI */
import { Grid, Typography } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const FormInputDate = ({ label, size = 4, ...otherProps }) => {
  return (
    <Fragment>
      <Grid item xs={size}>
        <Typography variant="h6" display='inline'>{label}</Typography>
      </Grid>

      <Grid item xs={11 - size} align="center">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="normal"
            KeyboardButtonProps={{ 'aria-label': 'change date', }}
            fullWidth
            {...otherProps}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Fragment>
  )
}

export default FormInputDate;