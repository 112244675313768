import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Alert } from '@material-ui/lab';
import { getMaintenanceWindow } from "../../services/APIClient";

export const AcceptableFileTypes = ["pdf", "jpg", "jpeg"];

export const AcceptableFileTypesString = "image/jpg,.jpeg,.pdf"

export const PrimaryStreetTypes = {
ALLEY: 'ALLEY',
AVENUE: 'AVENUE',
BOULEVARD: 'BOULEVARD',
CIRCLE: 'CIRCLE', 
COURT: 'COURT', 
COURTS: 'COURTS', 
CROSSING: 'CROSSING', 
DRIVE: 'DRIVE', 
HIGHWAY: 'HIGHWAY',
HILL: 'HILL',
LANE: 'LANE',
LOOP: 'LOOP', 
PARK: 'PARK',
PLACE: 'PLACE',
PLAZA: 'PLAZA', 
ROAD: 'ROAD',
ROW: 'ROW', 
STREET: 'STREET',
TERRACE: 'TERRACE',
TUNNEL: 'TUNNEL',
WALK: 'WALK',
WAY: 'WAY',
}

export const validateArea = (totalArea, imperm, perm, excluded) => {
    if (imperm < 0) {
        return false;
    }
    if (perm < 0) {
        return false;
    }
    if (excluded < 0) {
        return false;
    }
    return totalArea === imperm + perm + excluded;
};

export const MaintenanceBanner = () => {
    const location = useLocation();
    const [maintenance, setMaintenance] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        getMaintenanceWindow().then((response) => {
            if (response.status === 204) {
                setMaintenance(false);
                return;
            } else {
                setMaintenance(true);
                setMessage(response.data[0].message);
                return;
            }
        }).catch((error) => {
            console.log(error);
            setMaintenance(false);
            setMessage('');
        });
    }, [location?.pathname]);

    return (
        <>
            {maintenance &&
                <Alert severity="error" variant='filled' style={{ fontSize: '20px' }}>
                    <strong>{message}</strong>
                </Alert>}
        </>

    );

}

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}