/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import ExcludedAreaContent from '../appeal-content/excluded-area-content.component';
import DecisionReview from '../decision-review/decision-review.component';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '20px',
    backgroundColor: "whitesmoke"
  },
}));

const ExcludedAreaAppealReview = ({ title, appealData, appealReview, setAppealReview, role, initStatus, ...decisionReviewProps }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} align="center" className={classes.title}>
        <Typography variant="h5" gutterBottom color='primary'>{title}</Typography>
      </Grid>
      <ExcludedAreaContent
        appealData={appealData}
        appealReview={appealReview}
        setAppealReview={setAppealReview}
        role={role}
        initStatus={initStatus}
      />
      <DecisionReview
        role={role}
        initStatus={initStatus}
        {...decisionReviewProps}
      />
    </Grid>
  )
}

export default ExcludedAreaAppealReview;