import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* MUI */
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

/* Langan custom components */
import EsriMap from "../components/map/EsriMap";
import LegendWidgetCompact from "../components/map/LegendWidgetCompact";
import FeatureLayer from "../components/map/FeatureLayer";
import ParcelPageData from "../components/parcel/ParcelPageData";
import ParcelFooter from "../components/parcel/ParcelFooter"

const useStyles = makeStyles((theme) => ({
  root: theme.root,
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  listItem: {
    backgroundColor: "#D0E1EB",
    borderRadius: "3px",
    marginTop: "2px",
    marginBottom: "2px"
  },
}));

function ParcelPage() {
  const classes = useStyles();
  let params = useParams();
  let navigate = useNavigate();

  return (
    <Fragment>
      <CssBaseline />
      <main className={classes.root}>
        <div className={classes.heroContent}>
          <Grid container >
            <Grid item xs={5}>
              <EsriMap screenSize="chip" itemId={process.env.REACT_APP_WEBMAP_ID} type="static">
                <LegendWidgetCompact />
                <FeatureLayer parcelId={params.id} navigation={false} ></FeatureLayer>
              </EsriMap>
              <Typography variant="body2">
                See a problem with this property? <Link onClick={() => { navigate('/appeals') }} >File an appeal</Link>.
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <ParcelPageData featureLayer={process.env.REACT_APP_SFPUC_PARCEL_FL_URL} parcelId={params.id} />
            </Grid>
          </Grid>
        </div >
        <ParcelFooter />
      </main>
    </Fragment>
  );
}

export default ParcelPage;
