import React, { Fragment } from "react";
import { observer } from 'mobx-react-lite';
import { useNavigate, Outlet } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Typography, Grid, Button } from "@material-ui/core";

import FormInputTypography from "../../form-input/form-input-typography.component";
import FormAttachmentList from "../../form-input/form-attachment-list.component";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: "20px",
    marginBottom: theme.spacing(5)
  },
  title: {
    margin: theme.spacing(2, 0, 2),
    backgroundColor: 'whitesmoke',
  },
  wrapper: {
    textTransform: 'initial'
  },
  button: {
    margin: theme.spacing(2, 0, 2),
  }
}));

const GenericAppealView = observer(props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const manualAppeal = props.view;

  const handleCancelSubmit = () => {
    manualAppeal.reset();
    navigate('../');
  };

  return (
    <Fragment>
      {manualAppeal &&
        <Container maxWidth="md">
          <Paper className={classes.paper}>
            <Grid container className={classes.title} align='center'>
              <Grid item xs={12} >
                <Typography variant="h3" color='primary' >Manual Appeal Information</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} >
              <FormInputTypography align="left" label={'Appeal ID'} name='manual_Appeal_ID' size={6} value={manualAppeal.manual_Appeal_ID} />
              <FormInputTypography align="left" label={'Account Number'} name='Account_No' size={6} value={manualAppeal.account_No} />
              <FormInputTypography align="left" label={'Map Block/lot'} size={6} name='Map_Block_Lot_No' value={manualAppeal.map_Block_Lot_No ? manualAppeal.map_Block_Lot_No : ''} />
              <FormInputTypography align="left" label={"Assessor's Block Lot"} size={6} name='assessors_BlkLot' value={manualAppeal.assessors_BlkLot ? manualAppeal.assessors_BlkLot : ''} />
              <FormInputTypography align="left" label={'Premise ID'} size={6} name='premise_ID' value={manualAppeal.premise_ID} />
              <FormInputTypography align="left" label={'Wastewater SA ID'} size={6} name='wastewater_Sa_ID' value={manualAppeal.wastewater_Sa_ID} />
              <FormInputTypography align="left" label={'Correction Type'} size={6} name='correction_Type' value={manualAppeal.correction_Type} />
              <FormInputTypography align="left" label={'Existing Field'} size={6} name='existing_Field' value={manualAppeal.existing_Field} />
              <FormInputTypography align="left" label={'Corrected Field(s)'} size={6} name='corrected_Fields' value={manualAppeal.corrected_Fields} />
              <FormInputTypography align="left" label={'Appealed Net Area'} size={6} name='F_Appealed_Net_Total_Area' value={manualAppeal.f_Appealed_Net_Total_Area} />
              <FormInputTypography align="left" label={'Location of Documentation'} size={6} name='r_Document_Location' value={manualAppeal.r_Document_Location ? manualAppeal.r_Document_Location : ''} />
              <FormInputTypography align="left" label={'Approved By'} size={6} name='R_Review_Submitted_By' value={manualAppeal.r_Review_Submitted_By ? manualAppeal.r_Review_Submitted_By : ''} />
              <FormInputTypography align="left" label={'Decision'} size={6} name='r_Decision' value={manualAppeal.r_Decision ? manualAppeal.r_Decision : ''} />
              {manualAppeal.r_Decision === 'Approved' &&
                <FormInputTypography align="left" label={'Date Implemented'} size={6} name='r_Date_Implemented' value={manualAppeal.r_Date_Implemented ? manualAppeal.r_Date_Implemented.substring(0, 10) : ''} />
              }
              <FormInputTypography align="left" label={'Decision Date'} size={6} name='r_Decision_Date' value={manualAppeal.r_Decision_Date ? manualAppeal.r_Decision_Date.substring(0, 10) : ''} />

              <Grid item xs={6}>
                <Typography variant="h6" >Documentation</Typography>
              </Grid>
              <Grid item xs={6}>
                {
                  manualAppeal.files &&
                  <FormAttachmentList
                    file={manualAppeal.files.filter(f => f.document_Type === "ManualAppeal")}
                    type='ManualAppeal'
                  />
                }
              </Grid>
              <FormInputTypography align="left" label={'Comments'} size={6} multiline name='F_Applicant_Comments' value={manualAppeal.f_Applicant_Comments ? manualAppeal.f_Applicant_Comments : ''} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button variant='contained' color='primary' className={classes.button} fullWidth onClick={handleCancelSubmit}>
                  Return
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      }
      <Outlet />
    </Fragment>
  )
});

export default GenericAppealView;