import React from 'react';
/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Box, Grid, Link, Divider, List } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { LinkTextDisplay, ListItemLinkDisplay, ListItemTextDisplay } from '../../data-display/data-display.component';

/* Langan Components */


const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  btnSpacing: {
    marginTop: "10px",
  },
  logo: {
    height: '30px',
    width: '30px'
  },
  link: theme.link,
}));

const CreditInfo = (props => {
  const classes = useStyles();

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="md">
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Si necesita ayuda en español llame al (415) 551-3000. <br />
            Kung kailangan ninyo ng tulong sa Filipino mangyaring tumawag sa (415) 551-3000. <br />
            如果您需要中文協助、請致電 (415) 551-3000。 <br />
          </Typography>
        </Grid>
        <Typography variant="h5" align="left" color="textPrimary" style={{ fontWeight: 'bold' }} gutterBottom>
          What is the stormwater credit?
        </Typography>
        <Typography variant="body1" color="inherit">
          The stormwater credit is a reduction on the stormwater charge portion of your bill. SFPUC customers who install green infrastructure, such as rain gardens, permeable pavement, or rain-harvesting cisterns, that reduce stormwater runoff from their property may qualify for the Stormwater Credit Program.
        </Typography>
        <Box m={2} />

        <Grid item xs={12} align="left">
          <Typography variant="body1" color="inherit">
            Standard rate customers, including most large properties and all businesses, may apply for a standard rate stormwater credit. Most residential customers may apply for the simplified residential rate stormwater credit.
          </Typography>
        </Grid>
        <Box m={2} />

        <Typography variant="h5" align="left" color="inherit" style={{ fontWeight: 'bold' }} gutterBottom>
          How to apply?
        </Typography>

        <Typography variant="body1" color="inherit" component='span'>
          To apply, click on the&nbsp;<Link display='inline' color='primary' className={classes.link} onClick={() => { props.setOpen(!props.open) }}>
            Stormwater Credit Application
          </Link>&nbsp;button above. Enter the SFPUC account number and service address ZIP code of the account to be credited. Applying for the stormwater credit is free. For more detailed information on the Stormwater Credit Program and how to apply, refer to the <LinkTextDisplay linkName={'Stormwater Credit Guidebook'} href={'https://www.sfpuc.org/sites/default/files/accounts-and-services/StormwaterCreditGuidebook_JUL2023.pdf'} linkStyle={classes.link} externalLink={true} />.
        </Typography>
        <Box m={2} />

        <Grid item xs={12} align="left">
          <Typography variant="body1" color="inherit">
            You may also contact SFPUC staff at 415-523-4414 or <LinkTextDisplay href={'mailto:stormwatercredits@sfwater.org'} linkStyle={classes.link} linkName={'StormwaterCredits@sfwater.org'} externalLink={true} />.
            You can also apply by downloading an application form from resources below and emailing it to <LinkTextDisplay href={'mailto:stormwatercredits@sfwater.org'} linkStyle={classes.link} linkName={'StormwaterCredits@sfwater.org'} externalLink={true} />.
            You may mail paper credit applications to: Stormwater Credit Program, 11th floor, 525 Golden Gate Avenue, San Francisco, CA 94102. You may also hand deliver paper applications to the <LinkTextDisplay href='https://sfpuc.org/about-us/contact-us' linkName={'Customer Service window'} linkStyle={classes.link} externalLink={true} /> at 525 Golden Gate Avenue, San Francisco, 1st Floor, 8:30am to 4:30pm, Monday to Friday, excluding legal holidays.
          </Typography>
          <Box m={2} />
          <Typography variant="body1" color="inherit">
            Green infrastructure must meet <LinkTextDisplay href='https://sfpuc.org/construction-contracts/design-guidelines-standards/stormwater-requirements' linkName={'SFPUC design standards'} linkStyle={classes.link} externalLink={true} /> to be eligible for the Stormwater Credit.
          </Typography>
        </Grid>

      </Container>
      <Box p={5} bgcolor="background.paper">
        <Divider variant="middle" />
      </Box>
      <Container maxWidth="md">
        <Typography variant="h4" align="left" color="textPrimary" style={{ fontWeight: 'bold' }} gutterBottom>
          Resources
        </Typography>
        <List>
          <ListItemLinkDisplay linkName={'Stormwater Credit Program Rules and Regulations'} href={'https://www.sfpuc.org/sites/default/files/accounts-and-services/Resolution_23-0105_Stormwater-Credit%20Program-Rules-Regulations.pdf'} showInfoIcon={true} linkStyle={classes.link} externalLink={true} fontSize={'1rem'} />
          <ListItemLinkDisplay linkName={'Stormwater Credit Guidebook'} href={'https://www.sfpuc.org/sites/default/files/accounts-and-services/StormwaterCreditGuidebook_JUL2023.pdf'} linkStyle={classes.link} showInfoIcon={true} externalLink={true} fontSize={'1rem'} />
          <ListItemLinkDisplay linkName={'Simplified Residential Rate Stormwater Credit Application'} href={'https://www.sfpuc.org/sites/default/files/accounts-and-services/SCP-Simplified-ResiRate-Credit-App_29JUN23.pdf'} linkStyle={classes.link} showInfoIcon={true} externalLink={true} fontSize={'1rem'} />
          <ListItemLinkDisplay linkName={'Standard Rate Stormwater Credit Application'} href={'https://www.sfpuc.org/sites/default/files/accounts-and-services/SCP-Standard-Rate-Credit-App_29JUN23.pdf'} linkStyle={classes.link} showInfoIcon={true} externalLink={true} fontSize={'1rem'} />
          <ListItemTextDisplay text={'SFPUC has grant programs that can pay for the upfront costs of building your green infrastructure'} icon={<ExpandMore />} />
          <ListItemLinkDisplay linkName={'Green Infrastructure Grant Program'} href={'https://sfpuc.org/programs/grants/green-infrastructure-grant'} linkStyle={classes.link} showInfoIcon={true} externalLink={true} fontSize={'1rem'} paddingLeft='75px' />
          <ListItemLinkDisplay linkName={'Green Infrastructure Grants for Homes'} href={'https://sfpuc.org/programs/grants/green-infrastructure-grants-homes'} linkStyle={classes.link} showInfoIcon={true} externalLink={true} fontSize={'1rem'} paddingLeft='75px' />
          <ListItemLinkDisplay linkName={'SFPUC Bill Calculator'} href={'https://sfpuc.org/accounts-services/about-your-bill/water-and-sewer-bill-calculator'} linkStyle={classes.link} showInfoIcon={true} externalLink={true} fontSize={'1rem'} />
          <ListItemLinkDisplay linkName={'Stormwater Credit Calculator'} href={'https://www.sfpuc.org/sites/default/files/accounts-and-services/Stormwater_Credit_Program-Credit_Planning_%20Calculator_v1.xlsx'} linkStyle={classes.link} showInfoIcon={true} externalLink={true} fontSize={'1rem'} />
        </List>
      </Container>
    </div>
  )
})

export default CreditInfo;