import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/utils/utils';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootEl = document.getElementById('root')


ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_BASE_PATH} >
    <ScrollToTop />
    <App />
  </BrowserRouter>,
  rootEl
);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    ReactDOM.render(
      <BrowserRouter>
        <NextApp />
      </BrowserRouter>,
      rootEl
    )
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
