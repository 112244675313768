import { Routes, Route } from 'react-router-dom';
import AssessorPage from '../../pages/AssessorPage';

const AssessorRouter = (props) => {
  return (
    <Routes>
      <Route path=':id' element={<AssessorPage store={props.store} />} />
    </Routes>
  )
}

export default AssessorRouter;