import { useEffect, useState } from 'react';
/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { observer } from "mobx-react";

import CreditDecisionReview from './review-component/credit-decision-review.component';
import StandardCreditReviewContent from './review-component/standard-content.component';
import SimplifiedCreditReviewContent from './review-component/simplified-content.component';
import CreditAllocationReviewContent from './review-component/allocation-content.component';
import FormAttachmentList from '../../form-input/form-attachment-list.component';
import { FileUploadButton } from '../../form-input/form-file-upload-button.component';
import FormInputTypography from '../../form-input/form-input-typography.component';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '20px',
    backgroundColor: "whitesmoke"
  },
}));

const ReviewInfo = observer((props) => {
  const classes = useStyles();
  const { decision } = props;
  const { credit_Type, creditsDistributionData, creditsDocument } = props.store;
  const [applicant_comments, setApplicant_comments] = useState('');
  
  useEffect(() => {
    if (props.store) {
      if (credit_Type === 'Standard') {
        setApplicant_comments(props.store.creditsStandardType.f_Applicant_Comments);
      } else {
        setApplicant_comments(props.store.creditsSRType.f_Applicant_Comments);
      }
    }
  }, [props.store]);

  return (
    <>
      {props.store &&
        <Grid container spacing={2}>
          <Grid item xs={12} align="center" className={classes.title}>
            <Typography variant="h5" gutterBottom color='primary'>
              {props.rate} Credit Review
            </Typography>
          </Grid>
          {credit_Type === 'Standard' ?
            <StandardCreditReviewContent info={props.store} decision={props.decision}/>
            :
            <SimplifiedCreditReviewContent info={props.store} decision={props.decision}/>
          }

          <Grid item xs={12} align="center" className={classes.title}>
            <Typography variant="h5" gutterBottom color='primary'>
              Credit Allocation Review
            </Typography>
          </Grid>

          {creditsDistributionData &&
            <CreditAllocationReviewContent info={creditsDistributionData} decision={props.decision}/>
          }
          <FormInputTypography
            label="Applicant Comments"
            name="applicant_comments"
            value={applicant_comments ? applicant_comments : "The applicant did not provide any comments."}
            align="left"
          />
          <FormAttachmentList
            label='Reviewer Documents'
            file={creditsDocument.filter(element => element.document_Type === 'Review')}
            type='Review' />
          <Grid item xs={4} />
          <Grid item xs={8} >
            <FileUploadButton
              description='Attach Reviewer Documents'
              fileChange={props.store.handleReviewDocumentChange}
              name=''
              disabled={creditsDocument.filter(element => element.document_Type === 'Review').length > 0 ||
               ["APPROVED", "REJECTED", "CANCELLED"].includes(props.decision)} />
          </Grid>
          <CreditDecisionReview role={props.role} info={props.store} decision={props.decision}/>
        </Grid>
      }
    </>
  )
});

export default ReviewInfo;