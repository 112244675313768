import React, { useState, Fragment, useEffect } from 'react';
import { Typography, Box, Divider, Grid, Link, ListItem, ListItemIcon, Tooltip, ListItemText, ClickAwayListener } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { OpenInNew } from '@material-ui/icons';



//UTILITIES
export const addCommas = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

//DATA DISPLAY
export const SubTitle = ({
  variant = 'h6',
  color = 'textPrimary',
  align = 'left',
  value
}) => {
  return (
    <>
      <Typography variant={variant} color={color} align={align}>
        {value}
      </Typography>
      <Box p={1} />
      <Divider />
    </>
  );
};

//Custom tooltip
const TooltipTitle = (props) => {
  const title = props.title;
  return (
    <Fragment>
      <Typography variant="body1" color="inherit">
        {title}
      </Typography>
    </Fragment>
  )
}

export const CustomToolTip = (props) => {
  const { title } = props;
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        title={<TooltipTitle title={title} />}
        arrow={true}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={open}
        placement="bottom-start">
        <HelpOutlineIcon fontSize="small" color='primary' onClick={() => setOpen(!open)} />
      </Tooltip>
    </ClickAwayListener>
  )
}

const PropertyDetailItem = (props) => {
  const { name, value } = props;
  const [tooltipInfo, setTooltipInfo] = useState([false, '']);

  useEffect(() => {
    switch (name) {
      case 'Map Block Lot':
        setTooltipInfo([true, "Also known as parcel number or Assessor's Parcel Number."]);
        break;
      case 'Net Parcel Area':
        setTooltipInfo([true, "Net parcel area is the gross parcel area recorded with the San Francisco Assessor's Office less any excluded area that does not drain to the SFPUC combined sewer system."]);
        break;
      case 'Billable Impermeable Area':
        setTooltipInfo([true, "Impermeable area is surface area that prevents the land's natural ability to absorb and infiltrate rainfall or stormwater. Impervious area include, but are not limited to; building or structures, rooftops, impervious concrete and asphalt, and any other continuous watertight pavement or covering. This area is rounded down to the nearest 100 square feet. Standard rate customers are billed on this number."]);
        break;
      case 'Billable Permeable Area':
        setTooltipInfo([true, "Permeable area is surface area that allows stormwater to infiltrate into the ground. Examples include pasture, native vegetation areas, landscape areas, and permeable pavement. The measured permeable area plus the remainder of the impermeable area that was rounded down. Standard rate customers are billed on this number."]);
        break;
      case 'Current Gross Total Area':
        setTooltipInfo([true, "This is the total physical area for the parcel."]);
        break;
      case 'Current Excluded Area':
        setTooltipInfo([true, "Areas that does not drain to the SFPUC owned or operated sewers. This area is not subject to the stormwater charge."]);
        break;
      case 'Current Permeable Area':
        setTooltipInfo([true, "Area that allows stormwater to soak into the ground. Examples include lawns, landscaping, and soil."]);
        break;
      case 'Current Impermeable Area':
        setTooltipInfo([true, "Area that does not allow stormwater to soak into the ground. Examples include concrete, asphalt, buildings, and decking."]);
        break;
      case 'Number of Service Agreements':
        setTooltipInfo([true, "The number of customers receiving wastewater service from SFPUC."]);
        break;
      default: setTooltipInfo([false, '']);
    }
  }, [props]);

  return (
    <Fragment>
      <Typography variant="h6" color="textPrimary">
        {name.includes('Area') ? addCommas(value) : value}
      </Typography>
      <Typography variant="body2">
        {name}
        {tooltipInfo[0] &&
          <CustomToolTip title={tooltipInfo[1]} />}
      </Typography>
    </Fragment>
  )
}

export const AreaInformation = ({ data, showTitle = true, size = 4 }) => {

  return (
    <Fragment>
      {showTitle &&
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom color="primary" align="center" paragraph>
            Property Details
          </Typography>
        </Grid>
      }
      {data && Object.entries(data).map(([key, value]) => {
        return (
          <Grid key={key} item xs={size} align="center">
            <PropertyDetailItem name={key} value={value} />
          </Grid>
        )
      })}
    </Fragment>

  );
};

export const ListItemTextDisplay = ({ text, icon }) => {
  return (
    <ListItem >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
};

export const ListItemLinkDisplay = ({ linkName, href, linkStyle, fontSize, showInfoIcon, externalLink, paddingLeft = '' }) => {
  return (
    <ListItem button style={{ paddingLeft: paddingLeft }}>
      {showInfoIcon &&
        <ListItemIcon>
          <Tooltip title="Click to get more information"><InfoIcon /></Tooltip>
        </ListItemIcon>}
      <Link href={href} target='_blank' rel='noreferrer' className={linkStyle} style={{ fontSize: fontSize }}>{linkName}</Link>
      {externalLink && <OpenInNew fontSize='inherit' />}
    </ListItem>
  )
};

export const LinkTextDisplay = ({ linkName, href, linkStyle, fontSize, externalLink }) => {
  return (
    <>
      <Link href={href} target='_blank' rel='noreferrer' className={linkStyle}>
        {linkName}
      </Link>
      {externalLink && <OpenInNew fontSize='inherit' />}
    </>
  )
};