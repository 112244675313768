import { Fragment } from "react";
import { Grid, Typography } from '@material-ui/core';
import { downloadFile, downloadCreditFile, downloadManualAppealFile } from "../../services/APIClient";
import { makeStyles } from '@material-ui/core/styles';
import { OpenInNew } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  link: theme.link
}));

const FormAttachmentList = ({ label, file, size = 4, align = 'left', downloadParam, type }) => {
  const classes = useStyles();

  const handleDownload = (item) => {
    if (type === 'Credit' || type === 'Review') {
      downloadCreditFile({ doc_id: item.id, document_type: type })
        .then((res) => {
          const blob = new Blob([res.data], { type: res.headers['content-type'] });
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
          URL.revokeObjectURL(url);
        })
    }
    else if (type === 'ManualAppeal') {
      downloadManualAppealFile(item.doc_ID, item.document_Type)
        .then((res) => {
          const blob = new Blob([res.data], { type: res.headers['content-type'] });
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
          URL.revokeObjectURL(url);
        })
    } else {
      downloadFile(downloadParam)
        .then((res) => {
          const blob = new Blob([res.data], { type: res.headers['content-type'] });
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
          URL.revokeObjectURL(url);
        })
    }

    return;
  }

  return (
    <Fragment>
      <Grid item xs={size} align="left">
        <Typography variant="h6">{label}</Typography>
      </Grid>
      {
        file.length > 0 ?
          <Grid item xs={12 - size}>
            {
              file.map((item, index) =>
                <Typography key={index} className={classes.link} onClick={() => handleDownload(item)} variant="h6" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  {item.fileName ? item.fileName : ""}
                  {item.fileName ? <OpenInNew fontSize="inherit" /> : ''}
                </Typography>
              )
            }
          </Grid>
          :
          <Grid item xs={12 - size} align={align}>
            <Typography variant="h6">{"No documents submitted."}</Typography>
          </Grid>
      }
    </Fragment>
  )
}

export default FormAttachmentList;