import React, { Fragment, useEffect, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Grid, Checkbox, Typography, LinearProgress } from "@material-ui/core";
import DialogTitle from '@material-ui/core/DialogTitle';
import { removeUser, addUser } from "../../../services/APIClient";
import { useRootStore } from "../../../stores/RootStore";

const AssignmentDialog = (props) => {
  const open = props.open;
  const handleClose = props.handleClose;
  const handleCancel = props.handleCancel;
  const username = props.username;
  const roles = props.roles;
  const groupMapping = props.groupMapping;
  const token = useRootStore().user.token;
  const defaultGroupMapping = props.defaultGroupMapping;
  const [newRoles, setNewRoles] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (roles) {
      setNewRoles(roles);
    }
  }, [props.roles]);

  const handleAlertClose = () => {
    setAlertOpen(false);
    handleClose();
  }


  const processResponse = (res, type) => {
    if (res.status !== 200) {
      setMessage('There was an error processing your request. Please try again later.');
      return 'There was an error processing your request. Please try again later.';
    } else {
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].response[type].length !== 0) {
          setMessage('There was an error processing your request. Please try again later.');
          return 'There was an error processing your request. Please try again later.';
        } else {
          setMessage('');
          return 'Success!';
        }
      }
    }
  }

  const handleCancelChanges = () => {
    setNewRoles(roles);
    handleCancel();
  }

  const handleSubmitChanges = () => {
    setIsLoading(true);
    let removedRoles = roles.filter(role => !newRoles.includes(role)).map(role => groupMapping[role].groupId);
    let addedRoles = newRoles.filter(role => !roles.includes(role)).map(role => groupMapping[role].groupId);
    
    if (removedRoles.length > 0) {
      removeUser(username, token, removedRoles).then(res => {
        let message = processResponse(res, 'notRemoved');
        if (message === 'Success!') {
          ///Todo error handling
          setIsLoading(false);
          setAlertOpen(true);
        } else {
          setIsLoading(false);
          setAlertOpen(true);
        }
      }).catch(err => {
        setMessage('There was an error processing your request. Please try again later.');
        setIsLoading(false);
        setAlertOpen(true);
      });
    }
    if (addedRoles.length > 0) {
      addUser(username, token, addedRoles).then(res => {
        let message = processResponse(res, 'notAdded');
        if (message === 'Success!') {
          setIsLoading(false);
          setAlertOpen(true);
        }
      }).catch(err => {
        setMessage('There was an error processing your request. Please try again later.');
        setIsLoading(false);
        setAlertOpen(true);
      });
    }
  }

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setNewRoles([...newRoles, e.target.name]);
    } else {
      setNewRoles(newRoles.filter(role => role !== e.target.name));
    }
  }

  return (
    <Fragment>
      {(newRoles) &&
        <Dialog open={open}>
          <DialogTitle>Role Assignment for User: <br />
            <Typography variant="h6" color="primary" component="span">{username}</Typography>
          </DialogTitle>
          <DialogContent>
            {
              Object.keys(groupMapping).map((group, idx) => {
                return (
                  <Grid container key={idx} alignItems='center'>
                    <Grid item xs={2}>
                      <Checkbox name={group} color="primary" checked={newRoles.includes(group)} onChange={handleCheckboxChange} />
                    </Grid>
                    <Grid item xs={10} >
                      {group}
                    </Grid>
                  </Grid>
                )
              })}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelChanges} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitChanges} color="primary" disabled={isLoading}>
              Submit
            </Button>

          </DialogActions>
          {isLoading && <LinearProgress />}
        </Dialog>
      }
      <Dialog open={alertOpen}>
        <DialogTitle>Information: </DialogTitle>
        <DialogContent>
          <Typography>{message ? message : 'The roles are updated successfully.'}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AssignmentDialog;