import { useState, useEffect } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Grid, TextField, Typography, Divider, Box, Paper } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AreaInformation, SubTitle } from '../../../data-display/data-display.component';


const SimplifiedCreditReviewContent = observer((props) => {
  const [warning, setWarning] = useState("");
  const decision = props.decision;
  const { s_Current_Impermeable_Area, s_Current_Permeable_Area, creditsSRtypeDetail, creditsSRType } = props.info
  const [roof_credit, setRoofCredit] = useState(0);
  const [patio_credit, setPatioCredit] = useState(0);
  const [total_credit_amount, setTotalCreditAmount] = useState(0);

  useEffect(() => {
    let roofTmp = 0;
    let patioTmp = 0;

    if (creditsSRtypeDetail && creditsSRtypeDetail.length > 0) {
      creditsSRtypeDetail.map(element => {
        if (element.source === "Roof") {
          roofTmp = element.f_Credit_Amount;
          setRoofCredit(element.f_Credit_Amount);
        } else if (element.source === "Patio") {
          patioTmp = element.f_Credit_Amount;
          setPatioCredit(element.f_Credit_Amount);
        }
      })
      setTotalCreditAmount(parseFloat((roofTmp + patioTmp).toFixed(2)));
    }
  }, [creditsSRtypeDetail])

  const handleChange = (event, idx) => {
    const { name, value } = event.target;
    let tmp = creditsSRtypeDetail.slice();
    if (name === 'r_Approved_Area') {
      tmp[idx].setItem(name, parseFloat(value) ? parseFloat(value) : 0);
    } else {
      tmp[idx].setItem(name, value);
    }
    props.info.setItem('creditsSRtypeDetail', tmp);
    props.info.calculateCreditAmount(0);
    props.info.updateCreditAllocation();
  }


  return (
    <>
      <Grid container spacing={2}>
        <AreaInformation
          data={{
            "Net Total Area": s_Current_Impermeable_Area + s_Current_Permeable_Area,
            "Billable Impermeable Area": s_Current_Impermeable_Area,
            "Billable Permeable Area": s_Current_Permeable_Area
          }}
        />

        <Divider />

        {creditsSRtypeDetail && creditsSRtypeDetail.length > 0 && creditsSRtypeDetail.map((element, index) =>
        (<Grid item xs={12} key={index} align='center'>
          <Box p={2}>
            <Paper elevation={2}>
              {<Typography variant='h6' color="inherit" align='left'>Green Infrastructure Type Review</Typography>}
              <Grid container spacing={2}>
                <Grid item xs={3} align="center">
                  <TextField
                    name="credit_source"
                    variant="filled"
                    label="Submitted Source"
                    fullWidth
                    disabled
                    value={element.source}
                    inputProps={{ style: { color: 'black' } }}
                  />
                </Grid>

                <Grid item xs={3} align="center">
                  <TextField
                    name="credit_type"
                    variant="filled"
                    label="Submitted Type"
                    fullWidth
                    disabled
                    value={element.green_Infrastructure_Type}
                    inputProps={{ style: { color: 'black' } }}
                  />
                </Grid>

                <Grid item xs={3} align="center">
                  <TextField
                    name="area"
                    variant="filled"
                    label="Submitted Area"
                    fullWidth
                    disabled
                    value={element.f_Area}
                    InputProps={{ style: { color: 'black' } , endAdornment: <InputAdornment position="end">ft²</InputAdornment>}}
                  />
                </Grid>

                <Grid item xs={3} align="center">
                  <TextField
                    name="r_Approved_Area"
                    variant="outlined"
                    label="Approved Area"
                    fullWidth
                    disabled={["APPROVED", "REJECTED", "CANCELLED"].includes(decision)}
                    onChange={(e) => handleChange(e, index)}
                    value={element.r_Approved_Area}
                    InputProps={{ style: { color: 'black' } , endAdornment: <InputAdornment position="end">ft²</InputAdornment>}}
                  />
                </Grid>

                <Grid item xs={12} align="center">
                  <TextField
                    name="reviewer_Comments"
                    variant="outlined"
                    label="Reviewer Comments"
                    onChange={(e) => handleChange(e, index)}
                    fullWidth
                    disabled={["APPROVED", "REJECTED", "CANCELLED"].includes(decision)}
                    value={element.reviewer_Comments ? element.reviewer_Comments : ""}
                    inputProps={{ style: { color: 'black' } }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
        )
        )}
        {warning &&
          <Grid item xs={12} align="right" >
            <Typography color="error">{warning}</Typography>
          </Grid>
        }
        {/* TODO the credit amount should change based on the reviewer's input */}
        {creditsSRtypeDetail &&
          <Grid item xs={12} align="right" >
            <Box p={2}>
              <SubTitle value='Credit Amount Review' align='inherit' />
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <Typography color="textPrimary">Submitted Roof Credit: ${roof_credit}</Typography>
                  <Typography color="textPrimary">Submitted Ground-level Credit: ${patio_credit}</Typography>
                  <Typography color="textPrimary">Submitted Total Credit: ${total_credit_amount}</Typography>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <Typography color="textPrimary">Approved Roof Credit: ${creditsSRType.r_approved_roof_credit}</Typography>
                  <Typography color="textPrimary">Approved Ground-level Credit: ${creditsSRType.r_approved_patio_credit}</Typography>
                  <Typography color="textPrimary">Approved Total Credit: ${creditsSRType.r_approved_total_credit}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

        }
      </Grid>
    </>
  )
})

export default SimplifiedCreditReviewContent;