import React, { useState, useEffect } from 'react';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { validateAccount } from '../../../../services/APIClient';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '20px',
    backgroundColor: "whitesmoke"
  },
}));

const AppealInfo = props => {
  const classes = useStyles();
  const [rateType, setRateType] = useState("");
  const masterData = props.masterData;

  useEffect(() => {
    if (masterData) {
      const params = {
        "accountNumber": masterData.ccb_account,
        "zipCode": masterData.zipcode
      }
      validateAccount(params).then((res) => {
        if (res.status === 200) {
          if (res.data.stormwaterChargeType === 'EXEMPT') {
            setRateType("Exempt");
          } else if (res.data.stormwaterChargeType === 'SRT1') {
            setRateType("Simplified Residential Tier 1");
          } else if (res.data.stormwaterChargeType === 'SRT2') {
            setRateType("Simplified Residential Tier 2");
          } else if (res.data.stormwaterChargeType === 'SRT3') {
            setRateType("Simplified Residential Tier 3");
          } else if (res.data.stormwaterChargeType === 'STANDARD') {
            setRateType("Standard");
          } else {
            setRateType("Not Found");
          }
        }
      }).catch(() => {
        setRateType("Not Found");
      });
      ;
    }
  }, [masterData]);


  return (
    <Grid container spacing={2}>

      <Grid item xs={6} align="center">
        <Typography variant="h5" gutterBottom>Appeal Type</Typography>
      </Grid>
      <Grid item xs={6} align="center">
        <Typography variant="h5" gutterBottom>{props.title}</Typography>
      </Grid>

      <Grid item xs={12} align="center" className={classes.title}>
        <Typography variant="h5" gutterBottom color="primary">Appeal Information</Typography>
        <Typography variant="h6" gutterBottom color="primary">Stormwater Charge Rate Type - {rateType}</Typography>
      </Grid>
      {props.type === "0" && (
        <>
          <Grid item xs={4} align="center">
            <Typography variant="h6">{props.masterData?.customer_appeal_id ? props.masterData.customer_appeal_id : ""}</Typography>
            <Typography variant="body1">Appeal Id</Typography>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography variant="h6">{props.masterData?.applicant_name ? props.masterData.applicant_name : ""}</Typography>
            <Typography variant="body1">Submitted By</Typography>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography variant="h6">{props.assessorBlock}</Typography>
            <Typography variant="body1">Assessor BlockID</Typography>
          </Grid>
        </>)}

      {props.type === "1" && (
        <>
          <Grid item xs={3} align="center">
            <Typography variant="h6">{props.masterData?.customer_appeal_id ? props.masterData.customer_appeal_id : ""}</Typography>
            <Typography variant="body1">Appeal Id</Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography variant="h6">{props.masterData?.applicant_name ? props.masterData.applicant_name : ""}</Typography>
            <Typography variant="body1">Submitted By</Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography variant="h6">{props.mapblock}</Typography>
            <Typography variant="body1">MapBlockID</Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography variant="h6">{props.assessorBlock}</Typography>
            <Typography variant="body1">Assessor BlockID</Typography>
          </Grid>
        </>)}

      {props.type === "2" && (
        <>
          <Grid item xs={4} align="center">
            <Typography variant="h6">{props.masterData?.customer_appeal_id ? props.masterData.customer_appeal_id : ""}</Typography>
            <Typography variant="body1">Appeal Id</Typography>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography variant="h6">{props.masterData?.applicant_name ? props.masterData.applicant_name : ""}</Typography>
            <Typography variant="body1">Submitted By</Typography>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography variant="h6">{props.mapblock}</Typography>
            <Typography variant="body1">MapBlockID</Typography>
          </Grid>
        </>)}

    </Grid>
  )
};

export default AppealInfo;