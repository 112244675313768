import { Fragment, useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Tabs, Tab, Typography, CssBaseline } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 4),
    marginTop: theme.spacing(2),
  },
}));

const SearchNavigation = (props) => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  const [selectedTab, setSelectedTab] = useState("mapblocklot");

  useEffect(() => {
    if (location.pathname === "/search") {
      setSelectedTab("mapblocklot");
    } else {
      setSelectedTab(location.pathname.split('/')[2]);
    }
  }, [location.pathname])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    navigate(newValue);
  }

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="md" className={classes.heroContent} >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Search
        </Typography>
        <Tabs value={selectedTab} onChange={handleTabChange} centered >
          <Tab label="Map Block Lot" value="mapblocklot" />
          <Tab label="Assessors Block Lot" value="assessorblocklot" />
          <Tab label="Legal Owner" value="legalowner"/>
          {(props.role.admin || props.role.business) && <Tab label="Appeals" value="appeals"/>}
          <Tab label="Address" value="address"/>
          {(props.role.admin || props.role.credit) && <Tab label="Credits" value="credits"/>}
        </Tabs>
      </Container>
      <Outlet />
    </Fragment>
  )
}

export default SearchNavigation;