import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { Typography, Link, Container, Grid, Toolbar, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/images/white-logo.svg';
import { useNavigate } from 'react-router-dom';
import { OpenInNew } from '@material-ui/icons';



const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#007DC3",
    //padding: theme.spacing(1),
    display: "flex",
    flexFlow: "column",
    bottom: "0%",
    width: "100%",
    left: "0%",
    right: "0%",
  },
  toolbar: {
    width: '100%',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
  },
  logo: {
    marginTop: theme.spacing(1),
    height: '60px',
    maxWidth:'30%',
    cursor: 'pointer',
  },
  typography: {
    fontFamily: 'red hat display, sans-serif',
    color: '#FBB034',
  },
  footerContainer: {
    backgroundColor: "#007DC3",
    minHeight: '100px',
    display: "flex",
    padding: theme.spacing(0,0,0),
    alignItems: 'flex-end',
    maxWidth: '70%',
  },
  copyRightContainer: {
    backgroundColor: "#007DC3",
    minHeight: '50px',
    display: "flex",
    padding: theme.spacing(2),
    alignSelf: 'flex-end',
    flexDirection: 'column',
    alignContent: 'space-around',
    maxWidth: '100vw',
  },
  copyRightTypography: {
    color: '#FFFFFF',
    fontSize: '12px',
  },
  itemText: {
    fontFamily: 'Libre Franklin, sans-serif',
    fontSize: '14px',
    color: '#FFFFFF',
    cursor: 'pointer',
  }
}));

const AppFooter = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Fragment>
      <CssBaseline />
      <footer className={classes.footer} id='footer'>
        <Toolbar className={classes.toolbar}>
          <img src={Logo} alt="logo" className={classes.logo} onClick={() => { navigate('/') }} />
          <Container className={classes.footerContainer}>
            <Grid container spacing={4} >
              <Grid item>
                <Typography className={classes.typography} variant="body1" align="left" component="p">RESOURCES</Typography>
                <Typography className={classes.itemText}>
                <Link href='https://sfpuc.org/' target='_blank' className={classes.itemText}>SFPUC | Main Page</Link>
                <OpenInNew fontSize='inherit' />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.typography} variant="body1" align="left" component="p">HOW CAN WE HELP?</Typography>
                <Typography className={classes.itemText}>
                  <Link href='https://myaccount-water.sfpuc.org/' target='_blank' className={classes.itemText}>SFPUC | My Account - Water</Link> <OpenInNew fontSize='inherit' />
                </Typography>
                
                <Typography className={classes.itemText}>
                  <Link href='https://sfpuc.org/about-us/contact-us' target='_blank' className={classes.itemText}>SFPUC | Contact us</Link>
                  <OpenInNew fontSize='inherit' />
                </Typography>

              </Grid>


            </Grid>
          </Container>
        </Toolbar>

        <Typography className={classes.copyRightTypography} align='center'><Link target='_blank' href="https://sfpuc.org/privacy-policy" className={classes.copyRightTypography}>Privacy Policy</Link> | © 2024 San Francisco Public Utilities Commission. All Rights Reserved.</Typography>
      </footer>
      <Outlet />
    </Fragment>

  );
}

export default AppFooter;