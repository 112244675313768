import { Routes, Route } from 'react-router-dom';

/* Langan Components */
import AppealsPage from '../../pages/AppealsPage';
import AppealFormPage from '../../pages/AppealFormPage';
import AppealConfirmationPage from '../../pages/AppealConfirmationPage';
import AppealReviewPage from '../../pages/AppealReviewPage';

const AppealsRouter = (props) => {

  return (
    <Routes>
      <Route index element={<AppealsPage store={props.store} />} />
      <Route path='/:account/:zipcode' exact element={<AppealFormPage store={props.store} />} />
      <Route path='confirmation/:account/:zipcode' exact element={<AppealConfirmationPage store={props.store} />} />
      <Route path='review/:type/:appealid' exact element={<AppealReviewPage store={props.store}/>} />
    </Routes>
  )
}

export default AppealsRouter;