import { Fragment, useEffect, useState, useRef, useCallback, forwardRef } from 'react';
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";

import { getAppealByAccount } from '../services/APIClient'

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Box, Button, Container, Divider, Grid, Paper, Typography } from '@material-ui/core';

/* Langan Components */
import GeneralInformation from "../components/appeal/confirmation-form/GeneralInformation";
import LegalOwnerAppeal from "../components/appeal/confirmation-form/LegalOwnerAppeal";
import ImpermeableAreaAppeal from "../components/appeal/confirmation-form/ImpermeableAreaAppeal";
import ExcludedAreaAppeal from "../components/appeal/confirmation-form/ExcludedAreaAppeal";
import ServiceAgreementAppeal from "../components/appeal/confirmation-form/ServiceAgreementAppeal";


const useStyles = makeStyles((theme) => ({
  root: theme.root,
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  formRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: "20px",
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
    marginBottom: theme.spacing(5)
  },
  buttonSubmit: {
    marginBottom: 10,
  },
  buttonPrint: {
    position: 'relative',
    float: 'right',
    margin: '5px'
  }
}));

const AppealConfirmation = forwardRef((props, ref) => {
  const classes = useStyles();
  let navigate = useNavigate();

  const [generalInfo, setGeneralInfo] = useState({});
  const [legalOwnerData, setLegalOwnerData] = useState({});
  const [impermeableData, setImpermeableData] = useState({});
  const [excludedData, setExcludedData] = useState({});
  const [saData, setSaData] = useState(false);
  const [area, setArea] = useState({
    mapBlkLot: "",
    grossTotalArea: "",
    permeableArea: "",
    impermeableArea: "",
    excludedArea: "",
  });
  const [statusMaster, setStatusMaster] = useState(false);
  const [statusLegalOwner, setStatusLegalOwner] = useState(false);
  const [statusImpermeable, setStatusImpermeable] = useState(false);
  const [statusExcluded, setStatusExcluded] = useState(false);
  const [statusSA, setStatusSA] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // get account related info
  useEffect(() => {
    //console.log("props.store", props.store);
    setArea(props.store.area)
    const params = {
      CCB_Account: props.store.ccbAccount,
      Zipcode: props.store.zipcode
    }
    getAppealByAccount(params)
      .then((response) => {
        if (response.status === 200) {

          if (response.data?.masterAppeal) {
            setStatusMaster(true);
            setGeneralInfo(response.data.masterAppeal);
          }

          if (response.data?.legalOwnerAppeal) {
            setStatusLegalOwner(true);
            setLegalOwnerData(response.data.legalOwnerAppeal);
          }

          if (response.data?.impermeableAppeal) {
            setStatusImpermeable(true);
            setImpermeableData(response.data.impermeableAppeal);
          }

          if (response.data?.excludedAppeal) {
            setStatusExcluded(true);
            setExcludedData(response.data.excludedAppeal);
          }

          if (response.data?.saAppeal) {
            setStatusSA(true);
            setSaData(response.data.saAppeal)
          }
        }
        //console.log("response:", response);
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response", error.response);
        }
      });
  }, [props])

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleReturn = () => {
    navigate("/");
  }

  return (
    <div ref={ref}>
      {/* Hero unit */}
      <div className={classes.heroContent} style={{ backgroundColor: "whitesmoke" }}>
        <Container maxWidth="md">
          <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
            Appeal Confirmation
          </Typography>
          <Alert severity="success">
            <AlertTitle><strong>
              Thank you for submitting your Appeal Application. Your application has been received and will be reviewed. <br />
              Please allow 4-6 weeks for a response. <br/>
              Please print a copy of this receipt for your records. <br />
              The data you submitted is below.</strong></AlertTitle>
            {/* Thank you for your submittal. Here's your pending appeal information. */}
          </Alert>
        </Container>
      </div>
      <div >
        <Container maxWidth="md">
          <Paper className={classes.paper}>
            <div className={`${classes.formRoot} ${classes.form}`} >
              <Grid container spacing={2}>
                {statusMaster &&
                  <Fragment>
                    <Container>
                      <GeneralInformation
                        data={generalInfo}
                      />
                    </Container>
                    <Box p={2} bgcolor="background.paper">
                      <Divider variant="middle" />
                    </Box>
                  </Fragment>
                }

                {statusLegalOwner &&
                  <Fragment>
                    <Container>
                      <Grid container spacing={2} >
                        <LegalOwnerAppeal
                          expanded={expanded}
                          handleAccordianChange={handleAccordianChange}
                          data={legalOwnerData}
                        />
                      </Grid>
                    </Container>
                    <Box p={2} bgcolor="background.paper">
                      <Divider variant="middle" />
                    </Box>
                  </Fragment>
                }

                {statusImpermeable &&
                  <Fragment>
                    <Container>
                      <Grid container spacing={2}>
                        <ImpermeableAreaAppeal
                          expanded={expanded}
                          handleAccordianChange={handleAccordianChange}
                          data={impermeableData}
                          area={area}
                        />
                      </Grid>
                    </Container>
                    <Box p={2} bgcolor="background.paper">
                      <Divider variant="middle" />
                    </Box>
                  </Fragment>
                }

                {statusExcluded &&
                  <Fragment>
                    <Container>
                      <Grid container spacing={2}>
                        <ExcludedAreaAppeal
                          expanded={expanded}
                          handleAccordianChange={handleAccordianChange}
                          data={excludedData}
                          area={area}
                        />
                      </Grid>
                    </Container>
                    <Box p={2} bgcolor="background.paper">
                      <Divider variant="middle" />
                    </Box>
                  </Fragment>
                }

                {statusSA &&
                  <Fragment>
                    <Container>
                      <Grid container spacing={2}>
                        <ServiceAgreementAppeal
                          expanded={expanded}
                          handleAccordianChange={handleAccordianChange}
                          data={saData}
                          area={area}
                        />
                      </Grid>
                    </Container>
                    <Box p={2} bgcolor="background.paper">
                      <Divider variant="middle" />
                    </Box>
                  </Fragment>
                }

              </Grid>
            </div>
          </Paper>
          <Button
            className={classes.buttonSubmit}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            onClick={handleReturn}
            fullWidth
          >
            Return to Home
          </Button>
        </Container>
      </div>
    </div>
  );
});

const AppealConfirmationPage = (props) => {
  const componentRef = useRef(null);
  const classes = useStyles();

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = useCallback(() => {
    return (
      <Button variant="contained" color="primary" className={classes.buttonPrint}>Print</Button>
    );
  }, []);

  return (
    <div>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Appeal Confirmation"
        trigger={reactToPrintTrigger}
      />
      <AppealConfirmation ref={componentRef} store={props.store} />
    </div>
  );
};

export default AppealConfirmationPage;
