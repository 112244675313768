import { Fragment, useState } from 'react';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { DataGrid, getGridStringOperators } from '@material-ui/data-grid';
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#115293',
      color: theme.palette.common.white,
      fontSize: 14
    },
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: '#fffbf2',
        cursor: 'pointer'
      },
    }
  },
  appealTypeFilter: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    height: 48,
    paddingLeft: 20,
    marginTop: 8,
  },
}));

const DashboardTable = props => {
  const classes = useStyles();
  const [page, setPage] = useState(25);

  function AppealTypeValue(filterProps) {
    const { item, applyValue } = filterProps;

    const handleFilterChange = (event, value) => {
      applyValue({ ...item, value: value.appealType });
    };

    function getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    const optionsUnique = getUniqueListBy(props.data, item.columnField);

    return (
      <div className={classes.appealTypeFilter}>
        <Autocomplete
          id="name-filter"
          options={optionsUnique}
          getOptionLabel={(option) => option.appealType}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(option) => (
            <Fragment>{option.appealType}</Fragment>
          )}
          onChange={handleFilterChange}
        />
      </div>
    );
  }

  // Appeal filter settings
  const appealTypeColumn = props.header.find(
    (column) => column.field === "appealType"
  );

  const appealTypeIndex = props.header.findIndex(
    (col) => col.field === "appealType"
  );

  const appealTypeFilterOperators = getGridStringOperators()
    .filter((operator) => operator.value === "equals")
    .map((operator) => ({
      ...operator,
      InputComponent: AppealTypeValue
    }));

  props.header[appealTypeIndex] = {
    ...appealTypeColumn,
    filterOperators: appealTypeFilterOperators
  };

  return (
    <DataGrid
      className={classes.table}
      columns={props.header}
      rows={props.data}
      filterModel={props.filter}
      onCellClick={props.onCellClick}
      pageSize={page}
      onPageSizeChange={(pageSize, details) => { setPage(pageSize); }}
      autoHeight
      rowsPerPageOptions={[5, 10, 25]}
    />
  );
};

export default DashboardTable;
