import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
// import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, TextField, Grid, Button, Paper, Typography } from '@material-ui/core';

import { searchAssessorByAssessorBlock } from '../../services/APIClient';
import { SubmitSearchButton } from './SubmitSearchButton';
import { SearchFieldComponent } from './SearchFieldComponent';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
  },
}));

const SearchAssessor = props => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Typography variant="h6">Assessor Block Lot Search</Typography>
        {/* <form autoComplete="off" noValidate className={classes.form} onSubmit={assessorHandleSubmit}> */}
        <Grid container spacing={1} className={classes.form}>
          <SearchFieldComponent 
            name="as_blklot"
            label="Assessor Block Lot Number (Example: 1554/014)"
            searchAPI={searchAssessorByAssessorBlock} 
            searchType="assessor"
          />
        </Grid>
        {/* </form > */}
      </Paper >
    </Container>
  )
};

export default SearchAssessor;