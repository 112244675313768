import React, { useState, useEffect } from 'react';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Box } from '@material-ui/core';

import FormInputTypography from '../../form-input/form-input-typography.component.jsx';


const LegalOwnerAppeal = (props) => {
  const [data, setData] = useState({
    customerAppealId: "",
    new_legal_owner_name: "",
    appeal_record_date: "",
    phone_number: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "USA",
    applicant_comments: "",
    isGovernmentEntity: false
  });

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data])

  return (
    <Accordion expanded>
      <AccordionSummary id="incorrectOwnerAppeal" style={{ backgroundColor: "whitesmoke" }}>
        <Typography variant="h6" align="left" gutterBottom color="primary">
          Information for Change in Ownership of the Property
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Typography variant="body1" component='div'>
            If a property changes owners, the new owner is responsible for the stormwater charge for the
            property. You may appeal your stormwater charge if you do not own the property for which
            you are being billed.
            <Box sx={{ fontWeight: 'bold' }}>Along with the application, please include a copy of the grant deed documenting the property transfer and recordation date. </Box>
          </Typography>
          <FormInputTypography
            label="Change in Ownership Appeal ID"
            size={5}
            value={data.customerAppealId}
          />
          <FormInputTypography
            label="Appealed Legal Owner Name"
            size={5}
            value={data.new_legal_owner_name}
          />
          <FormInputTypography
            label="Record Date"
            size={5}
            value={data.appeal_record_date ? data.appeal_record_date.substring(0, 10) : ""}
          />
          <FormInputTypography
            label="Phone Number"
            size={5}
            value={data.phone_number}
          />
          <FormInputTypography
            label="New Owner Mailing Address"
            size={5}
            value={data.address}
          />
          <FormInputTypography
            label="New Owner Mailing Address 2"
            size={5}
            value={data.address2}
          />
          <FormInputTypography
            label="New Owner Mailing City"
            size={5}
            value={data.city}
          />
          <FormInputTypography
            label="New Owner Mailing State"
            size={5}
            value={data.state}
          />
          <FormInputTypography
            label="New Owner Mailing Zipcode"
            size={5}
            value={data.zip}
          />
          <FormInputTypography
            label="New Owner Mailing Country"
            size={5}
            value={data.country}
          />
          <FormInputTypography
            label="Is Government Entity"
            size={5}
            value={data.isGovernmentEntity ? 'Yes' : 'No'}
          />
          <FormInputTypography
            label="Applicant Comments"
            size={5}
            value={data?.applicant_comments ? data.applicant_comments : ""}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default LegalOwnerAppeal;