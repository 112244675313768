/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Box, Grid, Link, Divider } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
/* Langan Components */
import { LinkTextDisplay } from '../../data-display/data-display.component';
import form from '../../../assets/files/AppealForm-110723 Revised Ver_FINAL.pdf'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  btnSpacing: {
    marginTop: "10px",
  },
  logo: {
    height: '30px',
    width: '30px'
  },
  link: theme.link,
}));

const AppealInfo = (props => {
  const classes = useStyles();

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="md">
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Si necesita ayuda en español llame al (415) 551-3000. <br />
            Kung kailangan ninyo ng tulong sa Filipino mangyaring tumawag sa (415) 551-3000. <br />
            如果您需要中文協助、請致電 (415) 551-3000。 <br />
          </Typography>
        </Grid>
        <Typography variant="h5" align="left" gutterBottom>
          What is a Stormwater Charge Appeal?
        </Typography>
        <Typography variant="body1" component='span'>
          A stormwater charge appeal is a request to change the stormwater charge on a property. Customers may appeal their stormwater charge for three reasons:
          <ol>
            <li>Change in ownership of the property.</li>
            <li>Errors in the charged property's area assessment.</li>
            <li>Changing the split of the stormwater charge among accounts on a property.</li>
          </ol>
        </Typography>
        <Box m={2} />
        <Typography variant='body1'>
          SFPUC staff will check the appeal and any supporting information to approve or deny the change to the stormwater charge.
        </Typography>
        <Box m={2} />
        <Typography variant="h5" align="left" gutterBottom>
          How do I file a Stormwater Charge Appeal?
        </Typography>
        <Typography variant="body1">
          To file an appeal, click on the&nbsp;<Link className={classes.link} onClick={() => props.setOpen(!props.open)}>File Appeal</Link>&nbsp;button above. Enter the SFPUC account number and service address ZIP code connected to the property. Filing a stormwater charge appeal is free.
        </Typography>
        <Box m={2} />
        <Typography variant="body1">
        Residents or businesses may appeal or request changes to their stormwater charge by completing the <LinkTextDisplay linkName={'Stormwater Charge Appeal Form'} href={form} externalLink={true} linkStyle={classes.link} /> online or on paper. Paper copies of the appeal can be mailed or delivered to:&nbsp;<Link href='https://sfpuc.org/about-us/contact-us' target='_blank' className={classes.link}>Customer Service window</Link> <OpenInNew fontSize='inherit' />&nbsp;at 525 Golden Gate Avenue, 1st Floor, San Francisco, CA 94102, 8:30am to 4:30pm, Monday to Friday, excluding legal holidays.
        </Typography>
        <Box m={2} />
        <Typography variant="body1">
          For help with the application process or to check on the status of your application, please contact The Stormwater Group at <LinkTextDisplay href={'mailto:StormwaterAppeals@sfwater.org'} linkStyle={classes.link} linkName={'StormwaterAppeals@sfwater.org'} externalLink={true} /> or (415) 523-4413.
        </Typography>
      </Container>
      <Box p={5} bgcolor="background.paper">
        <Divider variant="middle" />
      </Box>
      <Container maxWidth="md">
        <Typography variant="h4" align="left" color="textPrimary" style={{ fontWeight: 'bold' }} gutterBottom>
          Types of Appeals
        </Typography>
        <Grid container >
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Change in Ownership
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              If a property changes owners, current customers with active service accounts will continue to be responsible for the stormwater charge for that property. If the property does not have an active service account, the new property owner will be responsible for the stormwater charge. Customers may appeal their stormwater charge if they believe they are being billed improperly. We require submitted documentation of the customer's grant deed report with recordation date for change in ownership.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Impermeable or Permeable Area Error
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" >
              This appeal option is only available to standard rate customers. You may appeal your stormwater charge if there is an error in the amount of impermeable or permeable area assigned to your property. Impermeable area is charged a higher rate because it stops stormwater from soaking into the ground. Impermeable area includes hard surfaces such as rooftops, concrete, buildings, and pavement. Permeable area lets some stormwater soak into the ground and is charged a lower rate. Some examples of permeable area are grass, dirt, and gravel.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Excluded Area Error
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" >
              This appeal option is only available to standard rate customers. In very rare cases, areas that do not drain to SFPUC sewers are not subject to the stormwater charge. This area is called excluded area. Area draining directly to San Francisco Bay, the Pacific Ocean, a lake, or a non-SFPUC owned sewer are the most common excluded areas. If you believe your property has excluded area you may appeal your stormwater charge. You must show proof, verified by the City of San Francisco, of your property's drainage.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Change Stormwater Charge Split
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              By default, the stormwater charge for a property is equally split across all SFPUC accounts with a sewer service agreement on that property. This appeal needs a signed document, such as a letter of agreement, from all SFPUC account holders agreeing to a new, customized split of the stormwater charge.
            </Typography>
          </Grid>
        </Grid>

      </Container>
    </div>
  )
})

export default AppealInfo;