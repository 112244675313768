import { Fragment } from 'react';

// Services
import { useResources } from "../services/APIClient";

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';

import SFImage from '../assets/images/SFImage_2.jpg';
import { ListItemLinkDisplay } from '../components/data-display/data-display.component';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 200px)",
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
    height: "100%"
  },
  link: theme.link,
}));

const ResourcesPage = (props) => {
  const classes = useStyles();

  const { isLoading, isError, data, error } = useResources();

  return (
    <Fragment>
      <CssBaseline />
      <main className={classes.root}>
        {/* Hero unit */}
        <div className={classes.heroContent} style={{ backgroundImage: 'url(' + SFImage + ')', backgroundSize: 'cover', width: '100%', height: '100%' }}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h2" align="center" gutterBottom>
              Resources
            </Typography>
          </Container>
        </div>
        <div>
          <Container maxWidth="md">
            <List component="nav" aria-label="resource folders">
              {data && data.map((item, index) => (
                <ListItemLinkDisplay key={index} linkName={item.resourcE_DESC} href={item.resourcE_URL} linkStyle={classes.link} fontSize={16} showInfoIcon={true} externalLink={true} paddingLeft={0} />
              ))}
            </List>
          </Container>
        </div>
      </main>
    </Fragment>
  );
};

export default ResourcesPage;
