import { Fragment, useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Grid, Button } from '@material-ui/core';

/* Langan Components */
import AppealInfo from '../components/appeal/appeal-info/appeal-info.component';
import AppealDialog from "../components/appeal/appeal-dialog/appeal-dialog.component";
import ComingSoonDialog from '../components/ComingSoonDialog';

const useStyles = makeStyles((theme) => ({
  root: theme.root,
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  btnSpacing: {
    marginTop: "10px",
  },
  logo: {
    height: '30px',
    width: '30px'
  },
}));

const AppealsPage = observer(props => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const env = process.env.REACT_APP_PORTAL_URL.includes('stormwater.sfpuc.org') ? 'prod' : 'dev';

  const handleClose = () => {
    setOpen(false);
  };

  const handleComingSoon = () => {
    setAlertDialogOpen(!alertDialogOpen);
  };

  return (
    <Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent} style={{ backgroundColor: "whitesmoke" }}>
          <Grid container direction="column" alignItems="center" justify="center">
            <Typography component="h1" variant="h3" align="center" color="inherit" gutterBottom>
            Stormwater Charge Appeals 
            </Typography>
            <Grid item xs={12} align="center" style={{ marginTop: "20px" }}>
              <Button color="primary" variant="contained" onClick={() => setOpen(true)}>File Appeal</Button>
              <AppealDialog store={props.store} open={open} handleClose={handleClose} />
            </Grid>
            <ComingSoonDialog open={alertDialogOpen} handleClose={handleComingSoon} />
          </Grid>
        </div>
        <AppealInfo open={open} setOpen={setOpen}/>
      </main>
    </Fragment>
  );
});

export default AppealsPage;
