import React from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexFlow: "column",
      height: "calc(100vh - 104px)"
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
  }));

const HelpPage = observer(props => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.root}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              About the Stormwater Charge
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Todo: Help page
            </Typography>
          </Container>
        </div>

      </main>
    </React.Fragment>
  );
});

export default HelpPage;
