import { useEffect, useContext } from "react";
import { MapViewContext } from "../../contexts";

/* Esri */
import Legend from "@arcgis/core/widgets/Legend";
import Expand from "@arcgis/core/widgets/Expand";


function LegendWidgetCompact() {
  const mapView = useContext(MapViewContext);

  useEffect(() => {
    if (mapView) {

      // Add Legend widget to map
      const legend = new Legend({
        view: mapView,
      });
      legend.style = "classic";
      const expand = new Expand({
        view: mapView,
        content: legend
      });
      expand.expanded = true;
      // Add the expand instance to the ui
      mapView.ui.add(expand, "top-right");
    }
  }, [mapView]);

  return (null)
};

export default LegendWidgetCompact;
