import { useEffect, useContext, useState } from "react";
import { MapViewContext } from "../../contexts";

/* Esri */
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
// import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils"

const invalidSymbol = {
  type: "simple-fill",
  style: "diagonal-cross",
  color: [255, 0, 0],
  outline: {
    color: [255, 0, 0],
    width: 4
  }
};

const impermeableSymbol = {
  type: "simple-fill",
  style: "solid",
  color: [205, 133, 63, 0.8],
  outline: {
    color: [255, 255, 255],
    width: 2
  }
};

const excludedSymbol = {
  type: "simple-fill",
  style: "solid",
  color: [0, 139, 139, 0.8],
  outline: {
    color: [255, 255, 255],
    width: 2
  }
};

const SketchWidget = ({ handleAreaChange, parcelId, handleGraphicsChange, excluded = false }) => {
  const mapView = useContext(MapViewContext);
  const [widget, setWidget] = useState(null);
  const [boundary, setBoundary] = useState(null);
  const [graphicLayer, setGraphicLayer] = useState(null);
  const [impermeable, setImpermeable] = useState(null);
  const [permeable, setPermeable] = useState(null);
  const validSymbol = excluded ? excludedSymbol : impermeableSymbol;

  useEffect(() => {
    if (mapView) {
      // Add sketch graphic layer to map
      const layer = new GraphicsLayer();
      mapView.map.add(layer);
      // Add Sketch widget to map
      const sketch = new Sketch({
        layer: layer,
        view: mapView,
        availableCreateTools: ["polygon", "rectangle", "circle"],
        creationMode: "single",
        visibleElements: {
          selectionTools: {
            "rectangle-selection": false,
            "lasso-selection": false
          },
          settingsMenu: false
        }
      });

      sketch.viewModel.polygonSymbol = validSymbol;
      mapView.ui.add(sketch, "top-right");
      // find the layers by id, update index when modify the webmap
      //console.log("mapView.map.layers", mapView.map.layers);
      //const impermeableLayer = mapView.map.layers.getItemAt(1);
      const impermeableLayer = mapView.map.layers.find(layer => layer.title === "Impermeable Area");
      //const permeableLayer = mapView.map.layers.getItemAt(0);
      const permeableLayer = mapView.map.layers.find(layer => layer.title === "Permeable Area");
      //const parcelLayer = mapView.map.layers.getItemAt(6);
      const parcelLayer = mapView.map.layers.find(layer => layer.title === "Parcels");
      // async query function
      const queryMapblklot = async (layer, setData) => {
        const queryParams = layer.createQuery();
        queryParams.outSpatialReference = mapView.spatialReference
        queryParams.where = `mapblklot = '${parcelId}'`;
        const result = await layer.queryFeatures(queryParams)
        //console.log(`query result ${layer.title}`, result);
        if (result.features.length > 0) {
          setData(result.features[0].geometry);
        } else {
          setData(null);
        }
        // return result;
      };

      queryMapblklot(parcelLayer, setBoundary);
      if (excluded) {
        queryMapblklot(impermeableLayer, setImpermeable);
        queryMapblklot(permeableLayer, setPermeable);
      }
      setGraphicLayer(layer);
      setWidget(sketch);

      return () => {
        // this now gets called when the component unmounts
      }
    }
  }, [mapView, excluded, invalidSymbol]);

  // create/update a new polygon
  useEffect(() => {
    if (widget && mapView && boundary) {
      widget.on(["create", "update", "undo", "redo"], event => {
        if (event.state === "complete") {
          let status = true;
          const geometries = [];
          // validate all the geometries
          graphicLayer.graphics.items.forEach(g => {
            geometries.push(g.geometry);
            const contain = geometryEngine.contains(boundary, g.geometry);
            if (contain) {
              g.symbol = validSymbol;
            } else {
              status = false;
              g.symbol = invalidSymbol;
            }
          })

          if (status && geometries.length > 0) {
            // calculate the area of all the geometries
            let area;
            let union;
            if (geometries.length > 1) {
              union = geometryEngine.union(geometries);
            } else {
              union = geometries[0];
            };
            area = Math.round(geometryEngine.geodesicArea(union, "square-feet"));

            if (excluded) {
              // calculate intersected impermeable area
              let impermeableIntersectArea = 0;
              if (impermeable) {
                const impermeableIntersect = geometryEngine.intersect(union, impermeable);
                if (impermeableIntersect) {
                  impermeableIntersectArea = Math.round(geometryEngine.geodesicArea(impermeableIntersect, "square-feet"));
                }
              }
              //console.log("impermeableIntersectArea", impermeableIntersectArea);
              
              // calculate intersected permeable area
              let permeableIntersectArea = 0;
              if (permeable) {
                const permeableIntersect = geometryEngine.intersect(union, permeable);
                if (permeableIntersect) {
                  permeableIntersectArea = Math.round(geometryEngine.geodesicArea(permeableIntersect, "square-feet"));
                }
              }
              //console.log("permeableIntersectArea", permeableIntersectArea);
              
              handleAreaChange({ total: area, a: impermeableIntersectArea, b: permeableIntersectArea });
            } else {
              handleAreaChange(area);
            }
            handleGraphicsChange(JSON.stringify(geometries));
          } else {
            if (excluded) {
              handleAreaChange({ total: 0, a: 0, b: 0 });
            } else {
              handleAreaChange(0);
            }
            handleGraphicsChange("");
          }
        }
      });
    }
  })

  return (null)
};

export default SketchWidget;
