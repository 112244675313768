import { Fragment } from 'react';

/* Langan Form Input Components */
import FormInputTextField from '../../../form-input/form-input-textfield.component';
import FormInputRadio from '../../../form-input/form-input-radio.component';

import { observer } from 'mobx-react-lite';
import { useCredits } from '../../../../stores/CreditStore/CreditModuleStore';

const decisions = [
  {
    label: "Lodged / In Progress",
    value: "LODGED",
  },
  {
    label: "Approved",
    value: "APPROVED"
  },
  {
    label: "Denied",
    value: "REJECTED",
  },
  {
    label: "Cancelled",
    value: "CANCELLED"
  },
];

const CreditDecisionReview = observer((props) => {
  const credits = useCredits();

  const handleChange = (event) => {
    const { name, value } = event.target;
    credits.creditReview.setDecision(name, value);
  };


  return (
    <> 
    {credits && 
    <Fragment>
      <FormInputTextField
        label="Reviewer Comments"
        name="r_Reviewer_Comments"
        value={credits.creditReview.credit_Type === 'Standard' 
        ? credits.creditReview.creditsStandardType.r_Reviewer_Comments ? credits.creditReview.creditsStandardType.r_Reviewer_Comments : ""
        : credits.creditReview.creditsSRType.r_Reviewer_Comments ? credits.creditReview.creditsSRType.r_Reviewer_Comments : ""}
        onChange={handleChange}
        disabled={(!props.role.credit&& !props.role.admin) || ["APPROVED", "REJECTED", "CANCELLED"].includes(props.decision)}
      />
      <FormInputRadio
        label="Review Decision"
        name="r_Decision"
        value={credits.creditReview.credit_Type === 'Standard' 
        ? credits.creditReview.creditsStandardType.r_Decision 
        : credits.creditReview.creditsSRType.r_Decision}
        onChange={handleChange}
        ariaLabel="Decision"
        options={decisions}
        disabled={(!props.role.credit&& !props.role.admin)|| ["APPROVED", "REJECTED", "CANCELLED"].includes(props.decision)}
      />
    </Fragment>}
    </>
  )
});

export default CreditDecisionReview;