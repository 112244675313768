import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { TextField, Grid, FormControl, Box, Divider } from '@material-ui/core';
import { SubmitSearchButton } from './SubmitSearchButton';

export const SearchFieldComponent = (props) => {
    let navigate = useNavigate();
    const {name, label, searchAPI, searchType} = props;
    
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSearchValueChange = (e) => {
      setErrorMessage('');
      setLoadingError(false);
      setIsLoading(false);
      setSearchValue(e.target.value);
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      setIsLoading(true);
        searchAPI(searchValue.replace("/", ""))
        .then((response) => {
          if (response) {
            setIsLoading(false);
            if (searchType === 'legalowner') {
              if (response.legalOwnerId) {
                navigate(`/${searchType}/${response.legalOwnerId}`);
              }
            } else {
              navigate(`/${searchType}/${searchValue.replace("/", "")}`);
            }
          } else {
            console.log(response);
          }
        })
        .catch(error => {
          setIsLoading(false);
          setLoadingError(true);
          console.log(error.response.data);
          setErrorMessage("No results found. Please try again.");
        })
    }

    return (
        <FormControl fullWidth margin='dense' onKeyPress={(event) =>{
          if (event.key === 'Enter') {
            handleSubmit(event)
          }
        }}>
          <Grid container spacing={1} justify='space-around'>
            <Grid item xs={10}>
              <TextField
                name={name}
                variant="outlined"
                label={label}
                type="search"
                fullWidth
                error={loadingError}
                helperText={loadingError ? errorMessage : ''}
                value={searchValue}
                onChange={(e) => handleSearchValueChange(e)}
              />
            </Grid>
            <Grid item xs={2}>
              <SubmitSearchButton
                isLoading={isLoading}
                disabled={searchValue && !isLoading ? false : true}
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </FormControl> 
    );
}