import { useState, useEffect } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Container, Box, Divider } from '@material-ui/core';
import FormInputTypography from '../../form-input/form-input-typography.component';
import { AreaInformation, SubTitle, addCommas } from '../../data-display/data-display.component';
import { useCredits } from '../../../stores/CreditStore/CreditModuleStore';
import FormAttachmentList from '../../form-input/form-attachment-list.component';

const SimplifiedResidential = observer((props) => {
  const credits = useCredits();
  const [data, setData] = useState(null);
 
  const [roof_credit, setRoofCredit] = useState(0);
  const [patio_credit, setPatioCredit] = useState(0);
  const [total_credit_amount, setTotalCreditAmount] = useState(0);

  useEffect(() => {
    if (credits.creditSubmission && !credits.creditReview)  {
      setData(credits.creditSubmission);
    } else if (credits.creditReview) {
      setData(credits.creditReview); 
      //console.log(credits.creditReview.creditsDocument)
    }
  }, [credits])

  useEffect(() => {
    let roofTmp = 0;
    let patioTmp = 0;
    if (data && data.creditsSRtypeDetail && data.creditsSRtypeDetail.length > 0) {
      data.creditsSRtypeDetail.map(element => {
        if (element.source === "Roof") {
          roofTmp = element.f_Credit_Amount;
          setRoofCredit(element.f_Credit_Amount);
        } else if (element.source === "Patio") {
          patioTmp = element.f_Credit_Amount;
          setPatioCredit(element.f_Credit_Amount);
        }
      })
      setTotalCreditAmount(parseFloat((roofTmp + patioTmp).toFixed(2)));
    }
  }, [data, credits])

  return (
    <> {data && 
    <Container maxWidth='md'>
    <Accordion expanded>
      <AccordionSummary id="SAChargeAllocAppeal" style={{ backgroundColor: "whitesmoke" }}>
        <Typography variant="h6" gutterBottom color="primary">
          Green Infrastructure Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <AreaInformation
            data={{
              "Map Block Lot": data.mapBlockLot,
              "Number of Service Agreements": data.creditsDistributionData?.length ? data.creditsDistributionData.length : 0,
              "Number of Green Infrastructure Facilities": data.creditsSRtypeDetail?.length ? data.creditsSRtypeDetail.length : 0,
              "Net Total Area": data.s_Current_Impermeable_Area + data.s_Current_Permeable_Area,
              "Billable Impermeable Area": data.s_Current_Impermeable_Area,
              "Billable Permeable Area": data.s_Current_Permeable_Area
            }}
          />
          
          {data.creditsSRtypeDetail && data.creditsSRtypeDetail.length > 0 && data.creditsSRtypeDetail.map((element, index) =>
          (<Grid item xs={12} key={index}>
            <SubTitle value={`Green Infrastructure Facility ${index + 1}`} />
            <Grid container spacing={2}>
              <FormInputTypography align='left' size={6} label='Green Infrastructure Source' value={element.source} variant='body1'/>
              <FormInputTypography align='left' size={6} label='Green Infrastructure Type' value={element.green_Infrastructure_Type} variant='body1'/>
              <FormInputTypography align='left' size={6} label='Area' value={addCommas(element.f_Area) + ' ft²'} variant='body1'/>
            </Grid>
            <Divider/>
          </Grid>)
          )}
          
          {data.creditsDocument &&
          <FormAttachmentList
          label='Submitted Documents'
          size={6}
          file={data.creditsDocument.filter(element => element.description !== 'Agreement Document' && element.document_Type === 'Credit')}
          type='Credit' />
          }
          
          <Grid item xs={12} align="right" >
            <Typography color="textPrimary">Roof Credit: ${roof_credit}</Typography>
            <Typography color="textPrimary">Ground-level Credit: ${patio_credit}</Typography>
            <Typography color="textPrimary">Total Credit: ${total_credit_amount}</Typography>
          </Grid>
         
        </Grid>
      </AccordionDetails>
    </Accordion>
    <Box p={1} />
    </Container>}
    </>
  )
})

export default SimplifiedResidential;