import React from 'react';

/* MUI */
import { Grid, Typography } from '@material-ui/core';

import FormInputCheckbox from '../../../form-input/form-input-checkbox.component';

const DocumentsReview = props => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} align="center" style={{ marginTop: "20px", backgroundColor: "whitesmoke" }}>
        <Typography variant="h5" gutterBottom color="primary">Documents Review</Typography>
      </Grid>

      {props.type === "0" && (
        <>
          <FormInputCheckbox
            label="Deed Submitted"
            name="r_Deed_Submitted"
            checked={props.docReview.r_Deed_Submitted}
            onChange={(event) => props.docChange(event)}
            disabled={(!props.role.business && !props.role.admin) || props.initStatus}
          />
          <FormInputCheckbox
            label="Additional Documents"
            name="r_Additional_Documents"
            checked={props.docReview.r_Additional_Documents}
            onChange={(event) => props.docChange(event)}
            disabled={(!props.role.business && !props.role.admin) || props.initStatus}
          />
        </>
      )}

      {props.type === "1" && (
        <>
          <FormInputCheckbox
            label="Sufficient Documentation Submitted"
            name="r_Documentary_Proof_Submitted"
            checked={props.docReview.r_Documentary_Proof_Submitted}
            onChange={(event) => props.docChange(event)}
            disabled={(!props.role.business && !props.role.admin) || props.initStatus}
          />
          <FormInputCheckbox
            label="Site Plan"
            name="r_Site_Plan"
            checked={props.docReview.r_Site_Plan}
            onChange={(event) => props.docChange(event)}
            disabled={(!props.role.business && !props.role.admin) || props.initStatus}
          />
          <FormInputCheckbox
            label="Marked-up image showing correct parcel boundary and/or impervious coverage"
            name="r_Marked_Up_Image"
            checked={props.docReview.r_Marked_Up_Image}
            onChange={(event) => props.docChange(event)}
            disabled={(!props.role.business && !props.role.admin) || props.initStatus}
          />
          <FormInputCheckbox
            label="Site Photographs"
            name="r_Site_Photographs"
            checked={props.docReview.r_Site_Photographs}
            onChange={(event) => props.docChange(event)}
            disabled={(!props.role.business && !props.role.admin) || props.initStatus}
          />
        </>
      )}

      {props.type === "2" && (
        <>
          <FormInputCheckbox
            label="Sufficient Documentation Submitted"
            name="r_Documentary_Proof_Submitted"
            checked={props.docReview.r_Documentary_Proof_Submitted}
            onChange={(event) => props.docChange(event)}
            disabled={(!props.role.business && !props.role.admin) || props.initStatus}
          />
          <FormInputCheckbox
            label="Additional Documents"
            name="r_Additional_Documents"
            checked={props.docReview.r_Additional_Documents}
            onChange={(event) => props.docChange(event)}
            disabled={(!props.role.business && !props.role.admin) || props.initStatus}
          />
        </>
      )}

    </Grid>
  )
};

export default DocumentsReview;