import { types } from "mobx-state-tree"

const AssessorBlkltStore = types
.model("AssessorBlklt", {
    assessorBlkLot: types.string,
    legalOwnerName: types.string,
    propertyAddress: types.string
})

const Area = types
.model("Area", {
    grossTotal: types.number,
    permeableArea: types.number,
    impermeableArea: types.number,
    excludedArea: types.number,
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string)
})

const Account = types
.model("Account", {
    account: types.string
})

export const AccountStore = types
.model(
    "AccountStore", {
        mapBlkLot: types.string,
        grossTotalArea: types.number,
        permeableArea: types.number,
        impermeableArea: types.number,
        excludedArea: types.number,
        isSimplifiedResidential: types.boolean,
        numberOfServiceAgreement: types.number,
        billableImpermeableArea: types.number,
        billablePermeableArea: types.number,
        stormwaterChargeType: types.maybeNull(types.string),
        assessorBlkLotList: types.array(AssessorBlkltStore),
        areaHistory: types.array(Area),
        accounts: types.array(Account)
      }
)
.actions((self) => ({
    afterCreate() {
    },
    setItem(item, value) {
        self[item] = value;
    }
}));
