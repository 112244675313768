import { useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, TextField, Typography, FormControl, Select, InputLabel, MenuItem, Container, Box } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AreaInformation, addCommas, LinkTextDisplay } from '../../data-display/data-display.component';
import { useCredits } from '../../../stores/CreditStore/CreditModuleStore';
import { AddRemoveItem } from '../../form-input/form-field.component';
import { FileUploadButton } from '../../form-input/form-file-upload-button.component';

const SimplifiedResidential = observer((props) => {
  const credits = useCredits();
  const [warning, setWarning] = useState('');


  const handleFieldChange = (event, idx) => {
    const { name, value } = event.target;
    let area = String(value).replace(/,/g, '');
    const form = credits.creditEntry;
    // if the area is changed, update the credit amount
    if (name === 'f_Area') {
      let areaValue = parseFloat(area) ? parseFloat(area) : 0;
      form.SRDetails[idx].setItem(name, areaValue);
    } else {
      form.SRDetails[idx].setItem(name, value);
    }
    form.setItem("SRDetails", form.SRDetails);
    form.calculateSRtotalCredit();
    setWarning(form.checkSRdetailsStatus());
  }

  const addRow = () => {
    credits.creditEntry.addSRdetails();
  };

  const removeRow = () => {
    credits.creditEntry.removeSRdetails();
    credits.creditEntry.calculateSRtotalCredit();
    setWarning(credits.creditEntry.checkSRdetailsStatus());
  };

  return (
    <>{(credits && credits.creditEntry) &&
      <Container>
        <Accordion expanded >
          <AccordionSummary id="SRdetails" style={{ backgroundColor: "whitesmoke" }}>
            <Typography variant="h6" gutterBottom color="primary">
              <Checkbox
                checked={credits.creditEntry.detailsStatus}//todo
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              Green Infrastructure Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" >
                  A green infrastructure facility is a piece of equipment designed to capture stormwater. For example, a rain garden is a green infrastructure facility. <br></br>
                  For each green infrastructure facility, enter the source of the stormwater runoff, the type of green infrastructure, and the area of impermeable surfaces that flow to the green infrastructure. For permeable pavers, put in the area of the permeable pavers as the area draining to facility.
                </Typography>
              </Grid>

              <AreaInformation
                data={{
                  "Map Block Lot": credits.creditEntry.MapBlockLot,
                  "Number of Service Agreements": credits.creditEntry.Accounts.length,
                  "Number of Green Infrastructure Facilities": credits.creditEntry.SRDetails.length,
                  "Net Parcel Area": credits.creditEntry.S_Current_Impermeable_Area + credits.creditEntry.S_Current_Permeable_Area,
                  "Billable Impermeable Area": credits.creditEntry.S_Current_Impermeable_Area,
                  "Billable Permeable Area": credits.creditEntry.S_Current_Permeable_Area
                }}
              />

              {credits.creditEntry.SRDetails.length > 0 && credits.creditEntry.SRDetails.map((element, index) =>
              (<Grid item xs={12} key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={4} align="center">
                    <FormControl fullWidth>
                      <InputLabel id="source">Source</InputLabel>
                      <Select
                        labelId="source"
                        value={element.source}
                        name='source'
                        label="Source"
                        onChange={(e) => handleFieldChange(e, index)}
                      >
                        <MenuItem value={"Patio"}>Patio/Driveway</MenuItem>
                        <MenuItem value={"Roof"}>Roof</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} align="center">
                    <FormControl fullWidth>
                      <InputLabel id="type">Type</InputLabel>
                      <Select
                        labelId="type"
                        name='green_Infrastructure_Type'
                        value={element.green_Infrastructure_Type}
                        label="Type"
                        onChange={(e) => handleFieldChange(e, index)}
                      >

                        {
                          element.source === "Patio" && <MenuItem value={"Permeable Paver"}>Permeable Paver</MenuItem>
                        }
                        <MenuItem value={"Rain Garden"}>Rain Garden</MenuItem>
                        <MenuItem value={"Cistern"}>Cistern</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} align="center">
                    <TextField
                      margin='dense'
                      name="f_Area"
                      variant="outlined"
                      label="Area draining to facility"
                      fullWidth
                      value={element.f_Area ? addCommas(element.f_Area) : ''}
                      onChange={(e) => handleFieldChange(e, index)}
                      InputProps={{ readOnly: false, endAdornment: <InputAdornment position="end">ft²</InputAdornment> }}
                    />
                  </Grid>
                </Grid>
              </Grid>)
              )}
              <AddRemoveItem addRow={addRow} removeRow={removeRow} />
              {!credits.creditEntry.detailsStatus &&
                <Grid item xs={12} align="left" >
                  <Typography>{warning}</Typography>
                </Grid>
              }

              {credits.creditEntry.SRDetails &&
                <Grid item xs={12} align="right" >
                  <Typography color="textPrimary">Roof Credit: ${credits.creditEntry.roof_credit}</Typography>
                  <Typography color="textPrimary">Ground-level Credit: ${credits.creditEntry.patio_credit}</Typography>
                  <Typography color="textPrimary">Total Credit: ${credits.creditEntry.total_credit}</Typography>
                </Grid>
              }

              <Grid item xs={12} align="left" key='plan'>
                <FileUploadButton
                  instruction={<Typography style={{ fontWeight: 'bold' }}>Please include design plans. Refer to the {<LinkTextDisplay linkName={'GI Grants for Homes homepage'} href={'https://sfpuc.org/programs/grants/green-infrastructure-grants-homes'} externalLink={true}/>} for guidance on residential green infrastructure design.</Typography>}
                  description='Attach Green Infrastructure Plan'
                  fileChange={credits.creditEntry.handlePlanFileUpload}
                  name='F_Attached_Green_Insfrastructure_Plan' />
              </Grid>

              <Grid item xs={12} align="left" key='photo'>
                <FileUploadButton
                  instruction='Please include photos of the green infrastructure in its current state'
                  description='Attach Green Infrastructure Photo'
                  fileChange={credits.creditEntry.handlePhotoFileUpload}
                  name='F_Attached_Green_Insfrastructure_Photo' />
              </Grid>


            </Grid>
          </AccordionDetails>
        </Accordion>
        <Box p={1} />
      </Container>
    }</>
  )
})

export default SimplifiedResidential;