import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box, Button, Container, Divider, Grid, Paper, Typography, Checkbox, TextField, LinearProgress, FormControl } from '@material-ui/core';
import { useCredits } from '../../stores/CreditStore/CreditModuleStore';
import { useRootStore } from '../../stores/RootStore';

/* Langan Components */
import GeneralInformation from '../../components/credit/credit-form/GeneralInformation';
import CreditAllocation from '../../components/credit/credit-form/CreditAllocation';
import StandardCredit from '../../components/credit/credit-form/StandardCredit';
import SimplifiedResidential from '../../components/credit/credit-form/SimplifiedResidential';
import { addCommas, LinkTextDisplay } from '../../components/data-display/data-display.component';


const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  formRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: "20px",
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
  },
  buttonSubmit: {
    marginBottom: 10,
  },
  link: theme.link,
}));

const CreditFormPage = observer(props => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [rate, setRate] = useState('');
  const [header, setHeader] = useState('Simplified Residential');
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const credits = useCredits();
  const rootStore = useRootStore();

  useEffect(() => {
    if (rootStore && credits) {
      credits.initEntryForm(rootStore.accountStore);
      credits.creditEntry.setItem('Account_Number', rootStore.ccbAccount);
      credits.creditEntry.setItem('Zip_Code', rootStore.zipcode);
      // console.log(getSnapshot(credits.creditEntry))
      //console.log(getSnapshot(credits));
    }
  }, [credits, rootStore]);

  //setting rate explanation for rates
  useEffect(() => {
    if (rootStore.accountStore) {
      if (rootStore.accountStore.stormwaterChargeType === "SRT1") {
        setLink('https://www.sfpuc.org/sites/default/files/accounts-and-services/SCP-Simplified-ResiRate-Credit-App_29JUN23.pdf')
        setRate('Simplified Residential Rate - Tier 1');
      } else if (rootStore.accountStore.stormwaterChargeType === "SRT2") {
        setLink('https://www.sfpuc.org/sites/default/files/accounts-and-services/SCP-Simplified-ResiRate-Credit-App_29JUN23.pdf')
        setRate('Simplified Residential Rate - Tier 2');
      } else if (rootStore.accountStore.stormwaterChargeType === "SRT3") {
        setLink('https://www.sfpuc.org/sites/default/files/accounts-and-services/SCP-Simplified-ResiRate-Credit-App_29JUN23.pdf')
        setRate('Simplified Residential Rate - Tier 3');
      } else if (rootStore.accountStore.stormwaterChargeType === "STANDARD") {
        setLink('https://www.sfpuc.org/sites/default/files/accounts-and-services/SCP-Standard-Rate-Credit-App_29JUN23.pdf')
        setRate('Standard');
        setHeader('Standard');
      }
    } else {
      navigate('/');
    }
  }, [rootStore.accountStore, navigate]);


  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    credits.creditEntry.setItem(name, checked);
    credits.creditEntry.statusComplete();
  }

  const handlePostForm = (e) => {
    e.preventDefault();
    setLoading(true);
    if (credits.creditEntry.Credit_Type === "Standard") {
      credits.creditEntry.submitStandardData().then(response => {
        if (response !== 400) {
          setLoading(false);
          navigate(`/credit/confirmation/${response.credit_Application_ID}`);
        } else {
          navigate(`/credit`)
        }
      });
    } else {
      credits.creditEntry.submitSRdata().then(response => {
        if (response !== 400) {
          setLoading(false);
          navigate(`/credit/confirmation/${response.credit_Application_ID}`);
        } else {
          navigate(`/credit`)
        }
      });
    }

  }

  return (
    <Fragment>
      <CssBaseline />
      {(credits && credits.creditEntry) &&
        <main>
          <div className={classes.heroContent} style={{ backgroundColor: "whitesmoke" }}>
            <Container maxWidth="md">
              <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
                {header} Rate
              </Typography>
              <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
                Stormwater Credit Application
              </Typography>
            </Container>
          </div>
          <div >
            <Container maxWidth="md">
              <Paper className={classes.paper}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    Si necesita ayuda en español llame al (415) 551-3000. <br />
                    Kung kailangan ninyo ng tulong sa Filipino mangyaring tumawag sa (415) 551-3000. <br />
                    如果您需要中文協助、請致電 (415) 551-3000。 <br />
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography variant="body1" >You may also apply by submitting a <LinkTextDisplay href={link} linkName={'paper application'} linkStyle={classes.link} externalLink={true} />. Please refer to the <LinkTextDisplay href='https://www.sfpuc.org/sites/default/files/accounts-and-services/StormwaterCreditGuidebook_JUL2023.pdf' linkName={'Stormwater Credit Guidebook'} linkStyle={classes.link} externalLink={true} /> for more detailed instruction.
                  </Typography>
                </Grid>
                <FormControl className={`${classes.formRoot} ${classes.form}`}>
                  <Grid container spacing={2}>
                    <GeneralInformation />

                    <Grid item xs={12} align="center" style={{ backgroundColor: "whitesmoke" }}>
                      <Typography variant="h5" gutterBottom color="primary">This property is subject to the <br></br>
                        <LinkTextDisplay href='https://sfpuc.org/accounts-services/water-power-sewer-rates/stormwater-charge' linkName={`Stormwater Charge - ${rate} Rate`} linkStyle={classes.link} externalLink={true} />
                        <br></br> (total parcel size {addCommas(props.store.accountStore.grossTotalArea)} ft²)
                      </Typography>
                    </Grid>
                    <Box p={2} />

                    {
                      (rootStore.accountStore && rootStore.accountStore.stormwaterChargeType) === 'STANDARD' ?
                        <StandardCredit />
                        :
                        <SimplifiedResidential />
                    }

                    <CreditAllocation />

                    <Container>
                      <Grid container spacing={2}>
                        <TextField name="F_Applicant_Comments" variant="outlined" label="Applicant Comments" fullWidth multiline
                          value={credits.creditEntry.F_Applicant_Comments} onChange={(e) => credits.creditEntry.setItem(e.target.name, e.target.value)} />
                      </Grid>
                    </Container>

                    <Box p={2} bgcolor="background.paper">
                      <Divider variant="middle" />
                    </Box>

                    <Grid container spacing={0}>
                      <Grid item xs={1} align='center'>
                        <Checkbox
                          color="primary"
                          onChange={handleCheckbox}
                          name="Self_Certified"
                          checked={credits.creditEntry.Self_Certified}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography display='inline' variant='subtitle1'>I self-certify that the Green Infrastructure associated with this Stormwater Credit Application meets the requirements of <LinkTextDisplay href='https://www.sfpuc.org/sites/default/files/accounts-and-services/Resolution_23-0105_Stormwater-Credit%20Program-Rules-Regulations.pdf' linkName={'SFPUC Rules and Regulations Implementing the Stormwater Credit Program'} linkStyle={classes.link} externalLink={true} />. I acknowledge that my Stormwater Credit may be denied or revoked if determined to not meet these Rules and Regulations.</Typography>
                      </Grid>
                    </Grid>

                    <Box p={2} bgcolor="background.paper">
                      <Divider variant="middle" />
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {loading && <LinearProgress />}
                        <Button
                          className={classes.buttonSubmit}
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          disabled={!credits.creditEntry.formReady || loading}
                          onClick={handlePostForm}
                          fullWidth >
                          Submit the application
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormControl>
              </Paper>
            </Container>
          </div>
        </main>
      }
    </Fragment>

  );
});

export default CreditFormPage;