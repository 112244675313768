import React from 'react';
import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

/* MobX */
// import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Typography, Container, Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

/* Langan Components */
import { searchLegalOwnerByID } from '../services/APIClient';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 200px)",
    backgroundColor: theme.palette.background.paper
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  link: theme.link,
}));

const LegalOwnerPage = (props => {
  const classes = useStyles();
  let params = useParams();
  let navigate = useNavigate();

  const [legalOwnerId, setLegalOwnerId] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [entity, setEntity] = useState("");
  const [assessor, setAssessor] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [page, setPage] = useState(5);
  
  const addressHeader = [
    { field: 'legalOwnerName', headerName: 'Legal Owner Name', width: 300 },
    { field: 'mailingAddress1', headerName: 'Mailing Address 1', width: 300 },
    { field: 'mailingAddress2', headerName: 'Mailing Address 2', width: 300 },
    { field: 'mailingAddress3', headerName: 'Mailing Address 3', width: 300 },
    { field: 'startDate', headerName: 'Start Date', width: 300 },
    { field: 'endDate', headerName: 'End Date', width: 300 },
    { field: 'updatedBy', headerName: 'Updated By', width: 300 },
  ]


  useEffect(() => {
    if (params.id) {
      searchLegalOwnerByID(params.id)
        .then((response) => {
          console.log("raw data: ", response);
          setLegalOwnerId(response.legalOwnerId);
          setName(response.legalOwnerName);
          setAddress(response.legalOwnerAddress);
          setEntity(response.entityType ? "Yes" : "No");
          setAssessor(response.assessorBlkLot);
          const formattedData = response.legalOwnerSearchHistory.map((address, index) => {
            const row = {
              id: index,
              legalOwnerName: address.legalOwnerName,
              mailingAddress1: address.mailingAddress1,
              mailingAddress2: address.mailingAddress2,
              mailingAddress3: address.mailingAddress3,
              startDate: address.startDate ? address.startDate.substring(0, 10) : "",
              endDate: address.endDate ? address.endDate.substring(0, 10) : "",
              updatedBy: address.updatedBy,
            }
            return row
          })
          setAddressData(formattedData);
        })
    }
  }, [params.id]);

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.root}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
              Legal Owner Details
            </Typography>
          </Container>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={3} style={{ marginTop: '20px' }}>
                <Typography variant="h6" color="textPrimary">{legalOwnerId}</Typography>
                <Typography variant="body2">SDMS Legal Owner ID</Typography>
              </Grid>
              <Grid item xs={3} style={{ marginTop: '20px' }}>
                <Typography variant="h6" color="textPrimary">{name}</Typography>
                <Typography variant="body2">Legal Owner Name</Typography>
              </Grid>
              <Grid item xs={6} style={{ marginTop: '20px' }}>
                <Typography variant="h6" color="textPrimary">{address}</Typography>
                <Typography variant="body2">Mailing Address</Typography>
              </Grid>
              <Grid item xs={3} style={{ marginTop: '10px' }}>
                <Typography variant="h6" color="textPrimary">{entity}</Typography>
                <Typography variant="body2">Government Entity</Typography>
              </Grid>
              <Grid item xs={3} style={{ marginTop: '10px' }}>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.link}
                  onClick={() => navigate(`/assessor/${assessor.replace("/", "")}`)}
                >
                  {assessor}
                </Typography>
                <Typography variant="body2">Assessor Block Lot</Typography>
              </Grid>
            </Grid>
          </Container>
          <Container>
            <Typography variant="h6" color="textPrimary" style={{ marginTop: '20px' }}>Legal Owner Details History</Typography>
            <div>
              <DataGrid
                className={classes.table}
                columns={addressHeader}
                rows={addressData}
                pageSize={page}
                onPageSizeChange={(pageSize, details) => { setPage(pageSize); }}
                autoHeight
                rowsPerPageOptions={[5, 10]}
              />
            </div>
          </Container>
        </div>
      </main>
    </React.Fragment>
  );
});

export default LegalOwnerPage;
