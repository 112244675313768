import React, { useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Tabs, Tab, Paper, Badge } from '@material-ui/core';

/* Langan Components */
import ReviewedLandUseCode from '../landUseCode/ReviewedLandUseCode';
import UnreviewedLandUseCode from '../landUseCode/UnreviewedLandUseCode';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  wrapper: {
    textTransform: 'initial'
  }
}));

const ManageLandUseCode = observer(props => {
  const classes = useStyles();

  // To handle tabs:
  const [selectedTab, setSelectedTab] = useState(1);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  }


  const getBadgeContent = () => {
    if (props.store.unreviewedLandUseCode) {
      return props.store.unreviewedLandUseCode.length;
    } else {
      return 0;
    }
  }

  
  return (
    <Container maxWidth="lg">
      <Paper className={classes.paper}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Reviewed Land Use Code" className={classes.wrapper}/>
          <Tab label={
            <Badge badgeContent={getBadgeContent()}  color='error'>
              Unreviewed Land Use Code
            </Badge>
          } className={classes.wrapper}/>
        </Tabs>
        {selectedTab === 0 &&
          <ReviewedLandUseCode store={props.store}/>
        }
        {selectedTab === 1 &&
          <UnreviewedLandUseCode handleRefreshData={props.handleRefreshData} store={props.store}/>
        }
      </Paper>
    </Container>
  )
});

export default ManageLandUseCode;
