import React, { useState, useRef, Fragment } from "react";
import { Button, IconButton, Typography, Box } from "@material-ui/core";
import { AcceptableFileTypes, AcceptableFileTypesString } from "../utils/utils";
import { Delete } from "@material-ui/icons";

const defaultHelperText = "Please select a file with .pdf, .jpg or .jpeg extension and with a size less than 200MB";
const fileTypeHelperText = "Please select a file with .pdf, .jpg, .jpeg extension";
const fileSizeHelperText = "The file size should be less than 200MB";
const fileNameLengthHelperText = "The file name is more than 255 and will be truncated when submitting."

export const FileUploadButton = ({ fileChange, disabled, description, name = '', instruction }) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState((disabled || instruction) ? '' : defaultHelperText);
  const [fileName, setFileName] = useState('');
  const fileRef = useRef(null);

  const onChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError(true);
      setHelperText("Please select a file");
      setFileName('');
      return;
    }
    if (file.size > 1024 * 1024 * 200) {
      setError(true);
      setHelperText(fileSizeHelperText);
      setFileName('');
      return;
    }
    let fileType = e.target.files[0].name.split('.').pop();
    if (!AcceptableFileTypes.includes(fileType)) {
      setError(true);
      setHelperText(fileTypeHelperText);
      setFileName('');
      return;
    }

    if (file.name.length >= 255) {
      setHelperText(fileNameLengthHelperText)
    }
    fileChange(e);
    setFileName(file.name);
    setError(false);
    setHelperText("");
  }

  const resetFile = () => {
    fileRef.current.value = null;
    setFileName('');
    fileChange(fileRef.current.input);
    setError(false);
    setHelperText((disabled || instruction) ? '' : defaultHelperText);
  }

  return (
    <Fragment>
      <Button
        variant="contained"
        component="label"
        disabled={disabled}
        color='primary'
      >
        {description}
        <input ref={fileRef} name={name} type="file" accept={AcceptableFileTypesString} hidden onChange={onChange} />
      </Button>
      {(instruction && !disabled) &&
        <Typography variant="body1" component='span'>
          <Box sx={{ fontWeight: 'bold' }}>{instruction}</Box>
        </Typography>}
      <Typography variant="body1" color="textPrimary">
        {disabled ? '' : helperText}
      </Typography>
      {(fileName !== '' && !error) &&
        <Typography component='span' variant="body1" display="inline" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {fileName}
          <IconButton color="primary" aria-label="delete-uploaded-files" component="span" size="small" onClick={resetFile}>
            <Delete />
          </IconButton>
        </Typography>
      }
    </Fragment>
  );
};