import { Fragment, useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Tabs, Tab, Typography, Badge, Box, CssBaseline } from '@material-ui/core';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 4),
    marginTop: theme.spacing(2),
  },
}));

const DashbaordNavigation = observer(props => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  const [selectedTab, setSelectedTab] = useState((props.role.admin || props.role.business) ? "pending" : "credits");

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      if (props.role.admin || props.role.business) {
        setSelectedTab("pending");
        navigate("pending");
      } else if (props.role.credit) {
        setSelectedTab("credits");
        navigate("credits");
      } else {
        setSelectedTab("/dashboard");
        navigate("/dashboard");
      }
    } else {
      setSelectedTab(location?.pathname.split('/')[2]);
      navigate(location?.pathname.split('/')[2]);
    }
  }, [props.role, props.role.admin, props.role.business, props.role.credit])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    navigate(newValue);
  }

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent} >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Dashboard
        </Typography>
        <Tabs value={selectedTab} onChange={handleTabChange} >
          {(props.role.admin || props.role.business) && <Tab label="Pending Appeals" value="pending" />}
          {(props.role.admin || props.role.business) && <Tab label={
            <Badge variant='dot' invisible={props.notif}  color='error'>
              Unassigned Appeals
            </Badge>
          } value="unassigned" />}
          {(props.role.admin || props.role.credit) && <Tab label='Credits' value="credits" />}
        </Tabs>       
      </Container>
      <Outlet />
    </Fragment>
  )
});

export default DashbaordNavigation;