import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    height: theme.spacing(7)
  },
}));

export const SubmitSearchButton = (props => {
    const classes = useStyles();
    const disabled = props.disabled;
    const onClick = props.onClick;
    const isLoading = props.isLoading;
    
    return (
        <Button
          className={classes.buttonSubmit}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
          disabled={disabled}
          onClick={onClick}>
          {
            isLoading 
            ? <CircularProgress color='primary'/> 
            : 'Submit'
          }
        </Button>    
)})