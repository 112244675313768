import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
// import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, FormControl, Grid, Paper, TextField, Typography, IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import EditIcon from '@material-ui/icons/RateReviewOutlined';
import { getAllCredits } from '../../services/APIClient';
import { useCredits as useCreditStore }  from '../../stores/CreditStore/CreditModuleStore';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
  },
  buttonSubmit: {
    // // marginBottom: 10,
    // margin: theme.spacing(1),
    // height: "75%"
    height: "56px"
  },
  table: {
    marginTop: "20px",
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#115293',
      color: theme.palette.common.white,
      fontSize: 14
    },
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: '#fffbf2',
        cursor: 'pointer'
      },
    }
  },
  link: theme.link,
}));

const SearchCredit = props => {
  const classes = useStyles();
  let navigate = useNavigate();
  const credits = useCreditStore();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // for search
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(10);
  const [creditId, setCreditId] = useState('');
  const [account, setAccount] = useState('');
  const [creditIdError, setCreditIdError] = useState(false);
  const [accountError, setAccountError] = useState(false);

  useEffect(() => {
    getAllCredits().then((response) => {
      setData(response);
    }).catch((error) => {
      setIsError(true);
    });
  }, []);


  const creditHeader = [
    { field: 'credit_Application_ID', headerName: 'Credit Application ID', width: 220, disableClickEventBubbling: true, 
      renderCell: (params) => {
        const onClick = () => {
          credits.resetCredits();
          credits.initReviewCredit(params.id).then((response) => {
            navigate(`/credit/review/${params.row.credit_Type}/${params.id}`);
          })   
        }
        return <Button color='primary' onClick={onClick}>
          <Typography className={classes.link}>{params.id}</Typography>
          </Button>
      }},
    { field: 'credit_Type', headerName: 'Credit Type', width: 220 },
    { field: 'account_Number', headerName: 'Account #', width: 180 },
    { field: 'zip_Code', headerName: 'Zipcode', width: 150 },
    { field: 'applicant_Name', headerName: 'Applicant Name', width: 180 },
    {
      field: 'submittal_Date',
      headerName: 'Date',
      width: 140,
      valueFormatter: (params) => {
        return params.value?.substring(0, 10);
      },
    },
  ];


  const filterData = (e) => {
    const {name, value} = e.target;
    if (value === '') {
      setFilteredData(data);
      setCreditIdError(false);
      setAccountError(false);
      return;
    } else {
      const dataCopy = data.slice();
      if (name === 'credit_Application_ID') {
        const temp = dataCopy.filter((row) => row.credit_Application_ID === parseInt(value))
        setFilteredData(temp)
        if (temp.length === 0) {
          setCreditIdError(true);
        } else {
          setCreditIdError(false);
        }
      } else if (name === 'account_Number') {
        const temp = dataCopy.filter((row) => row.account_Number.includes(value))
        setFilteredData(temp)
        if (temp.length === 0) {
          setAccountError(true);
        } else {
          setAccountError(false);
        }
      }
    }
  }

  const searchData = (e) => {
    const {name, value} = e.target;
    const dataCopy = data.slice();
      if (name === 'credit_Application_ID') {
        const temp = dataCopy.filter((row) => row.credit_Application_ID === parseInt(creditId))
        setFilteredData(temp)
        if (temp.length === 0) {
          setCreditIdError(true);
        } else {
          setCreditIdError(false);
        }
      } else if (name === 'account_Number') {
        const temp = dataCopy.filter((row) => row.account_Number.includes(account))
        setFilteredData(temp)
        if (temp.length === 0) {
          setAccountError(true);
        } else {
          setAccountError(false);
        }
      }
  }

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Typography variant="h6">Credits Search </Typography>
        {/* <form autoComplete="off" noValidate className={classes.form} onSubmit={appealsHandleSubmit}> */}
        <FormControl fullWidth margin='dense' onKeyPress={(e) => {
          if (e.key === 'Enter') {
            searchData(e)
          }
        }} >
        <Grid container spacing={1} className={classes.form}>
          {/* Search by Customer Appeal ID */}
          <Grid item xs={10}>
            <TextField
              name="credit_Application_ID"
              variant="outlined"
              label="Credit Application ID(Example: 1)"
              type="search"
              error={creditIdError}
              helperText={creditIdError ? 'The credit is not found.' : ''}
              fullWidth
              value={creditId}
              onChange={(e) => {
                setAccount('');
                setCreditId(e.target.value);
                filterData(e);
              }}
              
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              className={classes.buttonSubmit}
              name='credit_Application_ID'
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
              onClick={(e) => searchData(e)}
              disabled={creditId ? false : true}
            >
              Submit
            </Button>
          </Grid>

          {/* Search by Account NO */}
          <Grid item xs={10}>
            <TextField
              name="account_Number"
              variant="outlined"
              label="Account No(Example: 2690300000)"
              type="search"
              fullWidth
              error={accountError}
              helperText={accountError ? 'The records are not found.': ''}
              value={account}
              onChange={(e) => {
                setCreditId('');
                setAccount(e.target.value);
                filterData(e);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              className={classes.buttonSubmit}
              name='account_Number'
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
              onClick={(e) => filterData(e)}
              disabled={account ? false : true}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        </FormControl>

        {data && <DataGrid
            className={classes.table}
            columns={creditHeader}
            rows={filteredData.length > 0 ? filteredData : data}
            onCellClick={() => console.log("click")}
            pageSize={page}
            onPageSizeChange={(pageSize) => { setPage(pageSize); }}
            autoHeight
            getRowId={(row) => row.credit_Application_ID}
            rowsPerPageOptions={[10, 25]}
          />}
        {/* </form > */}
      </Paper >
    </Container>
  )
};

export default SearchCredit;