import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Button, Typography, LinearProgress } from '@material-ui/core';
import { validateAccount } from '../../../services/APIClient';
// const data = {
//   mapBlkLot: "0194012",
//   grossTotalArea: 5145,
//   permeableArea: 0,
//   billablePermeableArea: 45,
//   impermeableArea: 5145,
//   billableImpermeableArea: 5100,
//   excludedArea: 0,
//   isSimplifiedResidential: false,
//   numberOfServiceAgreement: 0,
//   stormwaterChargeType: 'STANDARD',
//   assessorBlkLotList: [
//     {
//       assessorBlkLot: "0194/012",
//       legalOwnerName: "SOO YUEN BENEVOLENT ASSN",
//       propertyAddress: "900 GRANT AVE - 916 GRANT AVE"
//     }
//   ],
//   areaHistory: [
//     {
//       grossTotal: 5145.807507,
//       permeableArea: 11.84581849,
//       impermeableArea: 5133.177323,
//       excludedArea: 0,
//       startDate: "2021-12-28T00:00:00",
//       endDate: "2021-12-31T00:00:00"
//     }
//   ],
//   accounts: [
//     {
//       account: "0491911060"
//     },
//     {
//       account: "0970707319"
//     },
//     {
//       account: "3190800000"
//     },
//     {
//       account: "3391115521"
//     },
//     {
//       account: "6999006933"
//     },
//     {
//       account: "7223910000"
//     },
//     {
//       account: "9999696791"
//     }
//   ]
// };
const AppealDialog = observer(props => {
  let navigate = useNavigate();

  const [account, setAccount] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [simple, setSimple] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.store.ccbAccount) {
      setAccount(props.store.ccbAccount);
    };

    if (props.store.zipcode) {
      setZipcode(props.store.zipcode);
    }
  }, [props.store])

  const updateAccount = (event) => {
    setAccount(event.target.value?.trim());
    setMessage("");
  };

  const updateZipcode = (event) => {
    setZipcode(event.target.value?.trim());
    setMessage("");
  };

  const handleChange = (event) => {
    setSimple(event.target.checked);
  };

  const submitAppeal = () => {
    // Validate account
    const params = {
      "accountNumber": account,
      "zipCode": zipcode
    };
    // props.store.setItem("ccbAccount", account);
    // props.store.setItem("zipcode", zipcode);
    // props.store.setItem("isSimply", simple);
    // props.store.setItem("area", data);
    // if (data.stormwaterChargeType != 'EXEMPT') {
    //   props.store.setItem("isExempt", false);
    //   navigate(`/appeals/${account}/${zipcode}`);
    // } else {
    //   props.store.setItem("isExempt", true);
    //   navigate(`/appeals/${account}/${zipcode}`);
    //   //setMessage('This account is exempted from stormwater charge');
    // }     
    setIsLoading(true);
    setMessage("");
    validateAccount(params)
      .then(response => {
        //console.log("response", response)
        if (response.status === 200) {
          let data = response.data;
          props.store.setItem("ccbAccount", account);
          props.store.setItem("zipcode", zipcode);
          props.store.setItem("isSimply", simple);
          props.store.setItem("area", data);
          if (data.stormwaterChargeType != 'EXEMPT') {
            props.store.setItem("isExempt", false);
            setIsLoading(false);
            navigate(`/appeals/${account}/${zipcode}`);
          } else {
            props.store.setItem("isExempt", true);
            setIsLoading(false);
            navigate(`/appeals/${account}/${zipcode}`);
          }     
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          console.log("error.response", error.response);
          setMessage("Account Number or Zip Code is invalid")
        }
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Sign In</DialogTitle>
      <DialogContent>
        <DialogContentText style={{color:'#0A0A0A'}}>
          Enter Account Number and ZIP Code to file an appeal.
          {isLoading && <LinearProgress />}
        </DialogContentText>
        <TextField autoFocus margin="dense" id="acc_no" label="Account Number" type="search" value={account} onChange={updateAccount} fullWidth disabled={isLoading}/>
        <TextField margin="dense" id="zip_code" label="Service Address ZIP Code" type="search" value={zipcode} onChange={updateZipcode} fullWidth disabled={isLoading}/>
        <div>
          <Typography variant="caption" color='error'>{message}</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={submitAppeal} color="primary" disabled={isLoading}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default AppealDialog;