import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
import { observer } from 'mobx-react-lite';


import { validateAccount } from "../../services/APIClient";

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Grid, TextField, Typography, LinearProgress } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "2em"
  },
  searchbox: {
    paddingLeft: "2em",
    paddingRight: "2em"
  }
}));

const Search = observer(props => {
  const classes = useStyles();
  let navigate = useNavigate();

  const [account, setAccount] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const updateAccount = (event) => {
    setAccount(event.target.value?.trim());
    setMessage("");
  };

  const updateZipcode = (event) => {
    setZipcode(event.target.value?.trim());
    setMessage("");
  };

  const buttonHandle = (event) => {
    event.preventDefault();
    // Validate account
    const params = {
      "accountNumber": account,
      "zipCode": zipcode
    }
    setIsLoading(true);

    validateAccount(params)
      .then(response => {
        //console.log("response", response)
        if (response.status === 200) {
          setIsLoading(false);
          props.store.setItem("ccbAccount", account);
          props.store.setItem("zipcode", zipcode);
          const mapBlkLot = response.data.mapBlkLot;
          props.store.setItem("mapBlkLot", mapBlkLot);
          props.store.setItem("parcelData", response.data)
          navigate(`/parcel/${mapBlkLot}`, {state: {stormwaterChargeType: response.data.stormwaterChargeType, numOfAccount: response.data.numberOfServiceAgreement}});
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          //console.log("error.response", error.response);
          setMessage("Account not found. Please try again.")
        }
      });

  };

  return (
    <Paper align="center" className={classes.root}>
      <form autoComplete="off" noValidate onSubmit={buttonHandle} >
        <Grid container className={classes.searchbox} spacing={3} >
          <Grid item xs={6}>
            <TextField
              name="acc_no"
              value={account}
              variant="outlined"
              label="Account Number"
              type="search"
              onChange={updateAccount} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="zip_code"
              value={zipcode}
              variant="outlined"
              label="Service Address ZIP Code"
              type="search"
              onChange={updateZipcode} />
          </Grid>
        </Grid>

        <div>
          <Typography variant="caption" color='error'>{message}</Typography>
        </div>
        {isLoading && <LinearProgress />}
        <Button variant="contained" color="primary" disabled={isLoading} onClick={buttonHandle} fullWidth type='submit'>
          Submit
        </Button>
      </form>
    </Paper>
  )
});

export default Search;