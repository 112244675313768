import { useEffect, useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, Container, Box, Divider } from '@material-ui/core';
import FormInputTypography from '../../form-input/form-input-typography.component';
import FormAttachmentList from '../../form-input/form-attachment-list.component';
import { useCredits } from '../../../stores/CreditStore/CreditModuleStore';


const CreditAllocation = observer((props) => {
  const credits = useCredits();
  const [data, setData] = useState(null);
  const [seeDetails, setSeeDetails] = useState(true);

  useEffect(() => {
    if (credits.creditSubmission && !credits.creditReview) {
      setData(credits.creditSubmission);
    } else if (credits.creditReview) {
      setData(credits.creditReview);
    }
  }, [credits])

  return (
    <> {data &&
      <Container>
        <Accordion expanded>
          <AccordionSummary id="SAChargeAllocAppeal" style={{ backgroundColor: "whitesmoke" }}>
            <Typography variant="h6" gutterBottom color="primary">
              Credit Split
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} />
              {data.creditsDocument &&
                <FormAttachmentList
                  label='Submitted Documents'
                  size={6}
                  file={data.creditsDocument.filter(element => element.description === 'Agreement Document' && element.document_Type === 'Credit')}
                  type='Credit' />
              }
              <Button
                variant="contained"
                fullWidth
                component="label"
                color='primary'
                onClick={() => setSeeDetails(!seeDetails)}
              >
                {seeDetails ? "Hide details" : "Show credit split details"}
              </Button>
              {seeDetails && data.creditsDistributionData.length > 0 && data.creditsDistributionData.map((element, index) =>
              (<Grid item xs={12} key={index}>
                <Box p={1} />
                <Typography variant="h6" color="textPrimary" align="left">
                  {`Account ${index + 1}`}
                </Typography>
                <Box p={1} />
                <Divider />
                <Grid container spacing={2}>
                  <FormInputTypography variant='body1' size={6} align='left' name="f_Appealed_Account_No" label={`Account Number`} value={element.f_Appealed_Account_No} />
                  <FormInputTypography variant='body1' size={6} align='left' name="f_Appealed_Distb_Percent" label={`Split Percentage`} value={element.f_Appealed_Distb_Percent + ' %'} />
                  <FormInputTypography variant='body1' size={6} align='left' name="f_Distributed_Credit_Amount" label={`Split Amount`} value={'$ ' + element.f_Distributed_Credit_Amount} />
                </Grid>
              </Grid>)
              )}
              <Grid item xs={9} align="left">
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Box p={1} />
      </Container>}
    </>
  )
})

export default CreditAllocation;