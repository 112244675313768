
import { types, flow, getParent, getSnapshot } from "mobx-state-tree";
import { submitManualAppeal, getManualAppealFiles, getManualAppealById } from "../../services/APIClient";

const FileStore = types.model({
  doc_ID: types.number,
  file_Path: types.string,
  fileName: types.string,
  document_Type: types.string,
  description: types.string
});

const ManualAppealForm = types.model({
  Account_No: types.string,
  Wastewater_Sa_ID: types.string,
  Premise_ID: types.string,
  Assessors_BlkLot: types.string,
  Map_Block_Lot_No: types.string,
  Correction_Type: types.string,
  Existing_Field: types.string,
  Corrected_Fields: types.string,
  F_Appealed_Net_Total_Area: types.maybeNull(types.number),
  R_Review_Submitted_By: types.string,
  R_Document_Location: types.string,
  F_Applicant_Comments: types.string,
  R_Decision: types.string,
  R_Decision_Date: types.string,
  R_Date_Implemented: types.maybeNull(types.string)
})
  .volatile(self => ({
    AttachmentFiles: [],
  }))
  .actions(self => ({
    afterCreate() {
    },
    isReady() {
      let status = false;
      if (self.Assessors_BlkLot !== '' && self.premise_ID !== '' && self.AttachmentFiles.length > 0 && self.R_Decision !== '' && self.R_Decision_Date !== '') {
        if (self.R_Decision === 'Approved') {
          if (self.R_Date_Implemented !== null && self.R_Date_Implemented !== ''){
            status = true;
          }
        }
        status = true;
      }
      return status;
    },
    setItem(item, value) {
      if (item === 'F_Appealed_Net_Total_Area') {
        value = parseFloat(value);
      }
      self[item] = value;
    },
    reset() {
      Object.keys(getSnapshot(self)).forEach((key) => {
        if (key !== 'F_Appealed_Net_Total_Area') {
          self.setItem(key, '');
        } else {
          self.setItem(key, 0);
        }
      });
      self.AttachmentFiles = [];
    },
    submit: flow(function* submit() {
      let formData = new FormData();
      if (self.R_Decision === 'Denied') {
        self.R_Date_Implemented = '';
      }
      Object.entries(getSnapshot(self)).forEach(([key, value]) => {
        if (key !== 'AttachmentFiles') {
          formData.append(key, value);
        }
      });
      self.AttachmentFiles.forEach((file) => {
        formData.append('AttachmentFiles', file);
      })
      const response = yield submitManualAppeal(formData);
      if (response.status === 201) {
        getParent(self).initManualAppealView(response.data);
        if (getParent(self).manualAppealView) {
          self.reset();
          return 200;
        }
      }
      return response;
    }),
  }))

const ManualAppealView = types.model({
  manual_Appeal_ID: types.number,
  account_No: types.maybeNull(types.string),
  wastewater_Sa_ID: types.maybeNull(types.string),
  map_Block_Lot_No: types.maybeNull(types.string),
  s_Appealed_Date: types.string,
  f_Applicant_Documents: types.maybeNull(types.string),
  f_Applicant_Comments: types.maybeNull(types.string),
  f_Appealed_Net_Total_Area: types.maybeNull(types.number),
  //r_Approved_Net_Total_Area: t.null,
  r_Reviewer_Documents: types.maybeNull(types.string),
  r_Reviewer_Comments: types.maybeNull(types.string),
  r_Decision: types.maybeNull(types.string),
  r_Decision_Date: types.maybeNull(types.string),
  r_Date_Implemented: types.maybeNull(types.string),
  r_Review_Submitted_By: types.maybeNull(types.string),
  flag: types.maybeNull(types.string),
  r_Document_Location: types.maybeNull(types.string),
  assessors_BlkLot: types.string,
  premise_ID: types.string,
  correction_Type:types.maybeNull(types.string),
  existing_Field: types.maybeNull(types.string),
  corrected_Fields: types.maybeNull(types.string),
  files: types.maybeNull(types.array(FileStore), []),
})
  .actions(self => ({
    afterCreate() {
      self.getFiles(self.manual_Appeal_ID);
    },
    getFiles: flow(function* getFiles(id) {
      const response = yield getManualAppealFiles(id);
      self.files = response.data.map((file) => { return FileStore.create(file) });
    }),
    reset() {
      Object.keys(getSnapshot(self)).forEach((key) => {
        if (key !== 'files' && key !== 'f_Appealed_Net_Total_Area' && key !== 'manual_Appeal_ID') {
          self[key] = '';
        } else {
          if (key === 'files') {
            self[key] = [];
          } else {
            self[key] = 0;
          }
        }
      });
    },
  }))


const ManualAppealStore = types.model({
  manualAppealForm: types.maybeNull(ManualAppealForm),
  manualAppealView: types.maybeNull(ManualAppealView),
})
  .actions(self => ({
    afterCreate() {
      self.initManualAppealForm();
    },
    initManualAppealForm() {
      self.manualAppealForm = ManualAppealForm.create({
        Account_No: '',
        Wastewater_Sa_ID: '',
        Premise_ID: '',
        Assessors_BlkLot: '',
        Map_Block_Lot_No: '',
        Correction_Type: '',
        Existing_Field: '',
        Corrected_Fields: '',
        F_Appealed_Net_Total_Area: 0,
        R_Review_Submitted_By: '',
        R_Document_Location: '',
        F_Applicant_Comments: '',
        R_Decision: '',
        R_Decision_Date: new Date().toISOString(),
        R_Date_Implemented: '',
      });
    },
    initManualAppealView(data) {
      self.manualAppealView = ManualAppealView.create(data);
    },
    getManualAppeal: flow(function* getManualAppeal(id) {
      const response = yield getManualAppealById(id);
      self.initManualAppealView(response);
      if (response) {
        return 200;
      }
    }),
  }))

let _manualAppealStore;
export const useManualAppealStore = () => {
  if (!_manualAppealStore) {
    _manualAppealStore = ManualAppealStore.create({});
  }
  return _manualAppealStore;
}
