import React, { Fragment } from "react";

/* Langan custom components */
import EsriMap from "../components/map/EsriMap";
import HomeWidget from "../components/map/HomeWidget";
import LegendWidget from "../components/map/LegendWidget";
import FeatureLayer from "../components/map/FeatureLayer";
import ParcelPopup from "../components/map/ParcelPopup";
import { CssBaseline } from "@material-ui/core";

function MapPage() {

  return (
    <Fragment>
      <CssBaseline />
      <div>
        <EsriMap screenSize="full" itemId={process.env.REACT_APP_WEBMAP_ID}>
          <HomeWidget />
          <LegendWidget />
          <FeatureLayer popupTemplate={ParcelPopup} />
        </EsriMap>
    </div>
    </Fragment>    
  );
}

export default MapPage;
