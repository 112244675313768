import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useManualAppealStore } from '../../stores/AppealStore/ManualAppealStore';
import ManageGenericAppeals from '../../components/admin/generic-appeal/ManageGenericAppeals';
import GenericAppealForm from '../../components/admin/generic-appeal/GenericAppealForm';
import GenericAppealView from '../../components/admin/generic-appeal/GenericAppealView';
import { useRootStore } from '../../stores/RootStore';

/* Langan Components */

const GenericAppealsRouter = () => {
  const manualAppeal = useManualAppealStore();
  const root = useRootStore()

  return (
    <Routes>
      <Route index element={<ManageGenericAppeals store={manualAppeal}/>} />
      <Route path='/new' exact element={<GenericAppealForm form={manualAppeal.manualAppealForm} root={root}/>} />
      <Route path='/view' exact element={<GenericAppealView view={manualAppeal.manualAppealView}/>} />
      {/* <Route path='/:account/:zipcode' exact element={<AppealFormPage store={props.store} />} />
      <Route path='confirmation/:account/:zipcode' exact element={<AppealConfirmationPage store={props.store} />} />
      <Route path='review/:type/:appealid' exact element={<AppealReviewPage store={props.store}/>} /> */}
    </Routes>
  )
}

export default GenericAppealsRouter;