import React from 'react';

/* MobX */
// import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Typography } from '@material-ui/core';

/* API */
import { searchParcelByMapBlock, searchParcelByAssessorBlock } from '../../services/APIClient';

import { SearchFieldComponent } from './SearchFieldComponent';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
  }
}));

const SearchMap = (props) => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Typography variant="h6">Map Block Lot Search </Typography>
          <Grid container spacing={1} className={classes.form}>
            <SearchFieldComponent 
                name="mapblklot_no"
                label="Map Block Lot (Example: 0838037)"
                searchAPI={searchParcelByMapBlock} 
                searchType="parcel"
              />
          </Grid>
      </Paper>
    </Container>
  )
};

export default SearchMap;