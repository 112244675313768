import { useEffect, useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Container, Box, Divider } from '@material-ui/core';
import FormInputTypography from '../../form-input/form-input-typography.component';
import FormAttachmentList from '../../form-input/form-attachment-list.component';
import { AreaInformation, SubTitle, addCommas } from '../../data-display/data-display.component';
import { useCredits } from '../../../stores/CreditStore/CreditModuleStore';

const StandardCredit = observer((props) => {
  const credits = useCredits();
  const [data, setData] = useState(null);
  const [perm_credit, setPerm_credit] = useState(0);
  const [imperm_credit, setImperm_credit] = useState(0);

  // todo have it calculate it in the store, asking api to return credit amount
  useEffect(() => {
    if (credits.creditSubmission && !credits.creditReview) {
      let creditAmount = credits.creditSubmission.calculateStandardCreditAmount();
      setPerm_credit(creditAmount.perm_credit);
      setImperm_credit(creditAmount.imperm_credit);
      setData(credits.creditSubmission);
    } else if (credits.creditReview) {
      let perm = 0;
      let imperm = 0
      credits.creditReview.creditsSTDTypeDetail.forEach(item => {
        //console.log(item.f_Permeable_Credit_Amount);
        perm += item.f_Permeable_Credit_Amount;
        imperm += item.f_Impermeable_Credit_Amount;
      })
      setPerm_credit(Math.floor(perm * 100) / 100);
      setImperm_credit(Math.floor(imperm * 100) / 100);
      setData(credits.creditReview);
    }
  }, [credits])

  return (
    <>{data &&
      <Container>
        <Accordion expanded>
          <AccordionSummary id="StandardCreditConfirmation" style={{ backgroundColor: "whitesmoke" }}>
            <Typography variant="h6" gutterBottom color="primary">
              Green Infrastructure Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <AreaInformation
                data={{
                  "Map Block Lot": data.mapBlockLot,
                  "Number of Service Agreements": data.creditsDistributionData.length,
                  "Number of Green Infrastructure Facilities": data.creditsSTDTypeDetail.length,
                  "Net Total Area": data.s_Current_Impermeable_Area + data.s_Current_Permeable_Area,
                  "Billable Impermeable Area": data.s_Current_Impermeable_Area,
                  "Billable Permeable Area": data.s_Current_Permeable_Area
                }}
              />

              {data.creditsSTDTypeDetail && data.creditsSTDTypeDetail.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <SubTitle value={`Green Infrastructure Facility ${index + 1}`} />
                  <Grid container spacing={1}>
                    <FormInputTypography variant='body1' size={6} align='left' label={'Facility Type'} value={item.f_Facility_Type} />
                    <FormInputTypography variant='body1' size={6} align='left' label={'Credited Type'} value={item.f_Credited_Type} />
                    <FormInputTypography variant='body1' size={6} align='left' label={'Facility size'} value={item.f_Facility_Size + ' ft²'} />
                    <FormInputTypography variant='body1' size={6} align='left' label={'Impermeable area managed by facility'} value={addCommas(item.f_Impermeable_Area_Drain) + ' ft²'} />
                    <FormInputTypography variant='body1' size={6} align='left' label={'Permeable area managed by facility'} value={addCommas(item.f_Permeable_Area_Drain) + ' ft²'} />
                  </Grid>
                  <Divider />
                </Grid>
              ))
              }

              {data.creditsDocument &&
                <FormAttachmentList
                  label='Submitted Documents'
                  size={6}
                  file={data.creditsDocument.filter(element => element.description !== 'Agreement Document' && element.document_Type === 'Credit')}
                  type='Credit' />
              }

              <Grid item xs={12} align="right" >
                <Typography color="textPrimary">Permeable Credit: ${perm_credit}</Typography>
                <Typography color="textPrimary">Impermeable Credit: ${imperm_credit}</Typography>
                <Typography color="textPrimary">Total Credit: ${(perm_credit + imperm_credit).toFixed(2)}</Typography>
              </Grid>

            </Grid>
          </AccordionDetails>
        </Accordion>
        <Box p={1} />
      </Container>
    }</>
  )
});

export default StandardCredit;
