import { useState, useEffect } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Grid, TextField, Typography, Container, Box } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useCredits } from '../../../stores/CreditStore/CreditModuleStore';
import { FileUploadButton } from '../../form-input/form-file-upload-button.component';


const CreditAllocationModule = observer((props) => {
  const credits = useCredits();
  const [warning, setWarning] = useState("");
  const [seeDetails, setSeeDetails] = useState(false);
  const [equalAlloc, setEqualAlloc] = useState(true);

  useEffect(() => {
    const form = credits.creditEntry;
    if (form.Accounts.length > 0) {
      let length = form.Accounts.length;
      form.Accounts.map(element => {
        element.setItem("f_Appealed_Distb_Percent", (Math.round(100 / length * 100) / 100));
        element.setItem('f_Distributed_Credit_Amount', (Math.floor(form.total_credit / length * 100) / 100));
        return element;
      })
    }
  }, [credits, credits.creditEntry.total_credit]);

  useEffect(() => {
    const form = credits.creditEntry;
    setWarning(form.checkAllocationStatus());
  }, [credits, credits.creditEntry.allocationStatus])


  const handleEqualAllocation = (event) => {
    const { name, checked } = event.target;
    const form = credits.creditEntry;
    setEqualAlloc(!equalAlloc);
    form.setItem(name, checked);
    //console.log(form[name]);
    if (checked) {
      form.handleAgreementFileUpload(null);
      let length = form.Accounts.length;
      form.Accounts.map(element => {
        element.setItem("f_Appealed_Distb_Percent", (Math.round(100 / length * 100) / 100));
        element.setItem('f_Distributed_Credit_Amount', (Math.floor(form.total_credit / length * 100) / 100));

      })
    } else {
      form.Accounts.map(element => {
        element.setItem("f_Appealed_Distb_Percent", 0)
        element.setItem('f_Distributed_Credit_Amount', 0)
      })
    }
    setWarning(form.checkAllocationStatus());
  }

  const handleFieldChange = (event, idx) => {
    const { value } = event.target;
    // render the equal allocation checkbox unchecked
    if (equalAlloc) {
      setEqualAlloc(!equalAlloc);
    }
    const form = credits.creditEntry;
    let tmp = form.Accounts.slice()
    tmp[idx] = {
      f_Appealed_Account_No: tmp[idx].f_Appealed_Account_No,
      f_Appealed_Distb_Percent: parseFloat(value) ? parseFloat(value) : 0,
      f_Distributed_Credit_Amount: (Math.floor(form.total_credit * (value / 100) * 100) / 100),
    }
    const equalSplit = tmp.filter(element => element.f_Appealed_Distb_Percent !== tmp[0].f_Appealed_Distb_Percent);
    if (equalSplit.length === 0) {
      setEqualAlloc(true);
      form.handleAgreementFileUpload(null);
      form.setItem("equalSplit", true);
    } else {
      setEqualAlloc(false);
      form.setItem("equalSplit", false);
    }
    form.setItem("Accounts", tmp);
    setWarning(form.checkAllocationStatus());
  }

  return (
    <>{(credits && credits.creditEntry) &&
      <Container>
        <Accordion expanded>
          <AccordionSummary
            id="creditAlloc" style={{ backgroundColor: "whitesmoke" }}>
            <Typography variant="h6" gutterBottom color="primary">
              <Checkbox
                checked={credits.creditEntry.allocationStatus}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              Credit Split
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" display='inline' component='span'>
                  <Box sx={{ fontWeight: 'normal' }} >If applying for a stormwater credit on a parcel with multiple service accounts, the default distribution of the credit is equally split across all accounts on the property.</Box>
                  For example, three accounts on a property would each receive a third of the credit. No further documentation is needed if the applicant agrees with this split.
                  Applicants may choose a customized credit split for accounts on the property, increasing or decreasing the share of the credit each specific account receives. For example, a property with two accounts may choose to have one account receive 75% of the credit and give the remaining 25% to the other account.
                  <Box sx={{ fontWeight: 'normal' }} > A customized stormwater credit split must have a signed agreement from all account holders on the property and fill out the table below.</Box>
                </Typography>
              </Grid>

              <Grid item xs={6} align="center">
                <Typography variant="body2" display='inline'>Total Credit to split: </Typography>
                <Typography variant="h6" color="textPrimary" display='inline'>
                  ${credits.creditEntry.total_credit}
                </Typography>
              </Grid>

              <Grid item xs={6} align="center">
                <Checkbox
                  color="primary"
                  onChange={handleEqualAllocation}
                  name="equalSplit"
                  checked={equalAlloc}
                />
                <Typography variant="body2" display='inline'>Equally split among all accounts</Typography>
              </Grid>
              


              <Button
                variant="contained"
                fullWidth
                component="label"
                color='primary'
                onClick={() => setSeeDetails(!seeDetails)}
              >
                {seeDetails ? "Hide details" : "Show credit split details"}
              </Button>
              {seeDetails && credits.creditEntry.Accounts.length > 0 && credits.creditEntry.Accounts.map((element, index) =>
              (<Grid item xs={12} key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={6} align="center">
                    <TextField
                      name="f_Appealed_Account_No"
                      variant="filled"
                      fullWidth
                      label={`Account Number ${index + 1}`}
                      value={element.f_Appealed_Account_No}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3} align="center">
                    <TextField
                      name="f_Appealed_Distb_Percent"
                      variant="outlined"
                      label="Split Percentage"
                      fullWidth
                      value={element.f_Appealed_Distb_Percent}
                      onChange={(e) => handleFieldChange(e, index)}
                      InputProps={{
                        min: 0,
                        max: 100,
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                    />
                  </Grid>

                  <Grid item xs={3} align="center">
                    <TextField
                      name="f_Distributed_Credit_Amount"
                      variant="filled"
                      label="Split Amount"
                      fullWidth
                      type="number"
                      value={element.f_Distributed_Credit_Amount}
                      InputProps={{
                        readOnly: true,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>

                </Grid>
              </Grid>)
              )}
              {!credits.creditEntry.allocationStatus &&
                <Grid item xs={12} align="left" >
                  <Typography>{warning}</Typography>
                </Grid>
              }
              {
                (credits.creditEntry.Accounts.length > 1 && !equalAlloc) &&
                <Grid item xs={12} align="left" key='plan'>
                  <FileUploadButton
                    description='Attach Signed Agreement'
                    fileChange={credits.creditEntry.handleAgreementFileUpload}
                    name='F_Attached_Agreement_Doc'
                  />
                </Grid>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Box p={1} />
      </Container>}
    </>
  )
})

export default CreditAllocationModule;