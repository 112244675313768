import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/RateReviewOutlined';
import { DataGrid } from '@material-ui/data-grid';

/* Components */
import ReviewLandUseCodeForm  from './ReviewLandUseCodeForm.component'
import { applySnapshot, getSnapshot, clone } from 'mobx-state-tree';



const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  }
}));

const UnreviewedLandUseCode = observer(props => {
  const classes = useStyles();
  const [page, setPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  
  let store = props.store;
  let data = getSnapshot(store.unreviewedLandUseCode);

  const handleClose = () => {
    setOpen(false);
  };

  const header = [
    { field: 'classCode', headerName: 'Class Code', width: 165 },
    { field: 'classDefinition', headerName: 'Class Definition', width: 220 },
    { field: 'useCode', headerName: 'Use Code', width: 165 },
    { field: 'customerClass', headerName: 'Customer Class', width: 200 },
    { field: 'landlordReversion', headerName: 'Landlord Reversion', width: 220 },
    { 
        field: 'review', 
        headerName: 'Review', 
        width: 150,
        sortable: false,
        renderCell: (params) => {
            const reviewButton = () => {
                setId(params.id)
                setOpen(true);
                //console.log('form data', formData);
            }; 
            return <IconButton color='primary' aria-label='review' onClick={reviewButton}>
                    <EditIcon />
                  </IconButton>
        }
    }
  ];

  return (
    <Container maxWidth="lg">
      <Paper className={classes.paper}>
        <DataGrid
          columns={header}
          rows={data}
          getRowId={(row) => row.classCode}
          pageSize={page}
          onPageSizeChange={(pageSize) => { setPage(pageSize); }}
          autoHeight
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Paper>
      <ReviewLandUseCodeForm
        open={open}
        handleClose={handleClose}
        handleRefreshData={props.handleRefreshData}
        store={store}
        id={id}
       />
    </Container>
    
  )
});

export default UnreviewedLandUseCode;