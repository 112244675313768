import { types, flow, getParent, getSnapshot } from "mobx-state-tree";
import { CreditType, FacilityType, creditedTypeLookup, IMPERM_RATE, PERM_RATE } from "./CreditConstant";
import { postStandard, postSR } from "../../services/APIClient";
import CreditSubmissionStore from "./CreditSubmissionStore";

const SRDetails = types.model('SRDetails', {
  source: types.optional(types.string, ''),
  green_Infrastructure_Type: types.optional(types.string, ''),
  f_Area: types.maybeNull(types.number),
  f_Credit_Amount: types.maybeNull(types.number),
})
  .actions((self) => ({
    setItem(item, value) {
      self[item] = value;
    },
  }))

const StandardDetails = types
  .model('StandardDetails', {
    f_Impermeable_Area_Drain: types.optional(types.number, 0),
    f_Impermeable_Credit_Amount: types.optional(types.number, 0),
    f_Permeable_Area_Drain: types.optional(types.number, 0),
    f_Permeable_Credit_Amount: types.optional(types.number, 0),
    f_Facility_Type: types.optional(types.enumeration([
      FacilityType.Bioretention,
      FacilityType.Swale,
      FacilityType.Trench,
      FacilityType.Pavement,
      FacilityType.Roof,
      FacilityType.OtherNonVegetated,
      FacilityType.OtherVegetated,
      FacilityType.Init,
      FacilityType.Media,
      FacilityType.DetentionVault,
      FacilityType.DryWell,
      FacilityType.Cistern]), FacilityType.Init),
    f_Facility_Size: types.optional(types.number, 0),
    f_Credited_Type: types.optional(types.enumeration([CreditType.Impermeable, CreditType.Permeable, CreditType.None]), CreditType.None),
  })
  .actions((self) => ({
    setItem(item, value) {
      self[item] = value;
    },
    setCreditedType() {
      if (creditedTypeLookup.hasOwnProperty(self.f_Facility_Type)) {
        self.f_Credited_Type = creditedTypeLookup[self.f_Facility_Type];
      } else {
        self.f_Credited_Type = CreditType.None;
      }
    },
    calculateItemCredit() {
      if (self.f_Credited_Type === CreditType.Permeable) {
        self.f_Permeable_Credit_Amount = (self.f_Facility_Size + self.f_Permeable_Area_Drain) * PERM_RATE;
        self.f_Impermeable_Credit_Amount = self.f_Impermeable_Area_Drain * IMPERM_RATE;
      } else if (self.f_Credited_Type === CreditType.Impermeable) {
        self.f_Impermeable_Credit_Amount = (self.f_Facility_Size + self.f_Impermeable_Area_Drain) * IMPERM_RATE;
        self.f_Permeable_Credit_Amount = self.f_Permeable_Area_Drain * PERM_RATE;
      } else {
        self.f_Permeable_Credit_Amount = self.f_Permeable_Area_Drain * PERM_RATE;
        self.f_Impermeable_Credit_Amount = self.f_Impermeable_Area_Drain * IMPERM_RATE;
      }
      self.f_Permeable_Credit_Amount = Math.floor(self.f_Permeable_Credit_Amount * 100) / 100;
      self.f_Impermeable_Credit_Amount = Math.floor(self.f_Impermeable_Credit_Amount * 100) / 100;
    }
  }))

const Account = types.model('Account', {
  f_Appealed_Account_No: types.optional(types.string, ''),
  f_Appealed_Distb_Percent: types.optional(types.number, 0),
  f_Distributed_Credit_Amount: types.optional(types.number, 0),
})
  .actions(self => ({
    setItem(item, value) {
      self[item] = value;
    },
  }))


const CreditEntryStore = types
  .model('Credit Entry Form', {
    // General Information
    Account_Number: types.string,
    Zip_Code: types.string,
    Applicant_Name: types.string,
    Applicant_Phone: types.string,
    Applicant_Email: types.string,
    Credit_Type: types.string,
    Tier_Type: types.maybeNull(types.string),
    // Parcel Information
    MapBlockLot: types.string,
    AssessorBlockLot: types.string,
    S_Current_Impermeable_Area: types.number,
    S_Current_Permeable_Area: types.number,
    S_Submitted_By: types.maybeNull(types.string),
    Submittal_Date: types.string,
    // Credit details
    SRDetails: types.maybeNull(types.array(SRDetails)),
    STDdetails: types.maybeNull(types.array(StandardDetails)),
    // Credit Split details
    Accounts: types.maybeNull(types.array(Account)),
    equalSplit: types.optional(types.boolean, true),
    F_Applicant_Comments: types.maybeNull(types.string),
    // form status
    Self_Certified: types.boolean,
    generalInfoStatus: types.optional(types.boolean, false),
    detailsStatus: types.optional(types.boolean, false),
    allocationStatus: types.optional(types.boolean, false),
    formReady: types.optional(types.boolean, false),
    // credit amount
    perm_credit: types.optional(types.number, 0), // standard credit
    imperm_credit: types.optional(types.number, 0), // standard credit
    total_credit: types.optional(types.number, 0), // total credit
    roof_credit: types.optional(types.number, 0), // sr credit
    patio_credit: types.optional(types.number, 0), // sr credit
  })
  .volatile(() => ({
    // file variables
    F_Attached_Green_Insfrastructure_Plan: null,
    F_Attached_Green_Insfrastructure_Photo: null,
    F_Attached_Agreement_Doc: null,
  }))
  .actions(self => ({
    afterCreate() {
      console.log('CreditEntryStore created');
    },
    getParent() {
      return getParent(self);
    },
    setItem(key, value) {
      self[key] = value;
    },
    // handle file upload
    handlePlanFileUpload(e) {
      if (e) {
        const name = e.target.name;
        const file = e.target.files[0];
        self.setItem(name, file);
        if (self.Credit_Type === 'Standard') {
          self.checkStandardDetailsStatus();
        } else {
          self.checkSRdetailsStatus();
        }
      } else {
        self.F_Attached_Green_Insfrastructure_Plan = null;
        if (self.Credit_Type === 'Standard') {
          self.checkStandardDetailsStatus();
        } else {
          self.checkSRdetailsStatus();
        }
      }
    },
    handlePhotoFileUpload(e) {
      if (e) {
        const name = e.target.name;
        const file = e.target.files[0];
        self.setItem(name, file);
        if (self.Credit_Type === 'Standard') {
          self.checkStandardDetailsStatus();
        } else {
          self.checkSRdetailsStatus();
        }
      } else {
        self.F_Attached_Green_Insfrastructure_Photo = null;
        if (self.Credit_Type === 'Standard') {
          self.checkStandardDetailsStatus();
        } else {
          self.checkSRdetailsStatus();
        }
      }
    },
    handleAgreementFileUpload(e) {
      if (e) {
        const name = e.target.name;
        const file = e.target.files[0];
        self.setItem(name, file);

      } else {
        self.F_Attached_Agreement_Doc = null;
      }
      self.checkAllocationStatus();
    },
    //Standard Details methods
    addStandardDetails() {
      self.STDdetails.push({
        f_Impermeable_Area_Drain: 0,
        f_Impermeable_Credit_Amount: 0,
        f_Permeable_Area_Drain: 0,
        f_Permeable_Credit_Amount: 0,
        f_Facility_Type: '',
        f_Facility_Size: 0,
        f_Credited_Type: 'None',
      });
    },
    removeStandardDetails() {
      self.STDdetails.pop();
    },
    calculateStandardTotalCredit() {
      let total_credit = 0;
      let perm_credit = 0;
      let imperm_credit = 0;
      let netArea = self.S_Current_Impermeable_Area + self.S_Current_Permeable_Area;


      let total_perm_area = 0;
      let total_imperm_area = 0;
      self.STDdetails.forEach((item) => {
        if (item.f_Credited_Type === CreditType.Permeable) {
          total_perm_area += item.f_Facility_Size;
        } else if (item.f_Credited_Type === CreditType.Impermeable) {
          total_imperm_area += item.f_Facility_Size;
        }
        total_imperm_area += item.f_Impermeable_Area_Drain;
        total_perm_area += item.f_Permeable_Area_Drain;
      });


      perm_credit = Math.floor((PERM_RATE * total_perm_area) * 100) / 100;
      imperm_credit = Math.floor((IMPERM_RATE * total_imperm_area) * 100) / 100;
      total_credit = parseFloat((perm_credit + imperm_credit).toFixed(2));

      self.setItem('perm_credit', perm_credit);
      self.setItem('imperm_credit', imperm_credit);
      self.setItem('total_credit', total_credit);
    },
    //allocation status
    checkAllocationStatus() {
      let sum = Math.round(self.Accounts.reduce((accumulator, curr) => accumulator + curr.f_Appealed_Distb_Percent, 0));
      if (sum !== 100) {
        self.allocationStatus = false;
        self.statusComplete();
        return 'The credit split should add up to 100.';
      } else if (!self.equalSplit && !self.F_Attached_Agreement_Doc) {
        self.allocationStatus = false;
        self.statusComplete();
        return 'Please upload the signed agreement.';
      } else {
        self.statusComplete();
        self.allocationStatus = true;
        return '';
      }

    },
    checkStandardDetailsStatus() {
      let netArea = self.S_Current_Impermeable_Area + self.S_Current_Permeable_Area;
      let totalArea = 0;
      // checking by item
      const res = self.STDdetails.filter((item) => {
        let permArea = item.f_Permeable_Area_Drain + (item.f_Credited_Type === CreditType.Permeable ? item.f_Facility_Size : 0);
        let impermArea = item.f_Impermeable_Area_Drain + (item.f_Credited_Type === CreditType.Impermeable ? item.f_Facility_Size : 0);
        totalArea += permArea + impermArea;
        if (impermArea > netArea) {
          return item;
        }
        if (permArea > self.S_Current_Permeable_Area) {
          return item;
        }
        if (totalArea > netArea) {
          return item;
        }
      });

      if (res.length > 0) {
        self.detailsStatus = false;
        self.statusComplete();
        return 'Exceeding the net total area or permeable area.';
      } else if (self.total_credit === 0) {
        self.detailsStatus = false;
        self.statusComplete();
        return 'The total Stormwater Credit cannot be 0.';
      } else if (!self.F_Attached_Green_Insfrastructure_Plan ||!self.F_Attached_Green_Insfrastructure_Photo) {
        self.detailsStatus = false;
        self.statusComplete();
        return 'Please upload required files.';
      } else {
        if (totalArea > (0.9 * netArea) && totalArea <= netArea) {
          return '90% maximum credit limit reached.';
        }
        self.detailsStatus = true;
        self.statusComplete();
        return '';
      }

    },
    statusComplete() {
      if (self.generalInfoStatus && self.allocationStatus && self.Self_Certified && self.detailsStatus) {
        self.formReady = true;
        return true;
      } else {
        self.formReady = false;
        return false;
      }
    },
    formatStandardData() {
      let formData = new FormData();
      formData.append('Account_Number', self.Account_Number);
      formData.append('Zip_Code', self.Zip_Code);
      formData.append('Applicant_Name', self.Applicant_Name);
      formData.append('Applicant_Phone', self.Applicant_Phone);
      formData.append('Applicant_Email', self.Applicant_Email);
      formData.append('F_Applicant_Comments', self.F_Applicant_Comments);
      formData.append('Self_Certified', self.Self_Certified);
      formData.append('AssessorBlockLot', self.AssessorBlockLot);
      formData.append('MapBlockLot', self.MapBlockLot);
      formData.append('Credit_Type', 'Standard');
      formData.append('S_Current_Impermeable_Area', self.S_Current_Impermeable_Area);
      formData.append('S_Current_Permeable_Area', self.S_Current_Permeable_Area);
      formData.append('F_Attached_Green_Insfrastructure_Plan', self.F_Attached_Green_Insfrastructure_Plan);
      formData.append('F_Attached_Green_Insfrastructure_Photo', self.F_Attached_Green_Insfrastructure_Photo);
      formData.append('F_Attached_Agreement_Doc', self.F_Attached_Agreement_Doc);
      self.STDdetails.forEach((item, idx) => {
        formData.append(`STDdetails[${idx}].f_Facility_Type`, item.f_Facility_Type);
        formData.append(`STDdetails[${idx}].f_Facility_Size`, item.f_Facility_Size);
        formData.append(`STDdetails[${idx}].f_Credited_Type`, item.f_Credited_Type);
        formData.append(`STDdetails[${idx}].f_Impermeable_Area_Drain`, item.f_Impermeable_Area_Drain);
        formData.append(`STDdetails[${idx}].f_Permeable_Area_Drain`, item.f_Permeable_Area_Drain);
        formData.append(`STDdetails[${idx}].f_Impermeable_Credit_Amount`, item.f_Impermeable_Credit_Amount);
        formData.append(`STDdetails[${idx}].f_Permeable_Credit_Amount`, item.f_Permeable_Credit_Amount);
      });
      self.Accounts.forEach((item, idx) => {
        formData.append(`Accounts[${idx}].f_Appealed_Account_No`, item.f_Appealed_Account_No);
        formData.append(`Accounts[${idx}].f_Appealed_Distb_Percent`, item.f_Appealed_Distb_Percent);
        formData.append(`Accounts[${idx}].f_Distributed_Credit_Amount`, item.f_Distributed_Credit_Amount);
      });
      return formData;
    },

    // SR detaisl methods
    addSRdetails() {
      self.SRDetails.push({
        source: '',
        f_Area: 0,
        green_Infrastructure_Type: '',
        f_Credit_Amount: 0,
      });
    },
    removeSRdetails() {
      self.SRDetails.pop();
    },
    calculateSRtotalCredit() {
      let tier = self.Tier_Type;
      let patio = self.SRDetails.filter((element) => element.source === 'Patio' && element.green_Infrastructure_Type !== '');
      let roof = self.SRDetails.filter((element) => element.source === 'Roof' && element.green_Infrastructure_Type !== '');
      let arr = {
        'patio': patio.reduce((accumulator, curr) => accumulator + curr.f_Area, 0),
        'roof': roof.reduce((accumulator, curr) => accumulator + curr.f_Area, 0),
      };

      let roof_credit = 0;
      let patio_credit = 0;
      // ADDING IN FOR TESTING REASONSE
      if (!tier) {
        tier = 'SRT1';
      }
      if (tier === 'SRT1') {
        if (arr.patio >= 100 && arr.patio <= 200) {
          patio_credit += 0.76;
        } else if (arr.patio > 200) {
          patio_credit += 1.26;
        }

        if (arr.roof >= 400 && arr.roof <= 600) {
          roof_credit += 2.01;
        } else if (arr.roof > 600) {
          roof_credit += 3.02;
        }

      } else if (tier === 'SRT2') {
        if (arr.patio >= 200 && arr.patio <= 400) {
          patio_credit += 1.18;
        } else if (arr.patio > 400) {
          patio_credit += 1.96;
        }

        if (arr.roof >= 600 && arr.roof <= 900) {
          roof_credit += 3.14;
        } else if (arr.roof > 900) {
          roof_credit += 4.70;
        }

      } else if (tier === 'SRT3') {
        if (arr.patio >= 300 && arr.patio <= 600) {
          patio_credit += 1.77;
        } else if (arr.patio > 600) {
          patio_credit += 2.95;
        }

        if (arr.roof >= 800 && arr.roof <= 1200) {
          roof_credit += 4.71;
        } else if (arr.roof > 1200) {
          roof_credit += 7.07;

        }
      }
      self.roof_credit = roof_credit;
      self.patio_credit = patio_credit;
      self.total_credit = parseFloat((roof_credit + patio_credit).toFixed(2));
    },
    checkSRdetailsStatus() {
      let totalArea = self.SRDetails.reduce((accumulator, curr) => accumulator + curr.f_Area, 0);
      let netArea = self.S_Current_Impermeable_Area + self.S_Current_Permeable_Area;
      if (self.total_credit === 0) {
        self.detailsStatus = false;
        self.statusComplete();
        return 'The total Stormwater Credit cannot be 0.';
      }
      const res = self.SRDetails.filter((element) => element.f_Area > netArea)
      if (res.length > 0 || totalArea > netArea) {
        self.detailsStatus = false;
        self.statusComplete();
        return 'The area draining to Green Infrastructure cannot be higher than the total property area.';
      } else if (!self.F_Attached_Green_Insfrastructure_Plan || !self.F_Attached_Green_Insfrastructure_Photo) {
        self.detailsStatus = false;
        self.statusComplete();
        return 'Please upload the required files.';
      } 

      self.detailsStatus = true;
      self.statusComplete();
      return '';
    },
    formatSRdata() {
      let patio = self.SRDetails.filter((element) => element.source === 'Patio' && element.green_Infrastructure_Type !== '');
      let roof = self.SRDetails.filter((element) => element.source === 'Roof' && element.green_Infrastructure_Type !== '');
      let total_area = {
        'patio': patio.reduce((accumulator, curr) => accumulator + curr.f_Area, 0),
        'roof': roof.reduce((accumulator, curr) => accumulator + curr.f_Area, 0),
      };
      let formData = new FormData();
      formData.append('Account_Number', self.Account_Number);
      formData.append('Zip_Code', self.Zip_Code);
      formData.append('Applicant_Name', self.Applicant_Name);
      formData.append('Applicant_Phone', self.Applicant_Phone);
      formData.append('Applicant_Email', self.Applicant_Email);
      formData.append('F_Applicant_Comments', self.F_Applicant_Comments);
      formData.append('Self_Certified', self.Self_Certified);
      formData.append('AssessorBlockLot', self.AssessorBlockLot);
      formData.append('MapBlockLot', self.MapBlockLot);
      formData.append('Credit_Type', 'Standard');
      formData.append('S_Current_Impermeable_Area', self.S_Current_Impermeable_Area);
      formData.append('S_Current_Permeable_Area', self.S_Current_Permeable_Area);
      formData.append('Tier_Type', self.Tier_Type);
      formData.append('Credit_Type', 'SR');
      formData.append('F_Attached_Green_Insfrastructure_Plan', self.F_Attached_Green_Insfrastructure_Plan);
      formData.append('F_Attached_Green_Insfrastructure_Photo', self.F_Attached_Green_Insfrastructure_Photo);
      formData.append('F_Attached_Agreement_Doc', self.F_Attached_Agreement_Doc);
      formData.append('F_Total_Patio_Area', total_area.patio);
      formData.append('F_Total_Roof_Area', total_area.roof);
      self.SRDetails.forEach((item, idx) => {
        formData.append(`SRDetails[${idx}].Source`, item.source);
        formData.append(`SRDetails[${idx}].Green_Infrastructure_Type`, item.green_Infrastructure_Type);
        formData.append(`SRDetails[${idx}].F_Area`, item.f_Area);
        formData.append(`SRDetails[${idx}].F_Credit_Amount`, item.source === 'Roof' ? self.roof_credit : self.patio_credit);
      });
      self.Accounts.forEach((item, idx) => {
        formData.append(`Accounts[${idx}].f_Appealed_Account_No`, item.f_Appealed_Account_No);
        formData.append(`Accounts[${idx}].f_Appealed_Distb_Percent`, item.f_Appealed_Distb_Percent); //TODO - check if this is correct
        formData.append(`Accounts[${idx}].f_Distributed_Credit_Amount`, item.f_Distributed_Credit_Amount);
      });
      return formData;
    },
    submitStandardData: flow(function* submitStandardData() {
      const responseData = yield postStandard(self.formatStandardData())
        .catch((error) => {
          //console.log(error);
        });

      if (responseData.status === 200) {
        self.getParent().setItem('creditSubmission', CreditSubmissionStore.create(responseData.data));
        //console.log('data submitted', getSnapshot(self.getParent()));
        return responseData.data;
      } else {
        return 400;
      }
    }),
    submitSRdata: flow(function* submitSRdata() {
      const responseData = yield postSR(self.formatSRdata())
        .catch((error) => {
          console.log(error);
        });
      if (responseData.status === 200) {
        self.getParent().setItem('creditSubmission', CreditSubmissionStore.create(responseData.data));
        // console.log('data submitted', getSnapshot(self.getParent()));
        return responseData.data;
      } else {
        return 400;
      }
    }),
  }))

export default CreditEntryStore;

