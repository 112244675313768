import { useState, useEffect } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Grid, Typography, Checkbox } from '@material-ui/core';
import FormInputTextField from '../../form-input/form-input-textfield.component';
import FormInputPhone from '../../form-input/form-input-phone.component';

const GeneralInformation = observer(({ statusMaster, statusChange, generalInfo, handleGeneralInfoChange, handlePhoneChange, handleTypeofAccountChange }) => {
  const [isValidName, setIsValidName] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isValidConfirmEmail, setIsValidConfirmEmail] = useState(false);
  const [helpText, setHelpText] = useState("Please confim the email address...");

  const { CCB_Account, Zipcode, ApplicantName, Applicant_Phone, Applicant_Email, TypeOfAccountHolder } = generalInfo;


  useEffect(() => {
    setIsValidName(ApplicantName.length > 0);
  }, [ApplicantName])

  useEffect(() => {
    setIsValidPhone(Applicant_Phone.length === 17);
  }, [Applicant_Phone])

  useEffect(() => {
    setIsValidEmail(/\S+@\S+\.\S+/.test(Applicant_Email));
    if (Applicant_Email && confirmEmail && Applicant_Email === confirmEmail) {
      setIsValidConfirmEmail(true);
      setHelpText("");
    } else {
      setIsValidConfirmEmail(false);
      setHelpText("Please confim the email address...")
    }
  }, [Applicant_Email, confirmEmail])

  useEffect(() => {
    if (statusMaster !== "submitted") {
      if (isValidName && isValidPhone && isValidEmail && isValidConfirmEmail) {
        statusChange("ready");
      } else {
        statusChange("initial");
      }
    }
  }, [statusMaster, isValidName, isValidPhone, isValidEmail, isValidConfirmEmail])

  return (
    <Grid container spacing={2} >
      <Grid item xs={12} align="center" style={{ backgroundColor: "whitesmoke" }}>
        <Typography variant="h5" gutterBottom color="primary">Applicant Information</Typography>
      </Grid>

      <FormInputTextField
        label="Account Number"
        required={true}
        name="CCB_Account"
        value={CCB_Account}
        InputProps={{ readOnly: true }}
      />

      <FormInputTextField
        label="ZIP Code"
        required={true}
        name="Zipcode"
        value={Zipcode}
        InputProps={{ readOnly: true }}
      />

      <FormInputTextField
        label="Applicant Name"
        required={true}
        name="ApplicantName"
        helperText='An applicant may apply on behalf of an SFPUC customer.'
        value={ApplicantName}
        onChange={handleGeneralInfoChange}
        InputProps={{ readOnly: statusMaster === "submitted" }}
        error={!isValidName}
      />

      <FormInputPhone
        label="Applicant Phone Number"
        required={true}
        name="Applicant_Phone"
        value={Applicant_Phone}
        onChange={handlePhoneChange}
        InputProps={{ readOnly: statusMaster === "submitted" }}
        error={!isValidPhone}
      />

      <FormInputTextField
        label="Applicant Email Address"
        required={true}
        name="Applicant_Email"
        helperText="If you do not have an email address, please submit a paper application."
        value={Applicant_Email}
        onChange={handleGeneralInfoChange}
        InputProps={{ readOnly: statusMaster === "submitted" }}
        error={!isValidEmail}
      />

      {statusMaster !== "submitted" &&
        <FormInputTextField
          label="Confirm Email Address"
          required={true}
          name="confirmApplicantEmail"
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.target.value)}
          error={!isValidConfirmEmail}
          helperText={helpText}
        />
      }
      <Grid item xs={4} align="left">
        <Typography variant="h6">The Applicant is</Typography>
      </Grid>

      {TypeOfAccountHolder &&
        <Grid item xs={8} align="left">
          {statusMaster !== "submitted" && <Typography variant="subtitle1">Please check all that apply of the following:</Typography>}
          {Object.entries(TypeOfAccountHolder).map(([key, value]) => {
            //a check box for each type of account holder
            return (
              <Grid container key={key} >
                <Grid item xs={12}>
                  <Checkbox name={key} color="primary" size='medium' checked={value} onChange={handleTypeofAccountChange} disabled={statusMaster === "submitted"} />
                  <Typography display='inline' variant='h6' style={{ fontSize: '1rem' }} >{key}</Typography>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      }


      <Grid item xs={12} align="left">
        <Typography variant="subtitle1"><i>Click on the sections below to provide information for each type of appeal. <br />The eligible appeal types will be shown to the current customer on record based on their stormwater charge type and their number of service agreements with SFPUC.</i></Typography>
      </Grid>
    </Grid>
  )
})

export default GeneralInformation;