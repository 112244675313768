import React, { useState, useEffect } from 'react';

/* MUI */
import { Grid, Typography } from '@material-ui/core';
// import MuiPhoneNumber from "material-ui-phone-number";

import FormInputTypography from '../../form-input/form-input-typography.component.jsx';


const GeneralInformation = (props) => {
  const [data, setData] = useState({
    ccB_Account_Number: "",
    ccB_ZipCode: "",
    applicant_Name: "",
    applicant_Phone: "",
    applicant_Email: "",
    typeOfAccountHolder: []
  });

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data])

  return (
    <Grid container spacing={2} >
      <Grid item xs={12} align="center" style={{ backgroundColor: "whitesmoke" }}>
        <Typography variant="h5" gutterBottom color="primary">Applicant Information</Typography>
      </Grid>
      <FormInputTypography
        label="Account Number"
        value={data.ccB_Account_Number}
      />
      <FormInputTypography
        label="ZIP Code"
        value={data.ccB_ZipCode}
      />
      <FormInputTypography
        label="Applicant Name"
        value={data.applicant_Name}
      />
      <FormInputTypography
        label="Applicant Phone Number"
        value={data.applicant_Phone}
      />
      <FormInputTypography
        label="Applicant Email Address"
        value={data.applicant_Email}
      />
      <FormInputTypography
        label="The Applicant is"
        value={(data.typeOfAccountHolder && data.typeOfAccountHolder.length > 0 && data.typeOfAccountHolder[0]) ? data.typeOfAccountHolder.join("\n") : "The applicant did not select any type of account holder."}
      />
    </Grid>
  )
}

export default GeneralInformation;