import { Fragment, useEffect, useState } from 'react';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@material-ui/core';

import FormInputTypography from '../../form-input/form-input-typography.component';
import { AreaInformation, LinkTextDisplay } from '../../data-display/data-display.component';
import form from '../../../assets/files/AppealForm-110723 Revised Ver_FINAL.pdf';

const ServiceAgreementAppeal = (props) => {
  const [data, setData] = useState({
    accounts: [],
    f_Applicant_Comments: ""
  })

  const [area, setArea] = useState({
    mapBlkLot: "",
    grossTotalArea: "",
    permeableArea: "",
    impermeableArea: "",
    excludedArea: "",
  });

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }

    if (props.area) {
      setArea(props.area);
    }
  }, [props])

  return (
    <Accordion expanded>
      <AccordionSummary id="SAChargeAllocAppeal" style={{ backgroundColor: "whitesmoke" }}>
        <Typography variant="h6" align="left" gutterBottom color="primary">
          Information for Change how the stormwater charge is split between multiple accounts on a property
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
        <Typography variant="body1" component={'div'}>
            By default, the stormwater charge for a property is equally split across all SFPUC accounts with a sewer service agreement on that property. However, customers may voluntarily agree to a different allocation for any reason.<br />
            <br />
            To modify the allocation of the stormwater charge, the appeal must contain a signed document, such as a letter of agreement, from all SFPUC account holders that share the parcel, clearly defining the agreed upon area that will be assigned to each customer. This can be either a gross percentage of the overall parcel area or a detailed delineation of the total permeable and impermeable area assigned to each account.<br />
            <br />
            <Typography display='inline' style={{fontWeight: 'bold'}}><LinkTextDisplay linkName={'This appeal needs a signed letter of agreement from all SFPUC account holders agreeing to a new customized split of the stormwater charge.'} href={form + '#page=7'} externalLink={true} /></Typography> Once approved by staff, the new percentage split will be used to calculate all future stormwater charges.<br />
          </Typography>
          <AreaInformation
            data={{
              "Map Block Lot": area.mapBlkLot,
              "Number of Service Agreements": data.accounts.length,
            }}
            size={6}
            showTitle={false}
          />

          <Grid item xs={6} align="center">
            <Typography>Account Number</Typography>
          </Grid>
          <Grid item xs={6} align="center">
            <Typography>Charge (%)</Typography>
          </Grid>

          {data.accounts.length > 0 && data.accounts.map((element, index) =>
          (<Fragment key={index} >
            <Grid item xs={6} align="center">
              <TextField name="acc_no" variant="outlined" fullWidth label={`Account Number ${index + 1}`}
                value={element.f_Appeal_Account_Num} InputProps={{ readOnly: true }} />
            </Grid>
            <Grid item xs={6} align="center">
              <TextField name="acc_charge" variant="outlined" label="Charge" fullWidth
                value={element.f_Appealed_Distb_Percent} InputProps={{ readOnly: true }} />
            </Grid>
          </Fragment>)
          )}

          <FormInputTypography
            label="Charge Allocations Appeal ID"
            size={5}
            value={data.customerAppealId}
          />
          <FormInputTypography
            label="Applicant Comments"
            size={5}
            value={data?.f_Applicant_Comments ? data.f_Applicant_Comments : ""}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ServiceAgreementAppeal;