import React, { Fragment, useEffect, useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, TextField, Paper, Typography, Button, Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

/* Langan Components */
import { getAllUsers } from '../../../services/APIClient';
import AssignmentDialog from './AssignmentDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  wrapper: {
    textTransform: 'initial'
  },
  link: theme.link,
}));

const defaultGroupMapping = {
  'SFPUC_Admin': 'Admin',
  'SFPUC_Business': 'Business User',
  'SFPUC_Viewer': 'Viewer',
  'SFPUC_Credit_Admin': 'Credit Admin',
}

const ManageUsers = observer(props => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [groupMapping, setGroupMapping] = useState({});
  const [users, setUsers] = useState({});
  const [open, setOpen] = useState(false);
  const [searchUsername, setSearchUsername] = useState('');
  const [dialogUser, setDialogUser] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (props.store) {
      setIsLoading(true);
      getAllUsers(props.store.user.token)
        .then(res => {
          setError('');
          let groups = {};
          let usersTmp = {};
          for (let i = 1; i < res.data.length; i += 2) {
            if (res.data[i].title === 'SFPUC_User') {
              continue;
            }
            let userIdx = i - 1;
            let groupOwner = res.data[userIdx].owner.username;
            let groupUsers = res.data[userIdx].users;
            groupUsers.map((user, idx) => {
              if (usersTmp[user.username]) {
                usersTmp[user.username].roles.push(defaultGroupMapping[res.data[i].title]);
              } else {
                if (user.username !== groupOwner) {
                  usersTmp[user.username] = { 'roles': [defaultGroupMapping[res.data[i].title]] };
                }
              }
            })
            let displayName = defaultGroupMapping[res.data[i].title];
            groups[displayName] = { 'title': res.data[i].title, 'groupId': res.data[i].id };
          }

          setGroupMapping(groups);
          setUsers(usersTmp);
          // console.log('groups', groups);
          // console.log('users', usersTmp);
          setIsLoading(false);
        })
        .catch(err => {
          setError('An error has occurred. Please try again later.')
        })
    }

  }, [props.store, refresh])

  const handleClose = () => {
    setOpen(false);
    setRefresh(!refresh);
  }

  const handleCancel = () => {
    setOpen(false);
  }

  const dashBoardHeader = [
    { field: 'username', headerName: 'Username', width: 220, disableClickEventBubbling: true, },
    { field: 'roles', headerName: 'Assigned Roles', width: 350, disableClickEventBubbling: true },
    {
      field: 'action', headerName: 'Action', width: 200, disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {

          setDialogUser({ 'username': params.row.username, 'roles': users[params.row.username].roles });
          setOpen(true);
        }
        return <Button color='primary' onClick={onClick}>
          <Typography className={classes.link}>Edit</Typography>
        </Button>
      }
    }
  ];

  return (
    <Fragment>
      {(props.store && props.store.user) &&
        <Container maxWidth="md">
          <Paper className={classes.paper}>
            <Typography variant="h6" align="left" gutterBottom>Role Assignment</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} align="center">
                <TextField name="username" label="Username" variant="outlined" type='search' required fullWidth value={searchUsername} onChange={(e) => setSearchUsername(e.target.value)} />
              </Grid>
            </Grid>
            <Box m={2} />
            {error ? <Typography variant="body1" align="center" color='error'>{error}</Typography> :
              <DataGrid
                rows={isLoading ? [] :
                  searchUsername ?
                    Object.keys(users).filter(user => user.toLowerCase().includes(searchUsername.toLowerCase())).map((user, idx) => {
                      return { 'id': user, 'username': user, 'roles': users[user].roles.join(',  ') }
                    })
                    :
                    Object.keys(users).map((user, idx) => {
                      return { 'id': user, 'username': user, 'roles': users[user].roles.join(',  ') }
                    })}
                columns={dashBoardHeader}
                autoPageSize
                disableSelectionOnClick
                loading={isLoading}
                autoHeight
              />
            }

          </Paper>
          <AssignmentDialog
            open={open}
            handleClose={handleClose}
            handleCancel={handleCancel}
            username={dialogUser.username}
            roles={dialogUser.roles}
            defaultGroupMapping={defaultGroupMapping}
            token={props.store.user.token}
            groupMapping={groupMapping} />
        </Container>
      }
    </Fragment>

  )
});

export default ManageUsers;
