import { Fragment, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

/* Langan Form Input Components */
import FormInputTextField from '../../../../form-input/form-input-textfield.component';
import FormInputDate from '../../../../form-input/form-input-date.component';
import FormInputRadio from '../../../../form-input/form-input-radio.component';
import FormInputDropDownList from '../../../../form-input/form-input-dropdownlist.component';
import FormAttachmentList from '../../../../form-input/form-attachment-list.component';
import { FileUploadButton } from '../../../../form-input/form-file-upload-button.component';

const decisions = [
  {
    label: "Lodged / In Progress",
    value: "inprogress",
  },
  {
    label: "Pending Documents",
    value: "pending",
  },
  {
    label: "Approved",
    value: "approved"
  },
  {
    label: "Denied",
    value: "rejected",
  },
  {
    label: "Cancelled",
    value: "cancelled"
  },
];

const reasonsList = {
  pending: {
    title: "Pending Documents Reason",
    reasons: ["Insufficient documentation", "Clarification Required"],
    reasonFieldName: "r_Pending_Doc_Reason"
  },
  cancelled: {
    title: "Cancel Reason",
    reasons: ["Customer request to cancel"],
    reasonFieldName: "r_Cancelled_Reason"
  },
  rejected: {
    title: "Denied Reason",
    reasons: ["Insufficient documentation", "Documentation doesn't support the appeal"],
    reasonFieldName: "r_Denied_Reason"
  }
};

const DecisionReview = ({ decisionReview, decisionReviewOnChange, file, fileName, fileChange, role, initStatus, masterData }) => {
  const [reasonField, setReasonField] = useState("");
  const [reasonList, setReasonList] = useState([]);
  const [reason, setReason] = useState("");
  const reviewFile = file.filter(f => f.documenT_TYPE === "Review");
  const downloadParam = masterData ? {
    master_id: masterData?.masterId,
    appeal_id: masterData?.appeal_type_id,
    appeal_type: masterData?.appeal_type,
    document_type: 'Review'
  } : {};

  useEffect(() => {
    if (decisionReview?.r_Decision && decisionReview.r_Decision in reasonsList) {
      setReason(decisionReview[reasonsList[decisionReview.r_Decision].reasonFieldName]);
      setReasonList(reasonsList[decisionReview.r_Decision].reasons);
      setReasonField(reasonsList[decisionReview.r_Decision].title);
    } else {
      setReason("");
      setReasonList([]);
      setReasonField(null);
    }
  }, [decisionReview.r_Decision])

  const handleChange = (event, name = null) => {
    if (name !== "r_Effective_Billing_Date") {
      decisionReviewOnChange((prev) => {
        return {
          ...prev,
          [event.target.name]: event.target.value
        }
      })
    } else {
      decisionReviewOnChange((prev) => {
        return {
          ...prev,
          [name]: event
        }
      })
    }
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);

    const temp = {
      r_Pending_Doc_Reason: "",
      r_Denied_Reason: "",
      r_Cancelled_Reason: ""
    }
    if (decisionReview.r_Decision === "pending") {
      temp.r_Pending_Doc_Reason = event.target.value
    } else if (decisionReview.r_Decision === "rejected") {
      temp.r_Denied_Reason = event.target.value
    } else if (decisionReview.r_Decision === "cancelled") {
      temp.r_Cancelled_Reason = event.target.value
    }
    decisionReviewOnChange((prev) => {
      return {
        ...prev,
        ...temp
      }
    })
  };

  return (
    <Fragment>
      <FormInputTextField
        label="Reviewer Comments"
        name="r_Reviewer_Comments"
        value={decisionReview.r_Reviewer_Comments}
        multiline
        onChange={handleChange}
        disabled={(!role.business && !role.admin) || initStatus}
      />
      <FormAttachmentList
        label="Reviewer Documents"
        onChange={fileChange}
        file={reviewFile}
        align={'left'}
        downloadParam={downloadParam}
        disabled={(!role.business && !role.admin) || initStatus}
      />
      <Grid item xs={4} />
      <Grid item xs={8}>
        <FileUploadButton
          description={'Attach Reviewer Documents'}
          fileChange={fileChange}
          disabled={(!role.business && !role.admin) || initStatus || reviewFile.length > 0} />
      </Grid>
      <FormInputRadio
        label="Review Decision"
        name="r_Decision"
        value={decisionReview.r_Decision}
        onChange={handleChange}
        ariaLabel="Decision"
        options={decisions}
        disabled={(!role.business && !role.admin) || initStatus}
      />
      {reasonField &&
        (
          <FormInputDropDownList
            label={reasonField}
            value={reason}
            onChange={handleReasonChange}
            reasonList={reasonList}
            disabled={(!role.business && !role.admin) || initStatus}
          />
        )
      }
      <FormInputDate
        label="Effective Billing Date"
        name="r_Effective_Billing_Date"
        value={decisionReview.r_Effective_Billing_Date}
        onChange={(evt) => handleChange(evt, "r_Effective_Billing_Date")}
        disabled={(!role.business && !role.admin) || initStatus}
      />
    </Fragment>
  )
};

export default DecisionReview;