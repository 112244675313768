import { useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, TextField, Typography, Container, MenuItem, Box } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

/* Components */
import { AreaInformation, CustomToolTip, addCommas, LinkTextDisplay } from '../../data-display/data-display.component';
import { useCredits } from '../../../stores/CreditStore/CreditModuleStore';
import { CreditType, FacilityType } from '../../../stores/CreditStore/CreditConstant';
import { AddRemoveItem } from '../../form-input/form-field.component';
import { FileUploadButton } from '../../form-input/form-file-upload-button.component';
import { getSnapshot } from 'mobx-state-tree';


const StandardCredit = observer((props) => {
  const credits = useCredits();
  const [warning, setWarning] = useState('');

  const handleAreaChange = (event, index) => {
    const { name, value } = event.target;
    let area = String(value).replace(/,/g, '');
    let data = parseFloat(area) > 0 ? parseFloat(area) : 0;
    const form = credits.creditEntry;
    form.STDdetails[index].setItem(name, data);
    form.STDdetails[index].calculateItemCredit();
    form.calculateStandardTotalCredit();
    setWarning(form.checkStandardDetailsStatus());
  }

  const handleFieldChange = (event, index) => {
    const { name, value } = event.target;
    const form = credits.creditEntry;
    form.STDdetails[index].setItem(name, value);
    form.STDdetails[index].setCreditedType();
    form.STDdetails[index].calculateItemCredit();
    form.checkStandardDetailsStatus();
    setWarning(form.checkStandardDetailsStatus());
  }

  const addRow = () => {
    // console.log(credits.creditEntry)
    credits.creditEntry.addStandardDetails();

  };

  const removeRow = () => {
    credits.creditEntry.removeStandardDetails();
    const res = credits.creditEntry.calculateStandardTotalCredit();
    setWarning(credits.creditEntry.checkStandardDetailsStatus());
  };

  return (
    <>
      {(credits && credits.creditEntry) &&
        <Container>
          <Accordion expanded>
            <AccordionSummary id="SAChargeAllocAppeal" style={{ backgroundColor: "whitesmoke" }}>
              <Typography variant="h6" gutterBottom color="primary">
                <Checkbox
                  checked={credits.creditEntry.detailsStatus}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                Green Infrastructure Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} key='instruction'>
                  <Typography variant="body1" >
                    Enter the drainage area information for each green infrastructure facility. A green infrastructure facility is a piece of equipment designed to capture stormwater. For example, a rain garden is a green infrastructure facility.
                  </Typography>
                </Grid>
                <AreaInformation
                  data={{
                    "Map Block Lot": credits.creditEntry.MapBlockLot,
                    "Number of Service Agreements": credits.creditEntry.Accounts.length,
                    "Number of Green Infrastructure Facilities": credits.creditEntry.STDdetails.length,
                    "Net Parcel Area": credits.creditEntry.S_Current_Impermeable_Area + credits.creditEntry.S_Current_Permeable_Area,
                    "Billable Impermeable Area": credits.creditEntry.S_Current_Impermeable_Area,
                    "Billable Permeable Area": credits.creditEntry.S_Current_Permeable_Area
                  }}
                />
                {credits.creditEntry.STDdetails.length > 0 && credits.creditEntry.STDdetails.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} key='f_Facility_Type' align="center">
                        <TextField
                          fullWidth
                          select
                          variant='outlined'
                          value={item.f_Facility_Type ? item.f_Facility_Type : FacilityType.Init}
                          name='f_Facility_Type'
                          label="Facility Type"
                          onChange={(e) => handleFieldChange(e, index)}
                        >
                          {Object.values(FacilityType).map((value) => {
                            if (value !== FacilityType.Init) {
                              return <MenuItem value={value} key={value}>{value}</MenuItem>
                            }
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={3} align="left" key='f_Credited_Type'>
                        <Box display="flex" alignItems="center">
                          <TextField
                            name="f_Credited_Type"
                            variant="outlined"
                            label="Credited Type"
                            fullWidth
                            disabled
                            value={item.f_Credited_Type}
                            inputProps={{ style: { color: 'black', backgroundColor: 'whitesmoke' }, }}
                          />
                          <CustomToolTip title="SFPUC credits the Green Infrastructure facility footprint according to the Stormwater Management Requirements and to ensure meaningful credit for meaningful effort. Permeable Pavement, for example is credited as impermeable surface because aerial imagery cannot tell which pavement is impermeable or permeable." />
                        </Box>
                      </Grid>
                      <Grid item xs={3} align="left" key='f_Facility_Size'>
                        <TextField
                          name="f_Facility_Size"
                          variant="outlined"
                          label="Facility size"
                          disabled={item.f_Credited_Type === CreditType.None}
                          fullWidth
                          value={item.f_Facility_Size}
                          onChange={(e) => handleAreaChange(e, index)}
                          InputProps={{ readOnly: false, endAdornment: <InputAdornment position="end">ft²</InputAdornment> }}
                        />
                      </Grid>
                      <Grid item xs={6} align="center" key='f_Impermeable_Area_Drain'>
                        <TextField
                          name="f_Impermeable_Area_Drain"
                          variant="outlined"
                          label="Impermeable area draining to facility"
                          fullWidth
                          value={addCommas(item.f_Impermeable_Area_Drain)}
                          onChange={(e) => handleAreaChange(e, index)}
                          InputProps={{ readOnly: false, endAdornment: <InputAdornment position="end">ft²</InputAdornment> }}
                        />
                      </Grid>
                      <Grid item xs={6} align="left" key='f_Permeable_Area_Drain'>
                        <TextField
                          name="f_Permeable_Area_Drain"
                          variant="outlined"
                          label="Permeable area draining to facility"
                          helperText='Do not include facility size'
                          fullWidth
                          value={addCommas(item.f_Permeable_Area_Drain)}
                          onChange={(e) => handleAreaChange(e, index)}
                          InputProps={{ readOnly: false, endAdornment: <InputAdornment position="end">ft²</InputAdornment> }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))
                }
                <AddRemoveItem addRow={addRow} removeRow={removeRow} />
                {!credits.creditEntry.detailsStatus &&
                  <Grid item xs={12} align="left" key='warning'>
                    <Typography>{warning}</Typography>
                  </Grid>
                }
                <Grid item xs={12} align="right" key='creditAmount'>
                  <Typography color="textPrimary">Permeable Credit: ${credits.creditEntry.perm_credit}</Typography>
                  <Typography color="textPrimary">Impermeable Credit: ${credits.creditEntry.imperm_credit}</Typography>
                  <Typography color="textPrimary">Total Credit: ${credits.creditEntry.total_credit}</Typography>
                </Grid>
                <Grid item xs={12} align="left" key='plan'>
                  <FileUploadButton
                    instruction={<Typography style={{ fontWeight: 'bold' }}>Please include stamped design plans. Refer to the {<LinkTextDisplay linkName={'Stormwater Management Requirements'} href={'https://sfpuc.org/construction-contracts/design-guidelines-standards/stormwater-requirements'} externalLink={true}/>} for guidance on green infrastructure design.</Typography>}
                    description='Attach Green Infrastructure Plan'
                    fileChange={credits.creditEntry.handlePlanFileUpload}
                    name='F_Attached_Green_Insfrastructure_Plan' />
                </Grid>
                <Grid item xs={12} align="left" key='photo'>
                  <FileUploadButton
                    instruction='Please include photos of the Green Infrastructure in its current state'
                    description='Attach Green Infrastructure Photo'
                    fileChange={credits.creditEntry.handlePhotoFileUpload}
                    name='F_Attached_Green_Insfrastructure_Photo' />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Box p={1} />
        </Container>}
    </>
  )
});

export default StandardCredit;
