import { Fragment } from "react";

/* MUI */
import { Grid, Typography } from '@material-ui/core';

const FormInputTypography = ({ label, required = false, size = 4, value = '', align = 'center', variant = 'h6' }) => {
  return (
    <Fragment>
      <Grid item xs={size} align='left'>
        <Typography variant={variant} display='inline'>{label}</Typography>
        {required && <Typography variant="h6" display='inline' color='error'>*</Typography>}
      </Grid>

      <Grid item xs={12 - size} align={align}>
        <Typography variant={variant} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {value}
        </Typography>
      </Grid>
    </Fragment>
  )
}

export default FormInputTypography;