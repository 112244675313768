import { useState, useEffect } from 'react';

/* MUI */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Grid, FormControl, InputLabel, MenuItem, Select, TextField, LinearProgress } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

/* MobX */
import { addLegalOwner } from '../../services/APIClient';

const AddLegalOwnerDialog = ((props) => {
  const [formData, setFormData] = useState({
    name: "",
    address1: "",
    address2: "",
    address3: "",
    postal: "",
    entityType: false,
    effectiveStartDate: new Date(),
  });
  const [bntStatus, setBntStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (formData.name && formData.address1 && formData.postal && props.blocklot && props.blocklot.length === 2) {
      setBntStatus(true)
    } else {
      setBntStatus(false)
    }
  }, [formData])

  const updateFormData = (evt, name) => {
    setFormData(prev => {
      return {
        ...prev,
        [name]: evt.target.value
      }
    })
  }

  const updateStartDate = (date) => {
    setFormData(prev => {
      return {
        ...prev,
        effectiveStartDate: date
      }
    })
  }

  const submitAppeal = () => {
    setIsLoading(true);
    addLegalOwner({...formData, block: props.blocklot[0], lot: props.blocklot[1]})
    .then(response => {
      setIsLoading(false);
      props.updateTable();
      props.handleClose();
    })
    .catch(err => {
      setIsLoading(false);
      console.log(err);
    })
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add a Legal Owner</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField autoFocus margin="dense" id="name" label="Legal Owner Name" required value={formData.name} onChange={(e) => updateFormData(e, "name")} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField margin="dense" id="address1" label="Legal Owner Address 1" required value={formData.address1} onChange={(e) => updateFormData(e, "address1")} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField margin="dense" id="address2" label="Legal Owner Address 2" value={formData.address2} onChange={(e) => updateFormData(e, "address2")} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField margin="dense" id="address3" label="Legal Owner Address 3" value={formData.address3} onChange={(e) => updateFormData(e, "address3")} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField  id="postal" label="Zipcode" required value={formData.postal} onChange={(e) => updateFormData(e, "postal")} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <FormControl required fullWidth >
              <InputLabel id="entity-label">Is Government Entity</InputLabel>
              <Select
                labelId="entity-label"
                id="entityType"
                value={formData.entityType}
                onChange={(e) => updateFormData(e, "entityType")}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} align="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker 
              disableToolbar 
              inputVariant="outlined" 
              format="MM/dd/yyyy" 
              margin="normal" 
              id="date-picker-inline" 
              label="Start Date" 
              KeyboardButtonProps={{ 'aria-label': 'change date', }} 
              required 
              fullWidth
              minDate={props.dateCheck}
              //disablePast={true}
              value={formData.effectiveStartDate} 
              onChange={updateStartDate} />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={submitAppeal} color="primary" disabled={!bntStatus || isLoading} >
          Submit
        </Button>
      </DialogActions>
      {isLoading && <LinearProgress />}
    </Dialog>
  )
})

export default AddLegalOwnerDialog;