import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, Button, DialogActions } from '@material-ui/core';
import { WatchLaterOutlined } from '@material-ui/icons';

const ComingSoonDialog = (props) => {
    const alertDialogOpen = props.open;
    const handleComingSoon = props.handleClose;

    return (
        <Dialog
          open={alertDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h5" align="center" display='inline'>Coming Soon! </Typography>
            <Typography display='inline'><WatchLaterOutlined /></Typography>
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleComingSoon}>
              <Typography color="primary">OK</Typography>
            </Button>
          </DialogActions>
        </Dialog>
    )
};

export default ComingSoonDialog;