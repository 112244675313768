import { Fragment } from "react";
import FormInputCheckbox from '../../../../form-input/form-input-checkbox.component';
import FormInputTextField from '../../../../form-input/form-input-textfield.component';
import FormInputTypography from '../../../../form-input/form-input-typography.component';
import { Grid, TextField, Typography } from '@material-ui/core';
import { useState } from "react";
import { useEffect } from "react";

const ExcludedAreaContent = ({ appealData, appealReview, setAppealReview, role, initStatus }) => {

  const [billableError, setBillableError] = useState(false);
  const [areaError, setAreaError] = useState(false);

  //check actual area sum
  useEffect(() => {
    let gross = parseFloat(appealReview.r_Approved_Impermeable_Area)
      + parseFloat(appealReview.r_Approved_Permeable_Area)
      + parseFloat(appealReview.r_Approved_Excluded_Area);
    if (gross != appealData.s_Current_Gross_Total_Area) {
      setAreaError(true);
    } else {
      setAreaError(false);
    }
  }
    , [appealReview.r_Approved_Excluded_Area, appealReview.r_Approved_Impermeable_Area])

  //check billable area sum
  useEffect(() => {
    let gross = parseFloat(appealReview.r_Approved_Billable_Impermeable_Area)
      + parseFloat(appealReview.r_Approved_Billable_Permeable_Area)
      + parseFloat(appealReview.r_Approved_Excluded_Area);
    if (gross != appealData.s_Current_Gross_Total_Area) {
      setBillableError(true);
    } else {
      setBillableError(false);
    }
  }
    , [appealReview.r_Approved_Excluded_Area, appealReview.r_Approved_Impermeable_Area])

  const handleChangeCheckbox = (event) => {
    const { name, checked } = event.target
    setAppealReview(prev => {
      return {
        ...prev,
        [name]: checked
      }
    })
  };

  const handleChange = (event) => {
    const { name, value } = event.target
    let numTypeValue = parseFloat(value) ? parseFloat(value) : 0;
    setAppealReview(prev => {
      if (name === 'r_Approved_Impermeable_Area') {
        let net = prev.r_Approved_Net_Total_Area;
        let imperm = Math.floor(numTypeValue / 100) * 100;
        let billablePerm = net - imperm > 0 ? net - imperm : 0;
        let perm = net - numTypeValue > 0 ? net - numTypeValue : 0;
        return {
          ...prev,
          [name]: numTypeValue === 0 ? "0" : numTypeValue,
          r_Approved_Billable_Impermeable_Area: imperm,
          r_Approved_Billable_Permeable_Area: billablePerm,
          r_Approved_Permeable_Area: perm
        }
      } else if (name === 'r_Approved_Excluded_Area') {
        let net = appealData.s_Current_Gross_Total_Area - numTypeValue;
        let imperm = prev.r_Approved_Billable_Impermeable_Area;
        let perm = net - imperm > 0 ? net - imperm : 0;
        return {
          ...prev,
          [name]: numTypeValue === 0 ? "0" : numTypeValue,
          r_Approved_Net_Total_Area: net,
          r_Approved_Billable_Permeable_Area: perm,
        }
      } else {
        return {
          ...prev,
          [name]: numTypeValue
        }
      }
    });
  };

  return (
    <Fragment>
      <FormInputCheckbox
        label="Claimed Area is valid"
        name="r_Claim_Area_is_Valid"
        checked={appealReview.r_Claim_Area_is_Valid}
        onChange={handleChangeCheckbox}
        disabled={(!role.business && !role.admin) || initStatus}
      />
      <FormInputCheckbox
        label="Area Changes Provided"
        name="r_Location_of_changes_provided"
        checked={appealReview.r_Location_of_changes_provided}
        onChange={handleChangeCheckbox}
        disabled={(!role.business && !role.admin) || initStatus}
      />
      <FormInputTextField
        label="Gross Total Area"
        name="s_Current_Gross_Total_Area"
        value={appealData?.s_Current_Gross_Total_Area ? Math.round(appealData.s_Current_Gross_Total_Area) : "0"}
        disabled
        InputProps={{ readOnly: true }}
      />

      <Grid item xs={4}>
        <Typography variant="h6">Excluded Area</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Current Area"
          name="s_Current_Excluded_Area"
          variant="outlined"
          value={appealData?.s_Current_Excluded_Area ? Math.round(appealData.s_Current_Excluded_Area) : "0"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Appealed Area"
          id="excluded_area"
          value={appealData?.excluded_area ? Math.round(appealData.excluded_area) : "0"}
          variant="outlined"
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Approved Area"
          name="r_Approved_Excluded_Area"
          value={appealReview?.r_Approved_Excluded_Area != null ? appealReview?.r_Approved_Excluded_Area : "0"}
          onChange={handleChange}
          variant="outlined"
          disabled={(!role.business && !role.admin) || initStatus}
          fullWidth
          error={billableError && areaError}
          helperText={billableError && areaError ? "Please check the area calculation" : ""}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6">Impermeable Area</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Current Area"
          name="s_Current_Impermeable_Area"
          variant="outlined"
          value={appealData?.s_Current_Impermeable_Area ? appealData?.s_Current_Impermeable_Area : "0"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Appealed Area"
          id="appealed_impermeable_area"
          value={appealData?.impermeable_area ? appealData?.impermeable_area : "0"}
          variant="outlined"
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Approved Area"
          name="r_Approved_Impermeable_Area"
          value={appealReview?.r_Approved_Impermeable_Area != null ? appealReview?.r_Approved_Impermeable_Area : "0"}
          onChange={handleChange}
          variant="outlined"
          disabled={(!role.business && !role.admin) || initStatus}
          fullWidth
          error={areaError}
          helperText={areaError ? "Please check the area calculation" : ""}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Billable Area"
          name="r_Approved_Billable_Impermeable_Area"
          value={appealReview?.r_Approved_Billable_Impermeable_Area != null ? appealReview?.r_Approved_Billable_Impermeable_Area : ""}
          variant="outlined"
          disabled
          fullWidth
          error={billableError} />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6">Permeable Area</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Current Area"
          name="s_Current_Impermeable_Area"
          variant="outlined"
          value={appealData?.s_Current_Permeable_Area ? appealData?.s_Current_Permeable_Area : "0"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Appealed Area"
          id="appealed_impermeable_area"
          value={appealData?.permeable_area ? appealData?.permeable_area : "0"}
          variant="outlined"
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Approved Area"
          name="r_Approved_Permeable_Area"
          value={appealReview?.r_Approved_Permeable_Area ? appealReview?.r_Approved_Permeable_Area : "0"}
          onChange={handleChange}
          variant="outlined"
          disabled
          fullWidth
          error={areaError} />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Billable Area"
          name="r_Approved_Billable_Permeable_Area"
          value={appealReview?.r_Approved_Billable_Permeable_Area ? appealReview?.r_Approved_Billable_Permeable_Area : "0"}
          onChange={handleChange}
          variant="outlined"
          disabled
          fullWidth
          error={billableError} />
      </Grid>
      <FormInputTypography
        label="Applicant Comments"
        name="applicant_comments"
        value={appealData?.applicant_comments ? appealData.applicant_comments : "The applicant did not provide any comments."}
        align="left"
      />
    </Fragment>
  )
}

export default ExcludedAreaContent;