import React, { useState } from 'react';

/* MUI */
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText} from '@material-ui/core';

/* Langan Components */
import DashboardTable from './DashboardTable';

import './dashbord.styles.scss';

const UnassignedAppeals = (props) => {
  const [openDialog, setOpenDialog] = useState(false);

  const unassignAppealHeader = [
    { field: 'customerId', headerName: 'Appeal Id', width: 140 },
    { field: 'appealType', headerName: 'Type', width: 200 },
    { field: 'ccbAccount', headerName: 'Account #', width: 150 },
    { field: 'zipcode', headerName: 'Zipcode', width: 130 },
    {
      field: 'appealDate',
      headerName: 'Date',
      width: 140,
      valueFormatter: (params) => {
        return params.value?.substring(0, 10);
      },
    },
    {
      field: "reviewerUsername",
      headerName: "Reviewer",
      type: "singleSelect",
      width: 200,
      editable: true,
      valueOptions: [
        // "",
        "DAndrus@sfwater.org",
        "DBatshoun@sfwater.org",
        "THutcherson@sfwater.org",
        "AGraham@sfwater.org",
        "JLaw@sfwater.org",
        "AMarshall@sfwater.org",
        "RPrasad@sfwater.org",
        "Tso@sfwater.org"
      ]
    },
    {
      field: 'assign', headerName: 'Assign', width: 130,
      renderCell: () => {
        return <Button size="small" color="primary" >Submit</Button>
      }
    },
  ];

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const submitReviewer = (params) => {
    if (params.field === "assign") {
      if (params.row.reviewerUsername !== "") {
        props.assignReview(params);
      } else {
        setOpenDialog(true);
      }
    } 
  };

  return (
    <Container maxWidth="lg" className='dashboard'>
      <DashboardTable
        header={unassignAppealHeader}
        data={props.data}
        filter={{ items: [{ columnField: 'appealType', operatorValue: 'equals' }] }}
        onCellClick={submitReviewer}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="submit-reviewer"
        aria-describedby="submit-reviewer-description"
      >
        <DialogTitle id="submit-reviewer">
          Alert
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="submit-reviewer-description">
            You need to assign a reviewer to submit.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default UnassignedAppeals;



