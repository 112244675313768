import React, { useState, useEffect } from 'react';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  }
}));

const ReviewedLandUseCode = observer(props => {
  const classes = useStyles(); 
  const [page, setPage] = useState(5);
  const data = getSnapshot(props.store.reviewedLandUseCode);

  const header = [
    { field: 'classCode', headerName: 'Class Code', width: 200 },
    { field: 'classDefinition', headerName: 'Class Definition', width: 300 },
    { field: 'useCode', headerName: 'Use Code', width: 200 },
    { field: 'customerClass', headerName: 'Customer Class', width: 200 },
    { field: 'landlordReversion', headerName: 'Landlord Reversion', width: 220 }
  ];


  return (
    <Container maxWidth="lg">
      <Paper className={classes.paper}>
        <DataGrid
          columns={header}
          rows={data}
          getRowId={(row) => row.classCode}
          pageSize={page}
          onPageSizeChange={(pageSize) => { setPage(pageSize); }}
          autoHeight
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Paper>
    </Container>

  )
});

export default ReviewedLandUseCode;