import { Fragment } from "react";
import FormInputCheckbox from '../../../../form-input/form-input-checkbox.component';
import FormInputTextField from '../../../../form-input/form-input-textfield.component';
import { Grid, TextField, Typography } from '@material-ui/core';
import FormInputTypography from "../../../../form-input/form-input-typography.component";

const ImpermeableAreaContent = ({ appealData, appealReview, setAppealReview, appealedPermeableArea, approvedPermeableArea, role, initStatus }) => {

  const handleChangeCheckbox = (event) => {
    const { name, checked } = event.target
    setAppealReview(prev => {
      return {
        ...prev,
        [name]: checked
      }
    })
  };

  const handleChange = (event) => {
    const { name, value } = event.target
    setAppealReview(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  };

  return (
    <Fragment>
      <FormInputCheckbox
        label="Claimed Area is valid"
        name="r_Claim_Area_is_Valid"
        checked={appealReview.r_Claim_Area_is_Valid}
        onChange={handleChangeCheckbox}
        disabled={(!role.business && !role.admin) || initStatus}
      />
      <FormInputCheckbox
        label="Area Changes Provided"
        name="r_Location_of_changes_provided"
        checked={appealReview.r_Location_of_changes_provided}
        onChange={handleChangeCheckbox}
        disabled={(!role.business && !role.admin) || initStatus}
      />
      <FormInputTextField
        label="Gross Total Area"
        name="s_Current_Gross_Total_Area"
        value={appealData?.s_Current_Gross_Total_Area ? Math.round(appealData.s_Current_Gross_Total_Area) : ""}
        disabled
        InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
      />
      <FormInputTextField
        label="Excluded Area"
        name="s_Current_Excluded_Area"
        value={appealData?.s_Current_Excluded_Area ? Math.round(appealData.s_Current_Excluded_Area) : ""}
        disabled
        InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
      />

      <Grid item xs={4}>
        <Typography variant="h6" >Impermeable Area</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Current Area"
          name="s_Current_Impermeable_Area"
          variant="outlined"
          value={appealData?.s_Current_Impermeable_Area ? Math.round(appealData.s_Current_Impermeable_Area) : ""}
          InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Appealed Area"
          name="impermeable_area"
          value={appealData?.impermeable_area ? Math.round(appealData?.impermeable_area) : ""}
          variant="outlined"
          InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Approved Area"
          name="r_Approved_Impermeable_Area"
          value={appealReview?.r_Approved_Impermeable_Area ? appealReview.r_Approved_Impermeable_Area : ""}
          onChange={handleChange}
          variant="outlined"
          disabled={(!role.business && !role.admin) || initStatus}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={8}>
        <TextField
          label="Approved Billable Impermeable Area"
          name="r_Approved_Impermeable_Area"
          value={appealReview?.r_Approved_Billable_Impermeable_Area ? appealReview.r_Approved_Billable_Impermeable_Area : ""}
          variant="outlined"
          InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6">Permeable Area</Typography>
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Appealed Area"
          name="permeable_appealed_area"
          variant="outlined"
          value={appealedPermeableArea ? Math.round(appealedPermeableArea) : "0"}
          InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          fullWidth />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Approved Area"
          name="permeable_approved_area"
          variant="outlined"
          value={approvedPermeableArea}
          InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          // onChange={(e) => setPermeableArea(e.target.value)} 
          fullWidth />
      </Grid>
      <FormInputTypography
        label="Applicant Comments"
        name="applicant_comments"
        value={appealData?.applicant_comments ? appealData.applicant_comments : "The applicant did not provide any comments."}
        align="left"
      />
    </Fragment>
  )
}

export default ImpermeableAreaContent;