import { Fragment } from "react";

/* MUI */
import { Checkbox, Grid, Typography } from '@material-ui/core';

const FormInputCheckbox = ({ label, align="left", size=[4,2], ...otherProps }) => {

  return (
    <Fragment>
      <Grid item xs={size[0]} align={align}>
        <Typography variant="h6">{label}</Typography>
      </Grid>

      <Grid item xs={size[1]} align={align}>
        <Checkbox 
        color="primary"
        style={{padding: '0px'}}
        {...otherProps}
        />
      </Grid>
    </Fragment>
  )
}

export default FormInputCheckbox;