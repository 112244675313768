import { useState, useEffect } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Container, Grid, Typography, Box } from '@material-ui/core';

import FormInputTextField from '../../form-input/form-input-textfield.component';
import FormInputPhone from '../../form-input/form-input-phone.component';
import { useCredits } from '../../../stores/CreditStore/CreditModuleStore';

const GeneralInformation = observer((props) => {
  const [isValidName, setIsValidName] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isValidConfirmEmail, setIsValidConfirmEmail] = useState(false);
  const [helpText, setHelpText] = useState("Please confim the email address...");
  const credits = useCredits();

  const updateNameField = (event) => {
    const { name, value } = event.target;
    credits.creditEntry.setItem(name, value);
    setIsValidName(credits.creditEntry.Applicant_Name.length > 0);
  }

  const updatePhoneField = (value) => {
    credits.creditEntry.setItem('Applicant_Phone', value);
    setIsValidPhone(credits.creditEntry.Applicant_Phone.length === 17);
  }

  const updateEmailField = (event) => {
    const { name, value } = event.target;
    credits.creditEntry.setItem(name, value);
    setIsValidEmail(/\S+@\S+\.\S+/.test(credits.creditEntry[name]));
  }

  const updateConfirmEmailField = (event) => {
    setConfirmEmail(event.target.value);
  }

  useEffect(() => {
    if (isValidEmail && confirmEmail && (credits.creditEntry.Applicant_Email === confirmEmail)) {
      setIsValidConfirmEmail(true);
      setHelpText("");
    } else {
      setIsValidConfirmEmail(false);
      setHelpText("Please enter the email address again to confirm.")
    }
  }, [confirmEmail])


  useEffect(() => {
    if (credits && credits.creditEntry) {
      if (isValidName && isValidPhone && isValidEmail && isValidConfirmEmail) {
        credits.creditEntry.setItem("generalInfoStatus", true);
      } else {
        credits.creditEntry.setItem("generalInfoStatus", false);
      }
      credits.creditEntry.statusComplete();
    }
  }, [isValidName, isValidPhone, isValidEmail, isValidConfirmEmail])

  return (
    <>
      {(credits && credits.creditEntry) &&
        <Container>
          <Grid container spacing={2} >
            <Grid item xs={12} align="center" style={{ backgroundColor: "whitesmoke" }}>
              <Typography variant="h5" gutterBottom color="primary">General Information</Typography>
            </Grid>

            <FormInputTextField
              label="Account Number"
              required={true}
              name="Account_Number"
              value={credits.creditEntry.Account_Number}
              InputProps={{ readOnly: true }}
            />

            <FormInputTextField
              label="ZIP Code"
              required={true}
              name="Zip_Code"
              value={credits.creditEntry.Zip_Code}
              InputProps={{ readOnly: true }}
            />

            <FormInputTextField
              label="Applicant Name"
              required={true}
              name="Applicant_Name"
              helperText='An applicant may apply on behalf of an SFPUC customer.'
              value={credits.creditEntry.Applicant_Name}
              onChange={updateNameField}
              InputProps={{ readOnly: false }}
              error={!isValidName}
            />

            <FormInputPhone
              label="Applicant Phone Number"
              required={true}
              name="Applicant_Phone"
              value={credits.creditEntry.Applicant_Phone}
              onChange={updatePhoneField}
              InputProps={{ readOnly: false }}
              error={!isValidPhone}
            />

            <FormInputTextField
              label="Applicant Email Address"
              required={true}
              name="Applicant_Email"
              helperText="If you do not have an email address, please submit a paper application."
              value={credits.creditEntry.Applicant_Email}
              onChange={updateEmailField}
              InputProps={{ readOnly: false }}
              error={!isValidEmail}
            />

            <FormInputTextField
              label="Confirm Email Address"
              required={true}
              name="confirmApplicantEmail"
              value={confirmEmail}
              onChange={updateConfirmEmailField}
              error={!isValidConfirmEmail}
              helperText={helpText}
            />

          </Grid>
          <Box p={2} />
        </Container>
      }
    </>
  )
})

export default GeneralInformation;