import { Routes, Route } from 'react-router-dom';

/* Langan Components */
import SearchNavigation from '../../components/search/search-navigation/search-navigation.component';
import SearchMap from '../../components/search/SearchMap';
import SearchAssessor from '../../components/search/SearchAssessor';
import SearchOwner from '../../components/search/SearchOwner';
import SearchAppeal from '../../components/search/SearchAppeal';
import SearchCredit from '../../components/search/SearchCredit';

import { makeStyles } from '@material-ui/core/styles';
import SearchAddress from '../../components/search/SearchAddress';
import GenericAppealsRouter from '../admin/genericappeals.router';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    backgroundColor: theme.palette.background.paper,
    minHeight: "calc(100vh - 200px)",
  },
}));

const SearchRouter = (props) => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Routes>
        <Route element={<SearchNavigation role={props.role}/>}>
          <Route index element={<SearchMap />} />
          <Route path='mapblocklot' element={<SearchMap />} />
          <Route path='assessorblocklot' element={<SearchAssessor />} />
          <Route path='legalowner' element={<SearchOwner />} />
          {
            (props.role.admin || props.role.business) && <Route path='appeals' element={<SearchAppeal />} />
          }
          <Route path='address' element={<SearchAddress />} />
          {
            (props.role.admin || props.role.credit)&& <Route path='credits' element={<SearchCredit />} />
          }
          <Route path='appeals/*' element={<GenericAppealsRouter />} />
          
        </Route>
      </Routes>
    </main>
  )
}

export default SearchRouter;