//enum class for credit type
export const CreditType = {
    Permeable: 'Permeable',
    Impermeable: 'Impermeable',
    None: 'None'
}

export const FacilityType = {
    Bioretention: 'Bioretention or Rain Garden',
    Swale: 'Vegetated Swale',
    Trench: 'Infiltration Trench (unvegetated)',
    Pavement: 'Permeable Pavement',
    Roof: 'Vegetated Roof',
    OtherNonVegetated: 'Other non-vegetated green infrastructure',
    OtherVegetated: 'Other vegetated green infrastructure',
    Init: '',
    Media: 'Media Filter',
    DryWell: 'Dry Well/Infiltration Gallery (subsurface)',
    Cistern: 'Rainwater Harvesting Cistern',
    DetentionVault: 'Detention Vault or Tank (subsurface)',
}

//lookup table for credit type
export const creditedTypeLookup = {
    'Bioretention or Rain Garden' : CreditType.Permeable,
    'Vegetated Swale': CreditType.Permeable,
    'Vegetated Roof': CreditType.Permeable,
    'Infiltration Trench (unvegetated)': CreditType.Impermeable,
    'Permeable Pavement': CreditType.Impermeable,
    'Other non-vegetated green infrastructure': CreditType.Impermeable,
    'Other vegetated green infrastructure': CreditType.Permeable,
    'Media Filter': CreditType.None,
    'Dry Well/Infiltration Gallery (subsurface)': CreditType.None,
    'Rainwater Harvesting Cistern': CreditType.None,
    'Detention Vault or Tank (subsurface)': CreditType.None,
}
/**2023-24 
export const IMPERM_RATE = 1.89 / 1000;
export const PERM_RATE = 0.19 / 1000; **/
export const PERM_RATE = 0.41 / 1000;
export const IMPERM_RATE = 4.11 / 1000;