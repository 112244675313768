import { Fragment } from "react";

/* MUI */
import { Grid, Typography } from '@material-ui/core';
import MuiPhoneNumber from "material-ui-phone-number";

const FormInputPhone = ({ label, required=false, size=4, ...otherProps }) => {
  return (
    <Fragment>
      <Grid item xs={size}>
        <Typography variant="h6" display='inline'>{label}</Typography>
        {required && <Typography variant="h6" display='inline' color='error'>*</Typography>}
      </Grid>

      <Grid item xs={12-size} align="center">
        <MuiPhoneNumber
          required={required}
          fullWidth
          variant="outlined"
          defaultCountry={'us'}
          regions={['north-america', 'central-america']}
          countryCodeEditable={false}
          {...otherProps}
        />
      </Grid>
    </Fragment>
  )
}

export default FormInputPhone;