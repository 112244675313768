import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
// import { observer } from 'mobx-react-lite';
import { useManualAppealStore } from '../../stores/AppealStore/ManualAppealStore';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, FormControl, Grid, Paper, TextField, Typography, Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

import { searchAppeals, getManualAppealById, getManualAppealByAccountNoAndSAID } from '../../services/APIClient';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
  },
  buttonSubmit: {
    height: "56px"
  },
  table: {
    marginTop: "20px",
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#115293',
      color: theme.palette.common.white,
      fontSize: 14
    },
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: '#fffbf2',
        cursor: 'pointer'
      },
    }
  },
  link: theme.link,
}));

const SearchAppeal = props => {
  const classes = useStyles();
  let navigate = useNavigate();
  const manualAppealStore = useManualAppealStore();

  const [customerAppealId, setCustomerAppealId] = useState({ CustomerAppealId: "" });
  const [id, setId] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [saId, setSaId] = useState('');
  const [premiseId, setPremiseId] = useState('');
  const [account, setAccount] = useState({ AccountNumber: "" });
  const [results, setResults] = useState([]);
  const [manualAppeals, setManualAppeals] = useState([]);
  const [appealIdError, setAppealIdError] = useState(false);
  const [accountError, setAccountError] = useState(false);

  useEffect(() => {
    if (!id && !accountNo && !saId) {
      setManualAppeals([]);
    }
  }, [id, accountNo, saId])

  const getReviewURL = (row) => {
    switch (row.appealType) {
      case 'Legal Owner':
        return `/appeals/review/incorrectowner/${row.customerAppealID}`;
      case 'Impermeable Area':
        return `/appeals/review/impermeablearea/${row.customerAppealID}`;
      case 'Excluded Area':
        return `/appeals/review/excludedarea/${row.customerAppealID}`;
      case 'Charge Allocations':
        return `/appeals/review/serviceagreement/${row.customerAppealID}`;
      default:
        return null;
    }
  };

  const compare = (a, b) => {
    if (a.appealedDate.substring(0, 10) > b.appealedDate.substring(0, 10)) {
      return -1;
    } else if (a.appealedDate.substring(0, 10) < b.appealedDate.substring(0, 10)) {
      return 1;
    } else {
      if (a.reviewerDecision === "Pending") {
        return -1;
      } else if (b.reviewerDecision === "Pending") {
        return 1;
      } else if (a.reviewerDecision < b.reviewerDecision) {
        return -1
      } else if (a.reviewerDecision > b.reviewerDecision) {
        return 1
      } else {
        return 0
      }
    }
  }

  const formatAppealType = (appeal) => {
    switch (appeal.appealType) {
      case 'LegalOwner':
        return 'Legal Owner';
      case 'ImpermeableArea':
        return 'Impermeable Area';
      case 'ExcludedArea':
        return 'Excluded Area';
      case 'ChargeAllocations':
        return 'Charge Allocations';
      default:
        return '';
    }
  };

  const queryAppeals = (params) => {
    searchAppeals(params)
      .then((response) => {
        if (response && response.data) {
          response.data.map((appeal) => {
            appeal.appealType = formatAppealType(appeal);
          })
          setResults(response.data.sort(compare));
        } else {
          setResults([])
        }
      })
      .catch((e) => {
        if ('AccountNumber' in params) {
          setAccountError(true);
        }
        if ('CustomerAppealId' in params) {
          setAppealIdError(true);
        }
        setResults([])
      })
  };

  const handleManualAppealSearch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === 'id') {
      getManualAppealById(value).then((response) => {
        setManualAppeals([response]);
      }).catch((e) => {
        setManualAppeals([]);
      })
    } else {
      getManualAppealByAccountNoAndSAID(value, name).then((response) => {
        setManualAppeals(response.data)
      }).catch((e) => {
        setManualAppeals([]);
      });
    }
  }

  const searchManualAppeals = (event, name, value) => {
    event.preventDefault();
    if (name === 'id') {
      getManualAppealById(value).then((response) => {
        setManualAppeals([response]);
      }).catch((e) => {
        setManualAppeals([]);
      })
    } else {
      getManualAppealByAccountNoAndSAID(value, name).then((response) => {
        console.log(response.data)
        setManualAppeals(response.data)
      }).catch((e) => {
        setManualAppeals([]);
      });
    }
  }

  const searchByCustomerAppealId = () => {
    queryAppeals(customerAppealId);
  };

  const searchByAccount = () => {
    queryAppeals(account);
  };

  const header = [
    {
      field: 'customerAppealID',
      headerName: 'SDMS Appeal ID',
      width: 210,
      renderCell: (params) => {
        const onClick = () => {
          const url = getReviewURL(params.row);
          navigate(url);
        };
        return <Button size="small" color="primary" onClick={onClick}>{params.value}</Button>;
      }
    },
    { field: 'appealType', headerName: 'Appeal Type', width: 180 },
    { field: 'accountNumber', headerName: 'Account', width: 140 },
    { field: 'reviewerDecision', headerName: 'Decision', width: 140 },
    {
      field: 'appealedDate',
      headerName: 'Appealed Date',
      width: 180,
      valueFormatter: (params) => {
        return params.value?.substring(0, 10);
      },
    },
    {
      field: 'statusChangedDate',
      headerName: 'Status Change Date',
      width: 220,
      valueFormatter: (params) => {
        return params.value?.substring(0, 10);
      },
    },
    { field: 'reviewSubmittedBy', headerName: 'Reviewed By', width: 180 },
  ];

  const manualAppealHeader = [
    {
      field: 'manual_Appeal_ID', headerName: 'Manual Appeal ID', width: 200, disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          manualAppealStore.initManualAppealView(params.row);
          navigate('./view')
        }
        return <Button color='primary' onClick={onClick}>
          <Typography className={classes.link}>{params.id}</Typography>
        </Button>
      }
    },
    { field: 'account_No', headerName: 'Account #', width: 180 },
    { field: 'map_Block_Lot_No', headerName: 'Mapblocklot', width: 180 },
    { field: 'assessors_BlkLot', headerName: 'Assessor Blklot', width: 180 },
    { field: 'premise_ID', headerName: 'Premise ID', width: 200 },
    { field: 'wastewater_Sa_ID', headerName: 'Wastewater SA ID', width: 200 },
    { field: 'r_Decision', headerName: 'Decision', width: 200 },
    {
      field: 's_Appealed_Date',
      headerName: 'Submission Date',
      width: 200,
      valueFormatter: (params) => {
        return params.value?.substring(0, 10);
      }
    },
  ];

  const handleFormSubmit = (event) => {
    if (event.target.name === 'CustomerAppealId' && event.key === 'Enter') {
      searchByCustomerAppealId();
    } else if (event.target.name === 'AccountNumber' && event.key === 'Enter') {
      searchByAccount();
    }
  }

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Typography variant="h6">Appeals Search </Typography>
        <FormControl fullWidth margin='dense' onKeyPress={handleFormSubmit}>
          <Grid container spacing={1} className={classes.form}>
            {/* Search by Customer Appeal ID */}
            <Grid item xs={10}>
              <TextField
                name="CustomerAppealId"
                variant="outlined"
                label="SDMS Appeal ID (Example: 60)"
                type="search"
                error={appealIdError}
                helperText={appealIdError ? 'The appeal is not found.' : ''}
                fullWidth
                value={customerAppealId.CustomerAppealId}
                onChange={(e) => {
                  setCustomerAppealId({ [e.target.name]: e.target.value });
                  setAppealIdError(false);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                onClick={searchByCustomerAppealId}
                disabled={customerAppealId.CustomerAppealId ? false : true}
              >
                Submit
              </Button>
            </Grid>
            {/* Manual Appeal Search Section*/}
            {/* Search by Account NO */}
            <Grid item xs={10}>
              <TextField
                name="AccountNumber"
                variant="outlined"
                label="Account No (Example: 2690300000)"
                type="search"
                fullWidth
                error={accountError}
                helperText={accountError ? 'The records are not found.' : ''}
                value={account.AccountNumber}
                onChange={(e) => {
                  setAccount({ [e.target.name]: e.target.value });
                  setAccountError(false)
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                onClick={searchByAccount}
                disabled={account.AccountNumber ? false : true}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </FormControl>
        <Box m={2} />
        <Typography variant="h6">Manual Appeals Search </Typography>
        <FormControl fullWidth margin='dense' onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleManualAppealSearch(e);
          }
        }}>
          <Grid container spacing={1} className={classes.form}>
            {/* Search by Customer Appeal ID */}
            <Grid item xs={10}>
              <TextField
                name="id"
                variant="outlined"
                label="Manual Appeal ID (Example: 60)"
                type="search"
                error={false}
                helperText={false ? 'No record is found.' : ''}
                fullWidth
                value={id}
                onChange={(e) => {
                  setId(e.target.value.trim());
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                name="id"
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                onClick={(e) => searchManualAppeals(e, 'id', id)}
                disabled={id ? false : true}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={10}>
              <TextField
                name="accountNo"
                variant="outlined"
                label="Account No (Example: 2690300000)"
                type="search"
                fullWidth
                error={false}
                helperText={false ? 'The records are not found.' : ''}
                value={accountNo}
                onChange={(e) => {
                  setAccountNo(e.target.value.trim());
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                onClick={(e) => searchManualAppeals(e, 'accountNo', accountNo)}
                disabled={accountNo ? false : true}
              >
                Submit
              </Button>
            </Grid>

            <Grid item xs={10}>
              <TextField
                name="saID"
                variant="outlined"
                label="Wastewater SA ID"
                type="search"
                fullWidth
                error={false}
                helperText={false ? 'The records are not found.' : ''}
                value={saId}
                onChange={(e) => {
                  setSaId(e.target.value.trim());
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                onClick={(e) => searchManualAppeals(e, 'saID', saId)}
                disabled={saId ? false : true}
              >
                Submit
              </Button>
            </Grid>

            <Grid item xs={10}>
              <TextField
                name="premiseID"
                variant="outlined"
                label="Premise ID"
                type="search"
                fullWidth
                error={false}
                helperText={false ? 'The records are not found.' : ''}
                value={premiseId}
                onChange={(e) => {
                  setPremiseId(e.target.value.trim());
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                onClick={(e) => searchManualAppeals(e, 'premiseID', premiseId)}
                disabled={premiseId ? false : true}
              >
                Submit
              </Button>
            </Grid>

          </Grid>
        </FormControl>
        {results.length > 0 && <DataGrid
          className={classes.table}
          columns={header}
          rows={results}
          getRowId={(row) => row.appealId}
          autoHeight
          autoPageSize={true}
        />}
        {(manualAppeals.length > 0 && manualAppealStore) && 
        <DataGrid
        autoHeight
        rows={manualAppeals}
        columns={manualAppealHeader}
        autoPageSize={true}
        getRowId={(row) => row.manual_Appeal_ID}
      />}
      </Paper >
    </Container >
  )
};

export default SearchAppeal;