import React from 'react';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#545454",
    padding: theme.spacing(2),
    display: "flex",
    bottom: "0%",
  },
  card: {
    width: '400px',
    float: 'left',
    paddingRight: '20px',
    margin:theme.spacing(3),
    backgroundColor: "#545454",
    border: "1px solid white"
  },
  cardContent: {
    color: "#fff",
  },
  cardText: {
    color: "#fff",
  },
  link: {
    color: "#9FCBED",
    fontWeight: 'bold',
    textDecoration: 'underline',
    fontFamily: 'Red Hat Display, sans-serif',
    cursor: 'pointer'
  },
}));

const CustomCard = ({ title, content, linkTitle, linkUrl }) => {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.cardText}>
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" component="p"className={classes.cardText}>
              {content} 
              <Link href={linkUrl} className={classes.link}>{linkTitle}</Link>
              .
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
};

const ParcelFooter = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Typography variant="h5" style={{ color: "whitesmoke" }} >Next Steps</Typography>
      <CustomCard
        title="Notice something wrong with the map or your stormwater charge?"
        content="Let us know by filing an "
        linkTitle="appeal"
        linkUrl={process.env.REACT_APP_BASE_PATH + "/appeals"}
      />
      <CustomCard
        title="This property may be eligible for Stormwater credits."
        content="Stormwater credits can reduce your monthly bill. "
        linkTitle="Learn more about credits"
        linkUrl={process.env.REACT_APP_BASE_PATH + "/credit"}
      />
    </div>
  );
}

export default ParcelFooter;