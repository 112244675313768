import React, { Fragment, useEffect, useState } from 'react';

import FormInputTextField from '../../../../form-input/form-input-textfield.component';
import FormInputRadio from '../../../../form-input/form-input-radio.component';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, Typography } from '@material-ui/core';
import FormInputTypography from '../../../../form-input/form-input-typography.component';

const options = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },]

const IncorrectOwnerContent = ({ appealData, setAppealData }) => {
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (appealData) {
      if (appealData.r_Decision === 'approved' || appealData.r_Decision === 'rejected' || appealData.r_Decision === 'cancelled') {
        setReadOnly(true);
      }
    }
  }, [appealData.r_Decision])

  useEffect(() => {
    if (appealData) {
      appealData.r_New_Legal_Owner_Name = appealData.r_New_Legal_Owner_Name != null ? appealData.r_New_Legal_Owner_Name : appealData.new_legal_owner_name;
      appealData.r_LO_Appeal_Record_Date = appealData.r_LO_Appeal_Record_Date != null ? appealData.r_LO_Appeal_Record_Date : appealData.appeal_record_date;
      appealData.r_Phone_Number = appealData.r_Phone_Number != null ? appealData.r_Phone_Number  : appealData.phone_number;
      appealData.r_New_Owner_Mailing_Address = appealData.r_New_Owner_Mailing_Address != null ? appealData.r_New_Owner_Mailing_Address : appealData.address;
      appealData.r_New_Owner_Mailing_Address_2 = appealData.r_New_Owner_Mailing_Address_2 != null ? appealData.r_New_Owner_Mailing_Address_2 : appealData.address2;
      appealData.r_New_Owner_Mailing_City = appealData.r_New_Owner_Mailing_City != null ? appealData.r_New_Owner_Mailing_City : appealData.city;
      appealData.r_New_Owner_Mailing_State = appealData.r_New_Owner_Mailing_State != null ? appealData.r_New_Owner_Mailing_State : appealData.state;
      appealData.r_New_Owner_Mailing_Zip = appealData.r_New_Owner_Mailing_Zip != null ? appealData.r_New_Owner_Mailing_Zip : appealData.zip;
      appealData.r_New_Owner_Mailing_Country = appealData.r_New_Owner_Mailing_Country != null ? appealData.r_New_Owner_Mailing_Country : appealData.country;
      appealData.r_Is_Government_Entity = appealData.r_Is_Government_Entity === null ? appealData.isGovernmentEntity : appealData.r_Is_Government_Entity;
      setAppealData(appealData);
    }
  }, [appealData.new_legal_owner_name])

  const handleChange = (event) => {
    const { name, value } = event.target
    setAppealData(prev => {
      if (name === 'r_Is_Government_Entity') {
        return {
          ...prev,
          [name]: value === 'Yes' ? true : false
        }
      } else {
        return {
          ...prev,
          [name]: value
        }
      }
    })
  };

  const handleDateChange = (date) => {
    if (date) {
      date.setHours(0, 0, 0, 0);
    }
    setAppealData(prev => {
      return {
        ...prev,
        r_LO_Appeal_Record_Date:  date
      }
    })
  };

  //freeze the fields if already approved, not editable
  return (
    <>
      {appealData &&
        <Fragment>
          <FormInputTextField
            label="Appealed Legal Owner Name"
            name="new_legal_owner_name"
            value={appealData?.new_legal_owner_name ? appealData.new_legal_owner_name : ""}
            InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          />

          <FormInputTextField
            label="Approved Legal Owner Name"
            name="r_New_Legal_Owner_Name"
            value={appealData?.r_New_Legal_Owner_Name ? appealData.r_New_Legal_Owner_Name : ""}
            onChange={(e) => handleChange(e)}
            InputProps={{ readOnly: readOnly }}
          />

          <FormInputTextField
            label="Record Date"
            name="appeal_record_date"
            value={appealData?.appeal_record_date ? appealData.appeal_record_date.substring(0, 10) : ""}
            InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          />

          <Fragment>
            <Grid item xs={4} >
              <Typography variant="h6" >Approved Record Date</Typography>
            </Grid>
            <Grid item xs={8} align='center'>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker disableToolbar inputVariant="outlined" format="yyyy-MM-dd" id="date-picker-inline" KeyboardButtonProps={{ 'aria-label': 'change date', }} required fullWidth disabled={readOnly} InputProps={{style: { color: 'black' }}}
                  value={appealData.r_LO_Appeal_Record_Date} name='r_LO_Appeal_Record_Date' onChange={handleDateChange} />
              </MuiPickersUtilsProvider>
            </Grid>
          </Fragment>


          <FormInputTextField
            label="Phone Number"
            name="phone_number"
            value={appealData?.phone_number ? appealData.phone_number : ""}
            //align={'left'}
            InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          />

          <FormInputTextField
            label="Approved Phone Number"
            name="r_Phone_Number"
            value={appealData?.r_Phone_Number ? appealData.r_Phone_Number : ""}
            onChange={(e) => handleChange(e)}
            InputProps={{ readOnly: readOnly }}
          />

          <FormInputTextField
            label="New Owner Mailing Address"
            name="address"
            value={appealData?.address ? appealData.address : ""}
            //disabled
            InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          />
          <FormInputTextField
            label="Approved New Owner Mailing Address"
            name="r_New_Owner_Mailing_Address"
            value={appealData?.r_New_Owner_Mailing_Address ? appealData.r_New_Owner_Mailing_Address : ""}
            onChange={(e) => handleChange(e)}
            InputProps={{ readOnly: readOnly }}
          />

          <FormInputTextField
            label="New Owner Mailing Address 2"
            name="address2"
            value={appealData?.address2 ? appealData.address2 : ""}
            //disabled
            InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          />

          <FormInputTextField
            label="Approved New Owner Mailing Address 2"
            name="r_New_Owner_Mailing_Address_2"
            value={appealData?.r_New_Owner_Mailing_Address_2 ? appealData.r_New_Owner_Mailing_Address_2 : ""}
            onChange={(e) => handleChange(e)}
            InputProps={{ readOnly: readOnly }}
          />

          <FormInputTextField
            label="New Owner Mailing City"
            name="city"
            value={appealData?.city ? appealData.city : ""}
            //disabled
            InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          />

          <FormInputTextField
            label="Approved New Owner Mailing City"
            name="r_New_Owner_Mailing_City"
            value={appealData?.r_New_Owner_Mailing_City ? appealData.r_New_Owner_Mailing_City : ""}
            onChange={(e) => handleChange(e)}
            InputProps={{ readOnly: readOnly }}
          />

          <FormInputTextField
            label="New Owner Mailing State"
            name="state"
            value={appealData?.state ? appealData.state : ""}
            //disabled
            InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          />

          <FormInputTextField
            label="Approved New Owner Mailing State"
            name="r_New_Owner_Mailing_State"
            value={appealData?.r_New_Owner_Mailing_State ? appealData.r_New_Owner_Mailing_State : ""}
            onChange={(e) => handleChange(e)}
            InputProps={{ readOnly: readOnly }}
          />

          <FormInputTextField
            label="New Owner Mailing Zip"
            name="zip"
            value={appealData?.zip ? appealData.zip : ""}
            //disabled
            InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          />

          <FormInputTextField
            label="Approved New Owner Mailing Zip"
            name="r_New_Owner_Mailing_Zip"
            value={appealData?.r_New_Owner_Mailing_Zip ? appealData.r_New_Owner_Mailing_Zip : ""}
            onChange={(e) => handleChange(e)}
            InputProps={{ readOnly: readOnly }}
          />

          <FormInputTextField
            label="New Owner Mailing Country"
            name="country"
            value={appealData?.country ? appealData.country : ""}
            //disabled
            InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }}
          />

          <FormInputTextField
            label="Approved New Owner Mailing Country"
            name="r_New_Owner_Mailing_Country"
            value={appealData?.r_New_Owner_Mailing_Country ? appealData.r_New_Owner_Mailing_Country : ""}
            onChange={(e) => handleChange(e)}
            InputProps={{ readOnly: readOnly }}
          />

          <FormInputRadio
            label="Is Government Entity"
            name="isGovernmentEntity"
            value={appealData?.isGovernmentEntity ? 'Yes' : 'No'}
            ariaLabel="Is Government Entity"
            disabled
            options={options}
          />
          <FormInputRadio
            label="Approved Is Government Entity"
            name="r_Is_Government_Entity"
            value={appealData?.r_Is_Government_Entity ? 'Yes' : 'No'}
            ariaLabel="approvedEntity"
            onChange={(e) => handleChange(e)}
            options={options}
            disabled={readOnly}
          />
          <FormInputTypography
            label="Applicant Comments"
            name="applicant_comments"
            value={appealData?.applicant_comments ? appealData.applicant_comments : "The applicant did not provide any comments."}
            align="left"
            InputProps={{ readOnly: readOnly }}
          />
        </Fragment>}
    </>

  )
};

export default IncorrectOwnerContent;