import { getParent, types } from "mobx-state-tree"

// Esri Modules
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import Portal from "@arcgis/core/portal/Portal";
import esriConfig from "@arcgis/core/config";

const GroupMapping = {
  SFPUC_Admin: "admin",
  SFPUC_Business: "business",
  SFPUC_Viewer: "viewer",
  SFPUC_User: "user",
  SFPUC_Credit_Admin: "credit",
};

export const UserStore = types
  .model("User", {
    id: types.string, // The Portal username
    // employeeId: types.string,
    fullName: types.string,
    email: types.string,
    token: types.string,
    role: types.string,
    groups: types.optional(types.array(types.string), []),
    // dismissDisclaimer: false
  })
  .views(self => ({
    // get root() {
    //   return getParent(self);
    // },
    get isLangan() {
      return self.id.endsWith("@langan.com");
    },
  }))
  .actions((self) => ({
    configurePortal() {
      const portalUrl = process.env.REACT_APP_PORTAL_URL;
      if (!portalUrl) throw Error("Portal URL not set!");
      esriConfig.portalUrl = portalUrl;
    },
    getRoot() {
      return getParent(self);
    },
    async auth() {
      const portal = new Portal();
      portal.authMode = "immediate";
      portal.load().then(() => {
        self.setItem("fullName", portal.user.fullName);
        self.setItem("email", portal.user.email);
        self.setItem("role", portal.user.role);
        
        portal.user.fetchGroups().then((groups) => {
          const groupNames = groups.map((group) => group.title);
          self.setItem("groups", groupNames);
          const rootRole = self.getRoot().role;
          Object.keys(GroupMapping).map((key, idx) => {
            if (groupNames.includes(key)) {
              rootRole.setItem([GroupMapping[key]], true);
            } else {
              rootRole.setItem([GroupMapping[key]], false);
            }
          });
          self.getRoot().updateLogin();
        });
      });
      self.setItem("portal", portal);
      const portalUrl = process.env.REACT_APP_PORTAL_URL;
      if (!portalUrl) throw Error("Portal URL not set!");
      const appId = process.env.REACT_APP_PORTAL_ID;

      if (!appId) throw Error("App ID not set!");

      const info = new OAuthInfo({
        appId: appId,
        portalUrl: portalUrl,
        popup: false,
      });

      IdentityManager.registerOAuthInfos([info]);

      const credentials = await IdentityManager.getCredential(portalUrl + "/sharing");
      self.setToken(credentials.token);
      self.setId(credentials.userId);
    },
    setItem(item, value) {
      self[item] = value;
    },
    setToken(value) {
      self.token = value;
    },
    setId(value) {
      self.id = value;
    },

  }));
