/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import ChargeAllocationsContent from '../appeal-content/charge-allocations-content';
import DecisionReview from '../decision-review/decision-review.component';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '20px',
    backgroundColor: "whitesmoke"
  },
}));

const ChargeAllocationsAppealReview = ({ title, appealData, approvedCharge, saHandleChange, warning, role, initStatus, ...decisionReviewProps }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} align="center" className={classes.title}>
        <Typography variant="h5" gutterBottom color='primary'>{title}</Typography>
      </Grid>
      <ChargeAllocationsContent
        appealData={appealData}
        approvedCharge={approvedCharge}
        saHandleChange={saHandleChange}
        warning={warning}
        role={role}
        initStatus={initStatus}
      />
      <DecisionReview role={role} initStatus={initStatus} {...decisionReviewProps} />
    </Grid>
  )
}

export default ChargeAllocationsAppealReview;