import React from 'react';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import FormInputCheckbox from '../../../form-input/form-input-checkbox.component';
import FormAttachmentList from '../../../form-input/form-attachment-list.component';
import FormInputTypography from '../../../form-input/form-input-typography.component.jsx';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '20px',
    backgroundColor: "white"
  }
}));

const AppealValidation = props => {
  const classes = useStyles();
  const file = props.file.filter(f => f.documenT_TYPE === "Appeal");
  const downloadParam = props ? {
    master_id: props.masterData?.masterId,
    appeal_id: props.masterData?.appeal_type_id,
    appeal_type: props.masterData?.appeal_type,
    document_type: 'Appeal'
  } : {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.title} />
      <FormInputCheckbox
        label="Valid Account"
        size={[4, 8]}
        align='left'
        checked
      />
      <FormInputTypography
        label="Account Number"
        value={props.masterData?.ccb_account ? props.masterData.ccb_account : ""}
        align={'left'}
      />
      <FormInputTypography
        label="ZIP Code"
        value={props.masterData?.zipcode ? props.masterData.zipcode : ""}
        align={'left'}
      />
      <FormInputTypography
        label="Applicant Name"
        value={props.masterData?.masterAppeals?.applicant_Name ? props.masterData.masterAppeals.applicant_Name : ""}
        align={'left'}
      />
      <FormInputTypography
        label="Applicant Phone"
        value={props.masterData?.masterAppeals?.applicant_Phone ? props.masterData.masterAppeals.applicant_Phone : ""}
        align={'left'}
      />
      <FormInputTypography
        label="Applicant Email"
        value={props.masterData?.masterAppeals?.applicant_Email ? props.masterData.masterAppeals.applicant_Email : ""}
        align={'left'}
      />

      <FormInputTypography
        label="The Applicant is"
        value={(props.masterData?.masterAppeals?.typeOfAccountHolder && props.masterData.masterAppeals.typeOfAccountHolder.length > 0 && props.masterData.masterAppeals.typeOfAccountHolder[0]) ? props.masterData.masterAppeals.typeOfAccountHolder.join("\n") : "The applicant did not select any type of account holder."}
        align={'left'}
      />

      <FormAttachmentList
        label="Submitted Docs"
        file={file}
        align={'left'}
        downloadParam={downloadParam}
      />
    </Grid>
  )
};

export default AppealValidation;