import React, { useState } from 'react';
import * as converter from "json-2-csv";

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid, Button, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';

import { searchAlternateAddress } from '../../../services/APIClient';
import CreateAlternateAddressDialog from './CreateAlternateAddressDialog'
import { SubmitSearchButton } from '../../search/SubmitSearchButton';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  }
}));

const ManageAlternateAddresses = props => {
  const classes = useStyles();
  const [noRecord, setNoRecord] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [address, setAddress] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(5);

  const addressHeader = [
    { field: 'premiseID', headerName: 'Premise ID', width: 200 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'address2', headerName: 'Address2', width: 200 },
    { field: 'address3', headerName: 'Address3', width: 200 },
    { field: 'address4', headerName: 'Address4', width: 200 },
    { field: 'altAddress', headerName: 'Alternate Address', width: 200 },
    { field: 'assessorBlock', headerName: 'Assessor Block', width: 180 },
    { field: 'assessorLot', headerName: 'Assessor Lot', width: 160 },
    { field: 'mapBlockLot', headerName: 'Map Block Lot', width: 170 },
    { field: 'startDate', headerName: 'Start Date', width: 150 },
    { field: 'endDate', headerName: 'End Date', width: 150 },
  ];

  const search = () => {
    const params = {
      searchTerm: address.trim(),
    }
    setIsloading(true);
    searchAlternateAddress(params)
      .then((response) => {
        setIsloading(false);
        if (response.status === 200) {
          if (response?.data && response.data.length > 0) {
            setNoRecord(false);
            const formattedData = response.data.map((record, index) => {
              const row = {
                id: index,
                premiseID: record.premiseID,
                address: record.address,
                address2: record.address2,
                address3: record.address3,
                address4: record.address4,
                altAddress: record.alternateAddress,
                assessorBlock: record.assessorBlock,
                assessorLot: record.assessorLot,
                mapBlockLot: record.mapBlockLot,
                startDate: record?.startDate ? record.startDate.slice(0, 10) : null,
                endDate: record?.endDate ? record.endDate.slice(0, 10) : null,
              }
              return row
            })
            setAddressData(formattedData);
          } else {
            setAddressData([]);
            setNoRecord(true);
          }
        } else {
          setAddressData([]);
          setNoRecord(true);
          //console.log(response);
        }
      })
  };

  const handleClose = () => {
    setOpen(false);
  };

  const exportToCSV = () => {

    if (addressData.length > 0) {
      converter
        .json2csvAsync(addressData)
        .then((csv) => {
          var downloadLink = document.createElement("a");
          var blob = new Blob(["\ufeff", csv]);
          var url = URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = `Alternate Address Search Result.csv`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        })
        .catch((err) => {
          console.warn("json2csv failed");
        });
    }
  }

  return (
    <div className={classes.paper}>
      <form autoComplete="off" noValidate onSubmit={search}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <TextField name="address" variant="outlined" label="Address" 
          fullWidth value={address} 
          error={noRecord}
          helperText={noRecord ? "No record found" : ""}
          onChange={(e) => {
            setNoRecord(false);
            setAddress(e.target.value);
            } }/>
        </Grid>
        <Grid item xs={2}>
          <SubmitSearchButton disabled={address === '' || isLoading} onClick={search} isLoading={isLoading} />
        </Grid>
        {addressData.length > 0 &&
          <Grid item xs={12}>
            <Typography >
              Export Search Result to CSV
              <IconButton color='primary' aria-label="Export to CSV" component="span" onClick={exportToCSV}>
                <PrintIcon />
              </IconButton>
            </Typography>
            <DataGrid
              columns={addressHeader}
              rows={addressData}
              pageSize={page}
              onPageSizeChange={(pageSize, details) => { setPage(pageSize); }}
              autoHeight
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Grid>
        }
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={() => setOpen(true)}
          >
            Update an Alternate Address
          </Button>
        </Grid>
      </Grid>
      </form>
      <CreateAlternateAddressDialog address={address} open={open} handleClose={handleClose} />
    </div>
  )
};

export default ManageAlternateAddresses;