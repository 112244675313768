import React from "react";
import { Grid, Typography } from '@material-ui/core';
import { addCommas, CustomToolTip } from "../data-display/data-display.component";

const ParcelDataGrid = ({ title, width = 'auto', data, description }) => {
  return (
    <Grid item xs={width}>
      <Grid container alignItems="center">
        <Typography variant="body2" color="inherit">
          {description}
        </Typography>
        <CustomToolTip title={title} />
      </Grid>

      <Typography variant="body1" color="textPrimary">
        {description.includes("Stormwater Charge Rate Type") || description.includes("# of Service Agreements") ? data : addCommas(data)}
        {description.includes("Stormwater Charge Rate Type") || description.includes("# of Service Agreements") ? '' : ' ft²'}
      </Typography>

    </Grid>
    // </Tooltip>
  )
}

export default ParcelDataGrid;