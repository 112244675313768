import { types } from "mobx-state-tree"
import { getLandUseCode, reviewLandUseCode } from "../services/APIClient"

const LandUseCode = types
.model("LandUseCode", {
    classCode: types.optional(types.string, ''),
    classDefinition: types.maybeNull(types.string),
    useCode: types.maybeNull(types.string),
    useDefinition: types.maybeNull(types.string),
    customerClass: types.maybeNull(types.string),
    landlordReversion: types.maybeNull(types.string),
    reviewStatus: types.optional(types.string, '')
})
.actions((self) => ({
    setItem(item, value) {
        self[item] = value;
    },
    validInput() {
        if (self.classDefinition && self.classDefinition !== '') return true;
        else return false;
    },
    async submitReview() {
        const response = await reviewLandUseCode(self.classCode, self);
        if (response.status === 204) {
            return true;
        } else {
            console.log(response);
            return false;
        }
    }
}))

export const LandUseCodeStore = types
.model("LandUseCodeStore", {
    reviewedLandUseCode: types.array(LandUseCode),
    unreviewedLandUseCode: types.array(LandUseCode)
})
.actions((self) => ({
    afterCreate() {
        console.log('create land use code store');
    },
    setItem(item, value) {
        self[item] = value;
    },
    async fetchLandUseCode() {
        const reviewedResponse = await getLandUseCode({ reviewStatus: 'YES' })
        if (reviewedResponse.status === 200) {
            self.setItem("reviewedLandUseCode", reviewedResponse.data);
        } else {
            console.log('reviewed retrieve error');
        }

        const unreviewedResponse = await getLandUseCode({ reviewStatus: 'NO' })
        if (unreviewedResponse.status === 200) {
            self.setItem("unreviewedLandUseCode", unreviewedResponse.data);
        } else {
            console.log('unreviewed retrieve error');
        }
    }
})
)

let _landUseCodeStore;
export const useLandUseCode = () => {
    if (!_landUseCodeStore) {
        _landUseCodeStore = LandUseCodeStore.create({});
    }
    return _landUseCodeStore;
}
