import React from 'react';
import { Routes, Route } from 'react-router-dom';

/* Langan Components */
import ReportNavigation from '../../components/report/report-navigation/report-navigation.component';
import NewMapBlockLotReports from '../../components/report/NewMapBlockReport';


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "calc(100vh - 200px)",
    backgroundColor: theme.palette.background.paper,
  },
}));

const ReportRouter = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Routes>
        {/* replace NewMapBlockLotReports to other components*/}
        <Route element={<ReportNavigation />}>
          <Route index element={<NewMapBlockLotReports />} />
          <Route path='newmapblock' element={<NewMapBlockLotReports />} />
          <Route path='deprecatedmapblock' element={<NewMapBlockLotReports />} />
          <Route path='newlegalowner' element={<NewMapBlockLotReports />} />
          <Route path='assessorblock' element={<NewMapBlockLotReports />} />
        </Route>
      </Routes>
    </main>
  )
}

export default ReportRouter;