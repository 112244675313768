import React, { Fragment, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, Container, CssBaseline } from '@material-ui/core';

// Data Stores
import { useRootStore } from "../stores/RootStore";

const LoginPage = observer(props => {

  const navigate = useNavigate();
  const root = useRootStore();

  // useeffects hook to run once on load
  useEffect(() => {
    root.user.auth();
  }, [root.user]);

  useEffect(() => {
    const roles = root.role;
    if (roles.business || roles.admin || roles.credit) {
      navigate('/dashboard');
    } else if (roles.viewer || roles.user) {
      navigate('/');
    }
  }, [root.role, root.role.business, root.role.admin, root.role.credit, navigate])

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="md" style={{ height: '100vh' }}>
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Checking Credentials..."}
            <CircularProgress size="1.5rem" />
          </DialogTitle>
        </Dialog>
      </Container>
    </Fragment>
  )
});

export default LoginPage;
