import { useRef, useEffect, useState } from "react";

/* MUI */
import { makeStyles } from '@material-ui/core/styles';

/* Esri */
import WebMap from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";

/* Langan custom components */
import { MapContext, MapViewContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  mapViewDivFull: {
    height: "calc(100vh - 104px)",
    width: "100%",
  },
  mapViewDivChip: {
    height: "500px",
    width: "95%",
  },
  mapViewDivSketch: {
    height: "400px",
    width: "100%",
  },
}));

function EsriMap(props) {
  const classes = useStyles();
  const [mapView, setMapView] = useState();
  const [map, setMap] = useState();
  const mapViewDiv = useRef(null);

  useEffect(() => {
    if (mapViewDiv.current) {
      const map = new WebMap({
        portalItem: { // autocasts as new PortalItem()
          id: props.itemId
        }
      });

      const view = new MapView({
        map: map,
        extent: {
          xmax: -13636904.7889539,
          xmin: -13637550.4420005,
          ymax: 4549055.63138381,
          ymin: 4548562.65428953,
          spatialReference: {
            wkid: 102100
          }
        },
        container: mapViewDiv.current,
        popupenabled: !props.type === "static"
      });

      view.when(() => {
        setMap(map);
        setMapView(view);
      });
    }
  }, [props.itemId]);

  const setStyle = (screenSize) => {
    if (screenSize === "full") {
      return classes.mapViewDivFull
    } else if (screenSize === "chip") {
      return classes.mapViewDivChip
    } else {
      return classes.mapViewDivSketch
    }
  }

  return (
    <div
      id="mapViewDiv"
      className={setStyle(props.screenSize)}
      ref={mapViewDiv}
    >
      <MapViewContext.Provider value={mapView}>
        <MapContext.Provider value={map}>
          {props.children}
        </MapContext.Provider>
      </MapViewContext.Provider>
    </div>
  );
}

export default EsriMap;
