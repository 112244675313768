import React from 'react';
import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

/* MobX */
// import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button, Typography, Container, Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

import { searchAssessorByAssessorBlock } from '../services/APIClient';

import AddLegalOwnerDialog from '../components/assessor/addLegalOwnerDialog.component';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 200px)",
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  addButton: {
    fontWeight: 'bold',
    fontFamily: 'Red Hat Display, sans-serif',
    textDecoration: 'underline',
  },
  link: theme.link,
}));

const AssessorPage = (props => {
  const classes = useStyles();
  let params = useParams();
  let navigate = useNavigate();
  // tmp showing legal owner address
  let addressList = []

  const [open, setOpen] = useState(false);
  const [mapBlkLot, setMapBlkLot] = useState("");
  const [assessorBlock, setAssessorBlock] = useState("");
  const [address, setAddress] = useState("");
  const [lulc, setLULC] = useState("");
  const [legalOwnerData, setLegalOwnerData] = useState([]);
  const [prevStartDate, setPrevStartDate] = useState("");

  const legalOwnerHeader = [
    {
      field: 'legalOwnerId', headerName: 'SDMS Legal Owner ID', width: 250,
      renderCell: (params) => {
        const onClick = () => {
          navigate(`/legalowner/${params.row.legalOwnerId}`)
        }
        return (
        <Button color='primary' onClick={onClick}>
          <Typography className={classes.link}>{params.value}</Typography>
        </Button>)
      },
    },
    { field: 'legalOwnerName', headerName: 'Legal Owner Name', width: 250 },
    { field: 'legalOwnerMailingAddress1', headerName: 'Legal Owner Address 1', width: 280 },
    { field: 'legalOwnerMailingAddress2', headerName: 'Legal Owner Address 2', width: 280 },
    { field: 'legalOwnerMailingAddress3', headerName: 'Legal Owner Address 3', width: 280 },
    { field: 'startDate', headerName: 'Start Date', width: 150 },
    { field: 'endDate', headerName: 'End Date', width: 140 },
    { field: 'updatedBy', headerName: 'Updated By', width: 180 },
  ]

  useEffect(() => {
    if (params.id) {
      searchAssessor();
    } else {
      navigate('/nomatch')
    }
  }, [params.id]);

  const searchAssessor = () => {
    searchAssessorByAssessorBlock(params.id)
      .then((response) => {
        setMapBlkLot(response.mapBlkLotNum);
        setAssessorBlock(response.assessorBlkLotNum);
        setAddress(response.propertyAddress);
        setLULC(response.landUseCodeDefinition);
        const formattedAssessorData = response.legalOwnerInfos.map((assessor, index) => {
          if (!assessor.endDate) {
            let today = new Date(assessor.startDate);
            setPrevStartDate(today.setDate(today.getDate() + 1));
          }
          const row = {
            id: index,
            legalOwnerId: assessor.legalOwnerId,
            legalOwnerName: assessor.legalOwnerName,
            legalOwnerMailingAddress1: assessor.legalOwnerMailingAddress1,
            legalOwnerMailingAddress2: assessor.legalOwnerMailingAddress2,
            legalOwnerMailingAddress3: assessor.legalOwnerMailingAddress3,
            startDate: assessor.startDate ? assessor.startDate.substring(0, 10) : "",
            endDate: assessor.endDate ? assessor.endDate.substring(0, 10) : "",
            updatedBy: assessor.updatedBy,
          }

          return row
        })
        setLegalOwnerData(formattedAssessorData);
      })
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.root}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography component="h3" variant="h3" align="center" gutterBottom>
              Assessor Block Lot Details
            </Typography>
          </Container>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={3} style={{ marginTop: '20px' }}>
                <Typography variant="body2">Assessor Block Lot</Typography>
                <Typography variant="h6" color="textPrimary">{assessorBlock}</Typography>

              </Grid>
              <Grid item xs={3} style={{ marginTop: '20px' }}>
                <Typography variant="body2">Property Address</Typography>
                <Typography variant="h6" color="textPrimary">{address}</Typography>

              </Grid>
              <Grid item xs={3} style={{ marginTop: '20px' }}>
                <Typography variant="body2">Land Use Code</Typography>
                <Typography variant="h6" color="textPrimary">{lulc}</Typography>

              </Grid>
              <Grid item xs={3} style={{ marginTop: '20px' }}>
                <Typography variant="body2">Map Block Lot</Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.link}
                  onClick={() => navigate(`/parcel/${mapBlkLot}`)}
                >
                  {mapBlkLot}
                </Typography>

              </Grid>
            </Grid>
          </Container>
          <Container>
            <div>
              {(props.store.role.admin || props.store.role.business) ?
                <Typography variant="h6" color="textPrimary" style={{ marginTop: '20px' }}>
                  Legal Owner <Button color="primary" onClick={() => setOpen(true)} className={classes.addButton}>Add Legal Owner</Button>
                </Typography>
                :
                <Typography variant="h6" color="textPrimary" style={{ marginTop: '20px' }}>
                  Legal Owner
                </Typography>
              }
            </div>
            <div>
              <DataGrid
                className={classes.table}
                columns={legalOwnerHeader}
                rows={legalOwnerData}
                autoHeight
                autoPageSize={true}
              />
            </div>
            <AddLegalOwnerDialog
              open={open}
              handleClose={handleClose}
              blocklot={assessorBlock.split("/")}
              updateTable={searchAssessor}
              dateCheck={prevStartDate}
            />
          </Container>
        </div>
      </main>
    </React.Fragment>
  );
});

export default AssessorPage;
