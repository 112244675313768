import { Fragment, useEffect, useState, useRef, useCallback, forwardRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Box, Button, Container, Divider, Grid, Paper, TextField } from '@material-ui/core';
import PrintRounded from '@material-ui/icons/PrintRounded';

/* Langan Components */
import GeneralInformation from '../../components/credit/credit-confirmation/GeneralInformation';
import SimplifiedResidential from '../../components/credit/credit-confirmation/SimplifiedResidential';
import StandardCredit from '../../components/credit/credit-confirmation/StandardCredit';
import CreditAllocation from '../../components/credit/credit-confirmation/CreditAllocation';
import { getSnapshot } from 'mobx-state-tree';
import { useCredits } from '../../stores/CreditStore/CreditModuleStore';


const useStyles = makeStyles((theme) => ({
  root: theme.root,
  heroContent: {
    backgroundColor: 'whitesmoke',
    // padding: theme.spacing(6, 0, 6),
  },
  formRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: "20px",
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
  },
  buttonSubmit: {
    marginBottom: 10,
  },
  buttonPrint: {
    position: 'relative',
    float: 'left',
    margin: '5px'
  }
}));

const CreditConfirmation = forwardRef((props, ref) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const credits = useCredits();
  const [data, setData] = useState(null);
  const [applicant_comments, setApplicantComments] = useState(null);

  useEffect(() => {
    if (credits) {
      //console.log(getSnapshot(credits));
      if (credits.creditSubmission) {
        //console.log(getSnapshot(credits.creditSubmission))
        if (credits.creditSubmission.credit_Type === 'Standard') {
          setApplicantComments(credits.creditSubmission.creditsStandardType.f_Applicant_Comments);
        } else {
          setApplicantComments(credits.creditSubmission.creditsSRType.f_Applicant_Comments);
        }
        setData(getSnapshot(credits.creditSubmission));
      } else {
        //console.log(getSnapshot(credits.creditReview))
        if (credits.creditReview.credit_Type === 'Standard') {
          setApplicantComments(credits.creditReview.creditsStandardType.f_Applicant_Comments);
        } else {
          setApplicantComments(credits.creditReview.creditsSRType.f_Applicant_Comments);
        }
        setData(getSnapshot(credits.creditReview));
      }
    }
  }, [credits])


  const handleReturn = () => {
    credits.resetCredits();
    navigate("/");
  }

  return (
    <div ref={ref}>
      <div ref={ref} >
        <Container maxWidth="md">
          <Paper className={classes.paper}>
            <div className={`${classes.formRoot} ${classes.form}`} >
              <Grid container spacing={2}>
                <Fragment>
                  <Container>
                    <GeneralInformation />
                  </Container>
                  <Box p={2} bgcolor="background.paper">
                    <Divider variant="middle" />
                  </Box>
                </Fragment>

                {(data && data.credit_Type === 'Standard') ?
                  <StandardCredit />
                  :
                  <SimplifiedResidential />
                }

                <CreditAllocation />

                <Container>
                  <Grid container spacing={2}>
                    {applicant_comments &&
                      <TextField
                        multiline
                        name="applicant_comments"
                        variant="outlined"
                        label="Applicant Comments"
                        fullWidth
                        disabled
                        value={applicant_comments}
                        inputProps={{ readOnly: true, style: { backgroundColor: 'whitesmoke', color: 'black' } }} />}
                  </Grid>
                </Container>

                <Box p={2} bgcolor="background.paper">
                  <Divider variant="middle" />
                </Box>

              </Grid>
            </div>
          </Paper>

        </Container>
      </div>
      <Container maxWidth="md">
        <Button
          className={classes.buttonSubmit}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          onClick={handleReturn}
          fullWidth
        >
          Return to Home
        </Button>
      </Container>
    </div>
  );
});

const CreditConfirmationPage = (props) => {
  const credits = useCredits();
  const componentRef = useRef(null);
  const classes = useStyles();
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = useCallback(() => {
    return (
      <Button variant='contained' color="primary" startIcon={<PrintRounded />} className={classes.buttonPrint}>Print</Button>
    );
  }, []);

  return (
    <div className={classes.heroContent} >
      {credits.isSubmitted ?
        <Alert severity="info">
          <AlertTitle><strong>Credit Application Information</strong></AlertTitle>
          Your application is currently being reviewed. You will receive an email when a decision has been made.
        </Alert>
        :
        <Alert severity="success">
          <AlertTitle><strong>
            Thank you for submitting your Appeal Application. Your application has been received and will be reviewed. <br />
            Please allow 4-6 weeks for a response. <br />
            Please print a copy of this receipt for your records. <br />
            The data you submitted is below.</strong></AlertTitle>
          {/* Thank you for your submission. Here's your pending credit information. */}
        </Alert>
      }
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Credit Application Information"
        trigger={reactToPrintTrigger}
      />
      <CreditConfirmation ref={componentRef} store={props.store} />
    </div>
  );
};

export default CreditConfirmationPage;
