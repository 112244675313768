import { types } from 'mobx-state-tree'
import { CreditType, IMPERM_RATE, PERM_RATE } from './CreditConstant';

const CreditSubmissionStore = types.model({
    credit_Application_ID: types.number,
    account_Number: types.string,
    zip_Code: types.string,
    applicant_Name: types.string,
    applicant_Phone: types.string,
    applicant_Email: types.string,
    credit_Type: types.string,
    mapBlockLot: types.string,
    assessorBlockLot: types.string,
    s_Current_Impermeable_Area: types.number,
    s_Current_Permeable_Area: types.number,
    s_Submitted_By: types.maybeNull(types.string),
    submittal_Date: types.string,
    received_Time: types.string,
    creditsStandardType: types.maybeNull(types.model({
        credit_Application_ID: types.number,
        f_Attached_Green_Insfrastructure_Plan: types.maybeNull(types.string),
        f_Attached_Green_Insfrastructure_Photo: types.maybeNull(types.string),
        f_Attached_Agreement_Doc: types.maybeNull(types.string),
        f_Applicant_Comments: types.maybeNull(types.string),
        f_No_Of_Accounts: types.maybeNull(types.number),
        self_Certified: types.string,
        r_Additional_Docs: types.maybeNull(types.string),
        r_Reviewer_Comments: types.maybeNull(types.string),
        r_Decision: types.maybeNull(types.string),
        r_Effective_Billing_Date: types.maybeNull(types.string),
        r_Denied_Reason: types.maybeNull(types.string),
        r_Cancelled_Reason: types.maybeNull(types.string),
        r_Approved_Date: types.maybeNull(types.string),
        r_Denied_Date: types.maybeNull(types.string),
        r_Cancelled_Date: types.maybeNull(types.string),
        r_Status_Change_Date: types.maybeNull(types.string),
        r_Review_Submitted_By: types.maybeNull(types.string),
        flag: types.maybeNull(types.string),
    })),
    creditsSTDTypeDetail: types.maybeNull(types.array(
        types.model({
            id: types.number,
            credit_Application_ID: types.number,
            f_Impermeable_Area_Drain: types.number,
            f_Permeable_Area_Drain: types.number,
            r_Impermeable_Area_Drain: types.maybeNull(types.number),
            r_Permeable_Area_Drain: types.maybeNull(types.number),
            f_Facility_Type: types.string,
            f_Facility_Size: types.number,
            f_Credited_Type: types.string,
            r_Credited_Type: types.maybeNull(types.string),
            reviewer_Comments: types.maybeNull(types.string)
        })
    )),
    creditsSRType: types.maybeNull(types.model({
        credit_Application_ID: types.number,
        f_Attached_Green_Insfrastructure_Plan: types.maybeNull(types.string),
        f_Attached_Green_Insfrastructure_Photo: types.maybeNull(types.string),
        f_Attached_Agreement_Doc: types.maybeNull(types.string),
        f_Applicant_Comments: types.maybeNull(types.string),
        self_Certified: types.string,
        r_Additional_Docs: types.maybeNull(types.string),
        r_Reviewer_Comments: types.maybeNull(types.string),
        r_Decision: types.maybeNull(types.string),
        r_Effective_Billing_Date: types.maybeNull(types.string),
        r_Denied_Reason: types.maybeNull(types.string),
        r_Cancelled_Reason: types.maybeNull(types.string),
        r_Approved_Date: types.maybeNull(types.string),
        r_Denied_Date: types.maybeNull(types.string),
        r_Cancelled_Date: types.maybeNull(types.string),
        r_Status_Change_Date: types.maybeNull(types.string),
        r_Review_Submitted_By: types.maybeNull(types.string),
        f_Total_Patio_Area: types.number,
        f_Total_Roof_Area: types.number,
        r_Total_Patio_Area: types.maybeNull(types.number),
        r_Total_Roof_Area: types.maybeNull(types.number),
        flag: types.maybeNull(types.string),
    })),
    creditsSRtypeDetail: types.maybeNull(types.array(
        types.model({
            id: types.number,
            credit_Application_ID: types.number,
            source: types.string,
            green_Infrastructure_Type: types.string,
            f_Area: types.number,
            tier_Type: types.maybeNull(types.string),
            f_Credit_Amount: types.number,
            r_Approved_Area: types.maybeNull(types.number)
        })
    )),
    creditsDistributionData: types.array(
        types.model({
            id: types.number,
            credit_Application_ID: types.number,
            f_Appealed_Account_No: types.string,
            f_Appealed_Distb_Percent: types.number,
            f_Distributed_Credit_Amount: types.number,
            r_Approved_Distb_Percent: types.maybeNull(types.number),
            r_Distributed_Credit_Amount: types.maybeNull(types.number)
        })
    ),
    creditsDocument: types.maybeNull(types.array(
        types.model({
            id: types.number,
            credit_Application_ID: types.number,
            file_Path: types.string,
            fileName: types.string,
            document_Type: types.string,
            description: types.string,
        }))
    ),
})
    .actions(self => ({
        calculateStandardCreditAmount() {
            let total_credit = 0;
            let perm_credit = 0;
            let imperm_credit = 0;

            let total_perm_area = 0;
            let total_imperm_area = 0;
            self.creditsSTDTypeDetail.forEach((item) => {
                if (item.f_Credited_Type === CreditType.Permeable) {
                    total_perm_area += item.f_Facility_Size;
                } else if (item.f_Credited_Type === CreditType.Impermeable) {
                    total_imperm_area += item.f_Facility_Size;
                }
                total_imperm_area += item.f_Impermeable_Area_Drain;
                total_perm_area += item.f_Permeable_Area_Drain;
            });

            perm_credit = parseFloat((PERM_RATE * total_perm_area).toFixed(2));
            imperm_credit = parseFloat((IMPERM_RATE * total_imperm_area).toFixed(2));
            total_credit = parseFloat((perm_credit + imperm_credit).toFixed(2));
            // console.log("perm_credit", perm_credit);
            // console.log("imperm_credit", imperm_credit);
            // console.log("total_credit", total_credit);
            return { perm_credit, imperm_credit, total_credit };
        },
        calculateSRcreditAmount() {
            let roof = 0;
            let patio = 0;
            self.creditsSRtypeDetail.map(element => {
                if (element.source === "Roof") {
                    roof = element.f_Credit_Amount;
                } else if (element.source === "Patio") {
                    patio = element.f_Credit_Amount;
                }
            });
            return { roof, patio };
        },
    }))

export default CreditSubmissionStore;