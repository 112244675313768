import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, Button, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

const CustomDialog = (props) => {
    const alertDialogOpen = props.open;
    const actions = props.actions;
    const title = props.title;
    const content = props.content;

    return (
        <Dialog
          open={alertDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h5" align="center" display='inline'>{title}</Typography>
          </DialogTitle>
          {content && <DialogContent>
            <DialogContentText id="alert-dialog-description" color='initial'>
              {content}
            </DialogContentText>
            </DialogContent>}
          <DialogActions>
            {actions && actions.map((action, index) => {
                return <Button key={index} onClick={action.onClick}>
                    <Typography color="primary">{action.text}</Typography>
                </Button>
            })
            }
          </DialogActions>
        </Dialog>
    )
};

export default CustomDialog;