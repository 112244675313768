import axios from "axios";
import { useQuery } from "react-query";

const api = process.env.REACT_APP_API_URL;

const getResources = async () => {
  const url = `${api}/Resources`;
  const { data } = await axios.get(url);
  return data;
}

const getReports = async () => {
  const url = `${api}/Reports`;
  const { data } = await axios.get(url);
  return data;
}

//get legal owner exception data
export async function getLegalOwnerExceptions() {
  const url = `${api}/LegalOwners`;
  const { data } = await axios.get(url);
  return data;
}

//admin user management
export async function getAllUsers(token) {
  const url = `${api}/GetAllUsers`;
  const response = await axios.get(url, {
    params: {
      token: token
    },
  });
 return response;
}

export async function removeUser(email, token, groupIds) {
  const url = `${api}/RemoveUsers`;
  const response = axios({
    method: 'POST',
    url: url,
    data: groupIds,
    params: {
      email: email,
      token: token
    }
  });
  return response;
}

export async function addUser(email, token, groupIds) {
  const url = `${api}/AddUsers`;
  const response = axios({
    method: 'POST',
    url: url,
    data: groupIds,
    params: {
      email: email,
      token: token
    }
  });
  return response;
}

// get basic info of all the appeals used in dashboard
const getAppeals = async () => {
  const url = `${api}/Appeals`;
  const { data } = await axios.get(url);
  return data;
}

// get basic info of a specific appeals (id=customer_appeal_id) 
export async function getAppealById(id) {
  const url = `${api}/Appeals/${id}`;
  const { data } = await axios.get(url);
  return data;
}

// get detail info of a specific type of appeal (id=appealId, appeal_type_id)
export async function getAppealDetail(type, id) {
  const url = `${api}/${type}/${id}`;
  const { data } = await axios.get(url);
  return data;
}

export async function getAppealByAccount(data) {
  const url = `${api}/ActiveAppeals`;
  const appeal = axios.get(url, {
    params: data
  })
  return appeal;
}

export async function validateAccount(data) {
  const url = `${api}/Portal`;
  const validate = axios.get(url, {
    params: data
  })
  return validate;
}

async function getAllManualAppeals() {
  const url = `${api}/Search/GetAllManualAppeals`;
  const { data } = await axios.get(url);
  return data;
}

export async function submitManualAppeal(data) {
  const url = `${api}/PostAppeals/PostManualType`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response;
}

export async function getManualAppealById(id) {
  const url = `${api}/Search/GetByManualAppealID/${id}`;
  const { data } = await axios.get(url);
  return data;
}

export async function getManualAppealByAccountNoAndSAID(id, type) {
  const url = `${api}/Search/GetByAccountNoAndSaID`;
  const response = await axios.get(url, {
    params: {
      [type]: id
    }
  });
  return response;
}

export async function submitLegalOwnerAppeal(data) {
  const url = `${api}/PostAppeals/PostLegalOwner`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response;
}

export async function submitImpermeableAreaAppeal(data) {
  const url = `${api}/PostAppeals/PostImpermeable`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response;
}

export async function submitExcludedAreaAppeal(data) {
  const url = `${api}/PostAppeals/PostExcluded`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response;
}

export async function submitSAAppeal(data) {
  const url = `${api}/PostAppeals/PostSA`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response;
}

export async function assignReviewer(id, data) {
  const url = `${api}/Appeals/${id}`;
  const response = await axios({
    method: 'put',
    url: url,
    data: data,
  })
  return response
}

export async function addLegalOwner(data) {
  const url = `${api}/LegalOwners`;
  const response = await axios({
    method: 'post',
    url: url,
    data: data,
  })

  return response;
}

export async function reviewLegalOwner(id, data) {
  const url = `${api}/ReviewAppeals/ReviewLegalOwner/${id}`;
  const response = axios({
    method: 'put',
    url: url,
    data: data,
  });
  return response;
}

export async function reviewImpermeableArea(id, data) {
  const url = `${api}/ReviewAppeals/ReviewImpermeable/${id}`;
  const response = axios({
    method: 'put',
    url: url,
    data: data,
  })
  return response;
}

export async function reviewExcludedArea(id, data) {
  const url = `${api}/ReviewAppeals/ReviewExcluded/${id}`;
  const response = axios({
    method: 'put',
    url: url,
    data: data,
  })
  return response;
}

export async function reviewServiceAgreement(id, data) {
  const url = `${api}/ReviewAppeals/ReviewSA/${id}`;
  const response = axios({
    method: 'put',
    url: url,
    data: data,
  });
  return response;
}

export async function postReviewFile(data) {
  const url = `${api}/File/PostFile`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response;
}

// Search MapBlockLot By MapBlklotId
export async function searchParcelByMapBlock(id) {
  const url = `${api}/Search/MapBlkLotByMapBlkLotId/${id}`;
  const { data } = await axios.get(url);
  return data;
}

// Search MapBlockLot By AssessorBlklotId
export async function searchParcelByAssessorBlock(id) {
  const url = `${api}/Search/MapBlkLotByAssessorBlkLotId/${id}`;
  const { data } = await axios.get(url);
  return data;
}

// Search Assessor By AssessorBlklotId
export async function searchAssessorByAssessorBlock(id) {
  const url = `${api}/Search/AssessorBlkLot/${id}`;
  const { data } = await axios.get(url);
  return data;
}

// Search Legal Owner By ID
export async function searchLegalOwnerByID(id) {
  const url = `${api}/Search/LegalOwnerByLegalOwnerId/${id}`;
  const { data } = await axios.get(url);
  return data;
}

// Search Legal Owner By Assessor ID
export async function searchLegalOwnerByAssessor(id) {
  const url = `${api}/Search/LegalOwnerByAssessorBlkLotId/${id}`;
  const { data } = await axios.get(url);
  return data;
}

// Search Appeals by customer appeal id, type and appeal id, account number
export async function searchAppeals(data) {
  const url = `${api}/Search/Appeals`;
  const appeals = axios.get(url, {
    params: data
  })
  return appeals;
}

// Search Alternate Address
export async function searchAlternateAddress(data) {
  const url = `${api}/AlternateAddress`;
  const response = axios.get(url, {
    params: data
  })
  return response;
}

// Search Retired Address
export async function searchRetiredAddress(data) {
  const url = `${api}/RetiredAddresses`;
  const response = axios.get(url, {
    params: data
  })
  return response;
}

export async function createNewAltAddress(data) {
  const url = `${api}/AlternateAddress`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
  })
  return response;
}

export async function searchAddress(streetNumber, streetName, streetType) {
  const url = `${api}/Search/SearchByAddress/${streetNumber}/${streetName}`;
  const response = await axios.get(url, {
    params: {
      st_type: streetType
    }
  });
  return response;
}

export async function setAlternateToRetired(address, data) {
  const url = `${api}/AlternateAddress/${address}/ToRetired`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
    // headers: {
    //   'Content-Type': 'multipart/form-data'
    // }
  })
  return response;
}

export function useResources() {
  return useQuery("Resources", getResources);
}

export function useReports() {
  return useQuery("Reports", getReports);
}

export function useAppeals() {
  return useQuery("Appeals", getAppeals);
}

export function useManualAppeals() {
  return useQuery("ManualAppeals", getAllManualAppeals);
}

export async function getManualAppealFiles(id) {
  const url = `${api}/ManualAppealsFile/GetFile`;
  const files = axios.get(url, {
    params: {
      master_id: id
    }
  });
  return files;
}

export async function downloadManualAppealFile(doc_id, appeal_type) {
  const url= `${api}/ManualAppealsFile/DownloadFile`;
  const file = axios({
    url: url,
    method: 'get',
    responseType: 'blob',
    params: {
      doc_id: doc_id,
      appeal_type: appeal_type,
    }
  })
  return file; 
}

export async function getFile(master_id, appeal_type_id, appeal_type) {
  const url = `${api}/File/GetFile`;
  const file = axios.get(url,
    {
      params: {
        master_id: master_id,
        appeal_type_id: appeal_type_id,
        appeal_type: appeal_type
      }
    })
  return file;
}

//credit get files
export async function getCreditFile(master_id) {
  const url = `${api}/CreditsFile/GetFile`;
  const file = axios.get(url,
    {
      params: {
        master_id: master_id,
      }
    })
  return file;
}

export async function downloadFile({ master_id, appeal_id, appeal_type, document_type }) {
  const url = `${api}/File/DownloadFile`;
  const file = axios({
    url: url,
    method: 'get',
    responseType: 'blob',
    params: {
      master_id: master_id,
      appeal_id: appeal_id,
      appeal_type: appeal_type,
      document_type: document_type
    }
  })
  return file;
}

export async function downloadCreditFile({ doc_id, document_type }) {
  const url = `${api}/CreditsFile/DownloadFile`;
  const file = axios({
    url: url,
    method: 'get',
    responseType: 'blob',
    params: {
      doc_id: doc_id,
      document_type: document_type
    }
  })
  return file;
}

export async function postCreditReviewFile(data) {
  const url = `${api}/CreditsFile/PostFile`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response;
}

// params: review_status: YES/NO
export async function getLandUseCode(data) {
  const url = `${api}/LandUseCodes`;
  const landUseCodeData = axios.get(url,
    { params: data });
  return landUseCodeData;
}


// params: id: CLASS CODE
export async function reviewLandUseCode(id, data) {
  const url = `${api}/LandUseCodes/${id}`;
  const response = axios({
    method: 'put',
    url: url,
    data: data,
  });
  return response;
}

export async function postSR(data) {
  const url = `${api}/MasterCredits/PostSRTypeCredit`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response;
}

export async function postStandard(data) {
  const url = `${api}/MasterCredits/PostStandardTypeCredit`;
  const response = axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response;
}

export const getCredits = async () => {
  const url = `${api}/MasterCredits/GetMasterCredit`;
  const { data } = await axios.get(url);
  return data;
}

export function useCredits() {
  return useQuery("Credits", getCredits);
}

export async function getCreditByAccountZip(data) {
  const url = `${api}/MasterCredits/GetCreditInfoByAccZip`;
  const credit = axios.get(url, {
    params: data
  })
  return credit;
}

export async function getCreditById(id) {
  const url = `${api}/MasterCredits/GetCreditInfoById/${id}`;
  const { data } = await axios.get(url);
  return data;
}

export async function getCreditByAcct(acct) {
  const url = `${api}/MasterCredits/GetCreditInfoByAccNo/${acct}`;
  const { data } = await axios.get(url);
  return data;
}

export async function getAllCredits() {
  const url = `${api}/MasterCredits/GetAllCredits`;
  const { data } = await axios.get(url);
  return data;
}

export async function reviewSR(params, data) {
  const url = `${api}/CreditsSRType/${params}`;
  const response = axios({
    params: params,
    method: 'put',
    url: url,
    data: data,
  });
  return response;
}

export async function reviewStandard(params, data) {
  const url = `${api}/CreditsStandardType/${params}`;
  const response = axios({
    params: params,
    method: 'put',
    url: url,
    data: data,
  });
  return response;
}

export async function getMaintenanceWindow() {
  const url = `${api}/MaintenanceWindow`;
  const response = axios.get(url);
  return response;
}