import React, { useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Tabs, Tab, Paper } from '@material-ui/core';

/* Langan Components */
import ManageAlternateAddresses from '../address/ManageAlternateAddress';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  wrapper: {
    textTransform: 'initial'
  }
}));

const ManageAddresses = observer(props => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Tabs value={0} >
          <Tab label="Alternate Address" className={classes.wrapper}/>
        </Tabs>
          <ManageAlternateAddresses />
      </Paper>
    </Container>
  )
});

export default ManageAddresses;
