
import { Fragment } from "react";

/* MUI */
import { Grid, Typography, FormControl, Select } from '@material-ui/core';


const FormInputDropDownList = ({ label, value, onChange, reasonList, ...otherProps }) => {

  return (
    <Fragment>
      <Grid item xs={4} align="left">
        <Typography variant="h6">{label}</Typography>
      </Grid>
      <Grid item xs={7} align="left">
        <FormControl variant="outlined" fullWidth>
          <Select
            native
            value={value}
            onChange={onChange}
            {...otherProps}
          >
            <option aria-label="None" key="0" value="" />
            {reasonList && reasonList.map((r) => {
              return <option key={r} value={r}>{r}</option>
            })}
          </Select>
        </FormControl>
      </Grid>
    </Fragment>
  )
}

export default FormInputDropDownList;