import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
/* MUI */
import { Container, IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/RateReviewOutlined';
/* Langan Components */

import './dashbord.styles.scss';
import { useCredits as useCreditStore }  from '../../stores/CreditStore/CreditModuleStore';
import { getCredits } from '../../services/APIClient';

const useStyles = makeStyles((theme) => ({
    table: {
      '& .MuiDataGrid-colCell': {
        backgroundColor: '#115293',
        color: theme.palette.common.white,
        fontSize: 14
      },
      '& .MuiDataGrid-row': {
        '&:hover': {
          backgroundColor: '#fffbf2',
          cursor: 'pointer'
        },
      }
    },
  }));

const defaultData = [{
  credit_Application_ID: "",
  credit_Type: "",
  account_Number: "",
  zip_Code: "",
  applicant_Name: "",
  submittal_Date: "",
  review: ""
}];

const CreditApplications = () => {
  let navigate = useNavigate();
  const classes = useStyles();
  const credits = useCreditStore();
  const [page, setPage] = useState(5);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const creditHeader = [
    { field: 'credit_Application_ID', headerName: 'Credit Application ID', width: 220 },
    { field: 'credit_Type', headerName: 'Credit Type', width: 220 },
    { field: 'account_Number', headerName: 'Account #', width: 180 },
    { field: 'zip_Code', headerName: 'Zipcode', width: 150 },
    { field: 'applicant_Name', headerName: 'Applicant Name', width: 180 },
    {
      field: 'submittal_Date',
      headerName: 'Date',
      width: 140,
      valueFormatter: (params) => {
        return params.value?.substring(0, 10);
      },
    },
    {
      field: 'review',
      headerName: 'Review',
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          credits.resetCredits();
          credits.initReviewCredit(params.id).then((response) => {
            navigate(`/credit/review/${params.row.credit_Type}/${params.id}`);
          })   
        }
        return <IconButton color='primary' aria-label='review' onClick={onClick}>
        <EditIcon />
      </IconButton>
      }
    },
  ];

  useEffect(() => {
    getCredits().then((response) => {
      setData(response);
    }).catch((error) => {
      setIsError(true);
    });
  }, [navigate]);
  
  return (
    <>
      {data &&
        <Container maxWidth="lg" className='dashboard'>
          <DataGrid
            className={classes.table}
            columns={creditHeader}
            rows={!isError ? data : defaultData}
            onCellClick={() => console.log("click")}
            pageSize={page}
            onPageSizeChange={(pageSize) => { setPage(pageSize); }}
            autoHeight
            getRowId={(row) => row.credit_Application_ID}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Container>}
    </>
  )
}
export default CreditApplications;
