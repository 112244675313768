import { observer } from 'mobx-react-lite';

/* MUI */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Grid, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

const ReviewLandUseCodeForm = observer((props) => {
  const item = props.store.unreviewedLandUseCode.filter(a => a.classCode === props.id)[0];
  const validInput = item ? item.validInput() : false;

  const handleFormChange = (key, event) => {
    item.setItem(key, event.target.value);
  }

  const handleSubmit = () => {
    item.setItem("reviewStatus", "YES");
    item.submitReview().then(res => {
      if (res) {
        props.handleClose();
        props.handleRefreshData();
      } else {
        console.log(res);
      }
    })
  }

  const handleSave = () => {
    item.submitReview().then(res => {
      if (res) {
        props.handleClose();
        props.handleRefreshData();
      } else {
        console.log(res);
      }
    })
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="review-land-use-code"
    >
      <DialogTitle id="review-land-use-code">Review Land Use Code</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField autoFocus margin="dense" id="classCode" label="Class Code" required value={item ? item.classCode : ''} disabled={true} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField 
            margin="dense" 
            id="classDefinition" 
            label="Class Definition" 
            required value={item ? item.classDefinition : ''} 
            onChange={(e) => handleFormChange("classDefinition", e)}
            error={!validInput}
            helperText={validInput ? '' : 'Class Definition is required'} 
            fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField margin="dense" id="customerClass" label="Customer Class" value={item ? item.customerClass : ''} onChange={(e) => handleFormChange("customerClass", e )} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField margin="dense" id="useCode" label="Use Code"  value={item ? item.useCode : ''} onChange={(e) => handleFormChange("useCode", e)} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField  id="useDefinition" label="Use Definition"  value={item ? item.useDefinition : ''} onChange={(e) => handleFormChange("useDefinition", e )} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <FormControl required fullWidth >
              <InputLabel id="landlordReversion">Landlord Reversion</InputLabel>
              <Select
                labelId="landlordReversion"
                id="landlordReversion"
                value={item ? (item.landlordReversion ? item.landlordReversion : 'NO') : ''}
                onChange={(e) => handleFormChange("landlordReversion", e)}
              >
                <MenuItem value={'YES'}>YES</MenuItem>
                <MenuItem value={'NO'}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Save Changes
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!validInput} >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default ReviewLandUseCodeForm;