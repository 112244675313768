import { types, flow, getSnapshot } from 'mobx-state-tree'
import { reviewSR, reviewStandard, postCreditReviewFile } from '../../services/APIClient'

/**const imperm_rate = 1.89 / 1000;
const perm_rate = 0.19 / 1000;**/
const imperm_rate = 4.11 / 1000;
const perm_rate = 0.41 / 1000;


const ReviewCreditStore = types.model({
  credit_Application_ID: types.number,
  account_Number: types.string,
  zip_Code: types.string,
  applicant_Name: types.string,
  applicant_Phone: types.string,
  applicant_Email: types.string,
  credit_Type: types.string,
  mapBlockLot: types.string,
  assessorBlockLot: types.string,
  s_Current_Impermeable_Area: types.number,
  s_Current_Permeable_Area: types.number,
  s_Submitted_By: types.maybeNull(types.string),
  submittal_Date: types.string,
  received_Time: types.string,
  creditsSRType: types.maybeNull(types.model({
    credit_Application_ID: types.number,
    f_Attached_Green_Insfrastructure_Plan: types.maybeNull(types.string),
    f_Attached_Green_Insfrastructure_Photo: types.maybeNull(types.string),
    f_Attached_Agreement_Doc: types.maybeNull(types.string),
    f_Applicant_Comments: types.maybeNull(types.string),
    self_Certified: types.string,
    r_Additional_Docs: types.maybeNull(types.string),
    r_Reviewer_Comments: types.maybeNull(types.string),
    r_Decision: types.maybeNull(types.string),
    r_Effective_Billing_Date: types.maybeNull(types.string),
    r_Denied_Reason: types.maybeNull(types.string),
    r_Cancelled_Reason: types.maybeNull(types.string),
    r_Approved_Date: types.maybeNull(types.string),
    r_Denied_Date: types.maybeNull(types.string),
    r_Cancelled_Date: types.maybeNull(types.string),
    r_Status_Change_Date: types.maybeNull(types.string),
    r_Review_Submitted_By: types.maybeNull(types.string),
    flag: types.maybeNull(types.string),
    tier_Type: types.maybeNull(types.string),
    f_Total_Patio_Area: types.number,
    f_Total_Roof_Area: types.number,
    r_Total_Patio_Area: types.maybeNull(types.number),
    r_Total_Roof_Area: types.maybeNull(types.number),
    r_approved_roof_credit: types.maybeNull(types.number),
    r_approved_patio_credit: types.maybeNull(types.number),
    r_approved_total_credit: types.maybeNull(types.number),
  }).actions(self => ({
    setItem(key, value) {
      self[key] = value
    },
  }))),
  creditsSRtypeDetail: types.maybeNull(types.array(
    types.model({
      id: types.number,
      credit_Application_ID: types.number,
      source: types.string,
      green_Infrastructure_Type: types.string,
      tier_Type: types.maybeNull(types.string),
      f_Area: types.number,
      f_Credit_Amount: types.number,
      r_Approved_Area: types.maybeNull(types.number),
      r_Credit_Amount: types.maybeNull(types.number),
      reviewer_Comments: types.maybeNull(types.string)
    }).actions(self => ({
      setItem(key, value) {
        self[key] = value
      }
    }))
  )),
  creditsStandardType: types.maybeNull(types.model({
    credit_Application_ID: types.number,
    f_Attached_Green_Insfrastructure_Plan: types.maybeNull(types.string),
    f_Attached_Green_Insfrastructure_Photo: types.maybeNull(types.string),
    f_Attached_Agreement_Doc: types.maybeNull(types.string),
    f_Applicant_Comments: types.maybeNull(types.string),
    f_No_Of_Accounts: types.maybeNull(types.number),
    self_Certified: types.string,
    r_Additional_Docs: types.maybeNull(types.string),
    r_Reviewer_Comments: types.maybeNull(types.string),
    r_Decision: types.maybeNull(types.string),
    r_Effective_Billing_Date: types.maybeNull(types.string),
    r_Denied_Reason: types.maybeNull(types.string),
    r_Cancelled_Reason: types.maybeNull(types.string),
    r_Approved_Date: types.maybeNull(types.string),
    r_Denied_Date: types.maybeNull(types.string),
    r_Cancelled_Date: types.maybeNull(types.string),
    r_Status_Change_Date: types.maybeNull(types.string),
    r_Review_Submitted_By: types.maybeNull(types.string),
    flag: types.maybeNull(types.string),
    r_Permeable_Credit_Amount: types.maybeNull(types.number),
    r_Impermeable_Credit_Amount: types.maybeNull(types.number),
    r_Total_Credit_Amount: types.maybeNull(types.number),
  }).actions(self => ({
    setItem(key, value) {
      self[key] = value
    },
  }))),
  creditsSTDTypeDetail: types.maybeNull(types.array(
    types.model({
      id: types.number,
      credit_Application_ID: types.number,
      f_Impermeable_Area_Drain: types.number,
      f_Permeable_Area_Drain: types.number,
      f_Impermeable_Credit_Amount: types.number,
      f_Permeable_Credit_Amount: types.number,
      f_Facility_Type: types.string,
      f_Facility_Size: types.number,
      f_Credited_Type: types.string,
      r_Impermeable_Credit_Amount: types.maybeNull(types.number),
      r_Permeable_Credit_Amount: types.maybeNull(types.number),
      r_Impermeable_Area_Drain: types.maybeNull(types.number),
      r_Permeable_Area_Drain: types.maybeNull(types.number),
      r_Credited_Type: types.maybeNull(types.string),
      reviewer_Comments: types.maybeNull(types.string)
    }).actions(self => ({
      setItem(key, value) {
        self[key] = value
      },
      calculateCreditAmount(netArea) {
        if (self.r_Credited_Type === 'Permeable') {
          self.r_Permeable_Credit_Amount = (self.f_Facility_Size + self.r_Permeable_Area_Drain) * perm_rate;
          self.r_Impermeable_Credit_Amount = self.r_Impermeable_Area_Drain * imperm_rate;
        } else if (self.r_Credited_Type === 'Impermeable') {
          self.r_Impermeable_Credit_Amount = (self.f_Facility_Size + self.r_Impermeable_Area_Drain) * imperm_rate;
          self.r_Permeable_Credit_Amount = self.r_Permeable_Area_Drain * perm_rate;
        } else {
          self.r_Permeable_Credit_Amount = self.r_Permeable_Area_Drain * perm_rate;
          self.r_Impermeable_Credit_Amount = self.r_Permeable_Area_Drain * imperm_rate;
        }
        self.r_Permeable_Credit_Amount = Math.floor(self.r_Permeable_Credit_Amount * 100) / 100;
        self.r_Impermeable_Credit_Amount = Math.floor(self.r_Impermeable_Credit_Amount * 100) / 100;
      },
    }))
  )),
  creditsDistributionData: types.array(
    types.model({
      id: types.number,
      credit_Application_ID: types.number,
      f_Appealed_Account_No: types.string,
      f_Appealed_Distb_Percent: types.number,
      f_Distributed_Credit_Amount: types.number,
      r_Approved_Distb_Percent: types.maybeNull(types.number),
      r_Distributed_Credit_Amount: types.maybeNull(types.number),
    }).actions(self => ({
      setItem(key, value) {
        self[key] = value
      },
    }))
  ),
  submittedTotalCredit: types.maybeNull(types.number),
  approvedTotalCredit: types.maybeNull(types.number),
})
  .volatile(self => ({
    creditsDocument: types.maybeNull(types.array(
      types.model({
        id: types.number,
        credit_Application_ID: types.number,
        file_Path: types.string,
        fileName: types.string,
        document_Type: types.string,
        description: types.string,
      })
    )),
    reviewDocument: types.maybeNull(types.model({
      master_id: types.number,
      FileName: types.string,
      Description: 'Review',
      Document_Type: 'Review',
      AttachmentFile: types.string,
      File_Path: '',
    }))
  }))
  .actions(self => ({
    afterCreate() {
      if (self.credit_Type === 'Standard') {
        if (self.creditsStandardType.r_Decision === null) {
          self.creditsStandardType.r_Decision = 'LODGED'
        }
        self.creditsSTDTypeDetail = self.creditsSTDTypeDetail.map(item => {
          // r_facility Size?
          if (item.r_Impermeable_Credit_Amount === null) {
            item.r_Impermeable_Credit_Amount = item.f_Impermeable_Credit_Amount
          }
          if (item.r_Permeable_Credit_Amount === null) {
            item.r_Permeable_Credit_Amount = item.f_Permeable_Credit_Amount
          }
          if (item.r_Impermeable_Area_Drain === null) {
            item.r_Impermeable_Area_Drain = item.f_Impermeable_Area_Drain
          }
          if (item.r_Permeable_Area_Drain === null) {
            item.r_Permeable_Area_Drain = item.f_Permeable_Area_Drain
          }
          if (item.r_Credited_Type === null) {
            item.r_Credited_Type = item.f_Credited_Type
          }
          return item
        })
      } else {
        if (self.creditsSRType.r_Decision === null) {
          self.creditsSRType.r_Decision = 'LODGED'
        }
        if (self.creditsSRType.r_Total_Patio_Area === null) {
          self.creditsSRType.r_Total_Patio_Area = self.creditsSRType.f_Total_Patio_Area;
        }
        if (self.creditsSRType.r_Total_Roof_Area === null) {
          self.creditsSRType.r_Total_Roof_Area = self.creditsSRType.f_Total_Roof_Area;
        }

        self.creditsSRtypeDetail = self.creditsSRtypeDetail.map(item => {
          if (item.r_Approved_Area === null) {
            item.r_Approved_Area = item.f_Area
          }
          if (item.r_Credit_Amount === null) {
            item.r_Credit_Amount = item.f_Credit_Amount
          }
          return item
        })
      }
      self.creditsDistributionData = self.creditsDistributionData.map(item => {
        if (item.r_Approved_Distb_Percent === null) {
          item.r_Approved_Distb_Percent = item.f_Appealed_Distb_Percent
        }
        if (item.r_Distributed_Credit_Amount === null) {
          item.r_Distributed_Credit_Amount = item.f_Distributed_Credit_Amount
        }
        return item;
      })
    },
    setItem(key, value) {
      self[key] = value
    },
    handleReviewDocumentChange(e) {
      if (e) {
        const fileName = e.target.files[0].name;
        const file = e.target.files[0];
        let data = {
          master_id: self.credit_Application_ID,
          FileName: fileName,
          Description: 'Review',
          Document_Type: 'Review',
          AttachmentFile: file,
          File_Path: '',
        }
        self.reviewDocument = data;
      } else {
        self.reviewDocument = null;
      }
    },
    formatDocumentData() {
      if (self.reviewDocument.AttachmentFile) {
        let formData = new FormData();
        formData.append('master_id', self.reviewDocument.master_id);
        formData.append('FileName', self.reviewDocument.FileName);
        formData.append('Description', self.reviewDocument.Description);
        formData.append('Document_Type', self.reviewDocument.Document_Type);
        formData.append('AttachmentFile', self.reviewDocument.AttachmentFile);
        formData.append('File_Path', self.reviewDocument.File_Path);
        return formData;
      } else {
        return null;
      }
    },
    submitCreditReview: flow(function* submitCreditReview() {
      let data;
      let date = new Date();
      if (self.credit_Type === 'Standard') {
        data = {
          r_Additional_Docs: self.creditsStandardType.r_Additional_Docs,
          r_Reviewer_Comments: self.creditsStandardType.r_Reviewer_Comments,
          r_Decision: self.creditsStandardType.r_Decision,
          r_Approved_Date: self.creditsStandardType.r_Decision === 'APPROVED' ? date.toISOString() : null,
          r_Denied_Date: self.creditsStandardType.r_Decision === 'REJECTED' ? date.toISOString() : null,
          r_Cancelled_Date: self.creditsStandardType.r_Decision === 'CANCELLED' ? date.toISOString() : null,
          r_Status_Change_Date: date.toISOString(),
          r_Review_Submitted_By: 'Username 1',
          accounts: self.creditsDistributionData,
          stdDetails: self.creditsSTDTypeDetail,
        }
        yield reviewStandard(self.credit_Application_ID, data).catch(error => { throw error });
        let fileData = self.formatDocumentData();
        if (fileData) {
          yield postCreditReviewFile(fileData).catch(error => { throw error })
        } else {
          console.log('No documents submitted from reviewer')
        }
      } else {
        data = {
          r_Additional_Docs: self.creditsSRType.r_Additional_Docs,
          r_Reviewer_Comments: self.creditsSRType.r_Reviewer_Comments,
          r_Decision: self.creditsSRType.r_Decision,
          r_Approved_Date: self.creditsSRType.r_Decision === 'APPROVED' ? date.toISOString() : null,
          r_Denied_Date: self.creditsSRType.r_Decision === 'REJECTED' ? date.toISOString() : null,
          r_Cancelled_Date: self.creditsSRType.r_Decision === 'CANCELLED' ? date.toISOString() : null,
          r_Status_Change_Date: date.toISOString(),
          r_Review_Submitted_By: 'Username 2',
          accounts: self.creditsDistributionData,
          srDetails: self.creditsSRtypeDetail,
          r_Total_Patio_Area: self.creditsSRType.r_Total_Patio_Area,
          r_Total_Roof_Area: self.creditsSRType.r_Total_Roof_Area,
        }
        yield reviewSR(self.credit_Application_ID, data).catch(error => { throw error });
        let fileData = self.formatDocumentData();
        if (fileData) {
          yield postCreditReviewFile(fileData).catch(error => { throw error })
        } else {
          console.log('No documents submitted from reviewer')
        }
      }
    }),
    calculateCreditAmount(netArea) {
      if (self.credit_Type === 'Standard') {
        let rperm = 0;
        let rimperm = 0;
        self.creditsSTDTypeDetail.forEach(item => {
          rperm += item.r_Permeable_Credit_Amount;
          rimperm += item.r_Impermeable_Credit_Amount;
        })
        self.creditsStandardType.r_Permeable_Credit_Amount = parseFloat(rperm.toFixed(2));
        self.creditsStandardType.r_Impermeable_Credit_Amount = parseFloat(rimperm.toFixed(2));
        self.creditsStandardType.r_Total_Credit_Amount = parseFloat((rperm + rimperm).toFixed(2));
        self.approvedTotalCredit = parseFloat((rperm + rimperm).toFixed(2));
      } else {
        let patio = self.creditsSRtypeDetail.filter((element) => element.source === 'Patio' && element.green_Infrastructure_Type !== '');
        let roof = self.creditsSRtypeDetail.filter((element) => element.source === 'Roof' && element.green_Infrastructure_Type !== '');
        let arr = {
          'patio': patio.reduce((accumulator, curr) => accumulator + curr.r_Approved_Area, 0),
          'roof': roof.reduce((accumulator, curr) => accumulator + curr.r_Approved_Area, 0),
        };
        // console.log(arr);
        self.creditsSRType.r_Total_Patio_Area = arr.patio;
        self.creditsSRType.r_Total_Roof_Area = arr.roof;

        let roof_credit = 0;
        let patio_credit = 0;

        if (self.creditsSRType.tier_Type === 'SRT1') {
          if (arr.patio >= 100 && arr.patio <= 200) {
            patio_credit += 0.76;
          } else if (arr.patio > 200) {
            patio_credit += 1.26;
          }

          if (arr.roof >= 400 && arr.roof <= 600) {
            roof_credit += 2.01;
          } else if (arr.roof > 600) {
            roof_credit += 3.02;
          }

        }
        else if (self.creditsSRType.tier_Type === 'SRT2') {
          if (arr.patio >= 200 && arr.patio <= 400) {
            patio_credit += 1.18;
          } else if (arr.patio > 400) {
            patio_credit += 1.96;
          }

          if (arr.roof >= 600 && arr.roof <= 900) {
            roof_credit += 3.14;
          } else if (arr.roof > 900) {
            roof_credit += 4.70;
          }

        } else if (self.creditsSRType.tier_Type === 'SRT3') {
          if (arr.patio >= 300 && arr.patio <= 600) {
            patio_credit += 1.77;
          } else if (arr.patio > 600) {
            patio_credit += 2.95;
          }

          if (arr.roof >= 800 && arr.roof <= 1200) {
            roof_credit += 4.71;
          } else if (arr.roof > 1200) {
            roof_credit += 7.07;

          }
        }
        //console.log(roof_credit + patio_credit)
        self.creditsSRType.r_approved_roof_credit = parseFloat(roof_credit.toFixed(2));
        self.creditsSRType.r_approved_patio_credit = parseFloat(patio_credit.toFixed(2));
        self.creditsSRType.r_approved_total_credit = parseFloat((roof_credit + patio_credit).toFixed(2));
        self.approvedTotalCredit = parseFloat((roof_credit + patio_credit).toFixed(2));
        self.creditsSRtypeDetail.forEach((element) => {
          if (element.source === 'Roof') {
            element.r_Credit_Amount = parseFloat(roof_credit.toFixed(2));
          } else if (element.source === 'Patio') {
            element.r_Credit_Amount = parseFloat(patio_credit.toFixed(2));
          }
        })
      }
    },
    updateCreditAllocation() {
      self.creditsDistributionData = self.creditsDistributionData.map(item => {
        item.r_Distributed_Credit_Amount = (Math.floor(self.approvedTotalCredit * item.r_Approved_Distb_Percent) / 100)
        return item;
      })
    },
    checkAllocationStatus() {
      let sum = Math.round(self.creditsDistributionData.reduce((accumulator, curr) => accumulator + curr.r_Approved_Distb_Percent, 0));
      if (sum === 100) {
        return '';
      } else {
        return 'Credit Allocation should add up to 100 and keep 2 digits after the small point';
      }
    },
    setDecision(name, value) {
      if (self.credit_Type === 'Standard') {
        self.creditsStandardType.setItem(name, value);
      } else {
        self.creditsSRType.setItem(name, value);
      }
    },
  })
  )

export default ReviewCreditStore;