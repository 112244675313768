import React, { useState, useEffect } from 'react';

/* MUI */
import { Grid, Typography } from '@material-ui/core';
// import MuiPhoneNumber from "material-ui-phone-number";

import FormInputTypography from '../../form-input/form-input-typography.component.jsx';
import { useCredits } from '../../../stores/CreditStore/CreditModuleStore.js';

const GeneralInformation = (props) => {
  const [data, setData] = useState({})
  const credits = useCredits();
  
  useEffect(() => {
    if (credits.creditSubmission && !credits.creditReview)  {
      setData(credits.creditSubmission);
    } else if (credits.creditReview) {
      setData(credits.creditReview); 
    }
  }, [credits])
  

  return (
    <>{ data &&
    <Grid container spacing={2} >
      <Grid item xs={12} align="center" style={{ backgroundColor: "whitesmoke" }}>
        <Typography variant="h5" gutterBottom color="primary">General Information</Typography>
      </Grid>
      <FormInputTypography
        label="Application ID"
        value={data.credit_Application_ID}
        variant='body1'
      />

      <FormInputTypography
        label="Account Number"
        value={data.account_Number}
        variant='body1'
      />
      <FormInputTypography
        label="ZIP Code"
        value={data.zip_Code}
        variant='body1'
      />
      <FormInputTypography
        label="Applicant Name"
        value={data.applicant_Name}
        variant='body1'
      />
      <FormInputTypography
        label="Applicant Phone Number"
        value={data.applicant_Phone}
        variant='body1'
      />
      <FormInputTypography
        label="Applicant Email Address"
        value={data.applicant_Email}
        variant='body1'
      />

    </Grid>}
    </>
  )
}

export default GeneralInformation;