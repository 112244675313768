import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

/* Langan Components */
import AdminNavigation from '../../components/admin/admin-navigation/admin-navigation.component';
import ManageUsers from '../../components/admin/manage-users/ManageUsers';
import ManageAddresses from '../../components/admin/manage-addresses/ManageAddresses';
import ManageReports from '../../components/admin/manage-reports/ManageReports';
import ManageLandUseCode from '../../components/admin/manage-lulc/ManageLandUseCode';
import ManageGenericAppeals from '../../components/admin/generic-appeal/ManageGenericAppeals';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';

/* mobx */
import { useLandUseCode } from '../../stores/LandUseCodeStore';
import GenericAppealsRouter from './genericappeals.router';
import { useRootStore } from '../../stores/RootStore';


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 200px)",
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
}));

const AdminRouter = () => {
  const classes = useStyles();
  const landUseCodeStore = useLandUseCode();
  const rootStore = useRootStore();
  
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    landUseCodeStore.fetchLandUseCode()
  }, [landUseCodeStore, refresh]);

  const handleRefreshData = () => {
    setRefresh(!refresh);
    console.log('hit refresh', refresh);
  }

  return (
    <main className={classes.root}>
      <Routes>
        <Route element={<AdminNavigation store={landUseCodeStore}/>}>
          <Route index element={<ManageUsers />} />
          <Route path='users' element={<ManageUsers store={rootStore}/>} />
          <Route path='addresses' element={<ManageAddresses />} />
          <Route path='landusecode' element={<ManageLandUseCode store={landUseCodeStore} handleRefreshData={handleRefreshData} />} />
          <Route path='reports' element={<ManageReports />} />
          <Route path='manualappeals/*' element={<GenericAppealsRouter />}  />
        </Route>
      </Routes>
    </main>
  )
};

export default AdminRouter;