import { Fragment, useState } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Typography, Container} from '@material-ui/core';
import { BeachAccess, Assignment, TrendingDown } from '@material-ui/icons';

/* Langan Components */
// import HomePageSearch from '../components/HomePageSearch';
import PortalCard from '../components/portal/PortalCard';
import Search from '../components/portal/Search';
import SFImage from '../assets/images/20140207G-11-GI-umbrellas-2265x729.jpg';
import ComingSoonDialog from '../components/ComingSoonDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 200px)",
    width: '100%',
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(10, 0, 10),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    padding: theme.spacing(8, 0, 8),
    width: '100%',
  },
  SFImage: {
    backgroundImage: 'url(' + SFImage + ')'
  },
}));

const HomePage = observer(props => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const env = process.env.REACT_APP_PORTAL_URL.includes('stormwater.sfpuc.org') ? 'prod' : 'dev';

  const handleComingSoon = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <CssBaseline />
      <main className={classes.root} >
        {/* Hero unit */}
        <div className={classes.heroContent} style={{ backgroundImage: 'url(' + SFImage + ')', backgroundSize: 'cover', width: '100%', height: '100%' }}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h2" align="center" style={{color: 'white'}}gutterBottom>
              Stormwater Charge Lookup
            </Typography>
            <Typography variant="h5" align="center" style={{ color: 'whitesmoke' }} paragraph>
              Search for your property to find the details of your stormwater charge.
            </Typography>

            <Grid container spacing={2} justify="center" className={classes.heroButtons}>
              <Search store={props.store} />
            </Grid>
            {/* <Grid item>
              <HomePageSearch />
            </Grid> */}
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            <Grid item key={2} xs={12} sm={6} md={4}>
              <PortalCard
                icon={BeachAccess}
                title="Calculate Your Charge"
                content="Learn how your stormwater charge is calculated."
                onClick={handleComingSoon}
                buttonText='Bill Calculator'
              />
            </Grid>
            <Grid item key={3} xs={12} sm={6} md={4}>
              <PortalCard
                icon={Assignment}
                title="File An Appeal"
                content="See a problem with your bill? Here's how to appeal your stormwater charge."
                onClick={() => { navigate('/appeals')}}
                buttonText='About Appeals'
              />
            </Grid>
            <Grid item key={1} xs={12} sm={6} md={4}>
              <PortalCard
                icon={TrendingDown}
                title="Reduce Your Charge"
                content="Learn about our credit and grant programs to lower your stormwater charge."
                onClick={() => { navigate('/credit') }}
                buttonText='About Credits'
              />
            </Grid>
            <ComingSoonDialog open={open} handleClose={handleComingSoon} />
          </Grid>
        </Container>
      </main>
    </Fragment>
  );
});

export default HomePage;
