import React from 'react';
import { observer } from "mobx-react";
/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

/* Custom component */
import FormInputCheckbox from '../../form-input/form-input-checkbox.component';
import FormAttachmentList from '../../form-input/form-attachment-list.component';
import FormInputTypography from '../../form-input/form-input-typography.component';


const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '20px',
    backgroundColor: "whitesmoke"
  },
}));


const CreditInfo = observer(props => {
  const classes = useStyles();
  const { credit_Application_ID, applicant_Name, mapBlockLot, account_Number, zip_Code, creditsDocument, applicant_Phone, applicant_Email } = props.store;
  
  return (
    <Grid container spacing={2}>

      <Grid item xs={6} align="center">
        <Typography variant="h5" gutterBottom>Credit Type</Typography>
      </Grid>
      <Grid item xs={6} align="center">
        <Typography variant="h5" gutterBottom>{props ? props.rate : ''}</Typography>
      </Grid>

      <Grid item xs={12} align="center" className={classes.title}>
        <Typography variant="h5" gutterBottom color="primary">Credit Information</Typography>
      </Grid>

      <Grid item xs={4} align="center">
        <Typography variant="h6">{credit_Application_ID}</Typography>
        <Typography variant="body1">Credit Application Id</Typography>
      </Grid>
      <Grid item xs={4} align="center">
        <Typography variant="h6">{applicant_Name}</Typography>
        <Typography variant="body1">Submitted By</Typography>
      </Grid>
      <Grid item xs={4} align="center">
        <Typography variant="h6">{mapBlockLot}</Typography>
        <Typography variant="body1">MapBlockID</Typography>
      </Grid>
    
      <FormInputCheckbox
        label="Valid Account"
        size={[4, 7]}
        checked
      />
      <FormInputTypography
        label="Account Number"
        value={account_Number}
        align={'left'}
      />
      <FormInputTypography
        label="ZIP Code"
        value={zip_Code}
        align={'left'}
      />
      <FormInputTypography
        label="Applicant Name"
        value={applicant_Name}
        align={'left'}
      />
      <FormInputTypography
        label="Applicant Phone"
        value={applicant_Phone}
        align={'left'}
      />
      <FormInputTypography
        label="Applicant Email"
        value={applicant_Email}
        align={'left'}
      />
      <FormAttachmentList
        label="Submitted Docs"
        file={creditsDocument.filter(element => element.document_Type === 'Credit')}
        align={'left'}
        type='Credit'
      />

    </Grid>
  )
});

export default CreditInfo;