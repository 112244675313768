import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
// import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, TextField, Grid, Button, Paper, Typography } from '@material-ui/core';

import { searchLegalOwnerByID, searchLegalOwnerByAssessor } from '../../services/APIClient';
import { SubmitSearchButton } from './SubmitSearchButton';
import { SearchFieldComponent } from './SearchFieldComponent';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
  },
}));

const SearchOwner = props => {
  const classes = useStyles();
  
  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Typography variant="h6">Legal Owner Search</Typography>
        <Grid container spacing={1} className={classes.form}>
          <SearchFieldComponent 
              name="sdms_legal_owner_id"
              label="SDMS Legal Owner ID (Example: 6656033)"
              searchAPI={searchLegalOwnerByID} 
              searchType="legalowner"
          />
          <SearchFieldComponent 
              name="as_blklot"
              label="Assessor Block Lot Number (Example: 0838/054)"
              searchAPI={searchLegalOwnerByAssessor} 
              searchType="legalowner"
          />
        </Grid>
      </Paper >
    </Container>
  )
};

export default SearchOwner;