import { Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    marginBottom: 10,
  }
}));

const CustomButton = ({ label, handleClick, size = 6, status }) => {
  const classes = useStyles();

  return (
    <Grid item xs={size}>
      <Button
        className={classes.buttonSubmit}
        variant="contained"
        color="primary"
        size="large"
        disabled={status}
        onClick={handleClick}
        fullWidth
      >
        {label}
      </Button>
    </Grid>
  )
};

const ReviewButton = ({ criteria1, criteria2, handleClick, status=false }) => {
  let navigate = useNavigate();

  return (
    <Grid container spacing={2}>
      {criteria1 ?
        criteria2 ?
          <CustomButton
            label="Save for later"
            handleClick={handleClick}
            status={status}
            size={12}
          />
          :
          (<Fragment>
            <CustomButton
              label="Submit"
              handleClick={handleClick}
              status={status}
            />
            <CustomButton
              label="Save for later"
              handleClick={handleClick}
              status={status}
            />
          </Fragment>)
        :
        <CustomButton
          label="Return"
          handleClick={() => { navigate(-1) }}
          status={status}
          size={12}
        />
      }
    </Grid>
  )
}

export default ReviewButton;