import React, { useState, useEffect } from 'react';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';

import FormInputTypography from '../../form-input/form-input-typography.component.jsx';
import { AreaInformation } from '../../data-display/data-display.component.jsx';

const ExcludedAreaAppeal = (props) => {
  const [data, setData] = useState({
    excluded_area: "",
    applicant_comments: ""
  })

  const [area, setArea] = useState({
    mapBlkLot: "",
    grossTotalArea: "",
    permeableArea: "",
    impermeableArea: "",
    excludedArea: "",
  });

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }

    if (props.area) {
      setArea(props.area);
    }
  }, [props])

  return (
    <Accordion expanded>
      <AccordionSummary id="inaccurateExcludedAreaAppeal" style={{ backgroundColor: "whitesmoke" }} >
        <Typography variant="h6" align="left" gutterBottom color="primary">
          Enter Information for Error in the assessment of the Excluded Parcel Area
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Typography variant="body1" component={'div'}>
            *Excluded area includes land that drains to a lake, pond, or to the Bay; submerged portions of waterfront parcels; and areas that are managed by their own stormwater management system under a separate permit. <br />
            <br />
            All appeals for excluded parcel areas require documentation that stormwater runoff from the appealed area flows into one of the qualified bodies of water or treatment facilities. <Typography display='inline' style={{ fontWeight: 'bold' }}>Customer must provide a map for review of your appealed excluded area.</Typography> <br />
            <br />
            Note: Impermeable or permeable area is not relative to the excluded area stormwater charge.<br />
            <br />
            <Typography variant='body2'>
              *San Francisco Public Utilities Commission<br />
              2023 Water and Wastewater Rate Study Final Report, May 15, 2023, page 111.
            </Typography>
          </Typography>
          <AreaInformation
            data={{
              'Map Block Lot': area.mapBlkLot,
              'Assessor Block Lot': data.s_Assessor_Block_Lot_No,
              'Current Gross Total Area': area.grossTotalArea,
              'Current Excluded Area': area.excludedArea,
              'Current Permeable Area': area.permeableArea,
              'Current Impermeable Area': area.impermeableArea,
            }}
            showTitle={false}
          />

          <FormInputTypography
            label="Inaccurate Excluded Area Appeal ID"
            size={5}
            value={data.customerAppealId}
          />
          <FormInputTypography
            label="Appealed Excluded Area"
            size={5}
            value={data.excluded_area}
          />
          <FormInputTypography
            label="Applicant Comments"
            size={5}
            value={data?.applicant_comments ? data.applicant_comments : ""}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ExcludedAreaAppeal;