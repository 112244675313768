import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { getAppealById, getAppealDetail, reviewExcludedArea, assignReviewer, getFile, postReviewFile } from '../../../../../services/APIClient';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, Paper, Typography, LinearProgress } from '@material-ui/core';

/* Langan Components */
import AppealInfo from '../../appeal-info/appeal-info.component';
import AppealValidation from '../../appeal-validation/appeal-validation.component';
import DocumentsReview from '../../documents-review/documents-review.component';
import ExcludedAreaAppealReview from './excluded-area-appeal-review.component';
import ReviewButton from '../../../../form-input/form-button-review.component';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: "20px",
    marginBottom: theme.spacing(5)
  },
  title: {
    marginTop: '20px',
    backgroundColor: "whitesmoke"
  },
}));

const defaultDocReview = {
  r_Documentary_Proof_Submitted: false,
  r_Site_Plan: false,
  r_Marked_Up_Image: false,
  r_Site_Photographs: false
};

const defaultDecisionReview = {
  r_Reviewer_Comments: "",
  r_Decision: "inprogress",
  r_Effective_Billing_Date: new Date().toISOString(),
  r_Pending_Doc_Reason: "",
  r_Denied_Reason: "",
  r_Cancelled_Reason: ""
};


const defaultAppealReview = {
  r_Claim_Area_is_Valid: false,
  r_Location_of_changes_provided: false,
  r_Approved_Excluded_Area: null,
  r_Approved_Impermeable_Area: null,
  r_Approved_Permeable_Area: null,
  r_Approved_Billable_Impermeable_Area: null,
  r_Approved_Billable_Permeable_Area: null,
  r_Approved_Net_Total_Area: null
};


const ReviewExcludedAreaAppeal = observer(props => {
  const classes = useStyles();
  let navigate = useNavigate();

  const [masterData, setMasterData] = useState({});
  const [appealData, setAppealData] = useState({});
  const [initStatus, setInitStatus] = useState(false);
  const [docReview, setDocReview] = useState(defaultDocReview);
  const [mapBlock, setMapBlock] = useState("");
  const [assessorBlock, setAssessorBlock] = useState("");
  const [file, setFile] = useState([]);
  const [decisionReview, setDecisionReview] = useState(defaultDecisionReview);
  const [appealReview, setAppealReview] = useState(defaultAppealReview);
  const [reviewFileName, setReviewFileName] = useState("");
  const [reviewFile, setReviewFile] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    getAppealById(props.appealid)
      .then((response) => {
        setMasterData(response);

        //get file data
        getFile(response.masterId, response.appeal_type_id, response.appeal_type)
          .then(response => {
            if (response.status === 200) {
              setFile(response.data);
            }
          })

        getAppealDetail("AppealsExcluded", response.appeal_type_id)
          .then((response) => {
            setAppealData(response);
            setMapBlock(response.s_Map_Block_Lot_No ? response.s_Map_Block_Lot_No : "");
            setAssessorBlock(response.s_Assessor_Block_Lot_No ? response.s_Assessor_Block_Lot_No : "");
            if (response?.r_Decision === "rejected" || response?.r_Decision === "cancelled" || response?.r_Decision === "approved") setInitStatus(true);
            setDocReview({
              r_Documentary_Proof_Submitted: response.r_Documentary_Proof_Submitted === "true" ? true : false,
              r_Site_Plan: response.r_Site_Plan === "true" ? true : false,
              r_Marked_Up_Image: response.r_Marked_Up_Image === "true" ? true : false,
              r_Site_Photographs: response.r_Site_Photographs === "true" ? true : false,
            });
            setDecisionReview({
              r_Reviewer_Comments: response?.r_Reviewer_Comments ? response.r_Reviewer_Comments : "",
              r_Decision: response?.r_Decision ? response.r_Decision : "inprogress",
              r_Effective_Billing_Date: response?.r_Effective_Billing_Date ? new Date(response.r_Effective_Billing_Date) : new Date(),
              r_Pending_Doc_Reason: response?.r_Pending_Doc_Reason ? response.r_Pending_Doc_Reason : "",
              r_Denied_Reason: response?.r_Denied_Reason ? response.r_Denied_Reason : "",
              r_Cancelled_Reason: response?.r_Cancelled_Reason ? response.r_Cancelled_Reason : ""
            });
            setAppealReview({
              r_Claim_Area_is_Valid: response.r_Claim_Area_is_Valid === "true" ? true : false,
              r_Location_of_changes_provided: response.r_Location_of_changes_provided === "true" ? true : false,
              r_Approved_Excluded_Area: response.r_Approved_Excluded_Area !== null ? response.r_Approved_Excluded_Area : response.excluded_area,
              r_Approved_Impermeable_Area: response.r_Approved_Impermeable_Area !== null ? response.r_Approved_Impermeable_Area : response.impermeable_area,
              r_Approved_Permeable_Area: response.r_Approved_Permeable_Area !== null ? response.r_Approved_Permeable_Area : response.permeable_area,
              r_Approved_Billable_Impermeable_Area: response.r_Approved_Billable_Impermeable_Area !== null ? response.r_Approved_Billable_Impermeable_Area : response.billable_impermeable_area,
              r_Approved_Billable_Permeable_Area: response.r_Approved_Billable_Permeable_Area !== null ? response.r_Approved_Billable_Permeable_Area : response.billable_Permeable_area,
              r_Approved_Net_Total_Area: response.r_Approved_Net_Total_Area !== null ? response.r_Approved_Net_Total_Area : response.net_total_area
            });
          })
      })
      .catch(error => {
        //console.log(error);
      });
  }, [props.appealid])

  const docChange = (event) => {
    const { name, checked } = event.target
    setDocReview(prev => {
      return {
        ...prev,
        [name]: checked
      }
    })
  };

  const fileChange = (event) => {
    if (event) {
      setReviewFileName(event.target.files[0].name);
      setReviewFile(event.target.files[0]);
    } else {
      setReviewFileName("");
      setReviewFile("");
    }
  };

  const submitReview = async () => {
    let today = new Date();
    today = today.toISOString();
    setSubmitting(true);
    const review = {
      sdmS_MASTER_APPEAL_ID: appealData.masterId,
      appeaL_TYPE_ID: appealData.appealId,

      r_Documentary_Proof_Submitted: docReview.r_Documentary_Proof_Submitted ? "true" : "false",
      r_Site_Plan: docReview.r_Site_Plan ? "true" : "false",
      r_Marked_Up_Image: docReview.r_Marked_Up_Image ? "true" : "false",
      r_Site_Photographs: docReview.r_Site_Photographs ? "true" : "false",

      ...appealReview,
      ...decisionReview,
      r_Claim_Area_is_Valid: appealReview.r_Claim_Area_is_Valid ? "true" : "false",
      r_Location_of_changes_provided: appealReview.r_Location_of_changes_provided ? "true" : "false",
      r_Effective_Billing_Date: decisionReview.r_Effective_Billing_Date.toISOString(),
      r_Status_Change_Date: today, // temp set to today
      r_Review_Submitted_By: masterData.reviewer ? masterData.reviewer : props.user.email,
    };
    if (decisionReview.r_Decision === "pending") {
      review.r_Pending_Doc_Date = today;
    } else if (decisionReview.r_Decision === "cancelled") {
      review.r_Cancelled_Date = today;
    } else if (decisionReview.r_Decision === "rejected") {
      review.r_Denied_Date = today;
    } else if (decisionReview.r_Decision === "approved") {
      review.r_Approved_Date = today;
    }

    const reviewResult = await reviewExcludedArea(appealData.appealId, review);

    // submit review file
    if (reviewFile !== "") {
      const postFile = new FormData();
      postFile.append("master_id", masterData.masterId);
      postFile.append("appeal_type_id", masterData.appeal_type_id);
      postFile.append("appeal_type", masterData.appeal_type);
      postFile.append("AttachmentFile", reviewFile);
      postFile.append("FileName", reviewFileName);
      const fileResult = await postReviewFile(postFile);
    }

    // assign user
    const user = {
      customer_appeal_id: masterData.customer_appeal_id,
      masterId: masterData.masterId,
      appeal_type: masterData.appeal_type,
      appeal_type_id: masterData.appeal_type_id,
      ccb_account: masterData.ccb_account,
      zipcode: masterData.zipcode,
      date_of_appeal: masterData.date_of_appeal,
      nextBillDate: masterData.nextBillDate,
      reviewer: masterData.reviewer ? masterData.reviewer : props.user.email,
      applicant_name: masterData.applicant_name
    }
    const assignRsult = await assignReviewer(masterData.customer_appeal_id, user)
    setSubmitting(false);
  };

  const handleClick = async (event) => {
    event.preventDefault();
    submitReview()
      .then(result => {
        navigate("/dashboard");
      }).catch((err) => {
        //console.log(err);
        setSubmitting(false);
      });
  }

  return (
    <Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent} style={{ backgroundColor: "whitesmoke" }}>
          <Container maxWidth="md">
            <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
              Inaccurate Excluded Parcel Level Area Appeal Review
            </Typography>
          </Container>
        </div>

        <Container maxWidth="md">
          <Paper className={classes.paper}>
            <AppealInfo title="Inaccurate Excluded Parcel Level Area Appeal" masterData={masterData} mapblock={mapBlock} assessorBlock={assessorBlock} type="1" />
            <AppealValidation masterData={masterData} file={file} />
            <DocumentsReview docReview={docReview} docChange={docChange} type="1" role={props.role} initStatus={initStatus} />
            <ExcludedAreaAppealReview
              title="Excluded Area Appeal Technical Review"
              appealData={appealData}
              appealReview={appealReview}
              setAppealReview={setAppealReview}
              decisionReview={decisionReview}
              decisionReviewOnChange={setDecisionReview}
              file={file}
              fileName={reviewFileName}
              fileChange={fileChange}
              role={props.role}
              initStatus={initStatus}
              masterData={masterData}
            />
            <ReviewButton
              criteria1={(props.role.admin || props.role.business) && !initStatus}
              criteria2={decisionReview.r_Decision === "inprogress"}
              status={submitting}
              handleClick={handleClick}
            />
            {submitting && <LinearProgress />}
          </Paper>
        </Container>
      </main>
    </Fragment>
  );
});

export default ReviewExcludedAreaAppeal;