import { Fragment, useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Tabs, Tab, Typography, Badge, CssBaseline } from '@material-ui/core';

/* MobX */
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 4),
    marginTop: theme.spacing(2),
  },
  wrapper: {
    textTransform: 'initial'
  }
}));

const AdminNavigation = observer(props => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  const [selectedTab, setSelectedTab] = useState("users");
  let landUseCodeStore = props.store;

  useEffect(() => {
    if (location.pathname === '/admin') {
      setSelectedTab('users');
      navigate('users');
    } else {
      setSelectedTab(location.pathname.split('/')[2]);
    }
  }, [location.pathname])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    navigate(newValue);
  }

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent} >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Admin Dashboard
        </Typography>
        <Tabs value={selectedTab} onChange={handleTabChange} centered >
          <Tab label="Users" value="users" className={classes.wrapper}/>
          <Tab label="Addresses" value="addresses" className={classes.wrapper}/>
          <Tab label={
            <Badge variant='dot' invisible={landUseCodeStore.unreviewedLandUseCode.length === 0}  color='error'>
              Land Use Code
            </Badge>
          } value="landusecode" className={classes.wrapper}/>
          <Tab label="Reports" value="reports" className={classes.wrapper}/>
          <Tab label='Manual Appeal' value='manualappeals' />
        </Tabs>
      </Container>
      <Outlet />
    </Fragment>
  )
});

export default AdminNavigation;