import { Fragment, useState, useEffect } from 'react';

import { useLocation, useNavigate, Outlet } from "react-router-dom";

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Tabs, Tab, Button, Box } from '@material-ui/core';
import Logo from "../../assets/images/SFWPS-Horz-T1.png";
import { MaintenanceBanner } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
  appbar: {
    width: '100%',
    position: 'sticky',
    backgroundColor: '#FFFFFF',
    height: '100%',
  },
  toolbar: {
    width: '100%',
    flexWrap: 'wrap',
  },
  tabsIndicator: {
    color: '#0A0A0A',
    alignSelf: 'flex-end',
  },
  logo: {
    height: '100px',
    padding: theme.spacing(1),
    alignSelf: 'center',
  },
  logoBox: {
    maxWidth: '20%',
  },
  loginBox: {
    color: '#0A0A0A',
    position: 'absolute',
    right: '0',
    top: '0',
  },
  icon: {
    color: '#0A0A0A',
  },
  wrapper: {
    textTransform: 'initial'
  },
  navText: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#0A0A0A',
  },
}));

function AppNavigation(props) {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();

  const [selectedTab, setSelectedTab] = useState("/");

  useEffect(() => {
    const pathname = location?.pathname.split("/");
    if (pathname.length >= 2 && ["", "admin", "dashboard", "reports", "resources", "search", "map"].includes(pathname[1])) {
      setSelectedTab(`/${pathname[1]}`);
    } else {
      setSelectedTab("/");
    };
  }, [location?.pathname])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    navigate(newValue);
  }

  return (
    <Fragment>
      <MaintenanceBanner />
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <img src={Logo} className={classes.logo} alt="SFPUC Logo" onClick={() => { navigate('/') }} style={{ "cursor": "pointer" }} />
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="secondary"
            variant='scrollable'
            scrollButtons="auto"
            className={classes.tabsIndicator}
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            <Tab label="Home" value="/" className={classes.navText} />
            {(props.role.admin || props.role.business || props.role.credit || props.role.viewer) && <Tab label="Search" value="/search" className={classes.navText} />}
            {(props.role.admin || props.role.business || props.role.credit) && <Tab label="Dashboard" value="/dashboard" className={classes.navText} />}
            {(props.role.admin) && <Tab label="Admin" value="/admin" className={classes.navText} />}
            {(props.role.admin || props.role.business || props.role.credit) && <Tab label="Reports" value="/reports" className={classes.navText} />}
            {(props.role.admin || props.role.business || props.role.credit || props.role.viewer) && <Tab label="City Map" value="/map" className={classes.navText} />}
            <Tab label="Resources" value="/resources" className={classes.navText} />

          </Tabs>
          <Box className={classes.loginBox}>
            <Button onClick={() => { navigate('login') }} className={classes.navText}>
              {(props.role.customer && !props.role.admin && !props.role.business && !props.role.credit && !props.role.viewer) ? 'Employee Login'
                : `${props.user}`
              }
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Outlet />
    </Fragment>
  );
}

export default AppNavigation;
