import { useEffect, useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Grid, TextField, Box, Divider, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useCredits } from '../../../../stores/CreditStore/CreditModuleStore';




const CreditAllocationReviewContent = observer((props) => {
  const credits = useCredits();
  const decision = props.decision;

  const handleChange = (e, idx) => {
    const {name, value} = e.target;
    let number = parseFloat(value);
    const allocation = credits.creditReview.creditsDistributionData;
    allocation[idx].setItem(name, number);
    allocation[idx].setItem("r_Distributed_Credit_Amount", (Math.floor(credits.creditReview.approvedTotalCredit * number) / 100));
    credits.creditReview.checkAllocationStatus();
  }

  return (
    <>
      <Box p={2} bgcolor="background.paper">
        <Divider variant="middle" />
      </Box>
      {credits &&
        <Grid item xs={12} align="right" >
          <Typography color="error">{credits.creditReview.checkAllocationStatus()}</Typography>
        </Grid>
      }
      {(credits && credits.creditReview) &&  credits.creditReview.creditsDistributionData.map((element, index) =>
      (<Grid item xs={12} key={index}>
        <Grid container spacing={2}>
          <Grid item xs={3} align="center">
            <TextField
              name="f_Appealed_Account_No"
              variant="filled"
              fullWidth
              label={`Account Number ${index + 1}`}
              value={element.f_Appealed_Account_No}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>

          <Grid item xs={3} align="center">
            <TextField
              name="f_Appealed_Distb_Percent"
              variant="filled"
              label="Submitted Percentage"
              fullWidth
              type="number"
              value={element.f_Appealed_Distb_Percent ? element.f_Appealed_Distb_Percent : ''}
              InputProps={{ readOnly: true,
                endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            />
          </Grid>

          <Grid item xs={3} align="center">
            <TextField
              name="r_Approved_Distb_Percent"
              variant="outlined"
              label="Approved Percentage"
              fullWidth
              type="number"
              disabled={["APPROVED", "REJECTED", "CANCELLED"].includes(decision)}
              value={element.r_Approved_Distb_Percent ? element.r_Approved_Distb_Percent : ''}
              onChange={(e) => handleChange(e, index)}
              InputProps={{ readOnly: false,
                endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            />
          </Grid>

          <Grid item xs={3} align="center">
            <TextField
              name="allocated_amount"
              variant="filled"
              label="Allocated Amount"
              fullWidth
              type="number"
              value={element.r_Distributed_Credit_Amount ? element.r_Distributed_Credit_Amount : 0}
              InputProps={{ readOnly: true,
                startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            />
          </Grid>

        </Grid>
      </Grid>)
      )}
      <Grid item xs={9} align="left">
      </Grid>

    </>
  )
})

export default CreditAllocationReviewContent;