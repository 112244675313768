import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import { Grid, Typography } from '@material-ui/core';

export const SelectedField = ({}) => {

}

export const UserInputField = ({}) => {
  
}

export const AddRemoveItem = ({ addRow, removeRow }) => {
  return (
    <>
      <Grid item xs={12} align="center" key='add-instructions'>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          Click the buttons below to add or remove a green infrastructure facility
        </Typography>
      </Grid>
      <Grid item xs={6} align="right" key='add'>
        <IconButton color="primary" aria-label="Add Credit Source" variant="contained" onClick={addRow} component="span">
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={6} align="left" key='remove'>
        <IconButton color="primary" aria-label="Remove Credit Source" variant="contained" onClick={removeRow} component="span">
          <RemoveIcon />
        </IconButton>
      </Grid>
    </>
  )
};
