import { Routes, Route } from 'react-router-dom';

/* Langan Components */
import CreditFormPage from '../../pages/CreditPages/CreditFormPage';
import CreditPage from '../../pages/CreditPages/CreditPage';
import CreditConfirmation from '../../pages/CreditPages/CreditConfirmationPage';
import CreditReviewPage from '../../pages/CreditPages/CreditReviewPage';

const CreditRouter = (props) => {

  return (
    <Routes>
      <Route index element={<CreditPage store={props.store} />} />
      <Route path='/:account/:zipcode' exact element={<CreditFormPage store={props.store} />} />
      <Route path='confirmation/:creditID' exact element={<CreditConfirmation store={props.store} />} />
      <Route path='review/:type/:id' exact element={<CreditReviewPage />} />
    </Routes>

  )
}

export default CreditRouter;