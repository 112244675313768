import React, { Fragment, useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useNavigate, Outlet } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Typography, Grid, Button, IconButton, LinearProgress, Box } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import FormInputTextField from "../../form-input/form-input-textfield.component";
import { AcceptableFileTypesString } from "../../utils/utils";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormInputRadio from "../../form-input/form-input-radio.component";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: "20px",
    marginBottom: theme.spacing(5)
  },
  title: {
    margin: theme.spacing(2, 0, 2),
    backgroundColor: 'whitesmoke',
  },
  wrapper: {
    textTransform: 'initial'
  },
  button: {
    margin: theme.spacing(2, 0, 2),
  }
}));

const decisions = [
  { value: 'Approved', label: 'Approved' },
  { value: 'Denied', label: 'Denied' }
]

const GenericAppealForm = observer(props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const manualAppeal = props.form;
  const user = props.root.user;
  const [formReady, setFormReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (user && manualAppeal) {
      manualAppeal.setItem('R_Review_Submitted_By', user.fullName);
    }
  }, [user])

  const handleCancelSubmit = () => {
    manualAppeal.reset();
    navigate('../');
  };

  const handleUploadFile = (event) => {
    if (event.target.files[0]) {
      manualAppeal.setItem('AttachmentFiles', [...manualAppeal.AttachmentFiles, event.target.files[0]]);
      event.target.value = '';
      setFormReady(manualAppeal.isReady());
    }
  }

  const handleDeleteFile = (event, idx) => {
    manualAppeal.setItem('AttachmentFiles', manualAppeal.AttachmentFiles.filter((file, index) => index !== idx));
    event.target.value = '';
    setFormReady(manualAppeal.isReady());
  }

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    if (name === 'R_Decision') {
      if (value === 'Approved') {
        manualAppeal.setItem('R_Date_Implemented', new Date().toISOString());
      } else {
        manualAppeal.setItem('R_Date_Implemented', '');
      }
      manualAppeal.setItem(name, value)
    } else {
      manualAppeal.setItem(name, value)
    }
    setFormReady(manualAppeal.isReady());
  }

  const submitAppeal = () => {
    setIsSubmitting(true);
    manualAppeal.submit().then((response) => {
      if (response === 200) {
        setIsSubmitting(false);
        manualAppeal.reset();
        navigate('../view');
      }
    })
  }

  return (

    <Fragment>
      {manualAppeal && user &&
        <Container maxWidth="md">
          <Paper className={classes.paper}>
            <Grid container className={classes.title} align='center'>
              <Grid item xs={12} >
                <Typography variant="h3" color='primary' >Manual Appeal Form</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <FormInputTextField label={'Account Number'} name='Account_No' size={6} onChange={handleFieldChange} />
              <FormInputTextField label={'Map Block/lot'} size={6} name='Map_Block_Lot_No' onChange={handleFieldChange} />
              <FormInputTextField label={"Assessor's Block Lot"} size={6} name='Assessors_BlkLot' onChange={handleFieldChange} required
                error={!manualAppeal.Assessors_BlkLot} helperText={!manualAppeal.Assessors_BlkLot && 'Please enter required information'} />
              <FormInputTextField label={'Premise ID'} size={6} name='Premise_ID' onChange={handleFieldChange} required
                error={!manualAppeal.Premise_ID} helperText={!manualAppeal.Premise_ID && 'Please enter required information'} />
              <FormInputTextField label={'Wastewater SA ID'} size={6} name='Wastewater_Sa_ID' onChange={handleFieldChange} />
              <FormInputTextField label={'Correction Type'} size={6} name='Correction_Type' onChange={handleFieldChange} />
              <FormInputTextField label={'Existing Field'} size={6} name='Existing_Field' onChange={handleFieldChange} />
              <FormInputTextField label={'Corrected Field(s)'} size={6} name='Corrected_Fields' onChange={handleFieldChange} />
              <FormInputTextField label={'Appealed Net Area'} size={6} name='F_Appealed_Net_Total_Area' value={manualAppeal.F_Appealed_Net_Total_Area ? manualAppeal.F_Appealed_Net_Total_Area : ''} onChange={handleFieldChange} />
              <FormInputTextField label={'Location of Documentation'} size={6} name='R_Document_Location' onChange={handleFieldChange} />
              <FormInputTextField label={'Approved By'} size={6} name='R_Review_Submitted_By' value={manualAppeal.R_Review_Submitted_By} onChange={handleFieldChange} />
              <Grid item xs={6}>
                <Typography variant="h6" display='inline'>Documentation</Typography>
                <Typography variant="h6" display='inline' color='error'>*</Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  component="label"
                  disabled={false}
                  color='primary'
                >
                  Upload Documentation
                  <input type="file" accept={AcceptableFileTypesString} hidden onChange={handleUploadFile} />
                </Button>
                {!manualAppeal.AttachmentFiles.length > 0 &&
                  <Typography variant="body1" color='error'>Please upload supporting documentation</Typography>}
                {(manualAppeal.AttachmentFiles && manualAppeal.AttachmentFiles.length > 0) &&
                  manualAppeal.AttachmentFiles.map((file, index) => {
                    return (
                      <div key={index}>
                        <Typography variant="body1" display="inline" >
                          {file.name}
                          <IconButton color="primary" name={file} aria-label="delete-uploaded-files" component="span" size="small" onClick={(e) => handleDeleteFile(e, index)}>
                            <Delete />
                          </IconButton>
                        </Typography>
                      </div>
                    )
                  })
                }
              </Grid>
              <FormInputRadio options={decisions} label={'Decision'} labelSize={6} RadioSize={6} name='R_Decision' onChange={handleFieldChange} error={manualAppeal.R_Decision ? '' : 'Please select the decision for the current appeal'} />
              {manualAppeal.R_Decision === 'Approved' &&
                <Fragment>
                  <Grid item xs={6} >
                    <Typography variant="h6" >Date Implemented</Typography>
                  </Grid>
                  <Grid item xs={6} >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker disableToolbar inputVariant="outlined" format="MM/dd/yyyy" id="date-impleted"
                        KeyboardButtonProps={{ 'aria-label': 'change date', }} required fullWidth name='R_Date_Implemented'
                        value={new Date(manualAppeal.R_Date_Implemented)} onChange={(value) => {
                          manualAppeal.setItem('R_Date_Implemented', (isNaN(new Date(value)) || !value) ? '' : value?.toISOString())
                          setFormReady(manualAppeal.isReady())
                        }} />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Fragment>
              }
              <Grid item xs={6} >
                <Typography variant="h6" >Decision Date</Typography>
              </Grid>
              <Grid item xs={6} >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker disableToolbar inputVariant="outlined" format="MM/dd/yyyy" id="date-decision" KeyboardButtonProps={{ 'aria-label': 'change date', }} required fullWidth
                    value={manualAppeal.R_Decision_Date} onChange={(value) => {
                      manualAppeal.setItem('R_Decision_Date', (isNaN(new Date(value)) || !value) ? '' : value?.toISOString())
                      setFormReady(manualAppeal.isReady())
                    }
                    } />
                </MuiPickersUtilsProvider>
              </Grid>
              <FormInputTextField label={'Comments'} size={6} multiline name='F_Applicant_Comments' onChange={handleFieldChange} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button variant='contained' color='primary' className={classes.button} disabled={!formReady || isSubmitting} fullWidth onClick={submitAppeal}>
                  Submit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant='contained' color='primary' className={classes.button} fullWidth onClick={handleCancelSubmit}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
            {isSubmitting && <LinearProgress />}
          </Paper>
        </Container>
      }
      <Outlet />
    </Fragment>
  )
});

export default GenericAppealForm;