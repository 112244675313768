import React, { useEffect, useState} from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { Grid, TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';

/* Langan Components */
import FormInputTextField from '../../form-input/form-input-textfield.component';
import { AreaInformation, LinkTextDisplay } from '../../data-display/data-display.component';
import EsriMap from "../../map/EsriMap";
import SketchWidget from "../../map/SketchWidget";
import FeatureLayer from "../../map/FeatureLayer";
import { FileUploadButton } from '../../form-input/form-file-upload-button.component';

const useStyles = makeStyles((theme) => ({
  link: theme.link,
}));

const ImpermeableAreaAppeal = observer((props) => {
  const { expanded,
    handleAccordianChange,
    statusReady, statusChange,
    statusImpermeable,
    impermeable,
    handleImpermeableChange,
    handleImpermeableAreaChange,
    setImperviousGraphics,
    imperviousFileName,
    imperviousFileChange,
    imperviousWarning,
    area,
    mapBlock,
    assessorBlock } = props;

  const classes = useStyles();

  const { F_Appealed_Impermeable_Area,
    F_Appealed_Permeable_Area,
    F_Appealed_Billable_Impermeable_Area,
    F_Applicant_Comments_Impermeable,
    r_Decision,
  } = impermeable;
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    if (r_Decision) {
      if (r_Decision === '' || r_Decision === 'pending' || r_Decision === 'inprogress') {
        setStatusMessage(<Typography variant="h6" color="error">There is a current appeal pending. Please contact us at (415)-551-3000 before submitting a new request.</Typography>)
      } else {
        setStatusMessage(<Typography variant="h6" color="error">A previous appeal submission has already been determined for this appeal type within the past 12 months from the date the initial appeal was filed. <br />
          <br />
          The San Francisco Public Utilities Commission considers only one stormwater charge appeal for each appeal type on each parcel annually. Please contact us at stormwaterappeals@sfwater.org for any questions.
        </Typography>)
      }
    } else {
      setStatusMessage(<Typography variant="h6" color="error">There is a current appeal pending. Please contact us at (415)-551-3000 before submitting a new request.</Typography>)
    }
  }, [r_Decision])

  return (
    <Accordion expanded={expanded === 'panel2'} onChange={handleAccordianChange('panel2')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="inaccurateImperviousAreaAppeal" style={{ backgroundColor: "whitesmoke" }} >
        <Typography variant="h6" gutterBottom color="primary" component='div'>
          <Checkbox
            checked={statusReady}
            disabled={statusChange}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          Enter Information for Error in the assessment of the property's impermeable or permeable areas
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Typography variant="body1" component='span'>
            Service Account holders that believe their assessed permeable or impermeable area is incorrect may
            submit an appeal of their billable areas.
            <br />
            <ul>
              <li> <b>Impermeable area</b> includes hard surfaces, such as rooftops, concrete, buildings, and pavement. Permeable area allows for some stormwater to soak into the ground. Examples of permeable areas are landscaping, grass, dirt, and gravel.
              </li>
              <li>
                <b>Please note: If your bill indicates that you are in the simplified residential tiers, you are not eligible for this appeal type.</b> Residential customers are billed on net parcel area only. If you believe your parcel area is incorrect, email us at <LinkTextDisplay href={'mailto:StormwaterAppeals@sfwater.org'} linkStyle={classes.link} linkName={'StormwaterAppeals@sfwater.org'} externalLink={true} />.
              </li>
            </ul>
          </Typography>
          <Grid item xs={12}>
            {statusImpermeable === 'submitted' && statusMessage}
            <Typography variant="body1">
              You may draw on the provided map below to show the area changes or manually fill in the area fields in the form below.
            </Typography>

            <EsriMap screenSize="sketch" itemId={process.env.REACT_APP_WEBMAP_ID} type="static">
              {statusImpermeable !== "submitted" &&
                <SketchWidget
                  handleAreaChange={handleImpermeableAreaChange}
                  handleGraphicsChange={setImperviousGraphics}
                  parcelId={mapBlock} />
              }
              <FeatureLayer parcelId={mapBlock} navigation={false} ></FeatureLayer>
            </EsriMap>
          </Grid>

          <AreaInformation
            data={{
              "Map Block Lot": mapBlock,
              "Assessor Block Lot": assessorBlock,
              "Current Gross Total Area": area.S_Current_Gross_Total_Area,
              "Current Excluded Area": area.S_Current_Excluded_Area,
              "Current Permeable Area": area.S_Current_Permeable_Area,
              "Current Impermeable Area": area.S_Current_Impermeable_Area,
            }}
            showTitle={false}
          />

          <FormInputTextField
            label="Appealed Impermeable Area"
            required={true}
            size={5}
            helperText='Enter the area you want to be considered as impermeable in square feet.'
            name="F_Appealed_Impermeable_Area"
            value={F_Appealed_Impermeable_Area}
            onChange={handleImpermeableChange}
            InputProps={{ readOnly: statusChange }}
          />

          <FormInputTextField
            label="Billable Appealed Impermeable Area"
            required={true}
            size={5}
            toolTip='The appealed impermeable area is rounded down to the nearest hundred. Standard charge customers are billed using this number.'
            name="F_Appealed_Billable_Impermeable_Area"
            value={F_Appealed_Billable_Impermeable_Area}
            disabled
          />

          <FormInputTextField
            label="Appealed Permeable Area"
            required={true}
            size={5}
            toolTip='Automatically updated to match appealed impermeable area input. Use the following formula to calculate your Appealed Permeable Area:
            Appealed Permeable Area = Current Gross Total Area - Appealed Impermeable Area'
            name="F_Appealed_Permeable_Area"
            value={Math.round(F_Appealed_Permeable_Area)}
            disabled
          />

          {imperviousWarning &&
            <Grid item xs={12} align="left" >
              <Typography color="error">{imperviousWarning}</Typography>
            </Grid>}
          <Grid item xs={12} align="left">
            <FileUploadButton
              description={"Attach Relevant Documentation To Support Appeal"}
              instruction={'Supporting documents may include; photos, plans, surveys, drainage reports. Please submit all documents as one file, with .pdf, .jpg, or .jpeg extension and with a file size of less than 200MB.'}
              fileChange={imperviousFileChange}
              disabled={statusChange} />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField name="F_Applicant_Comments_Impermeable" variant="outlined" label="Applicant Comments" fullWidth multiline
              value={F_Applicant_Comments_Impermeable} onChange={handleImpermeableChange} InputProps={{ readOnly: statusChange }} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
})

export default ImpermeableAreaAppeal;