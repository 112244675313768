import { Fragment } from "react";
import { Grid, TextField, Typography } from '@material-ui/core';
import FormInputTypography from '../../../../form-input/form-input-typography.component';

const ChargeAllocationsContent = ({ appealData, approvedCharge, saHandleChange, warning, role, initStatus }) => {

  return (
    <Fragment>
      <Grid item xs={4} style={{ marginTop: '20px' }} align="center">
        <Typography>Account Number</Typography>
      </Grid>
      <Grid item xs={4} style={{ marginTop: '20px' }} align="center">
        <Typography>Appealed Charge (%)</Typography>
      </Grid>
      <Grid item xs={4} style={{ marginTop: '20px' }} align="center">
        <Typography>Approved Charge (%)</Typography>
      </Grid>

      {appealData?.accounts?.length > 0 && appealData.accounts.map((element, index) =>
      (<Grid item xs={12} key={index}>
        <Grid container spacing={2} >
          <Grid item xs={4} align="center" >
            <TextField name="acc_no" variant="outlined" label={`Account Number ${index + 1}`}
              value={element?.f_Appeal_Account_Num ? element.f_Appeal_Account_Num : ""}
              InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }} />
          </Grid>
          <Grid item xs={4} align="center">
            <TextField name="appealedCharge" variant="outlined" label={`Appealed Charge ${index + 1}`}
              value={element?.f_Appealed_Distb_Percent ? element.f_Appealed_Distb_Percent : 0}
              InputProps={{ readOnly: true, style: { color: 'black', background: 'whitesmoke' } }} />
          </Grid>
          <Grid item xs={4} align="center">
            <TextField name="approvedCharge" variant="outlined" label={`Approved Charge ${index + 1}`}
              value={approvedCharge[index]?.r_Approved_Distb_Percent ? approvedCharge[index].r_Approved_Distb_Percent : 0}
              type="number"
              onChange={event => { saHandleChange(event.target.value, index) }}
              disabled={(!role.business && !role.admin) || initStatus}
            />
          </Grid>
        </Grid>
      </Grid>)
      )}

      {warning &&
        <Grid item xs={12} align="right" >
          <Typography color="error">{warning}</Typography>
        </Grid>
      }
      <FormInputTypography
        label="Applicant Comments"
        name="f_Applicant_Comments"
        value={appealData?.f_Applicant_Comments ? appealData.f_Applicant_Comments : "The applicant did not provide any comments."}
        align="left"
      />
    </Fragment>
  )
}

export default ChargeAllocationsContent;