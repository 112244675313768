import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
// import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Button, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  buttonSubmit: {
    // marginBottom: 10,
    margin: theme.spacing(1),
    height: "85%"
  },
}));

const ManageReports = props => {
  const classes = useStyles();
  let navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);
  // const myRef = createRef();

  useEffect(() => {
    console.log("selectedFile in manage reports:", selectedFile);
  }, [selectedFile])

  // On file select (from the pop up)
  const onFileChange = (event) => {
    console.log("file:", event.target.files);
    setSelectedFile(event.target.files[0]);
  };

  const mapHandleSubmit = () => { navigate('/') };

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <form autoComplete="off" noValidate onSubmit={mapHandleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* <TextField name="address" variant="outlined" label="Address" fullWidth value={address} onChange={(e) => setAddress(e.target.value)} /> */}
              <input type="file" onChange={onFileChange} />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Upload</Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
};

export default ManageReports;