import { useEffect, useContext } from "react";
import { MapViewContext } from "../../contexts";

/* Esri */
import Legend from "@arcgis/core/widgets/Legend";

function LegendWidget() {
  const mapView = useContext(MapViewContext);

  useEffect(() => {
    if (mapView) {
      // Add Legend widget to map
      const legend = new Legend({
        view: mapView,
      });
      
      mapView.ui.add(legend, "bottom-right");
    }
  }, [mapView]);

  return (null)
};

export default LegendWidget;
