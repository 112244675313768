import React, { useState, useEffect } from "react";

/* MUI */
import { Grid, Paper, Typography } from '@material-ui/core';


/* Langan Components */
import ParcelDataGrid from "./ParcelPageDataComponent";
import { LinkTextDisplay } from "../data-display/data-display.component";

function ParcelPageAreaInfo(props) {
  const areaData = props.areaData;
  const [rate, setRate] = useState();
  const [showRate, setShowRate] = useState(props.rate);
  const [showAcctNum, setShowAcctNum] = useState(props.rate);

  useEffect(() => {
    if (props.rate) {
      if (props.rate.stormwaterChargeType) {
        setShowRate(true);
        if (props.rate.stormwaterChargeType === 'SRT1') {
          setRate('Simplified Residential Tier 1')
        } else if (props.rate.stormwaterChargeType === 'SRT2') {
          setRate('Simplified Residential Tier 2')
        } else if (props.rate.stormwaterChargeType === 'SRT3') {
          setRate('Simplified Residential Tier 3')
        } else if (props.rate.stormwaterChargeType === 'STANDARD') {
          setRate('Standard')
        } else if (props.rate.stormwaterChargeType === 'EXEMPT') {
          setRate('Exempt')
        } else {
          setRate('Unknown')
        }
      } else {
        setShowRate(false);
      }

      if (props.rate.numOfAccount) {
        setShowAcctNum(true);
      } else {
        setShowAcctNum(false);
      }
    }
  }, [props.rate])

  return (
    <>
      <Grid item xs={12} >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              Billing Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" >
              Click the question mark to learn more about the details.
            </Typography>
          </Grid>
        </Grid>
        <Paper elevation={1}>
          <Grid container spacing={3}>
            <ParcelDataGrid
              title="This is the total charged area for the parcel. This is calculated by adding the billable impermeable and permeable areas."
              data={areaData.billablePermeableArea + areaData.billableImpermeableArea}
              description={"Net Area"}
            />
            <ParcelDataGrid
              title="The calculated impermeable area rounded down to the nearest hundred. Standard rate customers are billed on this number. Simplified residential rate customers are billed on the average for this number across their tier. "
              data={areaData.billableImpermeableArea}
              description={"Billable Impermeable Area"}
            />
            <ParcelDataGrid
              title="The calculated permeable area plus the remainder of the impermeable area that was rounded down. Standard rate customers are billed on this number. Simplified residential rate customers are billed on the average for this number across their tier."
              data={areaData.billablePermeableArea}
              description={"Billable Permeable Area"}
            />
            {showRate &&
              <ParcelDataGrid
                title={'Charge rate types determine the formula used for calculating the bill. See the SFPUC rate schedule for more information.'}
                data={rate}
                description={"Stormwater Charge Rate Type"}
              />
            }
            {showAcctNum &&
              <ParcelDataGrid
                title="The number of customers receiving wastewater service from SFPUC."
                data={props.rate.numOfAccount}
                description={"# of Service Agreements"}
              />
            }

          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              Calculated Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" >
              Click the question mark to learn more about the details.
            </Typography>
          </Grid>
        </Grid>
        <Paper elevation={2}>
          <Grid container spacing={3}>
            <ParcelDataGrid
              title="This is the total physical area for the parcel. "
              data={areaData.grossTotalArea}
              description={"Gross Total Area"}
            />
            <ParcelDataGrid
              title="Area that does not allow stormwater to soak into the ground. Examples include concrete, asphalt, buildings, and decking."
              data={areaData.impermeableArea}
              description={"Impermeable Area"}
            />
            <ParcelDataGrid
              title="Area that allows stormwater to soak into the ground. Examples include lawns, landscaping, and soil."
              data={areaData.permeableArea}
              description={"Permeable Area"}
            />
            <ParcelDataGrid
              title="Area that does not drain to SFPUC owned or operated sewers. This area is not subject to the stormwater charge."
              data={areaData.excludedArea}
              description={"Excluded Area"}
            />
          </Grid>
        </Paper>
      </Grid>
    </>

  );
}

export default ParcelPageAreaInfo;