import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

/* Util */
import { format } from 'date-fns'

/* Langan Components */
import { searchParcelByMapBlock } from '../../services/APIClient';
import ParcelPageAreaInfo from "./ParcelPageAreaInfo";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.text.secondary
  },
  listItem: {
    backgroundColor: "#D0E1EB",
    borderRadius: "3px",
    marginTop: "2px",
    marginBottom: "2px"
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  styleTable: {
    marginTop: "20px",
  }
}));

function ParcelPageData(props) {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();

  const [areaData, setAreaData] = useState({
    grossTotalArea: 0,
    excludedArea: 0,
    impermeableArea: 0,
    permeableArea: 0,
    billableImpermeableArea: 0,
    billablePermeableArea: 0,
  });
  const [assessorData, setAssessorData] = useState([]);
  const [historyAreaData, setHistoryAreaData] = useState([]);
  const [assessorPage, setAssessorPage] = useState(3);
  const [historyPage, setHistoryPage] = useState(3);

  const assessorHeader = [
    {
      field: 'assessorBlkLot',
      headerName: 'Block Lot No',
      width: 300,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          //console.log({ params });
          navigate(`/assessor/${params.value.replace("/", "")}`);
        };
        return <Button size="small" color="primary" onClick={onClick}>{params.value}</Button>;
      }
    },
    { field: 'legalOwnerName', headerName: 'Legal Owner Name', width: 300 },
    { field: 'propertyAddress', headerName: 'Property Address', width: 300 },
  ]

  const areaHeader = [
    { field: 'grossTotal', headerName: 'Gross Total Area', width: 200 },
    { field: 'impermeableArea', headerName: 'Impermeable Area', width: 200 },
    { field: 'permeableArea', headerName: 'Permeable Area', width: 200 },
    { field: 'excludedArea', headerName: 'Excluded Area', width: 200 },
    { field: 'startDate', headerName: 'Start Date', width: 150 },
    { field: 'endDate', headerName: 'End Date', width: 150 },
  ]

  useEffect(() => {
    if (props?.parcelId) {
      //console.log("props.parcelId:", props.parcelId);
      searchParcelByMapBlock(props.parcelId)
        .then((response) => {
          //console.log("parcel raw data: ", response);
          setAreaData({
            mapBlkLot: response.mapBlkLot,
            grossTotalArea: response.grossTotalArea,
            excludedArea: response.excludedArea,
            impermeableArea: response.impermeableArea,
            permeableArea: response.permeableArea,
            billableImpermeableArea: response.billableImpermeableArea,
            billablePermeableArea: response.billablePermeableArea
          })

          const formattedAssessorData = response.assessorBlkLotList.map((assessor, index) => {
            const row = {
              id: index,
              assessorBlkLot: assessor.assessorBlkLot,
              legalOwnerName: assessor.legalOwnerName,
              propertyAddress: assessor.propertyAddress,
            }
            return row
          })
          setAssessorData(formattedAssessorData);
          const formattedAreaData = response.areaHistory.map((area, index) => {
            const row = {
              id: index,
              grossTotal: Math.round(area.grossTotal * 100) / 100,
              permeableArea: Math.round(area.permeableArea * 100) / 100,
              impermeableArea: Math.round(area.impermeableArea * 100) / 100,
              excludedArea: Math.round(area.excludedArea * 100) / 100,
              startDate: area.startDate ? area.startDate.substring(0, 10) : "",
              endDate: area.endDate ? area.endDate.substring(0, 10) : "",
            }
            return row
          })
          setHistoryAreaData(formattedAreaData);
        })
    }
  }, [props]);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Typography variant="h3" align="center" gutterBottom>
          Parcel Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography variant="h5">
              Map Block Lot
            </Typography>
            <Typography variant="h6">
              {props?.parcelId ? props.parcelId : ""}<br />
            </Typography>
          </Grid>

          {/* Parcel area data information */}
          <ParcelPageAreaInfo areaData={areaData} rate={location.state}/>

          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary" style={{ marginTop: '20px' }}>Assessor Block Lot</Typography>
            <div>
              <DataGrid
                className={classes.table}
                columns={assessorHeader}
                rows={assessorData}
                pageSize={assessorPage}
                onPageSizeChange={(pageSize, details) => { setAssessorPage(pageSize); }}
                autoHeight
                rowsPerPageOptions={[3, 5]}
              />
            </div>

            <Typography variant="h6" color="textPrimary" style={{ marginTop: '20px' }}>Area History</Typography>
            <div>
              <DataGrid
                className={classes.table}
                columns={areaHeader}
                rows={historyAreaData}
                pageSize={historyPage}
                onPageSizeChange={(pageSize, details) => { setHistoryPage(pageSize); }}
                autoHeight
                rowsPerPageOptions={[3, 5]}
              />
            </div>
          </Grid>
        </Grid>
      </Container>

    </div>
  );
}

export default ParcelPageData;