import React, { useState } from 'react';

// /* MobX */
// import { observer } from 'mobx-react-lite';
import * as converter from "json-2-csv";

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px"
  },
  table: {
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#115293',
      color: theme.palette.common.white,
      fontSize: 14
    },
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: '#fffbf2',
        cursor: 'pointer'
      },
    }
  },
  buttonSubmit: {
    marginBottom: 10,
  },
}));

const NewMapBlockLotReports = (props) => {
  const classes = useStyles();

  const [fromDate, setFromDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
  const [toDate, setToDate] = useState(new Date());

  const appealsHeader = [
    { field: 'mapblocklot', headerName: 'Block Lot', width: 140 },
    { field: 'landuse', headerName: 'Land Use', width: 140 },
    { field: 'fromstreetnumber', headerName: 'From Street Number', width: 200 },
    { field: 'tostreetnumber', headerName: 'To Street Number', width: 180 },
    { field: 'fromstreetsuffix', headerName: 'From Street Suffix', width: 180 },
    { field: 'tostreetsuffix', headerName: 'To Street Suffix', width: 180 },
    { field: 'streetname', headerName: 'Street Name', width: 160 },
    { field: 'address', headerName: 'Address', width: 140 },
    { field: 'city', headerName: 'City', width: 160 },
    { field: 'postal', headerName: 'Postal', width: 120 }
  ];

  const appealsData =
    [
      { id: 0, mapblocklot: "7247004", landuse: "Residential", fromstreetnumber: "10", tostreetnumber: "16", fromstreetsuffix: "10", tostreetsuffix: "16", streetname: "Kimball Drive", city: "San Francisco", postal: "94118" },
      { id: 1, mapblocklot: "8720117", landuse: "Commercial", fromstreetnumber: "50", tostreetnumber: "50", fromstreetsuffix: "50", tostreetsuffix: "50", streetname: "Hagiwara Tea Garden Dr", city: "San Francisco", postal: "94118" },
    ];

  const exportToCSV = () => {
    console.log(appealsData);

    converter
      .json2csvAsync(appealsData)
      .then((csv) => {
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);

        downloadLink.href = url;
        downloadLink.download = `Map_Block_Lots_Results.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((err) => {
        console.warn("json2csv failed");
      });

  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={3} >
        <Grid item xs={2}>
          <Grid item xs={12} align="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar inputVariant="outlined" format="MM/dd/yyyy" margin="normal" id="date-picker-inline" label="From Date"
                value={fromDate} onChange={(date) => { setFromDate(date); }} KeyboardButtonProps={{ 'aria-label': 'change date', }} fullWidth />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} align="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar inputVariant="outlined" format="MM/dd/yyyy" margin="normal" id="date-picker-inline" label="To Date"
                value={toDate} onChange={(date) => { setToDate(date); }} KeyboardButtonProps={{ 'aria-label': 'change date', }} fullWidth />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth >Submit</Button>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" align="left" color="textPrimary" gutterBottom>
              Reports from {fromDate.toISOString().split('T')[0]} to {toDate.toISOString().split('T')[0]}
              <IconButton color="primary" aria-label="Export to CSV" component="span" onClick={exportToCSV}>
                <PrintIcon />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DataGrid className={classes.table} columns={appealsHeader} rows={appealsData} autoHeight autoPageSize={true} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewMapBlockLotReports;
