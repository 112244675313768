import { useState, useEffect } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Grid, MenuItem, Paper, TextField, Typography, Box, Divider } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getSnapshot } from 'mobx-state-tree';
import { AreaInformation, SubTitle } from '../../../data-display/data-display.component';


const StandardCreditReviewContent = observer((props) => {
  const { creditsStandardType, creditsSTDTypeDetail, s_Current_Impermeable_Area, s_Current_Permeable_Area } = props.info;
  const decision = props.decision;
  const [imperm_credit, setImpermCredit] = useState(0);
  const [perm_credit, setPermCredit] = useState(0);

  useEffect(() => {
    if (creditsSTDTypeDetail) {
      let perm = 0;
      let imperm = 0;
      creditsSTDTypeDetail.forEach(item => {
        perm += item.f_Permeable_Credit_Amount;
        imperm += item.f_Impermeable_Credit_Amount;
      })
      setPermCredit(parseFloat(perm.toFixed(2)));
      setImpermCredit(parseFloat(imperm.toFixed(2)));
    }
  },[props.info])

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    let tmp = creditsSTDTypeDetail.slice();
    if (name.includes("Area")) {
      tmp[id].setItem(name, parseFloat(value) ? parseFloat(value) : 0);
    } else {
      tmp[id].setItem(name, value);
    }
    tmp[id].calculateCreditAmount();
    props.info.setItem('creditsSTDTypeDetail', tmp);
    props.info.calculateCreditAmount();
    props.info.updateCreditAllocation();
  }


  return (
    <>
      <AreaInformation
        data={{
          "Net Total Area": s_Current_Impermeable_Area + s_Current_Permeable_Area,
          "Billable Impermeable Area": s_Current_Impermeable_Area,
          "Billable Permeable Area": s_Current_Permeable_Area
        }}
        size={4}
      />

      {creditsSTDTypeDetail.length > 0 && creditsSTDTypeDetail.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Paper elevation={3} style={{ padding: '10px' }}>
            <SubTitle value={`Green Infrastructure Facility Review ${index + 1}`} />
            <Grid container spacing={1}>
              <Grid item xs={12} align="center">
                <TextField
                  fullWidth
                  variant='filled'
                  value={item.f_Facility_Type}
                  name='f_Facility_Type'
                  label="Submitted Facility Type"
                  disabled
                  inputProps={{ style: { color: 'black', backgroundColor: 'whitesmoke' } }}
                >
                </TextField>
              </Grid>

              <Grid item xs={3} align="left">
                <TextField
                  name="f_Credited_Type"
                  variant="filled"
                  label="Submitted Credited Type"
                  fullWidth
                  disabled
                  value={item.f_Credited_Type}
                  inputProps={{ style: { color: 'black', backgroundColor: 'whitesmoke' } }}
                />
              </Grid>

              <Grid item xs={3} align="left">
                <TextField
                  name="r_Credited_Type"
                  variant="outlined"
                  label="Approved Credited Type"
                  onChange={(e) => handleChange(e, index)}
                  disabled={["APPROVED", "REJECTED", "CANCELLED"].includes(decision)}
                  select
                  fullWidth
                  value={item.r_Credited_Type}
                >
                  <MenuItem value="Permeable">Permeable</MenuItem>
                  <MenuItem value="Impermeable">Impermeable</MenuItem>
                  <MenuItem value="None">None</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={6} align="left">
                <TextField
                  name="f_Facility_Size"
                  variant="filled"
                  label="Submitted Facility size"
                  fullWidth
                  value={item.f_Facility_Size}
                  disabled
                  InputProps={{ endAdornment: <InputAdornment position="end">ft²</InputAdornment>, style: { color: 'black', backgroundColor: 'whitesmoke' } }}
                />
              </Grid>
              <Grid item xs={6} align="center">
                <TextField
                  name="f_Impermeable_Area_Drain"
                  variant="filled"
                  label="Submitted Impermeable area managed by facility"
                  fullWidth
                  value={item.f_Impermeable_Area_Drain}
                  disabled
                  InputProps={{ endAdornment: <InputAdornment position="end">ft²</InputAdornment>, style: { color: 'black', backgroundColor: 'whitesmoke' } }}
                />
              </Grid>
              <Grid item xs={6} align="center">
                <TextField
                  name="r_Impermeable_Area_Drain"
                  variant="outlined"
                  label="Approved Impermeable area managed by facility"
                  fullWidth
                  disabled={["APPROVED", "REJECTED", "CANCELLED"].includes(decision)}
                  onChange={(e) => handleChange(e, index)}
                  value={item.r_Impermeable_Area_Drain}
                  InputProps={{ endAdornment: <InputAdornment position="end">ft²</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={6} align="left">
                <TextField
                  name="f_Permeable_Area_Drain"
                  variant="filled"
                  label="Submitted Permeable area managed by facility"
                  helperText='Do not include facility size'
                  fullWidth
                  value={item.f_Permeable_Area_Drain}
                  disabled
                  InputProps={{ endAdornment: <InputAdornment position="end">ft²</InputAdornment>, style: { color: 'black', backgroundColor: 'whitesmoke' } }}
                />
              </Grid>
              <Grid item xs={6} align="center">
                <TextField
                  name="r_Permeable_Area_Drain"
                  variant="outlined"
                  label="Approved Permeable area managed by facility"
                  fullWidth
                  disabled={["APPROVED", "REJECTED", "CANCELLED"].includes(decision)}
                  onChange={(e) => handleChange(e, index)}
                  value={item.r_Permeable_Area_Drain}
                  InputProps={{ endAdornment: <InputAdornment position="end">ft²</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <TextField
                  name="reviewer_Comments"
                  variant="outlined"
                  label="Reviewer Comments"
                  fullWidth
                  disabled={["APPROVED", "REJECTED", "CANCELLED"].includes(decision)}
                  value={item.reviewer_Comments ? item.reviewer_Comments : ""}
                  onChange={(e) => handleChange(e, index)}
                />
              </Grid>
            </Grid>
        
          </Paper>
        </Grid>
      ))
      }
       {creditsSTDTypeDetail &&
          <Grid item xs={12} align="right" >
            <Box p={2}>
              <SubTitle value='Credit Amount Review' align='right'/>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <Typography color="textPrimary">Submitted Impermeable Credit: ${imperm_credit}</Typography>
                  <Typography color="textPrimary">Submitted Permeable Credit: ${perm_credit}</Typography>
                  <Typography color="textPrimary">Submitted Total Credit: ${(imperm_credit + perm_credit).toFixed(2)}</Typography>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <Typography color="textPrimary">Approved Impermeable Credit: ${creditsStandardType.r_Impermeable_Credit_Amount}</Typography>
                  <Typography color="textPrimary">Approved Permeable Credit: ${creditsStandardType.r_Permeable_Credit_Amount}</Typography>
                  <Typography color="textPrimary">Approved Total Credit: ${creditsStandardType.r_Total_Credit_Amount}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          }
    </>
  )
});

export default StandardCreditReviewContent;