import React, { useState, useEffect } from 'react';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { AreaInformation } from '../../data-display/data-display.component.jsx';
import FormInputTypography from '../../form-input/form-input-typography.component.jsx';

const ImpermeableAreaAppeal = (props) => {
  const [data, setData] = useState({
    impermeable_area: "",
    applicant_comments: ""
  })

  const [area, setArea] = useState({
    mapBlkLot: "",
    grossTotalArea: "",
    permeableArea: "",
    impermeableArea: "",
    excludedArea: "",
  });

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }

    if (props.area) {
      setArea(props.area);
    }
  }, [props])

  return (
    <Accordion expanded>
      <AccordionSummary id="inaccurateImperviousAreaAppeal" style={{ backgroundColor: "whitesmoke" }} >
        <Typography variant="h6" align="left" gutterBottom color="primary">
          Enter Information for Error in the assessment of the property's impermeable or permeable areas
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Typography variant="body1" component='span'>
            Impermeable area includes hard surfaces, such as rooftops, concrete, buildings, and pavement. Permeable area allows some stormwater to soak into the ground. Examples of permeable areas are landscaping, grass, dirt, and gravel. <br />
          </Typography>
          <AreaInformation
            data={{
              "Map Block Lot": area.mapBlkLot,
              "Assessor Block Lot": data.s_Assessor_Block_Lot_No,
              "Current Gross Total Area": area.grossTotalArea,
              "Current Excluded Area": area.excludedArea,
              "Current Permeable Area": area.permeableArea,
              "Current Impermeable Area": area.impermeableArea,
            }}
            showTitle={false}
          />
          <FormInputTypography
            label="Inaccurate Impermeable Area Appeal ID"
            size={6}
            value={data.customerAppealId}
          />
          <FormInputTypography
            label="Appealed Impermeable Area"
            size={6}
            value={data.impermeable_area}
          />
          <FormInputTypography
            label="Billable Appealed Impermeable Area"
            size={6}
            value={data.billable_impermeable_area}
          />
          <FormInputTypography
            label="Appealed Permeable Area"
            size={6}
            value={Math.round(data.permeable_area)}
          />
          <FormInputTypography
            label="Applicant Comments"
            size={6}
            value={data?.applicant_comments ? data?.applicant_comments : ""}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ImpermeableAreaAppeal;