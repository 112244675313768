import React, { useEffect, useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import Typography from '@material-ui/core/Typography';
import { Grid, TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';

/* Langan Components */
import FormInputTextField from '../../form-input/form-input-textfield.component';
import { AreaInformation } from '../../data-display/data-display.component';
import EsriMap from "../../map/EsriMap";
import SketchWidget from "../../map/SketchWidget";
import FeatureLayer from "../../map/FeatureLayer";
import { FileUploadButton } from '../../form-input/form-file-upload-button.component';


const ExcludedAreaAppeal = observer((props) => {
  const { expanded,
    handleAccordianChange,
    statusReady, statusChange,
    statusExcluded,
    excluded,
    handleExcludedChange,
    handleExcludedAreaChange,
    setExcludedGraphics,
    excludedFileName,
    excludedFileChange,
    excludedWarning,
    area,
    mapBlock,
    assessorBlock
  } = props;

  const {
    F_Appealed_Excluded_Area,
    F_Appealed_Billable_Impermeable_Area,
    F_Appealed_Impermeable_Area,
    F_Applicant_Comments_Excluded,
    r_Decision,
  } = excluded;
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    if (r_Decision) {
      if (r_Decision === '' || r_Decision === 'pending' || r_Decision === 'inprogress') {
        setStatusMessage(<Typography variant="h6" color="error">There is a current appeal pending. Please contact us at (415)-551-3000 before submitting a new request.</Typography>)
      } else {
        setStatusMessage(<Typography variant="h6" color="error">A previous appeal submission has already been determined for this appeal type within the past 12 months from the date the initial appeal was filed. <br />
          <br />
          The San Francisco Public Utilities Commission considers only one stormwater charge appeal for each appeal type on each parcel annually. Please contact us at stormwaterappeals@sfwater.org for any questions.
        </Typography>)
      }
    } else {
      setStatusMessage(<Typography variant="h6" color="error">There is a current appeal pending. Please contact us at (415)-551-3000 before submitting a new request.</Typography>)
    }
  }, [r_Decision])

  return (
    <Accordion expanded={expanded === 'panel3'} onChange={handleAccordianChange('panel3')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="inaccurateExcludedAreaAppeal" style={{ backgroundColor: "whitesmoke" }} >
        <Typography variant="h6" gutterBottom color="primary">
          <Checkbox
            checked={statusReady}
            disabled={statusChange}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          Enter Information for Error in the assessment of the Excluded Parcel Area
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Typography variant="body1" component={'div'}>
            Excluded Area pertains to parcels or portions of a parcel that do not drain to the SFPUC combined sewer system. Examples of Excluded Area are land that drains to a lake, pond, or to the Bay or Ocean; submerged portions of waterfront parcels; and areas that are managed by their own stormwater management system under a separate permit. Only customers with parcels that meet one of the conditions described above are eligible for the Excluded Parcel Area appeal type. <br />
            <br />
            All appeals for excluded parcel areas require documentation (such as engineering plans or hydraulic flow maps) to demonstrate that stormwater runoff from the appealed area does not flow to the SFPUC combined sewer system. <b>You must provide a map for review of your appealed excluded area.</b>  <br />
            <br />
            Note: Impermeable or permeable area is not relative to the excluded area stormwater charge.<br />
            <br />
          </Typography>

          <Grid item xs={12}>
            {statusExcluded === 'submitted' && statusMessage}
            <Typography variant="body1">
              You may draw on the provided map below to show the excluded area changes or manually fill in the area fields in the form below.
            </Typography>

            <EsriMap screenSize="sketch" itemId={process.env.REACT_APP_WEBMAP_ID} type="static">
              {statusExcluded !== "submitted" &&
                <SketchWidget
                  handleAreaChange={handleExcludedAreaChange}
                  handleGraphicsChange={setExcludedGraphics}
                  parcelId={mapBlock}
                  excluded={true} />
              }
              <FeatureLayer parcelId={mapBlock} navigation={false} />
            </EsriMap>

          </Grid>

          <AreaInformation
            data={{
              "Map Block Lot": mapBlock,
              "Assessor Block Lot": assessorBlock,
              "Current Gross Total Area": area.S_Current_Gross_Total_Area,
              "Current Excluded Area": area.S_Current_Excluded_Area,
              "Current Permeable Area": area.S_Current_Permeable_Area,
              "Current Impermeable Area": area.S_Current_Impermeable_Area,
            }}
            showTitle={false}
          />

          <FormInputTextField
            label="Appealed Excluded Area"
            required={true}
            name="F_Appealed_Excluded_Area"
            value={F_Appealed_Excluded_Area}
            onChange={handleExcludedChange}
            size={5}
            helperText='Enter the area you want to be considered as excluded in square feet.'
            InputProps={{ readOnly: statusChange }}
          />

          {excludedWarning &&
            <Grid item xs={12} align="left" >
              <Typography color="error">{excludedWarning}</Typography>
            </Grid>}
          <Grid item xs={12} align="left">
            <FileUploadButton
              description={"Attach Relevant Documentation To Support Appeal"}
              fileChange={excludedFileChange}
              instruction={'Supporting documents may include; photos, plans, surveys, drainage reports. Please submit all documents as one file, with .pdf, .jpg, or .jpeg extension and with a file size of less than 200MB.'}
              disabled={statusChange} />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField name="F_Applicant_Comments_Excluded" variant="outlined" label="Applicant Comments" fullWidth multiline
              value={F_Applicant_Comments_Excluded} onChange={handleExcludedChange} InputProps={{ readOnly: statusChange }} />
          </Grid>
        </Grid>

      </AccordionDetails>
    </Accordion>

  )
})

export default ExcludedAreaAppeal;