import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

/* React Query */
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

//MobX
import { observer } from "mobx-react";

// Data Stores
import { useRootStore } from "./stores/RootStore";

/* MUI Components */
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

/* Styles */
// import './App.css';
import '@arcgis/core/assets/esri/themes/light/main.css';

// /* Langan Components */
import AppNavigation from './components/app-navigation/app-navigation.component';
import HomePage from './pages/HomePage';
import HelpPage from './pages/HelpPage';
import LoginPage from './pages/LoginPage';
import ResourcesPage from './pages/ResourcesPage';
import MapPage from './pages/MapPage';
import AssessorRouter from './routes/assessor/assessor.router';
import LegalOwnerRouter from './routes/legalowner/legalowner.router';
import CreditRouter from './routes/credit/credit.router';
import ParcelRouter from './routes/parcel/parcel.router';
import SearchRouter from './routes/search/search.router';
import ReportRouter from './routes/report/report.router';
import AdminRouter from './routes/admin/admin.router';
import DashboardRouter from './routes/dashboard/dashboard.router';
import AppealsRouter from './routes/appeals/appeals.router'; 
import AppFooter from './components/app-footer/app-footer.component';


const queryClient = new QueryClient();

const lightTheme = createMuiTheme({
  palette: {
    primary: { main: "#007DC3" },
    secondary: { main: "#BC986A" },
    error: { main: "#BF2620" },
    info: { main: "#002D74" },
    type: "light",
  },
  typography: {
    fontFamily: "Libre Franklin, sans-serif",
    h1: {
      fontFamily: 'Red Hat Display',
      fontWeight: 'bold'
    },
    h2: {
      fontFamily: 'Red Hat Display',
      fontWeight: 'bold'
    },
    h3: {
      fontFamily: 'Red Hat Display',
      fontWeight: 'bold',
      color: '#0A0A0A'
    },
    h5: {
      fontFamily: "Red Hat Display, sans-serif",
      fontWeight: 'bold',
      color: '#0A0A0A'
    },
    h6: {
      color: '#0A0A0A'
    },
    body1: {
      fontFamily: 'Libre Franklin, sans-serif',
      color: '#0A0A0A'
    },
    body2: {
      color: '#0A0A0A'
    },
    button: {
      textTransform: 'Capitalize',
    },
    textfield: {
      color: '#0A0A0A'
    },
  },
  root: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 200px)"
  },
  link: {
    color: "#007DC3",
    fontWeight: 'bold',
    textDecoration: 'underline',
    fontFamily: 'Red Hat Display, sans-serif',
    cursor: 'pointer'
  },
});

const NotFound = () => {
  return (
    <h1>Not Found Page!</h1>
  )
}

export default observer(function App() {
  const root = useRootStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location?.pathname.split('/');
    if (root.role.customer && !root.role.admin && !root.role.business && !root.role.credit && !root.role.viewer && !root.role.user) {
      if (path.includes('search') 
      || path.includes('admin') 
      || path.includes('dashboard')
      || path.includes('assessor')
      || path.includes('legalowner')
      || path.includes('map')
      || path.includes('reports')
      || (path.includes('parcel') && !location?.state)
      ) {
        navigate('/');
      }
    }
  },[root.role, root.role.admin, root.role.business, root.role.credit, root.role.viewer, root.role.user]);

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={lightTheme}>
        <Routes>
          <Route path='/' element={<AppNavigation role={root.role} user={root.user.fullName}/>}>
            <Route index element={<HomePage store={root} />} />
            <Route path='appeals/*' element={<AppealsRouter store={root} />} />
            <Route path='credit/*' element={<CreditRouter store={root} />} />
            <Route path='search/*' element={<SearchRouter role={root.role}/>} /> 
            <Route path='admin/*' element={<AdminRouter />} />
            <Route path='dashboard/*' element={<DashboardRouter role={root.role}/>} />
            <Route path='assessor/*' element={<AssessorRouter store={root} />} />
            <Route path='legalowner/*' element={<LegalOwnerRouter />} />
            <Route path='map' element={<MapPage />} />
            <Route path='parcel/*' element={<ParcelRouter />} />
            <Route path='resources' element={<ResourcesPage />} />
            <Route path='reports/*' element={<ReportRouter />} />
            <Route path='help' element={<HelpPage />} />
            <Route path="login" element={<LoginPage store={root} />} />
            <Route path='*' element={<NotFound />} />
          </Route>
        </Routes>
        <AppFooter />
      </MuiThemeProvider>
    </QueryClientProvider>
  )
});