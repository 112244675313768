import { useEffect, useContext } from "react";
import { MapViewContext } from "../../contexts";

/* Esri */
import Home from "@arcgis/core/widgets/Home";

function HomeWidget() {
  const mapView = useContext(MapViewContext);

  useEffect(() => {
    if (mapView) {
      // Add Home widget to map
      var homeBtn = new Home({
        view: mapView
      });
      mapView.ui.add(homeBtn, "top-right");
    }
  }, [mapView]);

  return (null)
};

export default HomeWidget;
