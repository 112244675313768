import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

/* MUI */
import { Container, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/RateReviewOutlined';

/* Langan Components */
import DashboardTable from './DashboardTable';

import './dashbord.styles.scss';

const PendingAppeals = (props) => {
  let navigate = useNavigate();
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data])

  const appealHeader = [
    { field: 'customerId', headerName: 'Appeal Id', width: 140 },
    { field: 'appealType', headerName: 'Type', width: 200 },
    { field: 'ccbAccount', headerName: 'Account #', width: 150 },
    { field: 'zipcode', headerName: 'Zipcode', width: 130 },
    {
      field: 'appealDate',
      headerName: 'Date',
      width: 140,
      valueFormatter: (params) => {
        return params.value?.substring(0, 10);
      },
    },
    { field: 'reviewerUsername', headerName: 'Reviewer', width: 200 },
    {
      field: 'review',
      headerName: 'Review',
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          navigate(params.value);
        };
        return <IconButton color='primary' aria-label='review' onClick={onClick}>
        <EditIcon />
      </IconButton>
      }
    },
  ];

  return (
    <Container maxWidth="lg" className='dashboard'>
      <DashboardTable
        header={appealHeader}
        data={data}
        filter={{ items: [{ columnField: 'appealType', operatorValue: 'equals' }] }}
        onCellClick={() => {}}
      />
    </Container>
  )
}

export default PendingAppeals;



