import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//MobX
import { observer } from "mobx-react";

//mui
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, Paper, Typography } from '@material-ui/core';

//custom components
import ReviewButton from "../../components/form-input/form-button-credit-review.component";
import CreditInfo from "../../components/credit/credit-review/CreditInfo";
import ReviewInfo from "../../components/credit/credit-review/ReviewInfo";
import { useRootStore } from "../../stores/RootStore";
import { useCredits } from "../../stores/CreditStore/CreditModuleStore";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  formRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: "20px",
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

const CreditReviewPage = observer(() => {
  const classes = useStyles();
  let navigate = useNavigate();
  const credits = useCredits();
  const rootStore = useRootStore();
  const [rate, setRate] = useState('');
  const [decision, setDecision] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    credits.creditReview.submitCreditReview().then(() => {
      navigate("/dashboard");
    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    if (credits) {
      if (credits.creditReview) {
        credits.creditReview.calculateCreditAmount(); //populate approved total credit amount for credit amout review and allocation
        credits.creditReview.updateCreditAllocation();
        if (credits.creditReview.credit_Type === 'Standard') {
          setRate('Standard')
          setDecision(credits.creditReview.creditsStandardType.r_Decision);
        } else {
          setDecision(credits.creditReview.creditsSRType.r_Decision);
          let tmp = credits.creditReview.creditsSRType.tier_Type;
          if (tmp === 'SRT1') {
            setRate('Simplified Residential Tier 1');
          } else if (tmp === 'SRT2') {
            setRate('Simplified Residential Tier 2');
          } else if (tmp === 'SRT3') {
            setRate('Simplified Residential Tier 3');
          }
        }
      }
    }
  }, [credits])

  return (
    <React.Fragment>
      <CssBaseline />
      {(credits && credits.creditReview && rootStore) &&
        <main>
          <div className={classes.heroContent} style={{ backgroundColor: "whitesmoke" }}>
            <Container maxWidth="md">
              <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
                {rate ? rate : ''} Rate Review
              </Typography>
            </Container>
          </div>
          <div >
            <Container maxWidth="md">
              <Paper className={classes.paper}>
                <CreditInfo store={credits.creditReview} rate={rate} />
                <ReviewInfo store={credits.creditReview} role={rootStore.role} decision={decision}/>
                <ReviewButton
                  criteria1={(rootStore.role.credit || rootStore.role.admin) && ["LODGED", "PENDING"].includes(decision)}
                  criteria2={
                    credits.creditReview.credit_Type === 'Standard'
                      ? credits.creditReview.creditsStandardType.r_Decision === 'LODGED'
                      : credits.creditReview.creditsSRType.r_Decision === 'LODGED'
                  }
                  handleClick={handleSubmit}
                />
              </Paper>
            </Container>
          </div>
        </main>}
    </React.Fragment>
  )
})

export default CreditReviewPage;