import React, { Fragment, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Button, Typography, Box, CircularProgress, Grid, TextField, FormControl } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

/* Langan Components */
import { useManualAppeals } from '../../../services/APIClient';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  wrapper: {
    textTransform: 'initial'
  },
  link: theme.link,
  buttonSubmit: {
    height: "56px"
  },
}));

const ManageGenericAppeals = observer(props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [appealId, setAppealId] = useState();
  const [account, setAccount] = useState();
  const [saId, setSaId] = useState();
  const [premiseId, setPremiseId] = useState();
  const [filteredData, setFilteredData] = useState([]); // for search
  const [notFound, setNotFound] = useState(false);
  const { data, isLoading, isError, error, isSuccess } = useManualAppeals();

  const handleAddNewGenericAppeals = () => {
    props.store.initManualAppealForm();
    navigate('./new');
  };

  const filterData = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let tempDate = data.filter(item => item[name]?.toString().includes(value));
    if (tempDate.length === 0) {
      setNotFound(true);
    }
    setFilteredData(tempDate);
  }

  const manualAppealHeader = [
    {
      field: 'manual_Appeal_ID', headerName: 'Manual Appeal ID', width: 200, disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          props.store.initManualAppealView(data.filter(item => item.manual_Appeal_ID === params.id)[0]);
          navigate('./view');
        }
        return <Button color='primary' onClick={onClick}>
          <Typography className={classes.link}>{params.id}</Typography>
        </Button>
      }
    },
    { field: 'account_No', headerName: 'Account #', width: 180 },
    { field: 'map_Block_Lot_No', headerName: 'Mapblocklot', width: 180 },
    { field: 'assessors_BlkLot', headerName: 'Assessor Blklot', width: 180 },
    { field: 'premise_ID', headerName: 'Premise ID', width: 200 },
    { field: 'wastewater_Sa_ID', headerName: 'Wastewater SA ID', width: 200 },
    { field: 'r_Decision', headerName: 'Decision', width: 200 },
    {
      field: 's_Appealed_Date',
      headerName: 'Submission Date',
      width: 200,
      valueFormatter: (params) => {
        return params.value?.substring(0, 10);
      }
    },
  ];

  return (
    <Fragment>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Button variant='contained' color='primary' onClick={handleAddNewGenericAppeals}>
            Add new Manual Appeals
          </Button>
          <Box m={2} />
          <Typography variant="h6" >Manual Appeals</Typography>
          <FormControl fullWidth margin='dense' onKeyPress={(e) => {
          if (e.key === 'Enter') {
            filterData(e);
          }
        }} >
        <Grid container spacing={1} className={classes.form}>
          {/* Search by Customer Appeal ID */}
          <Grid item xs={12}>
            <TextField
              name="manual_Appeal_ID"
              variant="outlined"
              label="Search by Manual Appeal ID"
              type="search"
              error={notFound && appealId}
              helperText={(notFound && appealId) ? 'No matching record is found' : ''}
              fullWidth
              value={appealId}
              onChange={(e) => {
                setNotFound(false);
                setAppealId(e.target.value);
                filterData(e);
              }}
            />
          </Grid>
          {/* Search by Account NO */}
          <Grid item xs={12}>
            <TextField
              name="account_No"
              variant="outlined"
              label="Search by Account No"
              type="search"
              fullWidth
              error={notFound && account}
              helperText={(notFound && account) ? 'No matching record is found': ''}
              value={account}
              onChange={(e) => {
                setNotFound(false);
                setAccount(e.target.value);
                filterData(e);
              }}
            />
          </Grid>
          {/* Search by SA ID */}
          <Grid item xs={12}>
            <TextField
              name="wastewater_Sa_ID"
              variant="outlined"
              label="Search by Wastewater SA ID"
              type="search"
              fullWidth
              error={notFound && saId}
              helperText={(notFound && saId) ? 'No matching record is found': ''}
              value={saId}
              onChange={(e) => {
                setNotFound(false);
                setSaId(e.target.value);
                filterData(e);
              }}
            />
          </Grid>
          {/* Search by premise ID */}
          <Grid item xs={12}>
            <TextField
              name="premise_ID"
              variant="outlined"
              label="Search by Premise ID"
              type="search"
              fullWidth
              error={notFound && premiseId}
              helperText={(notFound && premiseId) ? 'No matching record is found': ''}
              value={premiseId}
              onChange={(e) => {
                setNotFound(false);
                setPremiseId(e.target.value);
                filterData(e);
              }}
            />
          </Grid>
        
        </Grid>
        </FormControl>
          {isSuccess &&
            <DataGrid
              autoHeight
              rows={filteredData.length > 0 ? filteredData : data}
              columns={manualAppealHeader}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize)
              }}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 50]}
              getRowId={(row) => row.manual_Appeal_ID}
            />}
          {isError &&
            <Typography variant="h6" color='error' >There's an unexpected error.</Typography>}
          {isLoading &&
            <Typography variant="h6" color='primary' >Loading...
              <CircularProgress color="inherit" size={20} />
            </Typography>}
        </Paper>
      </Container>
      <Outlet />
    </Fragment>
  )
});

export default ManageGenericAppeals;
