import { types, flow } from 'mobx-state-tree';
import CreditEntryStore from './CreditEntryStore';
import CreditSubmissionStore from './CreditSubmissionStore';
import ReviewCreditStore from './ReviewCreditStore';
import { getCreditByAccountZip, getCreditById, getCreditFile } from '../../services/APIClient';
import { AccountStore } from '../AccountStore';

export const CreditModuleStore = types
.model('CreditModuleStore', {
    creditEntry: types.maybeNull(CreditEntryStore),
    creditReview: types.maybeNull(ReviewCreditStore),
    creditSubmission: types.maybeNull(CreditSubmissionStore),
    accountStore: types.maybeNull(AccountStore),
    isSubmitted: types.optional(types.boolean, false),
})
.actions((self) => ({
    setItem(key, value) {
        self[key] = value;
    },
    initEntryForm(accountStore) {
        if (accountStore.stormwaterChargeType === 'STANDARD') {
            self.creditEntry = CreditEntryStore.create({
                Account_Number: '',
                Zip_Code: '',
                Applicant_Name: '',
                Applicant_Phone: '',
                Applicant_Email: '',
                Credit_Type: 'Standard',
                MapBlockLot: accountStore.mapBlkLot,
                AssessorBlockLot: accountStore.assessorBlkLotList[0].assessorBlkLot,
                S_Current_Impermeable_Area: accountStore.billableImpermeableArea,
                S_Current_Permeable_Area: accountStore.billablePermeableArea,
                S_Submitted_By: '',
                Submittal_Date: '',
                STDdetails: [{
                    f_Impermeable_Area_Drain: 0,
                    f_Impermeable_Credit_Amount: 0,
                    f_Permeable_Area_Drain: 0,
                    f_Permeable_Credit_Amount: 0,
                    f_Facility_Type: '',
                    f_Facility_Size: 0,
                    f_Credited_Type: 'None',
                }],
                Accounts: accountStore.accounts.map((account) => {
                   return  {
                    f_Appealed_Account_No: account.account,
                    f_Appealed_Distb_Percent: 0,
                    f_Distributed_Credit_Amount: 0,
                }}),
                F_Applicant_Comments: '',
                F_Attached_Green_Insfrastructure_Plan: '',
                F_Attached_Green_Insfrastructure_Photo: '',
                F_Attached_Agreement_Doc: '',
                Self_Certified: false,
                generalInfoStatus: false,
                detailsStatus: false,
                allocationStatus: false,
                formReady: false,
                equalSplit: true,
            });
        } else {
            self.creditEntry = CreditEntryStore.create({
                Account_Number: '',
                Zip_Code: '',
                Tier_Type: accountStore.stormwaterChargeType,
                Applicant_Name: '',
                Applicant_Phone: '',
                Applicant_Email: '',
                Credit_Type: accountStore.stormwaterChargeType,
                MapBlockLot: accountStore.mapBlkLot,
                AssessorBlockLot: accountStore.assessorBlkLotList[0].assessorBlkLot,
                S_Current_Impermeable_Area: accountStore.billableImpermeableArea,
                S_Current_Permeable_Area: accountStore.billablePermeableArea,
                S_Submitted_By: '',
                Submittal_Date: '',
                SRDetails: [{
                    source: '',
                    green_Infrastructure_Type: '',
                    f_Area: 0,
                    f_Credit_Amount: 0,
                }],
                Accounts: accountStore.accounts.map((account) => {
                    return  {
                     f_Appealed_Account_No: account.account,
                     f_Appealed_Distb_Percent: 0,
                     f_Distributed_Credit_Amount: 0,
                 }}),
                F_Applicant_Comments: '',
                F_Attached_Green_Insfrastructure_Plan: '',
                F_Attached_Green_Insfrastructure_Photo: '',
                F_Attached_Agreement_Doc: '',
                Self_Certified: false,
                generalInfoStatus: false,
                detailsStatus: false,
                allocationStatus: false,
                formReady: false,
                equalSplit: true,
            });
        }
    },
    resetCredits() {
        self.creditEntry = null;
        self.creditReview = null;
        self.creditSubmission = null;
        self.isSubmitted = false;
    },
    getSubmittedForm: flow(function* getSubmittedForm(params) {
        let data;
        yield getCreditByAccountZip(params)
            .then((res) => {
                data = res.data;
                if (data && data.credit_Type === 'Standard') {
                    if (data.creditsStandardType.r_Decision) {
                        if (data.creditsStandardType.r_Decision === 'APPROVED'
                        || data.creditsStandardType.r_Decision === 'REJECTED'
                        || data.creditsStandardType.r_Decision === 'CANCELLED') {
                            throw new Error('Credits under review');
                        }
                    }
                } else {
                    if (data.creditsSRType.r_Decision) {
                        if (data.creditsSRType.r_Decision === 'APPROVED'
                            || data.creditsSRType.r_Decision === 'REJECTED'
                            || data.creditsSRType.r_Decision === 'CANCELLED') {
                                throw new Error('Credits under review');
                            }
                    }
                }})
            .catch((error) => {
                throw error;
            });
        self.creditReview = ReviewCreditStore.create(data);
        yield getCreditFile(data.credit_Application_ID).then((res) => {
            self.creditReview.setItem('creditsDocument', res.data);
        }).catch((error) => {});
        return data;
    }),
    initReviewCredit: flow(function* initReviewCredit(id) {
        let data;
        yield getCreditById(parseInt(id))
            .then((res) => {
                //console.log(res);
                data = res;
            })
            .catch((error) => {
                throw error;
            });
        self.creditReview = ReviewCreditStore.create(data);
        yield getCreditFile(data.credit_Application_ID).then((res) => {
            self.creditReview.setItem('creditsDocument', res.data);
        }).catch((error) => {});
        return data;


        // const response = yield getCreditById(parseInt(id))
        //     .catch((error) => {
        //         console.log(error);
        //     });
        // console.log(response);
        // self.creditReview = ReviewCreditStore.create(response);
        // return response;
    }),
}));

let _credits;
export const useCredits = () => {
    if (!_credits) {
        _credits = CreditModuleStore.create({
            creditEntry: null,
            creditReview: null,
            creditSubmission: null,
            isSubmitted: false,
        });
    }
    return _credits;
}