import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';

/* Components */
import { PrimaryStreetTypes } from '../utils/utils';
import { searchAddress, searchAssessorByAssessorBlock } from '../../services/APIClient';
import { SubmitSearchButton } from './SubmitSearchButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
  },
}));

const SearchAddress = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [streetNum, setStreetNum] = useState('');
  const [streetName, setStreetName] = useState('');
  const [streetType, setStreetType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addressData, setAddressData] = useState([]); // search result
  const [page, setPage] = useState(25);

  const addressHeader = [
    {
      field: 'blklot', headerName: 'Blk/lot', width: 120,
      renderCell: (params) => {
        const onClick = () => {
          console.log(params.row);
          const blklot = params.row.blklot.replace("/", "");
          searchAssessorByAssessorBlock(blklot).then((res) => {
            setDialogOpen(false);
            navigate(`/assessor/${blklot}`);
          }).catch((err) => {
            setDialogOpen(true);
          });
        };
        return <Button size="small" color="primary" onClick={onClick}>{params.value}</Button>;
      }
    },
    { field: 'street_num', headerName: 'Street Number', width: 180 },
    { field: 'street_name', headerName: 'Street Name', width: 180 },
    { field: 'street_suffix', headerName: 'Street Type', width: 180 },
    { field: 'alternate_address', headerName: 'Alternate Address', width: 200 },
    { field: 'address2', headerName: 'Address 2', width: 150 },
    { field: 'address3', headerName: 'Address 3', width: 150 },
    { field: 'address4', headerName: 'Address 4', width: 150 },
    { field: 'unit_number', headerName: 'Unit Number', width: 150}
  ];

  const formatData = (data) => {
    const formattedData = data.map((item, index) => {
      return {
        id: index,
        blklot: item.block_num + '/' + item.lot_num,
        street_num: item.street_num,
        street_name: item.street_name,
        street_suffix: item.street_suffix,
        alternate_address: item.alternate_address,
        address2: item.address2,
        address3: item.address3,
        address4: item.address4,
        unit_number: item.unit_number,
      }
    });
    return formattedData;
  };

  const addressSearch = () => {
    setIsLoading(true);
    searchAddress(streetNum, streetName, streetType).then((res) => {
      if (res.status === 200) {
        setAddressData(formatData(res.data));
        setShowTable(true);
        setIsLoading(false);
      } else {
        setShowTable(false);
        setIsLoading(false);
      }
    }).catch((err) => {
      // console.log(err);
      setShowTable(false);
      setIsLoading(false);
    });
  }

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Typography variant="h6">Address Search </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <form autoComplete="off" noValidate onSubmit={addressSearch}>
              <Grid container spacing={1} className={classes.form}>
                <Grid item xs={3}>
                  <TextField name="streetNum" variant="outlined" label="Street Number" fullWidth value={streetNum} onChange={(e) => setStreetNum(e.target.value)} />
                </Grid>
                <Grid item xs={4}>
                  <TextField name="streetNum" variant="outlined" label="Street Name" fullWidth value={streetName} onChange={(e) => setStreetName(e.target.value)} />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    disablePortal
                    id="streetType"
                    options={Object.values(PrimaryStreetTypes)}
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => { setStreetType(newValue) }}
                    renderInput={(params) => (<TextField variant="outlined" {...params} label="Street Type" />)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SubmitSearchButton
                    isLoading={isLoading}
                    onClick={addressSearch}
                    disabled={streetNum === '' || streetName === '' || isLoading}
                  />
                </Grid>
              </Grid>
            </form >

          </Grid>
          {(showTable && !isLoading) &&
            <Grid item xs={12}>
              <DataGrid
                columns={addressHeader}
                rows={addressData}
                pageSize={page}
                onPageSizeChange={(pageSize) => { setPage(pageSize); }}
                autoHeight
                rowsPerPageOptions={[25, 50]}
              />
            </Grid>
          }
          <Dialog onClose={() => setDialogOpen(false)} aria-labelledby="simple-dialog-title" open={dialogOpen}>
            <DialogTitle id="no data found">No Assessor Data Found</DialogTitle>
            <DialogActions ><Button variant='contained' color='primary' onClick={() => setDialogOpen(false)}>Back to Search</Button></DialogActions>
          </Dialog>
        </Grid>
      </Paper>
    </Container>
  )
};

export default SearchAddress;