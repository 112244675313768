import { useEffect, useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, TextField, Box } from '@material-ui/core';
import MuiPhoneNumber from "material-ui-phone-number";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FileUploadButton } from '../../form-input/form-file-upload-button.component';

const LegalOwnerAppeal = observer(({ expanded, handleAccordianChange, statusLegalOwner, statusChange, legalOwner, handleLegalOwnerChange, handleLegalOwnerDateChange, handleLegalOwnerPhoneChange, newOwnerFileName, legalOwnerFileChange, assessorBlock }) => {
  const { F_New_Legal_Owner_Name,
    F_LO_Appeal_Record_Date,
    F_Phone_Number,
    F_New_Owner_Mailing_Address,
    F_New_Owner_Mailing_Address_2,
    F_New_Owner_Mailing_City,
    F_New_Owner_Mailing_State,
    F_New_Owner_Mailing_Zip,
    F_New_Owner_Mailing_Country,
    F_Applicant_Comments_legalOwner,
    F_Is_Government_Entity,
    r_Decision
  } = legalOwner;

  const [helperText, setHelperText] = useState('');
  const [isValidz, setIsValidz] = useState(statusLegalOwner === 'initial')

  // check legal owner info
  useEffect(() => {
    if (statusLegalOwner !== "submitted") {
      if (F_New_Legal_Owner_Name.length > 0 &&
        F_LO_Appeal_Record_Date &&
        F_New_Owner_Mailing_Address.length > 0 &&
        F_New_Owner_Mailing_City.length > 0 &&
        F_New_Owner_Mailing_State.length > 0 &&
        isValidZipCode(F_New_Owner_Mailing_Zip) &&
        F_New_Owner_Mailing_Country.length > 0) {
        statusChange("ready");
      } else {
        statusChange("initial");
      }
    }
  }, [statusLegalOwner, legalOwner])

  const containsOnlyNumber = (str) => {
    return /^[0-9]+$/.test(str);
  }

  const isValidZipCode = (zipcode) => {
    var isValid = false;
    if (zipcode.length == 5) {
      isValid = containsOnlyNumber(zipcode);
    } else if (zipcode.length == 10) {
      var firstFive = zipcode.slice(0, 5);
      var lastFour = zipcode.slice(6);
      isValid = containsOnlyNumber(firstFive) && containsOnlyNumber(lastFour) && zipcode.charAt(5) === '-';
    }
    setIsValidz(isValid);
    if (!isValid) {
      setHelperText('Please enter a correct zipcode');
    } else {
      setHelperText('');
    }
    return isValid;
  }

  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleAccordianChange('panel1')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="incorrectOwnerAppeal" style={{ backgroundColor: "whitesmoke" }}>
        <Typography variant="h6" gutterBottom color="primary">
          <Checkbox
            checked={statusLegalOwner === "submitted" || statusLegalOwner === "ready"}
            disabled={statusLegalOwner === "submitted"}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          Enter Information for Change in Ownership of the Property
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Typography variant="body1" component='div'>
            If a property changes owners, the new owner is responsible for the stormwater charge for the property, unless the property has a service account that is held by another party (such as a renter). SFPUC will automatically update the stormwater service agreement when the change of ownership is recorded with the County Assessor’s Office; however, there could be a multiple-month delay. If you recently changed ownership, please file a Change of Legal Ownership Appeal for the Property.
            <Box sx={{ fontWeight: 'bold' }}>Along with the application, please include a copy of the grant deed documenting the property transfer and recordation date. </Box>
          </Typography>
          <Grid item xs={12} style={{ marginTop: '20px' }} align="center">
            <Typography variant="h6" color="textPrimary">
              {assessorBlock ? assessorBlock : ""}
            </Typography>
            <Typography variant="body2">Assessor Block Lot</Typography>
          </Grid>
          {statusLegalOwner === "submitted" &&
            <Grid item xs={12} style={{ marginTop: '20px' }} align="left">
              {(r_Decision === '' || r_Decision === 'pending' || r_Decision === 'inprogress')
                ?
                <Typography variant="h6" color="error">
                  There is a current appeal pending. Please contact us at (415)-551-3000 before submitting a new request.</Typography>
                :
                <Typography variant="h6" color="error">A previous appeal submission has already been determined for this appeal type within the past 12 months from the date the initial appeal was filed. <br />
                  <br />
                  The San Francisco Public Utilities Commission considers only one stormwater charge appeal for each appeal type on each parcel annually. Please contact us at stormwaterappeals@sfwater.org for any questions.
                </Typography>}
            </Grid>}

          <Grid item xs={12} align="left">
            <FileUploadButton
              description={"Attach Copy of a grant deed documenting the property transfer"}
              fileChange={legalOwnerFileChange}
              disabled={statusLegalOwner === "submitted"} />
          </Grid>
          <Grid item xs={5} align="left" display='inline'>
            <Checkbox
              color="primary"
              onChange={handleLegalOwnerChange}
              name="F_Is_Government_Entity"
              disabled={statusLegalOwner === "submitted"}
              checked={F_Is_Government_Entity}
            />
            <Typography display='inline'>Is Government Entity</Typography>
          </Grid>

          <Grid item xs={12} align="center">
            <TextField name="F_New_Legal_Owner_Name" variant="outlined" label="Appealed Legal Owner Name" required fullWidth
              value={F_New_Legal_Owner_Name} onChange={handleLegalOwnerChange} InputProps={{ readOnly: statusLegalOwner === "submitted" }} />
          </Grid>

          <Grid item xs={6} align="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar inputVariant="outlined" format="MM/dd/yyyy" margin="normal" id="date-picker-inline" label="Record Date" KeyboardButtonProps={{ 'aria-label': 'change date', }} required fullWidth
                value={F_LO_Appeal_Record_Date} onChange={handleLegalOwnerDateChange}
                InputProps={{ readOnly: statusLegalOwner === "submitted" , style: { color: 'black' }}} disabled={statusLegalOwner === "submitted"}/>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} align="center">
            <MuiPhoneNumber variant="outlined" defaultCountry={'us'} regions={['north-america', 'central-america']} countryCodeEditable={false} label="Phone Number" fullWidth
              value={F_Phone_Number} onChange={handleLegalOwnerPhoneChange} InputProps={{ readOnly: statusLegalOwner === "submitted" }} />
          </Grid>

          <Grid item xs={12} align="center">
            <TextField name="F_New_Owner_Mailing_Address" variant="outlined" label="New Owner Mailing Address" required fullWidth
              value={F_New_Owner_Mailing_Address} onChange={handleLegalOwnerChange} InputProps={{ readOnly: statusLegalOwner === "submitted" }} />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField name="F_New_Owner_Mailing_Address_2" variant="outlined" label="New Owner Mailing Address 2" fullWidth
              value={F_New_Owner_Mailing_Address_2} onChange={handleLegalOwnerChange} InputProps={{ readOnly: statusLegalOwner === "submitted" }} />
          </Grid>

          <Grid item xs={6} align="center">
            <TextField name="F_New_Owner_Mailing_City" variant="outlined" label="New Owner Mailing City" required fullWidth
              value={F_New_Owner_Mailing_City} onChange={handleLegalOwnerChange} InputProps={{ readOnly: statusLegalOwner === "submitted" }} />
          </Grid>

          <Grid item xs={6} align="center">
            <TextField name="F_New_Owner_Mailing_State" variant="outlined" label="New Owner Mailing State" required fullWidth
              value={F_New_Owner_Mailing_State} onChange={handleLegalOwnerChange} InputProps={{ readOnly: statusLegalOwner === "submitted" }} />
          </Grid>
          <Grid item xs={6} align="center">
            <TextField
              name="F_New_Owner_Mailing_Zip"
              variant="outlined"
              label="New Owner Mailing Zip"
              required
              fullWidth
              error={!isValidz}
              helperText={helperText}
              //type="number"
              value={F_New_Owner_Mailing_Zip}
              onChange={handleLegalOwnerChange}
              InputProps={{ readOnly: statusLegalOwner === "submitted" }}
            />
          </Grid>

          <Grid item xs={6} align="center">
            <TextField name="F_New_Owner_Mailing_Country" variant="outlined" label="New Owner Mailing Country" required fullWidth
              value={F_New_Owner_Mailing_Country}
              onChange={handleLegalOwnerChange}
              InputProps={{ readOnly: statusLegalOwner === "submitted" }}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField name="F_Applicant_Comments_legalOwner" variant="outlined" label="Applicant Comments" fullWidth multiline
              value={F_Applicant_Comments_legalOwner} onChange={handleLegalOwnerChange} InputProps={{ readOnly: statusLegalOwner === "submitted" }} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
})

export default LegalOwnerAppeal;