import { Fragment, useState } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Grid, Button } from '@material-ui/core';

/* Langan Components */
import CreditInfo from '../../components/credit/credit-info/credit-info';
import CreditSignIn from '../../components/credit/credit-signin/credit-signin';

const useStyles = makeStyles((theme) => ({
  root: theme.root,
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  btnSpacing: {
    marginTop: "10px",
  },
  logo: {
    height: '30px',
    width: '30px'
  },
  wrapper: {
    textTransform: 'initial'
  }
}));

const CreditPage = observer(props => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <CssBaseline />
      <main className={classes.root}>
        {/* Hero unit */}
        <div className={classes.heroContent} style={{ backgroundColor: "whitesmoke" }}>
          <Grid container direction="column" alignItems="center" justify="center">
            <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
              Stormwater Credit Program
            </Typography>
            <Grid item xs={12} align="center" style={{ marginTop: "20px" }} >
              <Button color="primary" variant="contained" onClick={() => setOpen(true)} >Stormwater Credit Application</Button>
              <CreditSignIn open={open} handleClose={handleClose} store={props.store}/>
            </Grid>
          </Grid>
        </div>
        <CreditInfo open={open} setOpen={setOpen}/>
      </main>
    </Fragment>
  );
});

export default CreditPage;
