import { Routes, Route } from 'react-router-dom';
import LegalOwnerPage from '../../pages/LegalOwnerPage';

const LegalOwnerRouter = () => {
  return (
    <Routes>
      <Route path=':id' element={<LegalOwnerPage />} />
    </Routes>
  )
}

export default LegalOwnerRouter;