import { useEffect, useContext, useState } from "react";
import { MapViewContext } from "../../contexts";

/* React Router */
import { useNavigate } from "react-router-dom";

function FeatureLayer(props) {
    // const map = useContext(MapContext);
    const mapView = useContext(MapViewContext);
    const [parcelLayer, setParcelLayer] = useState(null);

    let navigate = useNavigate();
    let highlight;

    useEffect(() => {
        if (mapView) {
            //const parcel = mapView.map.layers.getItemAt(6);
            const parcel = mapView.map.layers.find(layer => layer.title === "Parcels");
            setParcelLayer(parcel);
        }
    }, [mapView])

    // highlight selected parcel and zoom into it
    useEffect(() => {
        if (mapView && parcelLayer && props.parcelId) {
            mapView.whenLayerView(parcelLayer).then((layerView) => {
                const query = parcelLayer.createQuery();
                query.outSpatialReference = mapView.spatialReference
                query.where = `mapblklot = '${props.parcelId}'`;
                parcelLayer.queryFeatures(query).then((result) => {
                    if (highlight) {
                        highlight.remove();
                    }
                    layerView.view.highlightOptions = {
                        fillOpacity: 0
                    };
                    highlight = layerView.highlight(result.features);
                    mapView.goTo(result.features[0].geometry.extent);
                })
            });
        };

        if (mapView && parcelLayer && props.parcelId && !props.navigation) {
            mapView.when(disableZooming);
        }
    }, [mapView, parcelLayer, props.parcelId, props.navigation]);

    // Set Popups
    useEffect(() => {
        if (parcelLayer && props.popupTemplate) {
            parcelLayer.popupTemplate = JSON.parse(JSON.stringify(props.popupTemplate));
            parcelLayer.popupEnabled = true;
        }
    }, [parcelLayer, props.popupTemplate]);

    // Setup Popup Action
    useEffect(() => {
        mapView?.popup.on("trigger-action", function (event) {
            // Execute the measureThis() function if the measure-this action is clicked
            if (event.action.id === "view-parcel") {
                const path = `/parcel/${mapView?.popup.selectedFeature.attributes.mapblklot}`;
                navigate(path);
            }
        });
    }, [mapView]);

    const disableZooming = (view) => {
        view.popup.dockEnabled = true;

        // Removes the zoom action on the popup
        view.popup.actions = [];

        // stops propagation of default behavior when an event fires
        const stopEvtPropagation = (event) => {
            event.stopPropagation();
        };

        // exlude the zoom widget from the default UI
        view.ui.components = ["attribution"];

        // disable mouse wheel scroll zooming on the view
        view.on("mouse-wheel", stopEvtPropagation);

        // disable zooming via double-click on the view
        view.on("double-click", stopEvtPropagation);

        // disable zooming out via double-click + Control on the view
        view.on("double-click", ["Control"], stopEvtPropagation);

        // disables pinch-zoom and panning on the view
        // view.on("drag", stopEvtPropagation);

        // disable the view's zoom box to prevent the Shift + drag
        // and Shift + Control + drag zoom gestures.
        view.on("drag", ["Shift"], stopEvtPropagation);
        view.on("drag", ["Shift", "Control"], stopEvtPropagation);

        // prevents zooming with the + and - keys
        view.on("key-down", (event) => {
            const prohibitedKeys = ["+", "-", "Shift", "_", "=", "ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"];
            const keyPressed = event.key;
            if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                event.stopPropagation();
            }
        });

        return view;
    }

    return null;
};

export default FeatureLayer;
